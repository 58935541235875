import React from 'react'
import './Header.css';
//import {getCurrentUser} from "../_services";
import {getCurrentUser} from "../_services/authService";
import {mannschatsService} from "../_services/mannschatsService";
// The Header creates links that can be used to navigate
// between routes.
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {notification} from "antd";
import {setErrorState} from "../util/ErrorUtil";
import {userService} from "../_services";
import {vereinsService} from "../_services/vereinsService";
import {abstimmungService} from "../_services/abstimmung/abstimmungService";
import {getRedirectUrlForNutzerInfo} from "../util/StartseiteUtil";


class Header extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: {},
      mannschaftenOnlyAdmin: [],
      mannschaftenAll: [],
      nutzerRedirectInfo: {}
    };
    
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadUser();
    }
  }
  
  handleClick() {
    this.props.onLogout();
  }

  
  loadUser(){
    getCurrentUser()
    .then(response => {
      this.setState({
        loggedInUser: response
      });
      
      if(response && response.id > 0){
        const nutzerId = response.id;

        userService.getAllRedirectInformationForNutzer(nutzerId)
            .then(response => {
              this.setState({
                nutzerRedirectInfo: response
              });
            })
            .catch(error => {
              if (this._isMounted) {
                this.setState({
                  //kein Fehler wegen der Startseite setzen
                  //ist keine nutzerRedirectInfo gesetzt, wuerde sonst auch ein Fehler kommen, weil NULL geliefert wird vom REST Service
                  //hasError: error,
                  nutzerRedirectInfo: {}
                });
              }
            });
        
        
        //Mannschaften laden
          //nur die Mannschaften, fuer die der Nutzer Admin ist
          mannschatsService.getMannschaftenForNavigationAdminRightsOnly(nutzerId)
              .then(response => {
                if (this._isMounted) {
                  this.setState({
                    mannschaftenOnlyAdmin: response
                  });
                }
              })
              .catch(error => {
                if (this._isMounted) {
                  this.setState({hasError: error})
                  notification.error({
                    message: 'Mannschatsplanung',
                    description: 'Fehler beim Laden der Mannschaften: ' + error.message
                  });
                }
              });
          
          //alle Mannschaften des Nutzers:
          //  - alle Mannschaften in denen der uebergebene Nutzer einen Spieler hat
          //  - alle Mannschaften, fuer die der Nutzer VIEW-Rechte hat
          //  - alle Mannschaften, fuer die der Nutzer ADMIN-Rechte hat     
          mannschatsService.getMannschaftenForNavigationViewRightsOrHigher(nutzerId)
              .then(response => {
                if (this._isMounted) {
                  this.setState({
                    mannschaftenAll: response
                  });
                }
              })
              .catch(error => {
                if (this._isMounted) {
                  this.setState({hasError: error})
                  notification.error({
                    message: 'Mannschatsplanung',
                    description: 'Fehler beim Laden der Mannschaften: ' + error.message
                  });
                }
              });


        //Vereine fuer das Vereins DropsDown laden
        //sieht nur der admin Nutzer
        vereinsService.findAllForNavigation(nutzerId)
            .then(response => {
              this.setState({
                vereine: response
              });
            })
            .catch(error => {
              if (this._isMounted) {
                setErrorState(error, this);
              }
            });
        }
      
    })
    .catch(error => {
      if (this._isMounted) {
        console.log('error 2');
        console.log(error);
        this.setState({
          hasError: error,
          loggedInUser: {}
        });
      }
    });

  }
  
  filterMannschaftenForPunktspielNav(mannschaften){
    return mannschaften.filter(mannschaft => mannschaft.spieltPunktspiele);
  }

  filterMannschaftenForTrainingNav(mannschaften){
    return mannschaften.filter(mannschaft => mannschaft.machtTraining);
  }
  
  render()
  {
    const { loggedInUser, nutzerRedirectInfo } = this.state;
    
    let logoutLink = null;
    if(!loggedInUser.id){
      logoutLink = <a className="btn btn-primary ml-auto mr-1" href="/login">Login</a>
    }

    var homeLink = getRedirectUrlForNutzerInfo(nutzerRedirectInfo);
    
    if(homeLink === undefined){
      homeLink ="/";
    }
    
    return (
       
        <div id="nav">
          <Navbar bg="light" expand="lg" className="parent-container-nav">
            <Navbar.Brand href={homeLink}>Trainings-App</Navbar.Brand>
            {logoutLink}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">

              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
              <VereineVerwaltenNav vereine={this.state.vereine} />
              }
              
              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
              <MannschaftenListeNav title="Mannschaften verwalten" url={"/verwaltung/mannschaft/edit"}
                                    mannschaften={this.state.mannschaftenOnlyAdmin} />
              }
              
              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
              <MannschaftenListeNav title="Mannschaftstraining" url={"/training/uebersicht"} mannschaften={this.filterMannschaftenForTrainingNav(this.state.mannschaftenAll)}/>
              }

              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
              <MannschaftenListeNav title="Punktspiele" url={"/punktspiele/uebersicht"} mannschaften={this.filterMannschaftenForPunktspielNav(this.state.mannschaftenAll)}/>
              }

              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
              <NavDropdown title="Matches" id="basic-nav-dropdown">
                <NavDropdown.Item key="Statistik"
                                  href={"/matches/statistik"}>Match-Statistik</NavDropdown.Item>
                <NavDropdown.Item key="Filter-Statistik"
                                  href={"/matches/bilanzen"}>Match-Bilanzen</NavDropdown.Item>
                <NavDropdown.Item key="Filter-Liste"
                                  href={"/matches/list"}>Match-Liste</NavDropdown.Item>
              </NavDropdown>
              }

              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
                <WettkampfNav loggedInUser={this.state.loggedInUser} mannschaften={this.state.mannschaftenAll}/>
              }

              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
                <AbstimmungsNav loggedInUser={this.state.loggedInUser}/>
              }

              {!!this.state.loggedInUser && !!this.state.loggedInUser.id &&
                <Nav.Link className="mr-sm-2" href="/termine/nutzer">Termine</Nav.Link>
              }
            </Nav>
           
              
              {!!this.state.loggedInUser && this.state.loggedInUser.id &&
              <Nav>
                <ProfilNav loggedInUser={this.state.loggedInUser} onLogout={this.handleClick}/>
              </Nav>
              }
              

            <Nav>
              <Nav.Link className="mr-sm-2" href="/impressum">Impressum</Nav.Link>
            </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

    );
  }

}


// tag::VereineVerwaltenNav[]
// Vereine verwalten Menupunkt
class VereineVerwaltenNav extends React.Component{
  _isMounted = false;

  render() {
    const vereine = this.props.vereine;
    return (
        <div>
          {!!vereine && !!vereine.length && !!vereine.length > 0 &&
          <NavDropdown title="Vereine verwalten" id="basic-nav-dropdown">
            <NavDropdown.Item key="new_verein" href={'/verwaltung/verein/new'}>neuen Verein anlegen</NavDropdown.Item>
            <NavDropdown.Divider/>
            {vereine.map((verein, index) =>
                <NavDropdown.Item key={verein.id} href={'/verwaltung/verein/edit/' + verein.id}>{verein.name}</NavDropdown.Item>
            )}
          </NavDropdown>
          }
        </div>
    )
  }
}
// end::VereineVerwaltenNav[]

// tag::MannschaftenListeNav[]
// Mannschaften verwalten Menupunkt
class MannschaftenListeNav extends React.Component{
  _isMounted = false;

  render() {
    const mannschaften = this.props.mannschaften;
    
    return (
        <div>
          {!!this.props.mannschaften && !!this.props.mannschaften.length && !!this.props.mannschaften.length > 0 && 
          <NavDropdown title={this.props.title} id="basic-nav-dropdown">
            {mannschaften.map((mannschaft, index) =>
                <NavDropdown.Item key={mannschaft.id} href={`${this.props.url}/${mannschaft.id}`}>{mannschaft.name}</NavDropdown.Item>
            )}
          </NavDropdown>
          }
        </div>
    )
  }
}
// end::MannschaftenListeNav[]


// tag::ProfilNav[]
// Profil Menupunkt
class ProfilNav extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);
    
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  handleClick() {
    this.props.onLogout();
  }

  render() {
    return (
        <div>
          {!!this.props.loggedInUser &&
          <NavDropdown title="Profil" id="basic-nav-dropdown">
            <NavDropdown.Item key="matchprofil" href={'/user/profil/matches'}>Matchprofil</NavDropdown.Item>
            <NavDropdown.Item key="spielerprofil" href={'/user/profil/spieler'}>Spielerprofile</NavDropdown.Item>
            <NavDropdown.Item key="profileinstellungen" href={'/user/profil/einstellungen'}>Einstellungen</NavDropdown.Item>
            <NavDropdown.Item key="abmelden" onClick={this.handleClick}>Abmelden</NavDropdown.Item>
          </NavDropdown>
          }
        </div>
    )
  }
}
// end::ProfilNav[]

// tag::WettkampfNav[]
// Wettkampf Menupunkt
class WettkampfNav extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      verein: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadVerein();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  loadVerein() {
    userService.getNutzerById(this.props.loggedInUser.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              verein: response.verein
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render() {
    return (
        <div>
          {!!this.props.loggedInUser && !!this.state.verein && !!this.state.verein.id &&
          <NavDropdown title="Wettkampf" id="basic-nav-dropdown">
            <NavDropdown title="Vorbereitung" id="basic-nav-dropdown">
              <NavDropdown.Item key="doppel-einstellungen" href={'/wettkampf/doppelaufstellung/doppelbewertung/' + this.state.verein.id}>Doppel Einstellungen</NavDropdown.Item>

              <MannschaftenListeNav title="Doppelaufstellung" url={"/wettkampf/doppelaufstellung"}
                                    mannschaften={this.props.mannschaften} renderNewMenuItem={false}/>
            </NavDropdown>

            <NavDropdown title="Erfassung" id="basic-nav-dropdown">
              <NavDropdown.Item key="gegner-erfassung" href={'/wettkampf/gegnererfassunng/' + this.state.verein.id}>Gegnererfassung</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          }
        </div>
    )
  }
}
// end::WettkampfNav[]



// tag::AbstimmungsNav[]
// Abstimmungen Menupunkt
class AbstimmungsNav extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);
 
    this.state = {
      verein: {},
      abstimmungen: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData() {
    abstimmungService.getAllByVereinForNavigation(this.props.loggedInUser.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              abstimmungen: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  render() {
    const { abstimmungen } = this.state;
    return (
        <div>
          {!!this.props.loggedInUser && !!this.state.abstimmungen && !!this.state.abstimmungen.length > 0 &&
          <NavDropdown title="Abstimmungen" id="basic-nav-dropdown">
            {abstimmungen.map((abstimmung, index) =>
                <NavDropdown.Item key={abstimmung.id} href={`/abstimmung/view/${abstimmung.id}`}>{abstimmung.name}</NavDropdown.Item>
            )}
          </NavDropdown>
          }
        </div>
    )
  }
}
// end::AbstimmungsNav[]

export default Header;
