import React from 'react'
import {Button} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import GastHeimLabel from "../../_components/match/GastHeimLabel";
import MatchEinzel from "../../dto/match/MatchEinzel";
import MatchDoppel from "../../dto/match/MatchDoppel";

class MatchList extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      successMsg: '',
      errorMsg: ''
    };
  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  
  render()
  {
    const matches = this.props.matches;
    const loggedInUser = this.props.loggedInUser;
    const duplicateMatches = this.props.duplicateMatches;
    
    let key = "leer_";
    if(matches.length > 0){
      if(matches[0] instanceof MatchEinzel){
        key = "einzel_";
      } else if(matches[0] instanceof MatchDoppel){
        key = "doppel_";
      }
    }

    let duplicateMatchesIds = [];
    if(duplicateMatches && duplicateMatches.length > 0){
      duplicateMatchesIds = duplicateMatches.map((elem) => elem.id);
    }
    
    let matchRows = [];
    for (const match of matches) {
      const isDuplicate = duplicateMatchesIds.includes(match.id);
      matchRows.push(<Match key={key + match.id}
                                      loggedInUser={loggedInUser}
                                      match={match}
                                      onEditMatch={this.props.onEditMatch}
                                      isOnlyView={this.props.isOnlyView}
                                      isDuplicate={isDuplicate}/>)
    }

    
    return (
        <React.Fragment>
          <div id="matches_list">
            {!!matches && !!matches.length <= 0 &&
              <p style={{paddingTop: '1.5rem'}}>Es wurden noch keine Matches gespielt.</p>
            }
            
            {!!matches && !!matches.length > 0 &&
            <table className="table table-hover">
              <tbody>
              {matchRows}
              </tbody>
            </table>
            }
    
          </div>
        </React.Fragment>
    );
  }
}


// tag::employee[]
class Match extends React.Component{
  
  render() {
    let editButton =  <span></span>;
    const match = this.props.match;
    const isDuplicate = this.props.isDuplicate || false;
    
    if(this.props.loggedInUser){
      const loggedInUserId = this.props.loggedInUser.id;
      //nur wenn der eingeloggte Nutzer ein Spieler der Partie war, kann er diese bearbeiten
      if(match.istNutzerBeteiligt(loggedInUserId)){
        editButton =  <Button
            className="btn btn-primary"
            onClick={e => this.props.onEditMatch(match)}>
          <FontAwesomeIcon icon={faPen}  />
        </Button>;
      }
    }

    
    let fontWeightHeim = 'normal';
    let fontWeightGast = 'normal';
    if(match.gewinner === 'HEIM'){
      fontWeightHeim = 'bold';
    }
    if(match.gewinner === 'GAST'){
      fontWeightGast = 'bold';
    }

    return (
        <tr style={ isDuplicate ? { color:'red'} : {}}>
          <td style={{textAlign: 'left'}}>                          
            <Moment format="DD.MM.YYYY">
              {match.datum}
            </Moment>
          </td>
          <td>
            <span style={{fontWeight: fontWeightHeim}}>
              <GastHeimLabel gastHeim={match.getHeim()} />
            </span>
            
          </td>
          <td>
            <span style={{fontWeight: fontWeightGast}}>
              <GastHeimLabel gastHeim={match.getGast()} />
            </span>

          </td>
          <td style={{textAlign: 'left'}}><span>{match.ergebnis}</span></td>
          <td style={{display: this.props.isOnlyView ? "none" : "" }}>
            {!!match && !!match.id && 
              !!this.props.loggedInUser && !!this.props.loggedInUser.id &&
            <React.Fragment>{editButton}</React.Fragment>
            }
          </td>
        </tr>
    )
  }
}
// end::employee[]

export default withRouter(MatchList);
