import '../matchEditAndListTabPaneView.css';
import React from 'react'
import {withRouter} from "react-router-dom";
import {Tab, Tabs} from "react-bootstrap";
import MatchFilterEinzelView from "./MatchFilterEinzelView";
import MatchFilterDoppelView from "./MatchFilterDoppelView";

class MatchFilterTabPanel extends React.Component
{
  _isMounted = false;

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  render()
  {
    return (
        <React.Fragment>
            
            <h1>Match-Liste</h1>
            
            <Tabs defaultActiveKey="einzel" id="matches-filter-statistik-tab-pane" className="matches-tab-pane" style={{fontSize: '1rem'}}>
              <Tab eventKey="einzel" title="Einzel" className="tab-header">
                <MatchFilterEinzelView/>
              </Tab>
              <Tab eventKey="doppel" title="Doppel">
                <MatchFilterDoppelView/>
              </Tab>
            </Tabs>
        </React.Fragment>
    );
  }
}

export default withRouter(MatchFilterTabPanel);
