import { request } from '../../util/APIUtils';
import {API_BASE_URL} from "../../constants";

export const abstimmungTeilnehmerService = {
    getById,
    getAllTeilnehmerForAbstimmungSorted,
    deleteTeilnehmerById
};

export function getById(abstimmungTeilnehmerId)
{
    return request({
        url: API_BASE_URL + "/abstimmungteilnehmer/"  + abstimmungTeilnehmerId,
        method: 'GET'
    });
}

export function getAllTeilnehmerForAbstimmungSorted(abstimmungId)
{
    return request({
        url: API_BASE_URL + "/abstimmungteilnehmer/all/"  + abstimmungId,
        method: 'GET'
    });
}

//loescht den Spieler und all seine Abhaengigkeiten (Punktspiel/Trainigns-Zusagen, Startseite, etc)
export function deleteTeilnehmerById(teilnehmerId)
{
    return request({
        url: API_BASE_URL + "/abstimmungteilnehmer/deleteById/"  + teilnehmerId,
        method: 'DELETE'
    });
}

// erstellt fuer die uebergebenen Nuetzerliste jeweils Spieler und uegt sie der uebergebenen Mannschaft hinzu
export function createAndAddNutzerForAbstimmung(addNutzerToAbstimmungRequest) {
    return request({
        url: API_BASE_URL + "/abstimmungteilnehmer/addNewNutzerToAbstimmung",
        method: 'POST',
        body: JSON.stringify(addNutzerToAbstimmungRequest)
    });
}