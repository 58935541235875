import React from "react";
import styled from "styled-components"


const GridItemWrapper = styled.div`
    flex: 0 0 33.3%;
    display: flex;

`;

export const GridItem = ({ forwardedRef, ...props }) => (
    <GridItemWrapper ref={forwardedRef} {...props} />
);