import { request } from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const punktspielService = {
    getPunktspielById,
    getPunktspieleSortedByDatumForMannschaft,
    savePunktspiel,
    deletePunktspielById,
    getPunktspielAbstimmungForSpieler,
    getAllPunktspielAbstimmungForSpieler,
    getStammspielerPunktspielAntwortenForMannschaft,
    getErsatzspielerPunktspielAntwortenForMannschaft,
    zusagen,
    absagen,
    unsicher,
    savePunktspielKommentar,
    getPunktspielKommentarForMannschaft,
};

export function getPunktspielById(punktspielId)
{
    return request({
        url: API_BASE_URL + "/punktspiele/" + punktspielId,
        method: 'GET'
    });
}

export function getPunktspieleSortedByDatumForMannschaft(mannschaftId)
{
    return request({
        url: API_BASE_URL + "/punktspiele/mannschaft/" + mannschaftId,
        method: 'GET'
    });
}

export function savePunktspiel(punktspielRequest) {
    return request({
        url: API_BASE_URL + "/punktspiele/save",
        method: 'POST',
        body: JSON.stringify(punktspielRequest)
    });
}

export function deletePunktspielById(punktspielId)
{
    return request({
        url: API_BASE_URL + "/punktspiele/delete/"  + punktspielId,
        method: 'DELETE'
    });
}

export function getPunktspielAbstimmungForSpieler(spielerId)
{
    return request({
        url: API_BASE_URL + "/punktspielspieler/overview/" + spielerId,
        method: 'GET'
    });
}

//liefert fuer die nicht beantwortete leere
export function getAllPunktspielAbstimmungForSpieler(spielerId)
{
    return request({
        url: API_BASE_URL + "/punktspielspieler/sieler/all/" + spielerId,
        method: 'GET'
    });
}

export function getStammspielerPunktspielAntwortenForMannschaft(mannschaftId)
{
    return request({
        url: API_BASE_URL + "/punktspielspieler/overview/aggregated/" + mannschaftId + "?onylErsatz=" + false,
        method: 'GET'
    });
}

export function getErsatzspielerPunktspielAntwortenForMannschaft(mannschaftId)
{
    return request({
        url: API_BASE_URL + "/punktspielspieler/overview/aggregated/" + mannschaftId + "?onylErsatz=" + true,
        method: 'GET'
    });
}

export function zusagen(punktspielId, spielerId)
{
    return antworten(punktspielId, spielerId, 'zusagen');
}

export function absagen(punktspielId, spielerId)
{
    return antworten(punktspielId, spielerId, 'absagen');
}

export function unsicher(punktspielId, spielerId)
{
    return antworten(punktspielId, spielerId, 'unsicher');
}

export function antworten(punktspielId, spielerId, antwort)
{
    return request({
        url: API_BASE_URL + "/punktspielspieler/punktspiele/" + antwort + "/?punktspielId=" + punktspielId + "&spielerId=" + spielerId,
        method: 'GET'
    });
}


export function getPunktspielKommentarForMannschaft(mannschaftsId)
{
    return request({
        url: API_BASE_URL + "/punktspiele/punktspielKommentarForMannschaft/" + mannschaftsId,
        method: 'GET'
    });
}

export function savePunktspielKommentar(punktspielKommentarRequest) {
    return request({
        url: API_BASE_URL + "/punktspiele/savePunktspielKommentar/",
        method: 'POST',
        body: JSON.stringify(punktspielKommentarRequest)
    });
}

