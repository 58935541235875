import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const matchDoppelService = {
    getMatchById,
    findAllOrderByDatum,
    saveMatch,
    findAlreadyExistent,
    filterMatches,
    filterMatchesStatistik,
    findMatchesByNutzerId,
    getStatistikForMannschaft,
    getJederMitJedemStatistikForMannschaft,
    getJederMitJedemStatistikForTraining
};

export function getMatchById(matchId)
{
    return request({
        url: API_BASE_URL + "/matchdoppel/"  + matchId,
        method: 'GET'
    });
}

export function findAllOrderByDatum()
{
    return request({
        url: API_BASE_URL + "/matchdoppel/list",
        method: 'GET'
    });
}

export function saveMatch(matchRequest) {
    return request({
        url: API_BASE_URL + "/matchdoppel/save",
        method: 'POST',
        body: JSON.stringify(matchRequest)
    });
}

export function filterMatches(matchFilterRequest)
{
    return request({
        url: API_BASE_URL + "/matchdoppel/filterListe",
        method: 'POST',
        body: JSON.stringify(matchFilterRequest)
    });
}

export function filterMatchesStatistik(matchFilterRequest)
{
    return request({
        url: API_BASE_URL + "/matchdoppel/filterStatistik",
        method: 'POST',
        body: JSON.stringify(matchFilterRequest)
    });
}

export function findMatchesByNutzerId(nutzerId)
{
    return request({
        url: API_BASE_URL + "/matchdoppel/findByNutzer?nutzerId="  + nutzerId,
        method: 'GET'
    });
}
export function getStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/matchdoppel/getStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET'
    });
}

export function getJederMitJedemStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }
    
    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }
    
    return request({
        url: API_BASE_URL + "/matchdoppel/getJederMitJedemStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET'
    });
}

export function getJederMitJedemStatistikForTraining(trainingsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }
    
    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    filterParams = filterParams + '&filterDoppelpartner=' + false;
    
    return request({
        url: API_BASE_URL + "/matchdoppel/getJederMitJedemStatistikForTraining?trainingsId="  + trainingsId + filterParams,
        method: 'GET'
    });
}


export function findAlreadyExistent(matchRequest)
{
    let id = matchRequest.id;
    if(id === undefined){
        id = 0;
    }
    let requestParams = '?id=' + id;
    requestParams = requestParams + '&nutzerHeimId=' + matchRequest.nutzerHeimId;
    requestParams = requestParams + '&nutzerHeim2Id=' + matchRequest.nutzerHeim2Id;
    requestParams = requestParams + '&nutzerGastId=' + matchRequest.nutzerGastId;
    requestParams = requestParams + '&nutzerGast2Id=' + matchRequest.nutzerGast2Id;
    requestParams = requestParams + '&ergebnis=' + matchRequest.ergebnis;
    requestParams = requestParams + '&datum=' + matchRequest.datum;

    return request({
        url: API_BASE_URL + "/matchdoppel/findAlreadyExistent"  + requestParams,
        method: 'GET'
    });
}
