import React from 'react';
import MyApp from "./MyApp";
import {DndProvider} from "react-dnd";
import {GridProvider} from "./GridContext";
import {HTML5Backend} from "react-dnd-html5-backend";


class MyDnD extends React.Component {
    _isMounted = false;


    constructor(props) {
        super(props);

        this.state = {
            spieler1: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler2: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },

        };

    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        //const { items, moveItem } = useContext(GridContext);

        return (
            <DndProvider backend={HTML5Backend}>
                <GridProvider>
            <MyApp />
                </GridProvider>
            </DndProvider>
        )
    }
}



export default MyDnD;