import '../matchEditAndListTabPaneView.css';
import React from 'react'
import {withRouter} from "react-router-dom";
import MatchListFilterStatistikComponent from "./match_list_filter_statistik_component";

class MatchFilterStatistikEinzelView extends React.Component
{
  _isMounted = false;

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }



  
  render()
  {
    return (
        <MatchListFilterStatistikComponent isDoppel={false}/>
    );
  }
}

export default withRouter(MatchFilterStatistikEinzelView);
