import React, {Component} from 'react';
import {checkEmailAvailability, invite} from '../../_services/authService';
import './Signup.css';
import './invitation_new.css';
import {setErrorStateWithMessage} from '../../util/ErrorUtil';
import {EMAIL_MAX_LENGTH, USERNAME_MAX_LENGTH} from '../../constants';

import {Button} from 'react-bootstrap';
//import { Form, Input, Button, notification } from 'antd';
import {Form, Input} from 'antd';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import BackButton from "../../common/BackButton";

const FormItem = Form.Item;

//Das Formular, welches der Admin ausfuellt, um einen neuen Spieler einzuladen.
//Beim Abschicken des Formulares, wird eine E-Mail an diesen Nutzer mit einer Einladung geschickt.
//Zeitgleich wurde bereits ein Nutzer mit einem Spieler fuer die entsprechende Mannschaft angelegt. Dieser
//Nutzer ist aber noch als inaktiv markiert, und wird erst durch die Annahme der Einladung aktiviert.
class InvitationSpieler extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            disableSubmitButton: false,
            mannschaft: this.props.mannschaft,
            vorname: {
                value: ''
            },
            nachname: {
                value: ''
            },
            nickname: {
                value: ''
            },
            email: {
                value: '',
                validateStatus: 'success',
                errorMsg: null
            },
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSend = this.onSend.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);

    }
    
    handleInputChange(event, validationFun, label) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue, label)
            }
        });
    }

    onSend() {
     
        this.setState({
            successMsg: null,
            errorMsg: null,
            disableSubmitButton: true
        });

        //TODO: ich glaube das bringt nix. da nigends mehr isFormInvalid aufgerufen wird
        this.validateEmail();
        
        document.body.classList.add('busy-cursor');
        
        const inviteRequest = {
            email: this.state.email.value,
            vorname: this.state.vorname.value,
            nachname: this.state.nachname.value,
            nickname: this.state.nickname.value,
            mannschaft: this.state.mannschaft
        };
        
        invite(inviteRequest)
        .then(response => {
            this.setState({
                successMsg: "Die Einladung wurde an die angegebene E-Mail-Adresse des Spielers verschickt!"
            });
            
            document.body.classList.remove('busy-cursor');
            this.setState({
                disableSubmitButton: false
            });
            
        }).catch(error => {
            setErrorStateWithMessage("Die Einladungs-Mail konnte nicht versendet werden. Versuchen Sie es erneut oder benachrichtigen Sie den Administrator.", this);
            console.log(error);
            document.body.classList.remove('busy-cursor');
            this.setState({
                disableSubmitButton: false
            });
        });
    }

    isFormInvalid() {
        var invalid = this.areFormInputsInvalid();
        return invalid;
    }
    
    areFormInputsInvalid(){
        return !(this.state.vorname.validateStatus === 'success' &&
            this.state.nachname.validateStatus === 'success' &&
            this.state.nickname.validateStatus === 'success' &&
            this.state.email.validateStatus === 'success'
        );
    }

    toButtonDisable() {
        return (this.areFormInputsInvalid());
    }

    
    render() {
        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };
        
        return (
            <div className="invitation-new-container">
                {!!this.state.successMsg &&
                <SuccessMessage message={this.state.successMsg}/>
                }

                {!!this.state.errorMsg &&
                <ErrorMessage message={this.state.errorMsg}/>
                }
                
                <div className="invitation-container">
                    <Form {...layout} className="signup-form">
                        <FormItem
                            labelAlign="left"
                            label="Vorname"
                            name="vorname"
                            rules={[{
                                      required: true,
                                      message: 'Bitte gib deinen Vornamen an' }]}
                            hasFeedback
                            validateStatus={this.state.vorname.validateStatus}
                            help={this.state.vorname.errorMsg}>
                            <Input 
                                size="large"
                                name="vorname" 
                                autoComplete="on"
                                placeholder="Dein Vorname"
                                value={this.state.vorname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateName, "Vorname")} />
                        </FormItem>
                        <FormItem labelAlign="left"
                                  label="Nachname"
                                  name="nachname"
                                  rules={[{
                                      required: true,
                                      message: 'Bitte gib deinen Nachnamen an' }]}
                                  hasFeedback
                                  validateStatus={this.state.nachname.validateStatus}
                                  help={this.state.nachname.errorMsg}>
                            <Input
                                size="large"
                                name="nachname"
                                autoComplete="on"
                                placeholder="Dein Nachname"
                                value={this.state.nachname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateName, "Nachname")}/>
                        </FormItem>
                        <FormItem labelAlign="left"
                                  label="Nickname"
                                  name="nickname"
                                  rules={[{
                                      required: true,
                                      message: 'Bitte gib deinen Nicknamen an' }]}
                                  hasFeedback
                                  validateStatus={this.state.nickname.validateStatus}
                                  help={this.state.nickname.errorMsg}>
                            <Input
                                size="large"
                                name="nickname"
                                autoComplete="on"
                                placeholder="Dein Nickname"
                                value={this.state.nickname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateName, "Nickname")}/>
                        </FormItem>
                        <FormItem
                            labelAlign="left"
                            label="E-Mail"
                            name="email"
                            rules={[{
                                required: true,
                                message: 'Bitte gib deinen E-Mail-Adresse an' }]}
                            hasFeedback
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input 
                                size="large"
                                name="email" 
                                type="email" 
                                autoComplete="on"
                                placeholder="Deine Email-Adresse"
                                value={this.state.email.value}
                                onBlur={this.validateEmailAvailability}
                                onChange={(event) => this.handleInputChange(event, this.validateEmail)} />    
                        </FormItem>
                    </Form>
                </div>
                <Button type="submit"
                        style={{float: 'right'}}
                        size="large"
                        className="myLargeBtn"
                        onClick={() => this.onSend()}
                        disabled={this.toButtonDisable() || this.state.disableSubmitButton}>Einladung senden</Button>

                <br/>
                <br/>
                <BackButton/>
            </div>
        );
    }

    // Validation Functions

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib deine Email-Adresse an. Die Angabe der Email-Adresse ist pflicht.'                
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Das ist keine valide Email-Adresse.'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Email-Adresse ist zu lang. Es sind maximal ${EMAIL_MAX_LENGTH} Zeichen erlaubt.`
            }
        }

        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    validateName = (name, label) => {
        if(!name) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deinen ${label}n an. Die Angabe des ${label}s ist pflicht.`
            }
        } else if (name.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der ${label} ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }


    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });    
            return;
        }

        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
        .then(response => {
            if(response.available) {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            } else {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'error',
                        errorMsg: 'Ein Konto mit dieser Email-Adresse existiert bereits. Bitte melde ich an.'
                    }
                });
            }
        }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
        });
    }

}

export default InvitationSpieler;