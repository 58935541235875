import React from 'react';
import {spielerService} from "../../_services/spielerService";

import {TIME_REGEX} from "../../constants/regex";
import {setErrorState} from "../../util/ErrorUtil";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import BackButton from "../../common/BackButton";
import {Button} from "react-bootstrap";
import {Form} from "antd";
//import { useNavigate } from 'react-router-dom';

//TODO: auf neues Formular umstellen
// tag::SpielerProfilComponent[]
// Das Profils eines Spielers der Mannschaft
// Das hier ist die eigentliche React-Componente, die ein Spieler-Objekt in den Properties uebergeben bekommt
class SpielerProfilComponent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            errorMsg: null,
            successMsg: null,
            errors: {},
            formIsValid: true,
            showAdminFields: this.props.showAdminFields,
            // Note: think carefully before initializing
            // state based on props!
            spieler: this.props.spieler
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateNickname = this.validateNickname.bind(this);
        this.validatePosition = this.validatePosition.bind(this);
        this.validateVon = this.validateVon.bind(this);
        this.validateSpieltKeinTraining = this.validateSpieltKeinTraining.bind(this);
        this.validateSpieltKeinePunktspiele = this.validateSpieltKeinePunktspiele.bind(this);
        this.validateErsatzTraining = this.validateErsatzTraining.bind(this);
        this.validateErsatzPunktspiel = this.validateErsatzPunktspiel.bind(this);
        this.validateImmerAbsagen = this.validateImmerAbsagen.bind(this);

    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    handleSubmit() {
        this.setState({
            errorMsg: null,
            successMsg: null
        });

        spielerService.saveSpieler(this.state.spieler)
            .then(response => {
                this.setState({
                    successMsg: "Profil erfolgreich gespeichert."
                });
            }).catch(error => {
            setErrorState(error, this);
        });
    }

    validateNickname(event) {
        let errors = {};
        let formIsValid = true;

        const nickname = event.target.value;

        if (nickname.length > 200) {
            formIsValid = false;
            errors.nickname = 'Name ist zu lang. Der Name darf nicht mehr als 200 Zeichen haben.'
        }

        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.nickname = nickname;
        this.setState({spieler: oldSpieler});
    }

    validatePosition(event) {
        let errors = {};
        let formIsValid = true;

        const position = event.target.value;
        
        if (isNaN(position)) {
            formIsValid = false;
            errors.position = 'Die Position muss eine valide Zahl sein.'
        }

        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.position = position;
        this.setState({spieler: oldSpieler});
    }
    
    validateVon(event) {
        let errors = {};
        let formIsValid = true;

        const von = event.target.value;

        if (!von.match(TIME_REGEX)) {
            formIsValid = false;
            errors.von = 'Von entspricht nicht dem geforderten Format. Bitte geben Sie eine Uhrzeit im Format ##:## an.'
        }


        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.von = von;
        this.setState({spieler: oldSpieler});
    }
    
    validateSpieltKeinTraining(event) {
        let errors = {};
        let formIsValid = true;

        const spieltKeinTraining = event.target.checked;

        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.spieltKeinTraining = spieltKeinTraining;
        this.setState({spieler: oldSpieler});
    }

    validateSpieltKeinePunktspiele(event) {
        let errors = {};
        let formIsValid = true;

        const spieltKeinePunktspiele = event.target.checked;

        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.spieltKeinePunktspiele = spieltKeinePunktspiele;
        this.setState({spieler: oldSpieler});
    }
    
    validateErsatzTraining(event) {
        let errors = {};
        let formIsValid = true;

        const ersatzTraining = event.target.checked;

        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.ersatzTraining = ersatzTraining;
        this.setState({spieler: oldSpieler});
    }

    validateErsatzPunktspiel(event) {
        let errors = {};
        let formIsValid = true;

        const ersatzPunktspiel = event.target.checked;

        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.ersatzPunktspiel = ersatzPunktspiel;
        this.setState({spieler: oldSpieler});
    }

    
    validateImmerAbsagen(event) {
        let errors = {};
        let formIsValid = true;

        const immerAbsagen = event.target.checked;

        this.setState({errors: errors});
        this.setState({formIsValid: formIsValid});

        //update the value
        var oldSpieler = this.state.spieler;
        oldSpieler.immerAbsagen = immerAbsagen;
        this.setState({spieler: oldSpieler});
    }

    render() {
        const spieler = this.state.spieler;

        // var history = undefined;
        // if(_isMounted){
        //     let history = useHistory(); 
        // }
        //const history = useHistory();
        //const navigate = useNavigate();
        
        return (
            <React.Fragment>
                
                {!!spieler && !!spieler.id &&
                <Form onFinish={this.handleSubmit}>
                    <fieldset>
                        <legend><h2>{spieler.mannschaft.name}</h2></legend>
                    
                    {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                    }

                    {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                    }

                    <fieldset>
                        {/*<h3>Profil</h3>*/}

                        <div className="form-group">
                            <label>Von</label>
                            <input type="text" name="von" value={this.state.spieler.von}
                                   onChange={this.validateVon} className="form-control"/>
                            <span style={{color: "red"}}>{this.state.errors.von}</span>
                        </div>

                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" name="nickname" value={this.state.spieler.nickname}
                                   required={true}
                                   onChange={this.validateNickname} className="form-control"/>
                            <span style={{color: "red"}}>{this.state.errors.nickname}</span>
                        </div>

                        <div className="form-group" style={{display: this.state.showAdminFields ? 'block' : 'none' }}>
                            <label>Meldelistenposition</label>
                            <input type="text" name="position" value={this.state.spieler.position}
                                   onChange={this.validatePosition} className="form-control"/>
                            <span style={{color: "red"}}>{this.state.errors.position}</span>
                        </div>

                        <div className="form-check" style={{display: this.state.showAdminFields ? 'block' : 'none' }}>
                            <input type="checkbox" name="spieltKeinePunktspiele" value={this.state.spieler.spieltKeinePunktspiele}
                                   onChange={this.validateSpieltKeinePunktspiele}
                                   checked={this.state.spieler.spieltKeinePunktspiele}
                                   className="form-check-input"
                                   id="spieltKeinePunktspiele"/>
                            <label className="form-check-label" htmlFor="spieltKeinePunktspiele">spielt keine Punktspiele</label>
                            <span style={{color: "red"}}>{this.state.errors.spieltKeinePunktspiele}</span>
                        </div>

                        <div className="form-check" style={{display: this.state.showAdminFields ? 'block' : 'none' }}>
                            <input type="checkbox" name="spieltKeinTraining" value={this.state.spieler.spieltKeinTraining}
                                   onChange={this.validateSpieltKeinTraining}
                                   checked={this.state.spieler.spieltKeinTraining}
                                   className="form-check-input"
                                   id="spieltKeinTraining"/>
                            <label className="form-check-label" htmlFor="spieltKeinTraining">spielt kein Training</label>
                            <span style={{color: "red"}}>{this.state.errors.spieltKeinTraining}</span>
                        </div>
                        
                        <div className="form-check" style={{display: this.state.showAdminFields ? 'block' : 'none' }}>
                            <input type="checkbox" name="ersatzTraining" value={this.state.spieler.ersatzTraining}
                                   onChange={this.validateErsatzTraining}
                                   checked={this.state.spieler.ersatzTraining}
                                   className="form-check-input"
                                   id="ersatzTraining"/>
                            <label className="form-check-label" htmlFor="ersatzTraining">Ersatz Training</label>
                            <span style={{color: "red"}}>{this.state.errors.ersatzTraining}</span>
                        </div>

                        <div className="form-check" style={{display: this.state.showAdminFields ? 'block' : 'none' }}>
                            <input type="checkbox" name="ersatzPunktspiel" value={this.state.spieler.ersatzPunktspiel}
                                   onChange={this.validateErsatzPunktspiel}
                                   checked={this.state.spieler.ersatzPunktspiel}
                                   className="form-check-input"
                                   id="ersatzPunktspiel"/>
                            <label className="form-check-label" htmlFor="ersatzPunktspiel">Ersatz Punktspiel</label>
                            <span style={{color: "red"}}>{this.state.errors.ersatzPunktspiel}</span>
                        </div>
                        
                        <div className="form-check">
                            <input type="checkbox" name="immerAbsagen" value={this.state.spieler.immerAbsagen}
                                   onChange={this.validateImmerAbsagen}
                                   checked={this.state.spieler.immerAbsagen}
                                   className="form-check-input"
                                   id="immerAbsagen"/>
                            <label className="form-check-label" htmlFor="immerAbsagen">immer als 'abgesagt'
                                markieren</label>
                            <span style={{color: "red"}}>{this.state.errors.immerAbsagen}</span>
                        </div>
                    </fieldset>

                    <br/>

                    <fieldset style={{display: 'none'}}>
                        {/*<h3>Einstellungen für Benachritigungen</h3>*/}

                        <div className="form-group">
                            <label>Mobilnummer</label>
                            <input type="text" name="phonenumber"
                                   value={this.state.spieler.nutzer.phonenumber}
                                   onChange={this.validatePhonenumber} placeholder="0176###########"
                                   className="form-control"/>
                            <span style={{color: "red"}}>{this.state.errors.phonenumber}</span>
                        </div>

                        <div className="form-check">
                            <input type="checkbox" name="receiveResponseMessages"
                                   value={this.state.spieler.receiveResponseMessages}
                                   className="form-check-input"
                                   id="receiveResponseMessages"/>
                            <label className="form-check-label" htmlFor="receiveResponseMessages">Benachrichtig
                                werden bei Ab- und Zusagen</label>
                            <span style={{color: "red"}}>{this.state.errors.receiveResponseMessages}</span>
                        </div>

                        <div className="form-check">
                            <input type="checkbox" name="receiveResultMessages"
                                   value={this.state.spieler.receiveResultMessages}
                                   className="form-check-input"
                                   id="receiveResultMessages"/>
                            <label className="form-check-label" htmlFor="receiveResultMessages">Übersicht der
                                Ab-
                                und Zusagen erhalten</label>
                            <span style={{color: "red"}}>{this.state.errors.receiveResultMessages}</span>
                        </div>

                        <div className="form-check">
                            <input type="checkbox" name="receiveReminderMessages"
                                   value={this.state.spieler.receiveReminderMessages}
                                   className="form-check-input"
                                   id="receiveReminderMessages"/>
                            <label className="form-check-label" htmlFor="receiveReminderMessages">Erinnerungsnachricht
                                erhalten</label>
                            <span style={{color: "red"}}>{this.state.errors.receiveReminderMessages}</span>
                        </div>
                    </fieldset>


                    <BackButton/>
                    <Button type="submit"
                            size="large"
                            style={{marginLeft: '1em'}}
                            className="myLargeBtn">Speichern</Button>
                        
                    <br/>
                    <br/>
                    <br/>
                    </fieldset>
                </Form>
                }
            </React.Fragment>

        );
    }
}


export default SpielerProfilComponent;