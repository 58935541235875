import React from 'react'

import {trainingsService} from "../../_services/trainingsService";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

import Trainingsuebersicht from "./trainingsuebersicht_component";
import TrainingsuebersichtEmpty from "./trainingsuebersicht_empty_component";
import {getCurrentUser} from "../../_services";
import {setErrorState} from "../../util/ErrorUtil";

//zeigt immer das naechste Training fuer die Mannschaft
class TrainingsuebersichtByMannschaft extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: {},
      training: {},
      successMsg: '',
      errorMsg: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
    }
  }

  loadData(){
    this.loadTrainingByMannschaft();
    this.loadLoggedInUser();
  }
  
  loadTrainingByMannschaft() {
    const mannschaftsId = this.props.match.params.mannschaftsId;

    trainingsService.getTrainingByMannschaftsId(mannschaftsId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              training: response,
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  loadLoggedInUser(){
    getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }
        })
        .catch(error => {
          setErrorState(error);
        });
  }

  handleChange(event) {
    const trainingstagId = event.target.value;
    
    if (this._isMounted) {
      //window.location.href = '/training/uebersicht/id/' + trainingstagId;
      this.loadTraining(trainingstagId);
    }
  }

  loadTraining(trainingstagId) {
    trainingsService.getTrainingById(trainingstagId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              training: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    return (
        <div id="training-by-mannschaft">
          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          {!!this.state.training && this.state.training.id === 0 &&
          <TrainingsuebersichtEmpty training={this.state.training} mannschaftsId={this.props.match.params.mannschaftsId}/>
          }
          
        {!!this.state.training && !!this.state.training.id && !!this.state.loggedInUser && !!this.state.loggedInUser.id &&
          <Trainingsuebersicht training={this.state.training} onChange={this.handleChange} loggedInUser={this.state.loggedInUser}/>
        }
        </div>
    );
  }

}


export default TrainingsuebersichtByMannschaft;
