import React from 'react';
import {matchEinzelService} from "../../_services/matchEinzelService";
import {setErrorState} from "../../util/ErrorUtil";
import JederGegenJedenComponent from "./jeder_gegen_jeden_component";
import {getCurrentUser, userService} from "../../_services";
import {mannschatsService} from "../../_services/mannschatsService";
import {startseiteService} from "../../_services/startseiteService";
import {matchDoppelService} from "../../_services/matchDoppelService";
import FilterFrom from "../statistik/filter/filterForm";

class JederGegenJeden extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            selectedMannschaftsId: null,
            mannschaften: [],
            einzelStatistik: [],
            doppelStatistik: [],
            beispielNutzer2: {
                id: 0,
                nutzer: {}
            }
        };
        
        this.loadStatistik = this.loadStatistik.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFitler = this.handleFitler.bind(this);
    }

    loadData(){
        this.loadStatistik();
        this.loadMannschaften();
    }

    loadStatistik(filter){
        if(this.state.selectedMannschaftsId > 0){
            matchEinzelService.getJederGegenJederStatistikForMannschaft(this.state.selectedMannschaftsId, filter)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            einzelStatistik: response
                        });
                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        setErrorState(error, this);
                        console.log('error');
                        console.log(error);
                    }
                });


            matchDoppelService.getJederMitJedemStatistikForMannschaft(this.state.selectedMannschaftsId, filter)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            doppelStatistik: response
                        });

                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        setErrorState(error, this);
                    }
                });
        }
    }
    

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps, nextState) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }

        if(this.state.selectedMannschaftsId !== nextState.selectedMannschaftsId) {
            // Reset State
            this.loadData();
        }
    }

    loadMannschaften(){
        
        getCurrentUser()
            .then(response => {
                this.loadMannschaftenByNutzer(response.id);
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });
    }


    loadMannschaftenByNutzer(nutzerId){

        //alle Mannschaften des Nutzers:
        //  - alle Mannschaften in denen der uebergebene Nutzer einen Spieler hat
        //  - alle Mannschaften, fuer die der Nutzer VIEW-Rechte hat
        //  - alle Mannschaften, fuer die der Nutzer ADMIN-Rechte hat     
        mannschatsService.getMannschaftenForNavigationViewRightsOrHigher(nutzerId)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        mannschaften: response
                    });
                    
                    if(!this.state.selectedMannschaftsId){
                        startseiteService.findByNutzer(nutzerId)
                            .then(response => {
                                if(this._isMounted){
                                    this.setState({
                                        selectedMannschaftsId: response.objectId
                                    });
                                }

                            })
                            .catch(error => {
                                if (this._isMounted) {
                                    if(response.length > 0){
                                        this.setState({
                                            //kein Fehler wegen der Startseite setzen
                                            //ist keine Startseite gesetzt, wuerde sonst auch ein Fehler kommen, weil NULL geliefert wird vom REST Service
                                            //hasError: error,
                                            selectedMannschaftsId: response[0].id
                                        });                                       
                                    }

                                }
                            });
                    }
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });
    }

    handleChange(event) {
        const mannschaftsId = event.target.value;

        if (this._isMounted) {
            this.setState({
                selectedMannschaftsId: mannschaftsId
            });
        }
    }

    getNutzerById(nutzerId){
        if(nutzerId && nutzerId > 0){
            //sonst wuerde er staendig neu laden. UND: wirklich nur != mit einem =
            if(this.state.beispielNutzer2.id != nutzerId){
                userService.getNutzerById(nutzerId)
                    .then(response => {
                        this.setState({
                            beispielNutzer2: {
                                id: response.id,
                                nutzer: response
                            }
                        });
                    })
                    .catch(error => {
                        if (this._isMounted) {
                            setErrorState(error, this);
                        }
                    });
            }
        }


    }
    
    handleFitler(filter){
        this.loadStatistik(filter);
    }
    
    render() {
        const { einzelStatistik, doppelStatistik, mannschaften, selectedMannschaftsId, beispielNutzer2 } = this.state;

        let mannschaftItems = mannschaften.map((mannschaft) =>
            <option value={mannschaft.id} key={mannschaft.id}>{mannschaft.name}</option>
        );

        let beispielNutzer1Vorname = '';
        let beispielNutzer2Vorname = '';
        let gewonnen = '';
        let verloren = '';
        if(doppelStatistik.length > 0){
            let myMap = new Map( Object.entries( doppelStatistik[0].statistikPerNutzer ) )
            var key = Array.from(myMap.keys())[1];
            this.getNutzerById(key);
            
            gewonnen = myMap.get(key).gewonnen.length;
            verloren = myMap.get(key).verloren.length;

            beispielNutzer1Vorname = doppelStatistik[0].nutzer.vorname;
            beispielNutzer2Vorname = beispielNutzer2.nutzer.vorname;
        }
        let filterData = {
            mannschaftsId: selectedMannschaftsId
        };

            
        return (
            <div>
                <h1>Match Statistik</h1>
                
                <p>
                    Hier findest du eine Übersicht der Match-Bilanzen der Spieler aus deiner Mannschaft.
                </p>
                <p>
                    Es werden nur Matches berücksichtigt, an denen ausschließlich Spieler der selektierten Mannschaft beteiligt waren. <br/>
                    Matches, mit Beteiligung von Spielern, die nicht der Mannschaft angehören, werden also nicht betrachtet.
                </p>
                <br/>
                
                {!!mannschaften && !!mannschaften.length > 0 && !!selectedMannschaftsId &&
                <span>
                  <select onChange={this.handleChange} value={selectedMannschaftsId} style={{fontSize: "1.6rem"}}>
                    {mannschaftItems}
                  </select>
                </span>
                }
                
                <br/><br/><br/>
                
                {!!selectedMannschaftsId &&
                <div id="filter-container">
                    <FilterFrom filterData={filterData} onFitler={this.handleFitler} showSpielerFilter={true}/>
                </div>
                }
                <br/>
                
                
                <h1>Einzel</h1>
                {!!einzelStatistik && 
                    <JederGegenJedenComponent einzelStatistik={einzelStatistik} isDoppel={false} onlyPlanung={false}/>
                }

                <h1>Doppel</h1>
                <p>In der Doppel-Statistik ist, im Gegensatz zu der Einzelstatistik, wo die spielt-gegen-Relation abgebildet wird, die spielt-zusammen-mit-Relation abgebildet.</p>
                <p>Die Bilanz zeigt also an, wie viele ihrer <b>zusammen</b>, als Doppelpaarung gespielten Matches, die beiden Spielerinnen gewonnen oder verloren haben.</p>

                {!!doppelStatistik && !!doppelStatistik.length > 0 && !!this.state.beispielNutzer2 && !!this.state.beispielNutzer2.id &&
                <p>Der Eintrag {gewonnen}:{verloren} in der Zeile von {beispielNutzer1Vorname} und Spalte von {beispielNutzer2Vorname} bedeutet zum Beispiel, 
                    dass {beispielNutzer1Vorname} und {beispielNutzer2Vorname} zusammen als Doppel {gewonnen} Matches gewonnen und {verloren} Matches verloren haben.
                </p>
                }
                
                {!!doppelStatistik &&
                <JederGegenJedenComponent einzelStatistik={doppelStatistik} isDoppel={true} onlyPlanung={false}/>
                }
            </div>

        );
    }
}


export default JederGegenJeden;