import '../matchEditAndListTabPaneView.css';
import React from 'react'
import {withRouter} from "react-router-dom";
import {Tab, Tabs} from "react-bootstrap";
import MatchFilterStatistikEinzelView from "./MatchFilterStatistikEinzelView";
import MatchFilterStatistikDoppelView from "./MatchFilterStatistikDoppelView";

class MatchFilterStatistikTabPanel extends React.Component
{
  _isMounted = false;

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  render()
  {
    return (
        <React.Fragment>
            
            <h1>Match-Bilanzen</h1>
            <p>Anhand des Match-Filters kannst du dir hier Match-Bilanzen generieren lassen.</p>
            <p>
                Die Angabe einer Mannschaft ist optional. <br/> 
                Wird eine Mannschaft angegeben, werden nur die Matches angezeigt, die innerhalb von Mitgliedern der Mannschaft ausgetragen wurden. <br/>
                Matches, mit Beteiligung von Spielern, die nicht der Mannschaft angehören, werden also aussortiert.
            </p>
            <br/>
            
            <Tabs defaultActiveKey="einzel" id="matches-filter-statistik-tab-pane" className="matches-tab-pane" style={{fontSize: '1rem'}}>
              <Tab eventKey="einzel" title="Einzel" className="tab-header">
                <MatchFilterStatistikEinzelView/>
              </Tab>
              <Tab eventKey="doppel" title="Doppel">
                <MatchFilterStatistikDoppelView/>
              </Tab>
            </Tabs>
        </React.Fragment>
    );
  }
}

export default withRouter(MatchFilterStatistikTabPanel);
