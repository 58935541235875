import './doppelaufstellung_filter.css';
import React from 'react'
import {Button} from 'react-bootstrap';
import {Checkbox, Col, Collapse, Form, Row, Select} from "antd";
import {faFilter, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const FormItem = Form.Item;
const { Panel } = Collapse;

class DoppelaufstellungFilter extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      successMsg: '',
      errorMsg: '',
      spielerListe: [],
      doppelReihenfolgeBeachten: {
        value: false,
        validateStatus: '',
        errorMsg: null
      },
      spieler1: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler2: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler3: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler4: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler5: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler6: {
        value: '',
        validateStatus: '',
        errorMsg: null
      }
    };
    this.onFilter = this.onFilter.bind(this);
  }

  formRef = React.createRef();

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  


  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    
    this.setState({
      [inputName] : {
        value: inputValue
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }

  handleSelectInputChange(value, name, event) {
    let displayName = null;
    
    if(event){
      displayName = event.children;
    }
    
    this.setState({
      [name] : {
        value: value,
        displayNameTemp: displayName,
        //sonst wird er mit null/undefined hier ueberschrieben, wenn man den hier nicht mit setzt
        displayName: this.state[name].displayName
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const inputName = target.name;
    const checked = target.checked;

    this.setState({
      [inputName] : {
        value: checked
      }
    });
  }

  getNutzerDisplayNameFromSelect() {

    let displayNameArr = [];
    if (this.state.nutzerHeim.value > 0 && this.state.nutzerHeim.displayName) {
      displayNameArr.push(this.state.nutzerHeim.displayName + '\'s');
    }

    if (this.state.nutzerHeim2.value > 0 && this.state.nutzerHeim2.displayName) {
      displayNameArr.push(this.state.nutzerHeim2.displayName  + '\'s');
    }
    
    let text = displayNameArr.join(" und ");
    
    return text;
  }
  
  onFilter(){
    const filterRequest = {
      doppelReihenfolgeBeachten: this.state.doppelReihenfolgeBeachten.value,
      spieler1: this.state.spieler1.value,
      spieler2: this.state.spieler2.value,
      spieler3: this.state.spieler3.value,
      spieler4: this.state.spieler4.value,
      spieler5: this.state.spieler5.value,
      spieler6: this.state.spieler6.value,
    };

    this.props.onFilter(filterRequest);
  }

  onClearFilter(){

    this.setState({
      doppelReihenfolgeBeachten: {
        value: false,
        validateStatus: '',
        errorMsg: null
      },
      spieler1: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler2: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler3: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler4: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler5: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      spieler6: {
        value: '',
        validateStatus: '',
        errorMsg: null
      }
    });
    
    this.resetFields();
    this.props.onClearFilter();
  }

  resetFields(){
    this.formRef.current.resetFields();
  }
  
  getLabelForDoppel(doppelNummer){
    if(this.state.doppelReihenfolgeBeachten && this.state.doppelReihenfolgeBeachten.value){
      return doppelNummer + ' Doppel';
    }
    
    return 'Doppel';
  }
  
  render()
  {
    const spielerListe = this.props.spielerListe;

    let spielerItems = spielerListe.map((spieler) =>
        <Select.Option value={spieler.id} key={spieler.id}>{spieler.nickname}</Select.Option>
    );

    let emptyItem = <Select.Option value={""} key={0}></Select.Option>;
    spielerItems = [emptyItem].concat(spielerItems)
    
    //initialValues is like defaultValue of input, it will only works on first render. Please use setFieldValue or setFieldsValue to set new value.
    // heißt: fuer die Edit-Ansicht, bringt mir das nix. Da reichen die initialVaules nicht. Da muss ich wirklich die onChangeMethode aufrufen
    var initialValues = {};

    
    return (
        <React.Fragment>
          {!!spielerListe && !!spielerListe.length &&
          <Collapse>
            <Panel header="Filter" key="1">

            <Form
                ref={this.formRef}
                hideRequiredMark={true}
                initialValues={initialValues}>

              <Row gutter={32} align="bottom">
                <Col>
                  <FormItem labelAlign="left"
                            name="doppelReihenfolgeBeachten"
                            valuePropName="checked"
                            validateStatus={this.state.doppelReihenfolgeBeachten.validateStatus}
                            help={this.state.doppelReihenfolgeBeachten.errorMsg}
                            onChange={(event) => this.handleCheckboxChange(event)}>
                    <Checkbox name="doppelReihenfolgeBeachten"><label>Reihenfolge der Doppel beachten</label></Checkbox>
                  </FormItem>
                </Col>
              </Row>
              
              <Row gutter={32} align="bottom">
                <Col>
                  <FormItem
                      label={this.getLabelForDoppel('1.')}
                      name="spieler1"
                      style={{fontWeight: 'bold', marginBottom: '0px'}}
                      validateStatus={this.state.spieler1.validateStatus}
                      help={this.state.spieler1.errorMsg}>
    
                    <Select
                        style={{width: '11em', marginBottom: '0px', fontWeight: 'normal'}}
                        size="medium"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'spieler1')}>
                      {spielerItems}
                    </Select>
                  </FormItem>
    
                  <FormItem
                      name="spieler2"
                      className='wettkampf-doppel-heim2-filter'
                      validateStatus={this.state.spieler2.validateStatus}
                      help={this.state.spieler2.errorMsg}>
    
                    <Select
                        style={{width: '11em'}}
                        size="medium"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'spieler2')}>
                      {spielerItems}
                    </Select>
                  </FormItem>
                </Col>
              </Row>


              <Row gutter={32} align="bottom">
                <Col>
                  <FormItem
                      label={this.getLabelForDoppel('2.')}
                      name="spieler3"
                      style={{fontWeight: 'bold', marginBottom: '0px'}}
                      validateStatus={this.state.spieler3.validateStatus}
                      help={this.state.spieler3.errorMsg}>

                    <Select
                        style={{width: '11em', marginBottom: '0px', fontWeight: 'normal'}}
                        size="medium"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'spieler3')}>
                      {spielerItems}
                    </Select>
                  </FormItem>

                  <FormItem
                      name="spieler4"
                      className='wettkampf-doppel-heim2-filter'
                      validateStatus={this.state.spieler4.validateStatus}
                      help={this.state.spieler4.errorMsg}>

                    <Select
                        style={{width: '11em'}}
                        size="medium"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'spieler4')}>
                      {spielerItems}
                    </Select>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={32} align="bottom">
                <Col>
                  <FormItem
                      label={this.getLabelForDoppel('3.')}
                      name="spieler5"
                      style={{fontWeight: 'bold', marginBottom: '0px'}}
                      validateStatus={this.state.spieler5.validateStatus}
                      help={this.state.spieler5.errorMsg}>

                    <Select
                        style={{width: '11em', marginBottom: '0px', fontWeight: 'normal'}}
                        size="medium"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'spieler5')}>
                      {spielerItems}
                    </Select>
                  </FormItem>

                  <FormItem
                      name="spieler6"
                      className='wettkampf-doppel-heim2-filter'
                      validateStatus={this.state.spieler6.validateStatus}
                      help={this.state.spieler6.errorMsg}>

                    <Select
                        style={{width: '11em'}}
                        size="medium"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'spieler6')}>
                      {spielerItems}
                    </Select>
                  </FormItem>
                </Col>
              </Row>

            </Form>




              <Button type="submit"
                      size="large"
                      style={{marginLeft: '1em'}}
                      onClick={() => this.onFilter()}
                      className="myLargeBtn">
                <FontAwesomeIcon icon={faFilter}  style={{marginRight: '0.7em'}}/>
                Filtern
              </Button>

              <Button type="submit"
                      size="large"
                      variant="danger"
                      style={{marginLeft: '1em'}}
                      onClick={() => this.onClearFilter()}
                      className="myLargeBtn">

                <FontAwesomeIcon icon={faTrashAlt}  style={{marginRight: '0.7em'}}/>
                Filter löschen
              </Button>

            <br/>
            <br/>
            
            </Panel>
          </Collapse>
          }
        </React.Fragment>
    );
  }
}
export default DoppelaufstellungFilter;


