import React from 'react'
import {Button} from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Form, Input} from "antd";
import {vereinsService} from "../../_services/vereinsService";

const FormItem = Form.Item;

class VereinsEinstellungenEdit extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      verein: {},
      successMsg: '',
      errorMsg: '',
      name: {
        value: this.props.verein.name,
        validateStatus: 'success',
        errorMsg: null
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.verein !== nextProps.verein) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.setState({
        verein: this.props.verein
      });
    }
  }

  handleSubmit() {

    this.setState({
      successMsg: null,
      errorMsg: null
    });

    const vereinId = this.state.verein.id;
    const saveRequest = {
      name: this.state.name.value
    };
    
    vereinsService.save(vereinId, saveRequest)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Die Einstellungen wurden erfolgreich gespeichert.'});
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const inputName = target.name;
    const checked = target.checked;

    this.setState({
      [inputName] : {
        value: checked
      }
    });
  }
  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    
    const { verein } = this.state;
    
    return (
        <div id="verein_einstellungen_edit">
          <h2>Allgemeine Einstellungen</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!verein && !!verein.id > 0 &&
          <div className="form-container-breit-left-aligned">
            <Form onFinish={this.handleSubmit}
                {...layout} 
                className="mannschaft-einstellungen-edit-form"
                  initialValues={{
                    "name": this.state.verein.name
                  }}>

              <FormItem
                  labelAlign="left"
                  label="Name"
                  name="name"
                  rules={[{
                    required: true,
                    message: 'Bitte gib einen Vereinsnamen an' }]}
                  hasFeedback
                  validateStatus={this.state.name.validateStatus}
                  help={this.state.name.errorMsg}>
                <Input
                    size="large"
                    name="name"
                    autoComplete="on"
                    placeholder=""
                    value={this.state.name.value}
                    onChange={(event) => this.handleInputChange(event)}
                />
              </FormItem>

              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn">Speichern</Button>
            </Form>


          </div>
          }
        </div>
    );
  }
}


export default VereinsEinstellungenEdit;


