import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const rechtService = {
    save,
    getByNutzerId,
    deleteRecht
}

export function save(rechtRequest) {
    return request({
        url: API_BASE_URL + "/recht/save",
        method: 'POST',
        body: JSON.stringify(rechtRequest)
    });
}


export function getByNutzerId(nutzerId)
{
    return request({
        url: API_BASE_URL + "/recht/getByNutzer?nutzerId=" + nutzerId,
        method: 'GET'
    });
}

export function deleteRecht(rechtId) {
    return request({
        url: API_BASE_URL + "/recht/delete/" + rechtId,
        method: 'DELETE'
    });
}
