import React from 'react';
import {getDisplayName} from "../../_helpers/displayHelper";
import {Button, Modal} from "react-bootstrap";
import DetailedMatchOverview from "./detailed_match_overview";
import {matchMapper} from "../../_mapper/matchMapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

class JederGegenJedenComponent extends React.Component {
    _isMounted = false;


    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
        }

        if(this.props.reload !== nextProps.reload) {
            // Reset State
            //this.loadData();
        }
    }

    getTurncatedDisplayName(nutzer){
        return getDisplayName(nutzer, true);
    }
    

    render() {
        const einzelStatistik = this.props.einzelStatistik;
        const begenungsArrayNutzerIds = [];


        var begegnungsMap = [];
        for (let i = 0; i < einzelStatistik.length; i++) {
            const einzelStatistikEntry = einzelStatistik[i];
            begenungsArrayNutzerIds.push(einzelStatistikEntry.nutzer.id);
            begegnungsMap.push({key: einzelStatistikEntry.nutzer.id, value: einzelStatistikEntry.nutzer});
        }

        let matchBilanzColumn = <th>Match-Bilanz</th>;
        if (this.props.onlyPlanung) {
            matchBilanzColumn = null;
        }

        return (

            <div>
                {!!einzelStatistik &&
                <div>
                    <table className="table table-hover">
                        <thead className="thead-light">
                        <tr>
                            <th></th>
                            <th># Matches</th>
                            {matchBilanzColumn}
                            {begegnungsMap.map((entry) =>
                                <th key={entry.key + '_' + entry.value}>{this.getTurncatedDisplayName(entry.value)}</th>
                            )}
                        </tr>
                        </thead>
                        <tbody>

                        {einzelStatistik.map((spielerStatistik) =>
                            <SpielerJedenGegenJedenZeile
                                key={spielerStatistik.nutzer.id}
                                spielerStatistik={spielerStatistik}
                                begegungsArray={begenungsArrayNutzerIds}
                                begegnungsMap={begegnungsMap}
                                isDoppel={this.props.isDoppel}
                                onlyPlanung={this.props.onlyPlanung}/>
                        )}
                        </tbody>
                    </table>


                </div>
                }
            </div>

        );
    }
}

class SpielerJedenGegenJedenZeile extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            showStatistikInsgesamtModal: false
        };

        this.handleCloseStatistikInsgesamtModal = this.handleCloseStatistikInsgesamtModal.bind(this);
        this.handleShowStatistikInsgesamtModal = this.handleShowStatistikInsgesamtModal.bind(this);
    }


    getTurncatedDisplayName(nutzer){
        return getDisplayName(nutzer, true);
    }

    handleShowStatistikInsgesamtModal(){
        this.setState({showStatistikInsgesamtModal: true});
    }

    handleCloseStatistikInsgesamtModal(){
        this.setState({showStatistikInsgesamtModal: false});
    }

    render() {
        //damit die Reihenfolge auch wirklich nicht durcheinander geraet
        const isDoppel = this.props.isDoppel;
        const begegnungNutzerIds = this.props.begegungsArray;

        const spielerStatistik = this.props.spielerStatistik;
        const statistikInsgesamt = spielerStatistik.statistikInsgesamt;
        const jederGegenJedenStatistikMap = spielerStatistik.statistikPerNutzer;

        //REST gibt zwar eine HashMap zurueck, aber die wird nicht so hier aufgenommen.
        let parsedMap = new Map();
        for (const [key, value] of Object.entries(jederGegenJedenStatistikMap)) {
            var keyAsNumber = Number(key)
            parsedMap.set(keyAsNumber, value);
        }

        const begegnungsMap = new Map(
            this.props.begegnungsMap.map(object => {
                return [object.key, object.value];
            }),
        );

        let matchStatistikMapped = {};
        if(isDoppel){
            matchStatistikMapped = {
                anzahlMatchesInsgesamt: statistikInsgesamt.anzahlMatchesInsgesamt,
                gewonnen: matchMapper.mapDoppelList(statistikInsgesamt.gewonnen),
                verloren: matchMapper.mapDoppelList(statistikInsgesamt.verloren),
                unentschieden: matchMapper.mapDoppelList(statistikInsgesamt.unentschieden)
            }
        }else{
            matchStatistikMapped = {
                anzahlMatchesInsgesamt: statistikInsgesamt.anzahlMatchesInsgesamt,
                gewonnen: matchMapper.mapEinzelList(statistikInsgesamt.gewonnen),
                verloren: matchMapper.mapEinzelList(statistikInsgesamt.verloren),
                unentschieden: matchMapper.mapEinzelList(statistikInsgesamt.unentschieden)
            }
        }

        let matchProfilHref = '/spieler/matches/'+ spielerStatistik.nutzer.id;

        if(isDoppel){
            matchProfilHref = '/spieler/matches-doppel/'+ spielerStatistik.nutzer.id;
        }

        let matchBilanzColumn = <td onClick={this.handleShowStatistikInsgesamtModal}>{statistikInsgesamt.gewonnen.length}:{statistikInsgesamt.verloren.length}</td>;
        if (this.props.onlyPlanung) {
            matchBilanzColumn = null;
        }

        return (

            <React.Fragment>
                {!!spielerStatistik && !!parsedMap.size > 0 &&
                <tr>
                    <td style={{textAlign: 'left'}}>
                        <a href={matchProfilHref}>
                            <FontAwesomeIcon icon={faUser}/>
                            &nbsp;
                            <span style={{color: 'black'}}>{this.getTurncatedDisplayName(spielerStatistik.nutzer)}</span>
                        </a>
                    </td>


                    <td>{statistikInsgesamt.anzahlMatchesInsgesamt}</td>

                    {matchBilanzColumn}
                    
                    {begegnungNutzerIds.map((gegnerId) =>
                        <React.Fragment key={'nutzerStatistik_' + spielerStatistik.nutzer.id + '_' + gegnerId}>
                            <BegegungsTableCell spielerStatistik={spielerStatistik} gegnerId={gegnerId} parsedMap={parsedMap} isDoppel={isDoppel} onlyPlanung={this.props.onlyPlanung} begegnungsMap={begegnungsMap}/>
                        </React.Fragment>
                    )}
                </tr>
                }

                {/*Der Dialog fuer die Gesamt-Statistik. Also alle Matches*/}
                {!!spielerStatistik &&
                <Modal show={this.state.showStatistikInsgesamtModal}
                       onHide={this.handleCloseStatistikInsgesamtModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{spielerStatistik.nutzer.vorname} {spielerStatistik.nutzer.nachname}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DetailedMatchOverview
                            matches={matchStatistikMapped}
                            isDoppel={isDoppel}
                            loggedInUser={null}
                            displayName={''}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseStatistikInsgesamtModal}>
                            Schließen
                        </Button>
                    </Modal.Footer>
                </Modal>
                }
            </React.Fragment>

        );
    }
}


class BegegungsTableCell extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            spielerStatistikMapped: {},
            showStatistikPerNutzerModal: false
        };

        this.handleShowStatistikPerNutzerModal = this.handleShowStatistikPerNutzerModal.bind(this);
        this.handleCloseStatistikPerNutzerModal = this.handleCloseStatistikPerNutzerModal.bind(this);
    }

    handleShowStatistikPerNutzerModal(){
        this.setState({showStatistikPerNutzerModal: true});

        const statistikPerNutzer = this.props.parsedMap.get(this.props.gegnerId);

        let matchStatistikMapped = {};
        if(this.props.isDoppel){
            matchStatistikMapped = {
                anzahlMatchesInsgesamt: statistikPerNutzer.anzahlMatchesInsgesamt,
                gewonnen: matchMapper.mapDoppelList(statistikPerNutzer.gewonnen),
                verloren: matchMapper.mapDoppelList(statistikPerNutzer.verloren),
                unentschieden: matchMapper.mapDoppelList(statistikPerNutzer.unentschieden)
            }
        }else{
            matchStatistikMapped = {
                anzahlMatchesInsgesamt: statistikPerNutzer.anzahlMatchesInsgesamt,
                gewonnen: matchMapper.mapEinzelList(statistikPerNutzer.gewonnen),
                verloren: matchMapper.mapEinzelList(statistikPerNutzer.verloren),
                unentschieden: matchMapper.mapEinzelList(statistikPerNutzer.unentschieden)
            }
        }

        this.setState({spielerStatistikMapped: matchStatistikMapped});
    }
    handleCloseStatistikPerNutzerModal(){
        this.setState({showStatistikPerNutzerModal: false});
    }

    getTurncatedDisplayName(nutzer){
        return getDisplayName(nutzer, true);
    }

    render() {
        const { spielerStatistik, gegnerId, parsedMap, begegnungsMap } = this.props;

        const nutzerId = this.props.spielerStatistik.nutzer.id;
        const gegner = begegnungsMap.get(gegnerId);

        let zwischenWort = ' gegen ';
        if(this.props.isDoppel){
            zwischenWort = ' mit '
        }

        const modalHeader = spielerStatistik.nutzer.vorname + zwischenWort + gegner.vorname;
        
        let tdContent = <span>{parsedMap.get(gegnerId).gewonnen.length}:{parsedMap.get(gegnerId).verloren.length}</span>;

        let unentschiedeneSpiele = parsedMap.get(gegnerId).unentschieden.length;
        if(unentschiedeneSpiele > 0){
            tdContent = <span>{parsedMap.get(gegnerId).gewonnen.length}:{unentschiedeneSpiele}:{parsedMap.get(gegnerId).verloren.length}</span>;
        }

        if(this.props.onlyPlanung){
            tdContent = unentschiedeneSpiele;
        }
        
        if(nutzerId === gegnerId){
            tdContent =  <span>-</span>
        }

        return (
            <React.Fragment>
                {!!spielerStatistik && !!parsedMap.size > 0 &&
                <td onClick={this.handleShowStatistikPerNutzerModal} key={nutzerId + '_' + gegnerId}>{tdContent}</td>
                }

                {/*Der Dialog fuer die Statistik gegen/mit einem einzelnen Spieler*/}
                {!!spielerStatistik && !!this.state.spielerStatistikMapped && !!this.state.spielerStatistikMapped.anzahlMatchesInsgesamt &&
                <Modal show={this.state.showStatistikPerNutzerModal}
                       onHide={this.handleCloseStatistikPerNutzerModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DetailedMatchOverview
                            matches={this.state.spielerStatistikMapped}
                            isDoppel={this.props.isDoppel}
                            loggedInUser={null}
                            displayName={''}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseStatistikPerNutzerModal}>
                            Schließen
                        </Button>
                    </Modal.Footer>
                </Modal>
                }
            </React.Fragment>

        );
    }
}

export default JederGegenJedenComponent;