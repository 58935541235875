import React from 'react'
import {getCurrentUser} from "../../_services";
import {mannschatsService} from "../../_services/mannschatsService";
import {Button} from "react-bootstrap";
import {userService} from "../../_services/user.service";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import InfiniteScroll from "react-infinite-scroll-component";
import {setErrorState, setErrorStateWithMessage} from "../../util/ErrorUtil";
import InvitationSpieler from "./invitation_spieler";
import {createAndAddSpielerForMannschaft} from '../../_services/spielerService';

class InvitationNew extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);

    this.state = {
      mannschaft: {},
      nutzer: [],  //die Nutzer fuer die Anzeige, die zum Hinzufügen ausgewählt werden können
        hasMore: true,
        pageSize: 10,
        actualPage: 0,
        nutzerPicked: [],  //die ausgewählten Nutzer, die der Mannschaft hinzugefügt werden sollen
        loggedInUser: {}
    };

      this.loadNutzerToInvite = this.loadNutzerToInvite.bind(this);
      this.fetchMoreData = this.fetchMoreData.bind(this);
      this.addHinzuzufuegendeNutzer = this.addHinzuzufuegendeNutzer.bind(this);
      this.deleteHinzuzufuegendeNutzer = this.deleteHinzuzufuegendeNutzer.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    this.loadMannschaft();
    this.fetchMoreData();
    this.loadLoggedInUser();
  }

  loadMannschaft() {
    const mannschaftId = this.props.match.params.mannschaftsId;
    mannschatsService.getMannschaftById(mannschaftId)
    .then(response => {
      if (this._isMounted) {
        this.setState({mannschaft: response});
      }
    })
    .catch(error => this.setState({hasError: error}));
  }

  loadNutzerToInvite(page, size) {
    const mannschaftId = this.props.match.params.mannschaftsId;
      userService.getNutzerForVereinMinusMannschaftsspieler(mannschaftId, page, size)
        .then(response => {
          if (this._isMounted) {
              this.setState({
                  //gespielt: response.content,
                  nutzer:  this.state.nutzer.concat(response.content),
                  actualPage: response.number + 1,
                  hasMore: !response.last
              });
          }
        })
        .catch(error => {
            setErrorState(error, this);
        })
  }

    fetchMoreData() {
        //erste Seite = 0
        this.loadNutzerToInvite(this.state.actualPage, this.state.pageSize);
    }
  
  loadLoggedInUser(){
    getCurrentUser()
    .then(response => {
      if (this._isMounted) {
        this.setState({loggedInUser: response});
      }
    })
    .catch(error => this.setState({hasError: error}));
  }

    handleInputChange(event) {

      const target = event.target;
      const nutzerId = target.name;  //die NutzerId
      const checked = target.checked;

      if(checked){
          this.addHinzuzufuegendeNutzer(nutzerId);
      }else{
          this.deleteHinzuzufuegendeNutzer(nutzerId);
      }
    }
    
    //fuegt den uebergebenen Nutzer der nutzerPicked-Liste hinzu
    addHinzuzufuegendeNutzer(nutzerId){
        // only add if the item doesn't exist in the list
        if(this.state.nutzerPicked.indexOf(nutzerId) < 0){
            this.setState((state) => ({
                nutzerPicked: state.nutzerPicked.concat([nutzerId])
            }))
        }
    }

    //loescht einen Nutzer von der nutzerPicked-Liste
    deleteHinzuzufuegendeNutzer(nutzerId){

        var array = this.state.nutzerPicked; // make a separate copy of the array
        var index = array.indexOf(nutzerId);

        if (index !== -1) {
            array.splice(index, 1);
            this.setState({nutzerPicked: array});
        }
    }

  onSend() {
      
      if(this.state.nutzerPicked && this.state.nutzerPicked.length > 0){
          console.log("es sind spieler hinzuzufuegen!")

          const addAndCreateRequest = {
              nutzerIdsToCreate: this.state.nutzerPicked,
              mannschaftsId: this.state.mannschaft.id
          };
          
          createAndAddSpielerForMannschaft(addAndCreateRequest)
              .then(response => {
                  this.setState({
                      successMsg: "Die Spieler wurden der Mannschaft hinzugefügt."
                  });
                  this.deletePickedSpielerFromPicklist();
                  
                  //die InfiniteScroll Komponente updated sich einfach nicht.
                  //daher mach ich hart einen Seitenreload....
                  window.location.href = '/invitation/new/' + this.state.mannschaft.id;

              }).catch(error => {
              setErrorStateWithMessage("Beim Hinzufügen der Spieler ist ein Fehler aufgetreten.", this);
              console.log(error);
          });
      }
  }
  
  deletePickedSpielerFromPicklist(){
      for (var i = 0; i < this.state.nutzerPicked.length; i++) {
          console.log(this.state.nutzerPicked[i]);
          this.deleteHinzuzufuegendeNutzer(this.state.nutzerPicked[i]);
      }

      this.setState({
          nutzerPicked: []
      });
  }
  
  render()
  {
    const { mannschaft, nutzer } = this.state;
    
    return (
        <div className="container" id="invite">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }
            
            <h1>Spieler zu den 
              {!!mannschaft &&
              <React.Fragment> {mannschaft.name} </React.Fragment>
              }
              hinzufügen
            </h1>
            
          <h2>Existierende Vereinsspieler</h2>
          <div>
              {nutzer === undefined  || nutzer.length === 0 ?
                  //Wenn nutzer NULL ist
                  <i>Es gibt keine weiteren Spieler, die der Mannschaft hinzugefügt werden könnten. Um neue Spieler in den Verein einzuladen, schaue weiter unten.</i>
                  :
                  <span>suche hier den gewünschten Spieler aus den bereits existierenden Spielern des Vereins heraus:</span>
              }


              {!!nutzer.length &&
              <div>
              <InfiniteScroll
                  dataLength={nutzer.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore}
                  loader={<h4>Loading...</h4>}
                  //total bescheurte Komponente. Wenn das Parent-Element nicht voll ist, wird auch nicht nachgeladen.
                  //also bei allen Screens, wo die bereits angezeigten Datensaetze locker in diese Hoehe reinpassen,
                  //klappt das mit dem Nachladen nicht.
                  height={250}
                  endMessage={
                      <p style={{ textAlign: 'center' }}>
                          <b>Das waren alle verfügbaren Nutzer :)</b>
                      </p>
                  }
              >
                  <table className="table table-striped">
                      <tbody>
                      {nutzer.map((n, index) =>
                          <tr key={n.id}>
                              <td>
                                  <input
                                      name={n.id}          
                                      type="checkbox"
                                      onChange={(event) => this.handleInputChange(event)} />
                              </td>
                              <td key={n.id + '_name'}>
                                  {n.nachname + ', ' + n.vorname}
                              </td>
                          </tr>
                      )}
                      </tbody>
                  </table>
              </InfiniteScroll>

              <Button style={{float: 'right'}} variant="primary" onClick={() => this.onSend()}>Spieler hinzufügen</Button>
              </div>
              }
              
          </div>
            
          <h2>Neuen Spieler einladen</h2>
          <div>
              {!!mannschaft && !!mannschaft.id &&
                <InvitationSpieler mannschaft={mannschaft}/>
              }
          </div>
          

        </div>
    );
  }

}

export default InvitationNew;
