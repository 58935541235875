import {API_BASE_URL} from "../../../constants";
import {request} from "../../../util/APIUtils";


export const trainingsplanungEinzelService = {
    getEinzelById,
    saveEinzel,
    deleteEinzelById,
    getStatistikForMannschaft,
    getJederGegenJederStatistikForMannschaft,
    getJederGegenJederStatistikForTraining
};


export function getEinzelById(einzelId)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-einzel/"  + einzelId,
        method: 'GET'
    });
}

export function saveEinzel(saveRequest) {
    return request({
        url: API_BASE_URL + "/trainingsplanung-einzel/save",
        method: 'POST',
        body: JSON.stringify(saveRequest)
    });
}

export function deleteEinzelById(id)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-einzel/delete/"  + id,
        method: 'DELETE'
    });
}

export function getStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/trainingsplanung-einzel/getStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET'
    });
}

export function getJederGegenJederStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/trainingsplanung-einzel/getJederGegenJederStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET',
    });
}

export function getJederGegenJederStatistikForTraining(trainingsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/trainingsplanung-einzel/getJederGegenJederStatistikForTraining?trainingsId="  + trainingsId + filterParams,
        method: 'GET',
    });
}
