import { request } from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const matchEinzelService = {
    getMatchById,
    findAllOrderByDatum,
    saveMatch,
    filterMatches,
    filterMatchesStatistik,
    findMatchesByNutzerId,
    getStatistikForMannschaft,
    getJederGegenJederStatistikForMannschaft,
    getJederGegenJederStatistikForTraining,
    findAlreadyExistent
};


export function getMatchById(matchId)
{
    return request({
        url: API_BASE_URL + "/matcheinzel/"  + matchId,
        method: 'GET'
    });
}

export function findAllOrderByDatum()
{
    return request({
        url: API_BASE_URL + "/matcheinzel/list",
        method: 'GET'
    });
}

export function saveMatch(matchRequest) {
    return request({
        url: API_BASE_URL + "/matcheinzel/save",
        method: 'POST',
        body: JSON.stringify(matchRequest)
    });
}

export function filterMatches(matchFilterRequest)
{
    return request({
        url: API_BASE_URL + "/matcheinzel/filterListe",
        method: 'POST',
        body: JSON.stringify(matchFilterRequest)
    });
}

export function filterMatchesStatistik(matchFilterRequest)
{
    return request({
        url: API_BASE_URL + "/matcheinzel/filterStatistik",
        method: 'POST',
        body: JSON.stringify(matchFilterRequest)
    });
}

export function findMatchesByNutzerId(nutzerId)
{
    return request({
        url: API_BASE_URL + "/matcheinzel/findByNutzer?nutzerId="  + nutzerId,
        method: 'GET'
    });
}

export function getStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/matcheinzel/getStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET'
    });
}

export function getJederGegenJederStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }
    
    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }
    
    return request({
        url: API_BASE_URL + "/matcheinzel/getJederGegenJederStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET',
    });
}

export function getJederGegenJederStatistikForTraining(trainingsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/matcheinzel/getJederGegenJederStatistikForTraining?trainingsId="  + trainingsId + filterParams,
        method: 'GET',
    });
}


export function findAlreadyExistent(matchRequest)
{
    let id = matchRequest.id;
    if(id === undefined){
        id = 0;
    }
    let requestParams = '?id=' + id;
    requestParams = requestParams + '&nutzerHeimId=' + matchRequest.nutzerHeimId;
    requestParams = requestParams + '&nutzerGastId=' + matchRequest.nutzerGastId;
    requestParams = requestParams + '&ergebnis=' + matchRequest.ergebnis;
    requestParams = requestParams + '&datum=' + matchRequest.datum;

    return request({
        url: API_BASE_URL + "/matcheinzel/findAlreadyExistent"  + requestParams,
        method: 'GET'
    });
}
