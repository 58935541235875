import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const doppelBewertungsService = {
    saveDoppelbewertung,
    getByVereinId,
    deleteById
};

export function saveDoppelbewertung(matchRequest) {
    return request({
        url: API_BASE_URL + "/doppelbewertung/save",
        method: 'POST',
        body: JSON.stringify(matchRequest)
    });
}

export function getByVereinId(vereinId)
{
    return request({
        url: API_BASE_URL + "/doppelbewertung/getByVereinId?vereinId="  + vereinId,
        method: 'GET'
    });
}

export function deleteById(doppelBewertungId)
{
    return request({
        url: API_BASE_URL + "/doppelbewertung/delete/"  + doppelBewertungId,
        method: 'DELETE'
    });
}
