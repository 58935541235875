//Uhrzeit im 24-Stunden-Format
//##:##   18:00
export const TIME_REGEX = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const DATE_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/;


//Datum mit Uhrzeit im 24-Stunden-Format
//##:##   18:00
export const DATE_TIME_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4} ([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

//E-Mail
export const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

export const SATZ_REGEX = /^([0-9]*):[0-9]([0-9]*)$/;

export const MATCH_REGEX = /^(([0-9]*):[0-9]([0-9]*)[ ]*)*$/;


