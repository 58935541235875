import React from 'react'
import {Button} from 'react-bootstrap';

import {Checkbox, Form, Input} from "antd";
import {abstimmungService, getAbstimmungById} from "../../../_services/abstimmung/abstimmungService";
import {setErrorState} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {abstimmungTeilnehmerService} from "../../../_services/abstimmung/abstimmungTeilnehmerService";
import {getDisplayName} from "../../../_helpers/displayHelper";
import {abstimmungOptionService} from "../../../_services/abstimmung/abstimmungOptionService";

const FormItem = Form.Item;


class AbstimmungEditComponent extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      abstimmungId: this.props.abstimmungId,
      abstimmung: {},
      abstimmungTeilnehmer: [],
      abstimmungOptionen: [],
      successMsg: '',
      errorMsg: '',
      name: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      aktiv: {
        value: true,
        validateStatus: 'success',
        errorMsg: null
      },
    };
    this.onSave = this.onSave.bind(this);
    this.loadAbstimmung = this.loadAbstimmung.bind(this);
    this.loadAbstimmungTeilnehmer = this.loadAbstimmungTeilnehmer.bind(this);
    this.loadAbstimmungOptionen = this.loadAbstimmungOptionen.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onAddTeilnehmer = this.onAddTeilnehmer.bind(this);
    
    
    //this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.abstimmungId !== nextProps.abstimmungId) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    if (this._isMounted) {
      if(!this.isNewAnsicht()){
        this.loadAbstimmung();
        this.loadAbstimmungOptionen();
        this.loadAbstimmungTeilnehmer();
      }
    }
  }

  loadAbstimmung() {
    
    const abstimmungId = this.state.abstimmungId;

    getAbstimmungById(abstimmungId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              abstimmung: response
            });

            this.setState({
              name: {
                value: response.name
              },
              aktiv: {
                value: response.aktiv
              }
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  loadAbstimmungTeilnehmer() {

    const abstimmungId = this.state.abstimmungId;

    abstimmungTeilnehmerService.getAllTeilnehmerForAbstimmungSorted(abstimmungId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              abstimmungTeilnehmer: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  loadAbstimmungOptionen() {

    const abstimmungId = this.state.abstimmungId;

    abstimmungOptionService.getAllOptionenForAbstimmung(abstimmungId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              abstimmungOptionen: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  //lieert die Mannschaft, je nach dem, um was fuer eine Ansicht es sich gerade handelt
  //(die Bearbeiten Ansicht, eines bereits existenten Punktspiels oder die Neu Ansicht, zum neu Erstellen eines Punktspiels)
  getVerein(){
    //dann handelt es sich um den Edit-Fall
    if(this.state.abstimmungId > 0){
      return this.state.abstimmung.verein;
    }else{
      //neu erstellen Fall
      return this.props.verein;
    }
  }
  
  isNewAnsicht(){
    //dann handelt es sich um den Edit-Fall
    if(this.state.abstimmungId > 0){
      return false;
    }else{
      //neu erstellen Fall
      return true;
    }
  }
  
  onSave() {
    
    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    var verein = this.getVerein();
    
    const saveRequest = {
      id: this.state.abstimmungId,
      verein: verein,
      name: this.state.name.value,
      aktiv: this.state.aktiv.value
    };

    if(this.isNewAnsicht()){
      this.saveNewAbstimmung(saveRequest);
    }else{
      this.updateAbstimmung(saveRequest);
    }
    

  }
  
  updateAbstimmung(saveRequest){
    abstimmungService.updateAllgemeineEinstellungen(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Die Abstimmung wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.setState({
            abstimmung: response,
            abstimmungId: response.id
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  saveNewAbstimmung(saveRequest){
    abstimmungService.saveAbstimmung(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Die Abstimmung wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.setState({
            abstimmung: response,
            abstimmungId: response.id
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  onBack() {
    var vereinId = 0;
    //dann handelt es sich um den Edit-Fall
    if(this.state.abstimmungId > 0){
      vereinId = this.state.abstimmung.verein.id;
    }else{
      //neu erstellen Fall
      vereinId = this.props.verein.id;
    }
    
    window.location.href = '/verwaltung/verein/edit/' + vereinId;
  }


  onAddTeilnehmer(e) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/verwaltung/verein/abstimmung/' + this.state.abstimmung.id;
  }

  deleteTeilnehmer(teilnehmerId) {
    abstimmungTeilnehmerService.deleteTeilnehmerById(teilnehmerId)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Der Spieler wurde erfolgreich gelöscht'});
            this.loadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  deleteOption(abstimmungOptionId) {
    abstimmungOptionService.deleteOptionById(abstimmungOptionId)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Die Option wurde erfolgreich gelöscht'});
            this.loadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  editOption(e, abstimmungOptionId){
    e.preventDefault();
    
    window.location.href = '/verwaltung/verein/abstimmungoption/edit/' + abstimmungOptionId;
  }

  onAddOption(e) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/verwaltung/verein/abstimmungoption/new/' + this.state.abstimmung.id;
  }


  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const inputName = target.name;
    const checked = target.checked;

    this.setState({
      [inputName] : {
        value: checked
      }
    });
  }
  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const { abstimmung, abstimmungTeilnehmer, abstimmungOptionen } = this.state;
    const isNewAnsicht = this.isNewAnsicht();
    
    var initalValueAktiv = true;
    if(!isNewAnsicht){
      initalValueAktiv = this.state.abstimmung.aktiv;
    }
    
    return (
        <React.Fragment>
          <div className="abstimmung-edit-container">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }

            {(isNewAnsicht || (!!abstimmung && !!abstimmung.id)) ?
            <div className="form-container">
              <Form onFinish={this.onSave}
                    {...layout}
                    className="punktspiel-edit-form"
                    initialValues={{
                      "name": this.state.abstimmung.name,
                      "aktiv": initalValueAktiv
                    }}>
                <FormItem
                    labelAlign="left"
                    label="Name"
                    name="name"
                    rules={[{
                      required: true,
                      message: 'Bitte gib den Namen der Abstimmung an' }]}
                    hasFeedback
                    validateStatus={this.state.name.validateStatus}
                    help={this.state.name.errorMsg}>
                  <Input
                      size="large"
                      name="name"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.name.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>

                <FormItem labelAlign="left"
                          label="aktiv"
                          name="aktiv"
                          valuePropName="checked"
                          validateStatus={this.state.aktiv.validateStatus}
                          help={this.state.aktiv.errorMsg}
                          onChange={(event) => this.handleCheckboxChange(event)}>
                  <Checkbox name="aktiv"/>
                </FormItem>

                <div id="btn-container" className={"form-container"}>
                  <Button type="submit"
                          style={{float: 'right'}}
                          size="large"
                          className="myLargeBtn"
                          disabled={this.state.disableSubmitButton}>Speichern</Button>

                </div>
              </Form>
              
              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onBack()}>Zurück</Button>

            </div>
                :
                null
            }
            


            {!!abstimmung && !!abstimmung.id &&
            <div>
              <div>
                <h2>Abstimmungs-Optionen</h2>
                {!!abstimmung && !!abstimmungOptionen && !!abstimmungOptionen.length > 0 &&
                <table className="table table-hover">
                  <tbody>
                  {abstimmungOptionen.map((option) =>
  
                      <tr key={"optiones_zeile_" + option.id}>
                        <td style={{textAlign: 'left'}}>{option.option}</td>
  
                        <td>
                          {/*bearbeiten Button*/}
                          <Button
                              className="btn btn-primary edit-button"
                              onClick={e => this.editOption(e, option.id)}>
                            <FontAwesomeIcon icon={faPen}  />
                          </Button>
                          <Button
                              className="btn btn-secondary delete-button"
                              onClick={()=>this.deleteOption(option.id)}>
                            <FontAwesomeIcon icon={faTrashAlt}  />
                          </Button>
                        </td>
                      </tr>
                  )}
                  </tbody>
                </table>
                }
                <Button variant="primary" onClick={e => this.onAddOption(e)}>Option hinzufügen</Button>
              </div>
              
              <br/>
              <br/>
              
              <div>
                <h2>Teilnehmer</h2>
                {!!abstimmung && !!abstimmungTeilnehmer && !!abstimmungTeilnehmer.length > 0 &&
                <table className="table table-hover">
                  <tbody>
                  {abstimmungTeilnehmer.map((teilnehmer) =>
  
                      <tr key={"teilnehmer_zeile_" + teilnehmer.id}>
                        <td style={{textAlign: 'left'}}>{getDisplayName(teilnehmer.nutzer)}</td>
  
                        <td>
                          {/*loeschen Button*/}
                          <Button
                              className="btn btn-secondary delete-button"
                              onClick={()=>this.deleteTeilnehmer(teilnehmer.id)}>
                            <FontAwesomeIcon icon={faTrashAlt}  />
                          </Button>
                        </td>
                      </tr>
                  )}
                  </tbody>
                </table>
                }
                <Button variant="primary" onClick={e => this.onAddTeilnehmer(e)}>Teilnehmer hinzufügen</Button>
              </div>
              <br/>
              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onBack()}>Zurück</Button>
            </div>
            }
            

            
          </div>
        </React.Fragment>

    );
  }

}

export default AbstimmungEditComponent;


