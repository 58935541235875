import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import {setErrorState} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {mannschatsService} from "../../../_services/mannschatsService";

class MannschaftList extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaften: {},
      successMsg: '',
      errorMsg: ''
    };
    this.deleteMannschaft = this.deleteMannschaft.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    if (this._isMounted) {
      this.loadAllMannschaftenForVerein();
    }
  }

  loadAllMannschaftenForVerein(){
    const vereinId = this.props.verein.id;

    mannschatsService.getAllMannschaftenForVerein(vereinId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              mannschaften: response
            });
          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }

  deleteMannschaft(mannschaft) {
    mannschatsService.deleteMannschaftById(mannschaft.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Die Mannschaft wurde erfolgreich gelöscht'});
            this.loadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    const { mannschaften } = this.state;
    
    return (
        <div id="mannschafts_liste">
          <h2>Mannschaften</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!mannschaften && !!mannschaften.length <= 0 &&
          <p style={{paddingTop: '1.5rem'}}>Dieser Verein hat noch keine Mannschaften.</p>
          }
          
          {!!mannschaften && !!mannschaften.length > 0 &&
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{textAlign: 'left'}}>Name</th>
                <th></th>
              </tr>
            </thead>
              <tbody>
                {mannschaften.map((mannschaft) =>
                      <MannschaftZeile key={mannschaft.id} mannschaft={mannschaft} onDeleteMannschaft={this.deleteMannschaft}/>
                  )}
              </tbody>
            </table>
          }
        </div>
    );
  }
}


// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class MannschaftZeile extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {mannschaft: this.props.mannschaft};

  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  

  onEdit(e, mannschaftsId) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/verwaltung/mannschaft/edit/' + mannschaftsId;
  }
  

  
  render()
  {
    const { mannschaft } = this.state;
    
    return (
      <React.Fragment>
        {!!mannschaft && !!mannschaft.id &&
          <tr key={mannschaft.id}>
            <td style={{textAlign: 'left'}}>{mannschaft.name}</td>
            
            {/*bearbeiten ist hier nicht moeglich. Nur jeder Spieler selbst kann seine Infos bearbeiten*/}
            <td>
              {/*bearbeiten Button*/}
              <Button
                  className="btn btn-primary edit-button"
                  onClick={e => this.onEdit(e, mannschaft.id)}>
                <FontAwesomeIcon icon={faPen}  />
              </Button>
              
            {/*loeschen Button*/}
              <Button
                  className="btn btn-secondary delete-button"
                  onClick={()=>this.props.onDeleteMannschaft(mannschaft)}>
                <FontAwesomeIcon icon={faTrashAlt}  />
              </Button>
            </td>
          </tr>
        }
      </React.Fragment>

    );
  }
}

export default MannschaftList;


