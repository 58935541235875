
import React, { Component } from 'react';
import { forgottenPassword, checkEmailAvailability } from '../../_services/authService';
import { setErrorState } from '../../util/ErrorUtil';

import {
    EMAIL_MAX_LENGTH
} from '../../constants';

import { Button } from 'react-bootstrap';
//import { Form, Input, Button, notification } from 'antd';
import { Form, Input } from 'antd';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

const FormItem = Form.Item;


class ForgottenPassword extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            successMsg: '',
            errorMsg: '',
            email: {
                value: ''
            }
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
    }
    
    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    handleSubmit(values) {
        const passwordForgottenRequest = {
            email: this.state.email.value
        };
        forgottenPassword(passwordForgottenRequest)
        .then(response => {
            this.setState({
                successMsg: "Dein Passwort wurde zurückgesetzt. Folge den Anweisungen in der Email."
            });
        }).catch(error => {
            setErrorState(error, this);
        });
    }

    isFormInvalid() {
        return !(this.state.email.validateStatus === 'success');
    }

    render() {
        return (
            <div className="signup-container">
                <h1 className="page-title">Passwort zurücksetzen</h1>
                <div className="signup-content">
                    {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                    }

                    {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                    }
                    
                    <Form onFinish={this.handleSubmit} className="signup-form">
                        <FormItem 
                            label="Email"
                            hasFeedback
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input 
                                size="large"
                                name="email" 
                                type="email" 
                                autoComplete="off"
                                placeholder="Deine Email-Adresse"
                                value={this.state.email.value} 
                                onBlur={this.validateEmailAvailability}
                                onChange={(event) => this.handleInputChange(event, this.validateEmail)} />    
                        </FormItem>
                        <FormItem>
                            <Button type="submit"
                                size="large" 
                                className="signup-form-button"
                                disabled={this.isFormInvalid()}>neues Passwort anfordern</Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }

    // Validation Functions

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib deine Email-Adresse an. Die Angabe der Email-Adresse ist pflicht.'                
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Das ist keine valide Email-Adresse.'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Email-Adresse ist zu lang. Es sind maximal ${EMAIL_MAX_LENGTH} Zeichen erlaubt.`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }

    
    
    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });    
            return;
        }

        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        //checkEmailAvailability: prueft die Verfuegbarkeit einer E-Mail:
        //Ist die Mail noch verfuegbar (also existiert diese nocht nicht): true
        //Kann die Mail nicht mehr verwendet werden, da es bereits einen Nuntzer mit dieser Mail gibt: false
        checkEmailAvailability(emailValue)
        .then(response => {
            //Wenn diese Mail nicht mehr verfuegbar ist, also wenn ein Nutzer mit dieser Mail bereits existiert:
            if(!response.available) {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            } else {
                // Marking validateStatus as success, Form will be recchecked at server
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'error',
                        errorMsg: 'Ein Konto mit dieser Email-Adresse existiert nicht. Bitte registriere dich!'
                    }
                });
            }
        }).catch(error => {
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'error',
                    errorMsg: 'Ein Konto mit dieser Email-Adresse existiert nicht. Bitte registriere dich!'
                }
            });
        });
    }


}

export default ForgottenPassword;