import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const wettkampfService = {
    getAllPossibleDoppelaufstellungen,
};


export function getAllPossibleDoppelaufstellungen(nutzer1Id, nutzer2Id, nutzer3Id, nutzer4Id, nutzer5Id, nutzer6Id)
{
    return request({
        url: API_BASE_URL + '/matchdoppel/getAllPossibleDoppelaufstellungen?spieler1Id=' + nutzer1Id 
            + '&spieler2Id=' + nutzer2Id
            + '&spieler3Id=' + nutzer3Id
            + '&spieler4Id=' + nutzer4Id 
            + '&spieler5Id=' + nutzer5Id
            + '&spieler6Id=' + nutzer6Id,
        method: 'GET'
    });
}

