import React from 'react'

const DefaultStartseite = () => (
    <div style={{fontSize: '1rem'}}>
        <h2>Hallo!</h2>
        <p>
            Willkommen auf der Seite zur Mannschaftsplanung.
        </p>
        <p>
            Falls du bereits registriert bist, logge dich ein, um Zugriff auf deine Mannschaften zu erhalten.
            <br/>
            Du kannst in deinem Profil auch deine persönliche Startseite festlegen, damit du hier dann zum Beispiel direkt dein Mannschaftstraining angezeigt bekommst.
        </p>
    </div>
)
export default DefaultStartseite;