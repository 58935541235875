import React, {useContext} from "react";
import DragItem from "./DragItem";
import {GridItem} from "./Grid";
import GridContext from "./GridContext";
import DoppelaufstellungDNDCell from "./DoppelaufstellungDNDCell";

function MyApp() {
    const { items, moveItem, pinItem, banItem } = useContext(GridContext);
    
    return (
        <div className='doppelaufstellungs-liste'>
                {items.map(item => (
                    <DragItem key={item.orderPosition} orderPosition={item.orderPosition} onMoveItem={moveItem}>
                        <GridItem>
                            <DoppelaufstellungDNDCell doppelAufstellung={item}
                                                   onPinItem={pinItem}
                                                   onBanItem={banItem}/>
                        </GridItem>
                    </DragItem>
                ))}
        </div>
    );
}

export default MyApp;