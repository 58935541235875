import Match from "./Match";
import GastHeimEinzel from "./GastHeimEinzel";
import Spielpaarung from "./Spielpaarung";

export default class MatchEinzel extends Match {
    
    constructor(matchJson) {
        super(matchJson);

        const heim = new GastHeimEinzel(matchJson.gastHeim.nutzerHeim);
        const gast = new GastHeimEinzel(matchJson.gastHeim.nutzerGast);
        
        this.spielpaarung = new Spielpaarung(heim, gast);
    }


    getHeimNutzer() {
        return this.spielpaarung.heim.nutzer;
    }

    getGastNutzer() {
        return this.spielpaarung.gast.nutzer;
    }
}