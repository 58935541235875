import DoppelPaarung_Filter from "./DoppelPaarung_Filter";

export default class DoppelAufstellung_Filter {

    constructor(filterRequest) {
        this.doppelReihenfolgeBeachten = filterRequest.doppelReihenfolgeBeachten;
        this.doppel1 = new DoppelPaarung_Filter(filterRequest.spieler1, filterRequest.spieler2);
        this.doppel2 = new DoppelPaarung_Filter(filterRequest.spieler3, filterRequest.spieler4);
        this.doppel3 = new DoppelPaarung_Filter(filterRequest.spieler5, filterRequest.spieler6);
    }

}