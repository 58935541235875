import './abstimmungsUbersicht.css';
import img_checked from '../img/checked.png';
import img_cancelled from '../img/cancelled.png';
import img_unbekannt from '../img/unbekannt.png';
import img_null from '../img/null.png';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from "@fortawesome/free-solid-svg-icons";


import {setErrorState, setErrorStateWithMessage} from "../util/ErrorUtil"

import React from 'react';
import moment from "moment";
import {getCurrentUser, userService} from "../_services";
import {abstimmungService, getAbstimmungById} from "../_services/abstimmung/abstimmungService";
import {abstimmungOptionService} from "../_services/abstimmung/abstimmungOptionService";
import {abstimmungOptionAntwortService} from "../_services/abstimmung/abstimmungOptionAntwortService";

import {getDisplayName} from "../_helpers/displayHelper";
import {abstimmungTeilnehmerService} from "../_services/abstimmung/abstimmungTeilnehmerService";
import {Form} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Button} from "react-bootstrap";
import SuccessMessage from "../_components/notifications/SuccessMessage";
import ErrorMessage from "../_components/notifications/ErrorMessage";

const FormItem = Form.Item;

class AbstimmungUebersicht extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: {},
      hasUserEditRights: false,
      abstimmung: {},
      abstimmungTeilnehmer: [],
      abstimmungsOptionen: [],
      abstimmungsOptionenLoaded: false,
      antwortenGrouped: {},
      kommentar: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
    };

    this.onSave = this.onSave.bind(this);
    this.setErrorState = this.setErrorState.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadLoggedInUserAndSetRights();
    this.loadAbstimmung();
    this.loadAbstimmungsOptionen();
    this.loadAbstimmungsteilnehmerSorted();
    this.loadAntworten();
  }



  loadAbstimmung() {
    const abstimmungId = this.props.match.params.abstimmungId;
    getAbstimmungById(abstimmungId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          abstimmung: response
        });
      }
    })
  .catch(error => this.setState({hasError: error}));
  }

  loadAbstimmungsteilnehmerSorted() {
    const abstimmungId = this.props.match.params.abstimmungId;
    abstimmungTeilnehmerService.getAllTeilnehmerForAbstimmungSorted(abstimmungId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              abstimmungTeilnehmer: response
            });
          }
        })
        .catch(error => this.setState({hasError: error}));
  }

  loadAbstimmungsOptionen() {
    const abstimmungId = this.props.match.params.abstimmungId;
    abstimmungOptionService.getAllOptionenForAbstimmung(abstimmungId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          abstimmungsOptionen: response,
          abstimmungsOptionenLoaded: true,
        });
      }
    })
    .catch(error => this.setState({hasError: error, abstimmungsOptionenLoaded: true}));
  }

  loadAntworten() {
    const abstimmungId = this.props.match.params.abstimmungId;
    abstimmungOptionAntwortService.getAbstimmungAntwortenAggregated(abstimmungId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          antwortenGrouped: response
        });
      }
    })
    .catch(error => this.setState({hasError: error}));
    
  }

  loadLoggedInUserAndSetRights(){
    getCurrentUser()
        .then(response => {
          const loggedInUser = response;
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }

          if(loggedInUser.id !== undefined){
            userService.getNutzerById(loggedInUser.id)
                .then(response => {
                  if (this._isMounted) {
                    this.setState({hasUserEditRights: response.admin});
                  }
                })
                .catch(error => {
                  this.setErrorState(error);
                });
          }
          
        })
        .catch(error => this.setState({hasError: error}));
  }

  setErrorState(fehlertext){
    setErrorStateWithMessage(fehlertext, this);
  }

  onSave(){

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    const abstimmungId = this.props.match.params.abstimmungId;
    var saveRequest = {
      abstimmungId: abstimmungId,
      kommentar: this.state.kommentar.value
    };

    abstimmungService.saveKommentarOnAbstimmung(saveRequest)
        .then(response => {
          this.setState({
            abstimmung: response,
            successMsg: "Der Kommentar wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }
  
  render()
  {

    const { abstimmung, abstimmungsOptionen, antwortenGrouped, abstimmungTeilnehmer, abstimmungsOptionenLoaded, hasUserEditRights, loggedInUser } = this.state;

    var abstimmungsName = '';
    if(abstimmung){
      abstimmungsName = abstimmung.name
    }

    let anzahlCols = abstimmungsOptionen.length;
    //+2: einmal die Spielernamen Spalte und einmal die Edit-Button-Spalte
    anzahlCols = anzahlCols + 2;
    return (
        <div id="trainingsuebersicht">
          <h1>{abstimmungsName}</h1>

          {!!abstimmung && abstimmungsOptionenLoaded && abstimmungsOptionen.length <= 0 &&
              <span>Für diese Abstimmung stehen keine Optionen zur Verfügung.</span>
          }
          
          {/*!!mannschaft && !!mannschaft.spielerIds &&*/}
          {!!abstimmung && abstimmungsOptionenLoaded && abstimmungsOptionen.length > 0 &&
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th key={'tableHeader_Spieler_Col'} className="normal">
                      
                    </th>
                    {abstimmungsOptionen.map((abstimmungOption) =>
                        <th key={'tableHeader_' + abstimmungOption.id}>
                            {abstimmungOption.option}
                        </th>
                    )}
                    {/*Die leere Zelle in der Spalte fuer den Bearbeiten-Button*/}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                
                {/*Die Summen-Zeile der Antworten*/}
                {!!antwortenGrouped &&
                <tr>
                  <td> </td>
                  {abstimmungsOptionen.map((abstimmungOption) =>
                      <AntwortenZeile  key={abstimmungOption.id} 
                                       antwort={antwortenGrouped[abstimmungOption.id]}/>
                  )}

                  {/*Die leere Zelle in der Spalte fuer den Bearbeiten-Button*/}
                  <td></td>
                </tr>
                }
                
                
                {/*Die einzelnen Teilmnehmer-Spieler-Zeilen*/}
                {!!abstimmungsOptionen && !!abstimmungTeilnehmer && !!abstimmungTeilnehmer.length && !!loggedInUser && !!loggedInUser.id &&
                  <React.Fragment>
                    {abstimmungTeilnehmer.map((teilnehmer) =>
                        <SpielerZeile key={teilnehmer.id}
                                      abstimmungId={abstimmung.id}
                                      abstimmungTeilnehmer={teilnehmer}
                                      abstimmungsOptionen={this.state.abstimmungsOptionen}
                                      loggedInUser={loggedInUser}
                                      hasUserEditRights={hasUserEditRights}/>
                    )}
                  </React.Fragment>
                }

                </tbody>
              </table>
          }

          {!!abstimmung && !!abstimmung.id > 0 && abstimmungsOptionenLoaded && abstimmungsOptionen.length > 0 &&
          <div id="abstimmung_kommentar">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }
            <Form
                onFinish={this.onSave}
                className="abstimmungKommentar-edit-form"
                initialValues={{
                  "kommentar": this.state.abstimmung.kommentar
                }}>
              <FormItem
                  name="kommentar"
                  validateStatus={this.state.kommentar.validateStatus}
                  help={this.state.kommentar.errorMsg}>
  
                <TextArea
                    size="large"
                    name="kommentar"
                    maxLength={1500}
                    rows={7}
                    style={{width: '100%'}}
                    className="small-responsive-field"
                    value={this.state.kommentar.value}
                    onChange={(event) => this.handleInputChange(event)}
                />
              </FormItem>
              <Button type="submit"
                      style={{float: 'right', marginTop: '1em', marginBottom: '1em'}}
                      size="large"
                      className="myLargeBtn"
                      disabled={this.state.disableSubmitButton}>Speichern</Button>
            </Form>
          </div>
          }
        </div>
    );
  }

}

// tag::AntwortenZeile[]
// Die Antworten-Zelle fuer eine Abstimmungsoption
class AntwortenZeile extends React.Component{
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const antwort = this.props.antwort;
    
    return (
              <td>
                {!!this.props.antwort && 
                <span>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-success pull-right">Zusagen: {antwort.zusagen}</span>
                  </h4>
                  <br/>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-warning">Unsicher: {antwort.unsicher}</span>
                  </h4>
                  <br/>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-danger">Absagen: {antwort.absagen}</span>
                  </h4>
                  <br/>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-secondary">Offen: {antwort.offen}</span>
                  </h4>
                </span>
                }
              </td>
    )
    
  }
}
// end::AntwortenZeile[]


// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class SpielerZeile extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      abstimmungAntworten: [],
      loggedInUser: {}
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData()
  {
    this.loadAbstimmungAntwortForSpieler();
  }
    

  loadAbstimmungAntwortForSpieler(){
    const abstimmungTeilnehmerId = this.props.abstimmungTeilnehmer.id;

    abstimmungOptionAntwortService.getAllAbstimmungAntwortForTeilnehmer(abstimmungTeilnehmerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          abstimmungAntworten: response
        });
      }
    })
    .catch(error => this.setState({hasError: error}));
  }

  isLoggedInUser(){
    if(this.props.abstimmungTeilnehmer!== undefined && this.props.abstimmungTeilnehmer.nutzer!== undefined && this.props.loggedInUser !== undefined){
      if(this.props.abstimmungTeilnehmer.nutzer.id === this.props.loggedInUser.id){
        return true;
      }
    }
    return false;
  }
  
  render() {

    const { abstimmungAntworten } = this.state;
    const abstimmungId = this.props.abstimmungId;
    const abstimmungTeilnehmer = this.props.abstimmungTeilnehmer;
    
    let zellen = [];
    
    if(this.props.abstimmungsOptionen){
      for (let i = 0; i < this.props.abstimmungsOptionen.length; i++) {
        let laufVar = this.props.abstimmungsOptionen[i];
        let act = abstimmungAntworten.filter(antwort => antwort.abstimmungOption.id === laufVar.id);
        
        if(act !=  null && act.length > 0){
          
          var antwort = act[0];
          var zeitpunkt = moment(antwort.letzteAenderung).format("DD.MM.YYYY HH:mm");
          
          if(antwort.antwort === 'ZUGESAGT'){
            zellen.push(<img src={img_checked} className="img-responsive zugesagt-img" alt="hat zugesagt" title={"hat zugesagt am " + zeitpunkt}/>);
          }else if (antwort.antwort === 'ABGESAGT'){
            zellen.push(<img src={img_cancelled} className="img-responsive zugesagt-img" alt="hat abgesagt" title={"hat abgesagt am " + zeitpunkt}/>);
          } else {
            zellen.push(<img src={img_unbekannt} className="img-responsive zugesagt-img" alt="hat abgestimmt" title={"hat abgestimmt am " + zeitpunkt}/>);
          }
        }else{
          zellen.push(<img src={img_null} className="img-responsive zugesagt-img" alt="hat noch nicht geantwortet" title="hat noch nicht geantwortet"/>);
        }
        
      }
    }
    
    return [
      !!abstimmungTeilnehmer && !!abstimmungTeilnehmer.id &&
        <tr key={'spielerZeile' + abstimmungTeilnehmer.id} style={{backgroundColor: this.props.ersatz ? '#C4C8CC' : 'none'}}>
          <td key={'abstimmungTeilnehmerName' + abstimmungTeilnehmer.nutzer.vorname + '_' + abstimmungTeilnehmer.nutzer.nachname} style={{textAlign: 'left'}}>
            {getDisplayName(abstimmungTeilnehmer.nutzer, true)}
          </td>
          
          {zellen.map((zelle, i) =>
            <td key={'spielerAntwort' + abstimmungTeilnehmer.id + '_' + i}>{zelle}</td>
          )}
          
          <td>
            {!!abstimmungTeilnehmer && !!abstimmungTeilnehmer.nutzer && (!!this.isLoggedInUser() || !!this.props.hasUserEditRights) &&
            <a href={'/abstimmungsantwort/edit/teilnehmer/' + abstimmungTeilnehmer.id}
               className="btn btn-primary edit-button">
              <FontAwesomeIcon icon={faPen} />
            </a>
            }

          </td>
        </tr>
    ]
  }
}
// end::SpielerZeile[]

export default AbstimmungUebersicht;
