import './match_statistik_mannschaft_component.css';
import React from 'react';
import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DetailedMatchOverview from "../match_statistik/detailed_match_overview";
import {matchMapper} from "../../_mapper/matchMapper";

//DIe Profilansicht. Ansicht, wo ich mein eigenes Profil anschauen kann.
class MatchStatistikMannschaftComponent extends React.Component {
    _isMounted = false;
    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            //this.loadData();
        }
    }

    
    render() {
        const einzelStatistik = this.props.einzelStatistik;
        const doppelStatistik = this.props.doppelStatistik;
        
        return (
            <React.Fragment>
            {!!einzelStatistik &&
                <Tabs defaultActiveKey="einzel" id="matches-statistik-tab-pane" className="matches-tab-pane" style={{fontSize: '1rem'}}>
                    <Tab eventKey="einzel" title="Einzel" className="tab-header">
                        <table className="table table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th></th>
                                    <th># Matches</th>
                                    <th>Match-Bilanz</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                            {einzelStatistik.map((spielerStatistik) =>
                                <SpielerStatistikZeile key={spielerStatistik.nutzer.id}
                                                       spielerStatistik={spielerStatistik}/>
                            )}
        
                            </tbody>
                        </table>
                    </Tab>
                    <Tab eventKey="doppel" title="Doppel">
                        <table className="table table-hover">
                            <thead className="thead-light">
                            <tr>
                                <th></th>
                                <th># Matches</th>
                                <th>Match-Bilanz</th>
                            </tr>
                            </thead>
                            <tbody>

                            {doppelStatistik.map((spielerStatistik) =>
                                <SpielerStatistikZeile key={spielerStatistik.nutzer.id}
                                                       spielerStatistik={spielerStatistik}
                                                        isDoppel={true}/>
                            )}

                            </tbody>
                        </table>
                    </Tab>
                </Tabs>
            }
            </React.Fragment>
            
        );
    }
}

class SpielerStatistikZeile extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            showStatistikInsgesamtModal: false
        };

        this.handleCloseStatistikInsgesamtModal = this.handleCloseStatistikInsgesamtModal.bind(this);
        this.handleShowStatistikInsgesamtModal = this.handleShowStatistikInsgesamtModal.bind(this);
    }

    handleShowStatistikInsgesamtModal(){
        this.setState({showStatistikInsgesamtModal: true});
    }

    handleCloseStatistikInsgesamtModal(){
        this.setState({showStatistikInsgesamtModal: false});
    }
    
    render() {
        
        const spielerStatistik = this.props.spielerStatistik;
        const isDoppel = this.props.isDoppel;
        let matchProfilHref = '/spieler/matches/'+ spielerStatistik.nutzer.id;
        
        if(isDoppel){
            matchProfilHref = '/spieler/matches-doppel/'+ spielerStatistik.nutzer.id;
        }

        let matchStatistikMapped = {};
        if(isDoppel){
            matchStatistikMapped = {
                anzahlMatchesInsgesamt: spielerStatistik.anzahlMatchesInsgesamt,
                gewonnen: matchMapper.mapDoppelList(spielerStatistik.gewonnen),
                verloren: matchMapper.mapDoppelList(spielerStatistik.verloren),
                unentschieden: matchMapper.mapDoppelList(spielerStatistik.unentschieden)
            }
        }else{
            matchStatistikMapped = {
                anzahlMatchesInsgesamt: spielerStatistik.anzahlMatchesInsgesamt,
                gewonnen: matchMapper.mapEinzelList(spielerStatistik.gewonnen),
                verloren: matchMapper.mapEinzelList(spielerStatistik.verloren),
                unentschieden: matchMapper.mapEinzelList(spielerStatistik.unentschieden)
            }
        }
        
        return (
            <React.Fragment>
                <tr>
                    <td style={{textAlign: 'left'}}>
                        <a href={matchProfilHref}>
                            <FontAwesomeIcon icon={faUser}/>
                            &nbsp;
                            <span style={{color: 'black'}}>{spielerStatistik.nutzer.nickname}</span>
                        </a>
                    </td>

                    <td>{spielerStatistik.anzahlMatchesInsgesamt}</td>

                    <td onClick={this.handleShowStatistikInsgesamtModal}>{spielerStatistik.gewonnen.length}:{spielerStatistik.verloren.length}</td>
                </tr>

                {/*Der Dialog fuer die Gesamt-Statistik. Also alle Matches*/}
                {!!spielerStatistik &&
                <Modal show={this.state.showStatistikInsgesamtModal}
                       onHide={this.handleCloseStatistikInsgesamtModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{spielerStatistik.nutzer.vorname} {spielerStatistik.nutzer.nachname}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DetailedMatchOverview
                            matches={matchStatistikMapped}
                            isDoppel={isDoppel}
                            loggedInUser={null}
                            displayName={''}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseStatistikInsgesamtModal}>
                            Schließen
                        </Button>
                    </Modal.Footer>
                </Modal>
                }
            </React.Fragment>
        );
    }
}

export default MatchStatistikMannschaftComponent;