import React from 'react';
import {Form, Select} from "antd";
import BackButton from "../../common/BackButton";
import {Button} from "react-bootstrap";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState} from "../../util/ErrorUtil";
import {checkEmailAvailability} from "../../_services";
import {EMAIL_MAX_LENGTH} from "../../constants";
import {rechtService} from "../../_services/rechtService";
import {mannschatsService as mannschaftsService} from "../../_services/mannschatsService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const FormItem = Form.Item;

// tag::SpielerProfilAllgemeineEinstellungen[]
class SpielerProfilRechteEinstellungen extends React.Component
{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            errorMsg: null,
            successMsg: null,
            errors: {},
            formIsValid: true,
            nutzer: {},
            rechte: [],
            objectSelectItems: [],
            mannschaften: [],
            selectedRecht: {
                value: 'VIEW'
            },
            selectedObject: {
                value: null
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDeleteRecht = this.onDeleteRecht.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    }
    
    componentDidMount()
    {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
        if(this.props.nutzer !== nextProps.nutzer && this.props.nutzer.id !== nextProps.nutzer.id) {
            // Reset State
            this.loadData();
        }
    }

    loadData(){
        this.loadNutzerRechte();
        this.loadMannschaften();
    }
    
    loadNutzerRechte(){
            const nutzerId = this.props.nutzer.id;
            
            rechtService.getByNutzerId(nutzerId)
                .then(responseRechte => {
                    if (this._isMounted) {
                        this.setState({
                            rechte: responseRechte
                        });
                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        this.setState({
                            hasError: error
                        });
                    }
                });
    }

    loadMannschaften(){
        
        const nutzerId = this.props.nutzer.id;

        mannschaftsService.getAllMannschaftenOfVereineWhereNutzerIs(nutzerId)
            .then(response => {
                if (this._isMounted) {


                    //response.map((mannschaft) =>  <li>{number}</li>);
                    let sitems = [];
                    response.map((mannschaft)=> {
                        let selectItem = {
                            id: mannschaft.id,
                            label: mannschaft.name
                        };
                        sitems.push(selectItem);
                    })
                    
                    this.setState({
                        objectSelectItems: sitems
                    });
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({
                        hasError: error
                    });
                }
            });
    }
    
    handleSelectInputChange(value, name) {
        this.setState({
            [name] : {
                value: value
            },
            //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
            //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
            successMsg: null,
        });
    }

    
    handleSubmit() {

        this.setState({
            errorMsg: null,
            successMsg: null
        });
        
        // //Wenn keine objectId gesetzt ist, dann wurde keine Startseite ausgewaehlt.
        // //Dann darf das Object auch gar nicht mitgesendet werden, da sonst fuer die id und objectId
        // //leere Felder mitgesendet werden und in der RESTApi sind das im Dto ja aber long (also Pflicht-) Felder
        // if(this.state.selectedStartseite.selectedObjectId > 0){
        //     startseiteToSave = {
        //         nutzer: this.props.nutzer,
        //         object: 'TRAINING',
        //         objectId: this.state.selectedStartseite.selectedObjectId
        //     };
        // }
        //
        var rechtSaveRequest = {
            nutzer: this.props.nutzer,
            recht: this.state.selectedRecht.value,
            objectId: this.state.selectedObject.value
        };
        
        rechtService.save(rechtSaveRequest)
            .then(response => {
                this.setState({
                    successMsg: "Rechte erfolgreich gespeichert."
                });
                this.loadData();
            }).catch(error => {
            setErrorState(error, this);
        });
    }

    onDeleteRecht(rechtId){
        rechtService.deleteRecht(rechtId)
            .then(response => {
                this.setState({
                    successMsg: "Recht wurde erfolgreich gelöscht."
                });
                this.loadData();
            }).catch(error => {
            setErrorState(error, this);
        });
    }

   
    render()
    {
        
        const {rechte, objectSelectItems } = this.state;


        var layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };
        
        var formLayout = 'form-container-breit';
        if(this.props.showAdminFields){
            formLayout = 'allgemeine-einstellungen-form';
            layout = {};
        }

        let selectItems = objectSelectItems.map((objectSelectItem) =>
            <Select.Option value={objectSelectItem.id} key={objectSelectItem.id}>{objectSelectItem.label}</Select.Option>
        );

        var initialValues={
            "selectedRecht": 'VIEW'
        };

        return (
            <React.Fragment>
              
                <div className={formLayout}>
                    {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                    }

                    {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                    }
                    <fieldset>
                        <legend><h2>Rechte des Nutzers</h2></legend>

                        <Form 
                            onFinish={this.handleSubmit}
                            initialValues={initialValues}
                              {...layout}>

                            <FormItem
                                label="Recht"
                                name="selectedRecht"
                                rules={[{
                                    required: true,
                                    message: 'Bitte wähle ein Recht aus.'
                                }]}
                                validateStatus={this.state.selectedRecht.validateStatus}
                                help={this.state.selectedRecht.errorMsg}>
                                <Select
                                    size="large"
                                    onChange={(value, event) => this.handleSelectInputChange(value, 'selectedRecht')}>
                                    <Select.Option value="ADMIN" key="Admin">ADMIN</Select.Option>
                                    <Select.Option value="VIEW" key="View">VIEW</Select.Option>
                                </Select>
                            </FormItem>

                            <FormItem
                                label="Objekt"
                                name="selectedObject"
                                rules={[{
                                    required: true,
                                    message: 'Bitte wähle ein Objekt aus, auf welches das Recht angewendet werden soll.'
                                }]}
                                validateStatus={this.state.selectedObject.validateStatus}
                                help={this.state.selectedObject.errorMsg}>
                                <Select
                                    size="large"
                                    onChange={(value, event) => this.handleSelectInputChange(value, 'selectedObject')}>
                                    {selectItems}
                                </Select>
                            </FormItem>

                            <BackButton/>
                            <Button type="submit"
                                    size="large"
                                    style={{marginLeft: '1em'}}
                                    className="myLargeBtn">Speichern</Button>

                            <br/>
                            <br/>
                        </Form>
                        
                        <table className="table table-hover left-aligined-table" style={{textAlign: 'left'}}>
                            <thead className="thead-light">
                            <tr>
                                <th>Recht</th>
                                <th>Objekt</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {rechte.map(recht => (
                                <tr key={recht.id}>
                                    <td>{recht.recht}</td>
                                    <td>{recht.object.label}</td>
                                    <td>
                                        {/*loeschen Button*/}
                                        <Button
                                            className="btn btn-secondary delete-button"
                                            onClick={()=>this.onDeleteRecht(recht.id)}>
                                            <FontAwesomeIcon icon={faTrashAlt}  />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        
                    </fieldset>

                </div>
        
            </React.Fragment>
        );
    }

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib deine Email-Adresse an. Die Angabe der Email-Adresse ist pflicht.'
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Das ist keine valide Email-Adresse.'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Email-Adresse ist zu lang. Es sind maximal ${EMAIL_MAX_LENGTH} Zeichen erlaubt.`
            }
        }

        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });
            return;
        }

        //zuerst schauen, ob die Email ueberhaupt geandert wurde.
        //sonst findet er ja ein Duplikat, und zwar meine eigene
        const originalEmail = this.props.nutzer.email;

        if(originalEmail === emailValue){
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
            return;
        }
        
        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
            .then(response => {
                if(response.available) {
                    this.setState({
                        email: {
                            value: emailValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    this.setState({
                        email: {
                            value: emailValue,
                            validateStatus: 'error',
                            errorMsg: 'Ein Konto mit dieser Email-Adresse existiert bereits. Bitte melde ich an.'
                        }
                    });
                }
            }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
        });
    }
}

export default SpielerProfilRechteEinstellungen;