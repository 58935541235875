import React from 'react';

import './doppelaufstellung.css';
import {Form, Select} from "antd";
import {spielerService} from "../../../../_services/spielerService";
import {Button} from "react-bootstrap";
import {wettkampfService} from "../../../../_services/wettkampfService";
import DoppelAufstellung from "../../../../dto/wettkampf/DoppelAufstellung";
import DoppelaufstellungCell from "./DoppelaufstellungCell";
import DoppelaufstellungFilter from "./doppelaufstellung_filter";
import DoppelAufstellung_Filter from "../../../../dto/wettkampf/DoppelAufstellung_Filter";

const FormItem = Form.Item;

class Doppelaufstellung extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            doppelaufstellungen: [],
            filtered: [],
            spielerListe: [],
            spieler1: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler2: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler3: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler4: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler5: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler6: {
                value: '',
                validateStatus: '',
                errorMsg: null
            }
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        
    }

    loadData(){
        this.loadSpieler();
    }
    
    loadSpieler(){
        const mannschaftsId = this.props.match.params.mannschaftsId;
        
        spielerService.getSpielerByMannschaftsIdSortedByPosition(mannschaftsId)
            .then(responseSpieler => {
                if (this._isMounted) {
                    this.setState({spielerListe: responseSpieler});

                    if(responseSpieler && responseSpieler.length > 5){
                        this.setState({
                            spieler1: {
                                value: responseSpieler[0].id,
                                validateStatus: '',
                                errorMsg: null
                            },
                            spieler2: {
                                value: responseSpieler[1].id,
                                validateStatus: '',
                                errorMsg: null
                            },
                            spieler3: {
                                value: responseSpieler[2].id,
                                validateStatus: '',
                                errorMsg: null
                            },
                            spieler4: {
                                value: responseSpieler[3].id,
                                validateStatus: '',
                                errorMsg: null
                            },
                            spieler5: {
                                value: responseSpieler[4].id,
                                validateStatus: '',
                                errorMsg: null
                            },
                            spieler6: {
                                value: responseSpieler[5].id,
                                validateStatus: '',
                                errorMsg: null
                            }
                        });
                    }

                }
            })
            .catch(errorSpieler => {
                if (this._isMounted) {
                    this.setState({hasError: errorSpieler})
                }
            });
    }

    
    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
    }


    handleSubmit() {

        this.setState({
            errorMsg: null,
            successMsg: null
        });


        wettkampfService.getAllPossibleDoppelaufstellungen(
            this.state.spieler1.value,
            this.state.spieler2.value,
            this.state.spieler3.value,
            this.state.spieler4.value,
            this.state.spieler5.value,
            this.state.spieler6.value)
            .then(response => {
                if (this._isMounted) {
                    let mapped = [];
                    if(response){
                        mapped = response.map((dp) => new DoppelAufstellung(dp));
                    }
                    
                    this.setState({
                        doppelaufstellungen: mapped,
                        filtered: mapped,
                    });
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({hasError: error})
                }
            });
    }


    handleSelectInputChange(value, name) {
        this.setState({
            [name] : {
                value: value
            }
        });
    }
    
    handleFilter(filterRequest){
        const doppelaufstellungen = this.state.doppelaufstellungen;

        const filter = new DoppelAufstellung_Filter(filterRequest);
        let filtered = doppelaufstellungen;
        filtered =  filtered.filter(dp => dp.matches(filter));
        this.setState({filtered: filtered});
        
    }

    handleClearFilter(){
        this.setState({filtered: this.state.doppelaufstellungen});
    }

    render() {
        const { spielerListe, filtered } = this.state;
        
        let spielerItems = spielerListe.map((spieler) =>
            <Select.Option value={spieler.id} key={spieler.id}>{spieler.nickname}</Select.Option>
        );

        var initialValues = {};
        if(spielerListe && spielerListe.length > 5){
            initialValues={
                "spieler1": spielerListe[0].id,
                "spieler2": spielerListe[1].id,
                "spieler3": spielerListe[2].id,
                "spieler4": spielerListe[3].id,
                "spieler5": spielerListe[4].id,
                "spieler6": spielerListe[5].id,
            };
        }

        return (
            <React.Fragment>
                {!!spielerListe && !!spielerListe.length &&
                <div>
                    <h1>Doppelaufstellungen</h1>
                    <p>Hier werden dir die möglichen Doppelaufstellungen angezeigt.</p>

                    <Form
                        onFinish={this.handleSubmit}
                        hideRequiredMark={true}
                        initialValues={initialValues}>

                        <FormItem
                            label="Spieler 1"
                            name="spieler1"
                            className="doppelaufstellung-form-item"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.spieler1.validateStatus}
                            help={this.state.spieler1.errorMsg}>

                            <Select
                                style={{width: '11em'}}
                                size="medium"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'spieler1')}>
                                {spielerItems}
                            </Select>
                        </FormItem>

                        <FormItem
                            label="Spieler 2"
                            name="spieler2"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.spieler2.validateStatus}
                            help={this.state.spieler2.errorMsg}>

                            <Select
                                style={{width: '11em'}}
                                size="medium"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'spieler2')}>
                                {spielerItems}
                            </Select>
                        </FormItem>

                        <FormItem
                            label="Spieler 3"
                            name="spieler3"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.spieler3.validateStatus}
                            help={this.state.spieler3.errorMsg}>

                            <Select
                                style={{width: '11em'}}
                                size="medium"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'spieler3')}>
                                {spielerItems}
                            </Select>
                        </FormItem>

                        <FormItem
                            label="Spieler 4"
                            name="spieler4"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.spieler4.validateStatus}
                            help={this.state.spieler4.errorMsg}>

                            <Select
                                style={{width: '11em'}}
                                size="medium"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'spieler4')}>
                                {spielerItems}
                            </Select>
                        </FormItem>

                        <FormItem
                            label="Spieler 5"
                            name="spieler5"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.spieler5.validateStatus}
                            help={this.state.spieler5.errorMsg}>

                            <Select
                                style={{width: '11em'}}
                                size="medium"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'spieler5')}>
                                {spielerItems}
                            </Select>
                        </FormItem>

                        <FormItem
                            label="Spieler 6"
                            name="spieler6"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.spieler6.validateStatus}
                            help={this.state.spieler6.errorMsg}>

                            <Select
                                style={{width: '11em'}}
                                size="medium"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'spieler6')}>
                                {spielerItems}
                            </Select>
                        </FormItem>
                        
                        <Button type="submit"
                                size="large"
                                style={{marginLeft: '1em'}}
                                className="myLargeBtn">Doppeln</Button>
                    </Form>

                    <br/>
                    <br/>
                    
                    <DoppelaufstellungFilter spielerListe={spielerListe} onFilter={this.handleFilter} onClearFilter={this.handleClearFilter}/>
                    
                    <br/>
                    <br/>
                    
                    <div>
                        <DoppelaufstellungenListe 
                            doppelaufstellungen={filtered}
                        />
                    </div>
                </div>
                }
            </React.Fragment>
        );
    }
}


// DoppelaufstellungenListe
class DoppelaufstellungenListe extends React.Component{
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            anzahlPinnedItems: 0,
            anzahlBannedItems: 0,
        };

        //this.moveItemFirst = this.moveItemFirst.bind(this);
        this.pinItem = this.pinItem.bind(this);
        this.banItem = this.banItem.bind(this);
    }

    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getStyleClass(doppelPaarung){
        return {
            //backgroundColor: '\' + doppelPaarung.getBackgroudnColor() +\''
            backgroundColor: `${doppelPaarung.getBackgroudnColor()}`
            //backgroundColor: '#35F04E'
        };
    }

    pinItem (doppelAufstellung) {
        let pin = doppelAufstellung.pinned;
        let isBanned = doppelAufstellung.banned;
        let sourceId = doppelAufstellung.orderPosition;

        pin = !pin;
        doppelAufstellung.pinned = pin;
        if(pin){
            if(isBanned){
                //pin banned item
                //1. UN-BAN
                doppelAufstellung.banned = !isBanned;
                this.countBanned(false);
                //2. PIN
                //das Item PINnen
                this.moveItemFirst(sourceId);
                this.countPinned(true);
                return;
            }
        }

        //pin the item
        if(pin){
            this.moveItemFirst(sourceId);
            this.countPinned(true);
            
        } else{
            //un-pin
            var anzahlPinnedItems = this.state.anzahlPinnedItems;
            if(anzahlPinnedItems < 0){
                return;
            }
            const destinationIndex = this.props.doppelaufstellungen[anzahlPinnedItems - 1].orderPosition;
            this.moveItem(sourceId, destinationIndex);
            this.countPinned(false);
        }
    }

    banItem(doppelAufstellung) {
        let ban = doppelAufstellung.banned;
        let isPinned = doppelAufstellung.pinned;
        let sourceId = doppelAufstellung.orderPosition;

        //pin = true, wenn das Item ge-banned werden soll
        // pin = false, wenn das Item un-banned werden soll
        ban = !ban;
        doppelAufstellung.banned = ban;
        if(ban){
            if(isPinned){
                doppelAufstellung.pinned = !isPinned;
                //---ban a pinned Item
                //1. UN-PIN
                //zuerst das Item un-bannen: wir zaehlen hier nur die banned items runter. das verhscieben sparen wir uns.
                //werden nachher nur einmal im Ganzen verschieben.
                this.countPinned(false);
                //2. BAN
                //das Item BANnen
                this.moveItemLast(sourceId);
                this.countBanned(true);
                return;
            }
        }


        //ban the item
        if(ban){
            this.moveItemLast(sourceId);
            this.countBanned(true);
        } else{
            //un-ban
            const size = this.state.items.length;
            var anzahlBannedItems = this.countBanned(false);
            if(anzahlBannedItems < 0){
                anzahlBannedItems = 0;
            }

            const destinationIndex = this.state.items[size- anzahlBannedItems - 1].orderPosition;
            this.moveItem(sourceId, destinationIndex);
        }
    };


    moveItemFirst(sourceId) {
        const destinationIndex = this.props.doppelaufstellungen[0].orderPosition;

        this.moveItem(sourceId, destinationIndex);
    }

    moveItemLast(sourceId) {
        const destinationIndex = this.props.doppelaufstellungen[this.props.doppelaufstellungen.length - 1].orderPosition;

        this.moveItem(sourceId, destinationIndex);
    }

    moveItem (sourceId, destinationId) {

        const sourceIndex = this.props.doppelaufstellungen.findIndex(
            item => item.orderPosition === sourceId
        );
        const destinationIndex = this.props.doppelaufstellungen.findIndex(
            item => item.orderPosition === destinationId
        );

        // If source/destination is unknown, do nothing.
        if (sourceId === -1 || destinationId === -1) {
            return;
        }

        const offset = destinationIndex - sourceIndex;

        const moved = this.moveElement(this.props.doppelaufstellungen, sourceIndex, offset);
        
        this.setState(state => ({
            items: moved
        }));
    }


    moveElement(array, index, offset) {
        const newIndex = index + offset;

        return this.move(array, index, newIndex);
    }

    move(array, oldIndex, newIndex) {

        if (newIndex >= array.length) {
            newIndex = array.length - 1;
        }
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
        
        return array;
    }

    countPinned(countUp){
        var anzahlPinnedItems = this.state.anzahlPinnedItems;
        if(countUp){
            //hochzaehlen
            anzahlPinnedItems = anzahlPinnedItems + 1;

        }else{
            //runter zaehlen
            anzahlPinnedItems = anzahlPinnedItems - 1;
            if(anzahlPinnedItems < 0){
                anzahlPinnedItems = 0;
            }
        }

        this.setState(state => ({
            anzahlPinnedItems: anzahlPinnedItems
        }));

        return anzahlPinnedItems;
    }

    countBanned(countUp){
        var anzahlBannedItems = this.state.anzahlBannedItems;
        if(countUp){
            //hochzaehlen
            anzahlBannedItems = anzahlBannedItems + 1;
        }else{
            //runter zaehlen
            anzahlBannedItems = anzahlBannedItems - 1;
            if(anzahlBannedItems < 0){
                anzahlBannedItems = 0;
            }
        }

        this.setState(state => ({
            anzahlBannedItems: anzahlBannedItems
        }));

        return anzahlBannedItems;
    }



    render() {
        const doppelaufstellungen = this.props.doppelaufstellungen;
        
        return (
            <React.Fragment>
                {!!doppelaufstellungen && !!doppelaufstellungen.length > 0 &&
                    <div className='doppelaufstellungs-liste'>
                    {doppelaufstellungen.map((doppelaufstellung, idx) =>
                        <React.Fragment key={idx + '_doppelaustellungen'} >
                            <DoppelaufstellungCell doppelAufstellung={doppelaufstellung}
                                                   onPinItem={this.pinItem}
                                                   onBanItem={this.banItem}/>
                        </React.Fragment>
                               
                        )}
                    </div>

                }
            </React.Fragment>
        )
    }
}
// end::DoppelaufstellungenListe

export default Doppelaufstellung;