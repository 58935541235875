
import React from 'react';
import {Modal} from "antd";


class DeleteConfirmModal extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            successMsg: '',
            errorMsg: ''
        };
    }

    render() {
        
        return (
            <Modal
                visible={this.props.show}
                title={this.props.title}
                okText="Löschen"
                cancelText="Abbrechen"
                onCancel={this.props.onHandleClose}
                onOk={this.props.onHandleOk}
            >
                {this.props.text}
            </Modal>

        );
    }
}


export default DeleteConfirmModal;