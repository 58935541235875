import React from 'react';

import './App.css';

import Header from './layout/Header.js';
import Main from './layout/Main.js';

import {withRouter} from 'react-router-dom';

import {getCurrentUser} from "./_services/authService";
import {ACCESS_TOKEN} from './constants';

import {Layout, notification} from 'antd';
import {Helmet} from "react-helmet";
import {getRedirectUrlForNutzerInfo} from "./util/StartseiteUtil";
import {userService} from "./_services";

const { Content } = Layout;

class App extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: false,
      nutzerRedirectInfo: {}
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });
  }

  loadCurrentUser() {
    this.setState({
      isLoading: true
    });
    getCurrentUser()
    .then(response => {
      if (this._isMounted) {
        this.setState({
          currentUser: response,
          isAuthenticated: true,
          //isLoading: false
        });      
      }

      userService.getAllRedirectInformationForNutzer(response.id)
          .then(nutzerRedirectInfoResponse => {
            this.setState({
              nutzerRedirectInfo: nutzerRedirectInfoResponse,
              isLoading: false
            });
          }).catch(error => {
        this.setState({
          hasError: error,
          isLoading: false
        });
      });
      
    })
    .catch(error => {
      if (this._isMounted) {
        if (this._isMounted) {
          this.setState({
            hasError: error,
            isAuthenticated: false,
            isLoading: false
          });
        }
      }
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadCurrentUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Handle Logout, Set currentUser and isAuthenticated state which will be passed to other components
  handleLogout(redirectTo="/", notificationType="success", description="You're successfully logged out.") {
    localStorage.removeItem(ACCESS_TOKEN);

    if (this._isMounted) {
      this.setState({
        currentUser: null,
        isAuthenticated: false
      });     
    }
    
    this.props.history.push(redirectTo);
  }

  /* 
   This method is called by the Login component after successful login 
   so that we can load the logged-in user details and set the currentUser &
   isAuthenticated state, which other components will use to render their JSX
  */
  handleLogin() {
    //this.loadCurrentUser();


    this.setState({
      isLoading: true
    });
    
    //load current user
    getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({
              currentUser: response,
              isAuthenticated: true,
              //isLoading: false
            });
          }

          //load Redirect Info
          userService.getAllRedirectInformationForNutzer(response.id)
              .then(nutzerRedirectInfoResponse => {
                this.setState({
                  nutzerRedirectInfo: nutzerRedirectInfoResponse,
                  isLoading: false
                });

                this.redirectAfterLogin(nutzerRedirectInfoResponse);
                
              }).catch(error => {
            this.setState({
              //wollen wegen der Startseite keinen Fehler setzen.
              //hasError: error,
              isLoading: false
            });
            //Trotzdem weiterleiten
            //hier wird auch reingegangen, wenn fuer den Spieler keine nutzerRedirectInfo geufunden wird.
            //liegt an diesem Mist, dass man NULL nicht returnieren kann vom Rest-Service
            this.redirectAfterLogin(this.state.nutzerRedirectInfo);
          });

        })
        .catch(error => {
          if (this._isMounted) {
            if (this._isMounted) {
              this.setState({
                hasError: error,
                isAuthenticated: false,
                isLoading: false
              });
            }
          }
        });
    
    // const { from } = this.props.location.state || { from: { pathname: "/" } };
    // this.props.history.push(from);
    //
    // console.log('--- from');
    //     console.log(from);
    // console.log(this.state.startseite);
    // this.props.history.push('/training/uebersicht/4');
  }

  handleSignup() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    
    this.props.history.push(from);
  }
  
  redirectAfterLogin(nutzerRedirectInfo){
    //redirect
    const redirectTo = this.getRedirectPathFromNutzerRedirectInfo(nutzerRedirectInfo);
    this.props.history.push(redirectTo);
  }
  
  getRedirectPathFromNutzerRedirectInfo(nutzerRedirectInfo){
    var homeLink = getRedirectUrlForNutzerInfo(nutzerRedirectInfo);

    if(homeLink === undefined){
      const { from } = this.props.location.state || { from: { pathname: "/" } };
      return from;
    }

    return homeLink;
  }

  render() {

    return (
        <Layout className="app-container">
          <Helmet>
            <title>Mannschaftsplanung</title>
            <description>Mannschaftsplanung fuer Mannschaftstraining und Wettkaempfe</description>
            {/*ist fuer die mobile View. Sonst passt das nicht, wenn man das Smartphone dreht und so weiter*/}
            {/*alles, was nach initial-scale kommt (also ab dem 3. Argument) ist nur fuer den Chrome Browser.*/}
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, target-densityDpi=device-dpi" />
            <meta http-equiv="Content-Security-Policy" content="script-src 'self'; style-src 'self' 'unsafe-inline' https://fonts.googleapis.com/ https://*.fontawesome.com/;"/>

          </Helmet>
          
          <Content className="app-content">
            <div>
                <Header onLogout={this.handleLogout} isAuthenticated={this.state.isAuthenticated} />
                <Main onLogin={this.handleLogin} onSignup={this.handleSignup}/>
            </div>
          </Content>
        </Layout>
    );
  }
}

export default withRouter(App);