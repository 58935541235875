import React from 'react'

import MatchEditAndListComponent from "./match_edit_and_list_component";
import {matchDoppelService} from "../../_services/matchDoppelService";
import {setErrorState} from "../../util/ErrorUtil";
import {matchMapper} from "../../_mapper/matchMapper";

class MatchEditAndListDoppel extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      successMsg: '',
      errorMsg: ''
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadMatches();
    this.props.onRefreshData();
  }

  loadMatches() {
    matchDoppelService.findAllOrderByDatum()
        .then(response => {
          if (this._isMounted) {
            this.setState({
              matches: matchMapper.mapDoppelList(response)
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    
    return (
        
        <MatchEditAndListComponent loggedInUser={this.props.loggedInUser} 
                                   verein={this.props.verein} 
                                   matches={this.state.matches} 
                                   isDoppel={true} 
                                   onRefreshData={this.loadData}/>
    );
  }
}

export default MatchEditAndListDoppel;
