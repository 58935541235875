import './trainingsplanung_runde_row.css';
import React from 'react'
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Button, Dropdown} from "react-bootstrap";
import TrainingsplanungEinzelModal from "./trainingsplanung_einzel_modal";
import {setErrorState} from "../../util/ErrorUtil";
import {trainingsplanungEinzelService} from "../../_services/training/planung/trainingsplanungEinzelService";
import {trainingsplanungDoppelService} from "../../_services/training/planung/trainingsplanungDoppelService";
import {getDisplayName} from "../../_helpers/displayHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import TrainingsplanungDoppelModal from "./trainingsplanung_doppel_modal";
import {trainingsplanungRundenService} from "../../_services/training/planung/trainingsplanungRundenService";
import DeleteConfirmModal from "../../common/DeleteConfirmModal";

class TrainingsplanungRundeRow extends React.Component
{
  _isMounted = false;


    constructor(props) {
        super(props);
        this.state = {
            visibleEinzel: false,
            visibleDoppel: false,
            // die bestätigen Modal Dialoge fuers löschen
            showDeleteConfirmModalRunde: false,
            showDeleteConfirmModalEinzel: false,
            showDeleteConfirmModalDoppel: false,
            successMsg: '',
            errorMsg: ''
        };
        this.reloadData = this.reloadData.bind(this);

        // die bestätigen Modal Dialoge fuers löschen
        this.handleCloseRundeModal = this.handleCloseRundeModal.bind(this);
        this.handleShowRundeModal = this.handleShowRundeModal.bind(this);
        this.handleActionRundeModal = this.handleActionRundeModal.bind(this);
        
        this.handleCloseDoppelModal = this.handleCloseDoppelModal.bind(this);
        this.handleShowDoppelModal = this.handleShowDoppelModal.bind(this);
        this.handleActionDoppelModal = this.handleActionDoppelModal.bind(this);
        
        this.handleCloseEinzelModal = this.handleCloseEinzelModal.bind(this);
        this.handleShowEinzelModal = this.handleShowEinzelModal.bind(this);
        this.handleActionEinzelModal = this.handleActionEinzelModal.bind(this);

    }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  

  reloadData(){
      this.props.onReloadRunden();
  }
  
  onDeleteEinzel(id){
    trainingsplanungEinzelService.deleteEinzelById(id)
        .then(response => {
          if (this._isMounted) {
              this.reloadData();
              this.setState({ showDeleteConfirmModalEinzel: false });
              this.props.onSetSuccessMessage("Einzel wurde erfolgreich gelöscht.");
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  showModalEinzel = () => {
    this.setState({ visibleEinzel: true });
  };

  handleCancelEinzel = () => {
    this.setState({ visibleEinzel: false });
  };

  reloadEinzel = () => {
      this.reloadData();
  };


  onDeleteDoppel(id){
    trainingsplanungDoppelService.deleteDoppelById(id)
        .then(response => {
          if (this._isMounted) {
              this.reloadData();
              this.setState({ showDeleteConfirmModalDoppel: false });
              this.props.onSetSuccessMessage("Doppel wurde erfolgreich gelöscht.");
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  showModalDoppel = () => {
    this.setState({ visibleDoppel: true });
  };

  
  handleCancelDoppel = () => {
    this.setState({ visibleDoppel: false });
  };
  
  reloadDoppel = () => {
      this.reloadData();
  };

  onDeleteRunde(id){
    trainingsplanungRundenService.deleteRundeById(id)
        .then(response => {
          if (this._isMounted) {
              this.reloadData();
              this.props.onSetSuccessMessage("Runde wurde erfolgreich gelöscht.");
              this.setState({ showDeleteConfirmModalRunde: false });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

    // die bestätigen Modal Dialoge fuers löschen
    // Runde
    handleCloseRundeModal(){
        this.setState({ showDeleteConfirmModalRunde: false });
    }

    handleShowRundeModal(){
        this.setState({ showDeleteConfirmModalRunde: true });
    }

    handleActionRundeModal(id){
        this.onDeleteRunde(id);
    }

    // Einzel
    handleCloseEinzelModal(){
        this.setState({ showDeleteConfirmModalEinzel: false });
    }

    handleShowEinzelModal(){
        this.setState({ showDeleteConfirmModalEinzel: true });
    }

    handleActionEinzelModal(id){
        this.onDeleteEinzel(id);
    }

    // Doppel
    handleCloseDoppelModal(){
        this.setState({ showDeleteConfirmModalDoppel: false });
    }

    handleShowDoppelModal(){
        this.setState({ showDeleteConfirmModalDoppel: true });
    }

    handleActionDoppelModal(id){
        this.onDeleteDoppel(id);
    }
    
  render()
  {


    const runde = this.props.runde;
    const einzel = this.props.runde.trainingsplanungEinzel;
    const doppel = this.props.runde.trainingsplanungDoppel;
      
    return (
        <React.Fragment>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          <tr className={'planung_match_table_row_spacer'} key={'training_palnung_runde_row_' + runde.id}>
            <td colSpan={5} style={{paddingBottom: '1em'}}>
                <h4 style={{float: 'left', marginRight: '1em'}}>{runde.bezeichnung}</h4>
                
                <Dropdown style={{float: 'left', marginRight: '1em'}}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        <FontAwesomeIcon
                            icon={faPlus}
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={this.showModalEinzel}>Einzel</Dropdown.Item>
                        <Dropdown.Item onClick={this.showModalDoppel}>Doppel</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Button
                    className="btn btn-secondary delete-button"
                    onClick={this.handleShowRundeModal}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>

                <DeleteConfirmModal 
                    show={this.state.showDeleteConfirmModalRunde}
                    onHandleClose={this.handleCloseRundeModal}
                    onHandleOk={() => this.handleActionRundeModal(runde.id)}
                    title={'Runde löschen'}
                    text={'Möchtest du die Runde wirklich löschen?'}/>
            </td>
          </tr>
            

            {einzel.map((ein, i) =>
                <tr className={'planung_match_table_content_rows'}
                    key={'training_palnung_runde_row_einzel_' + runde.id + '_' + i}>
                    <td><i>EINZEL</i></td>
                    <td>{getDisplayName(ein.nutzerHeim)}</td>
                    <td>-</td>
                    <td>{getDisplayName(ein.nutzerGast)}</td>
                    <td>
                        {/*loeschen Button*/}
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            size="xl"
                            style={{marginLeft: '0.7em'}}
                            className="delete-icon"
                            onClick={this.handleShowEinzelModal}
                        />
                        <DeleteConfirmModal
                            show={this.state.showDeleteConfirmModalEinzel}
                            onHandleClose={this.handleCloseEinzelModal}
                            onHandleOk={() => this.handleActionEinzelModal(ein.id)}
                            title={'Einzel löschen'}
                            text={'Möchtest du das Einzel wirklich löschen?'}/>
                    </td>
                </tr>
            )}
            

            {doppel.map((dop, i) =>
                <React.Fragment key={'training_palnung_runde_row_2_doppel_' + i}>
                    <tr className={'planung_match_table_content_rows planung_match_table_row_spacer_small'}>
                        <td><i>DOPPEL</i></td>
                        <td>{getDisplayName(dop.nutzerHeim1)}</td>
                        <td>-</td>
                        <td>{getDisplayName(dop.nutzerGast1)}</td>
                        <td rowSpan={2}>
                            {/*loeschen Button*/}
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="xl"
                                style={{marginLeft: '0.7em'}}
                                className="delete-icon"
                                onClick={this.handleShowDoppelModal}
                            />
                            <DeleteConfirmModal
                                show={this.state.showDeleteConfirmModalDoppel}
                                onHandleClose={this.handleCloseDoppelModal}
                                onHandleOk={() => this.handleActionDoppelModal(dop.id)}
                                title={'Doppel löschen'}
                                text={'Möchtest du das Doppel wirklich löschen?'}/>
                        </td>
                    </tr>
                    <tr className={'planung_match_table_content_rows'}>
                        <td></td>
                        <td>{getDisplayName(dop.nutzerHeim2)}</td>
                        <td></td>
                        <td>{getDisplayName(dop.nutzerGast2)}</td>
                    </tr>
                </React.Fragment>
            )}
            <tr className={'spacer_line_row'}>
                <td colSpan="100%"></td>
            </tr>
          
          <TrainingsplanungEinzelModal runde={this.props.runde}
                                       vereinsSpielerDistinctNutzer={this.props.vereinsSpielerDistinctNutzer}
                                       visible={this.state.visibleEinzel} 
                                      onCancel={this.handleCancelEinzel}
                                      onReloadEinzel={this.reloadData}
                                      onSetSuccessMessage={this.props.onSetSuccessMessage}
                                       onSetErrorMessage={this.props.onSetErrorMessage}/>

          <TrainingsplanungDoppelModal runde={this.props.runde}
                                       vereinsSpielerDistinctNutzer={this.props.vereinsSpielerDistinctNutzer}
                                       visible={this.state.visibleDoppel}
                                       onCancel={this.handleCancelDoppel}
                                       onReloadDoppel={this.reloadData}
                                       onSetSuccessMessage={this.props.onSetSuccessMessage}
                                       onSetErrorMessage={this.props.onSetErrorMessage}/>
        </React.Fragment>
    );
  }

}


export default TrainingsplanungRundeRow;
