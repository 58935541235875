import React from 'react'
import {getCurrentUser} from "../../_services";
import {mannschatsService} from "../../_services/mannschatsService";
import {spielerService} from "../../_services/spielerService";
import {Button} from "react-bootstrap";

//Ich glaub, das hier wird gar nicht verwendet. 
//Man kann einfach so Mannschaften hinzugefuegt werden.
class Invitation extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);

    this.state = {
      mannschaft: {},
      loggedInUser: {},
      accepted: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    this.loadMannschaft();
    this.loadLoggedInUser();
  }

  loadMannschaft() {
    const mannschaftId = this.props.match.params.mannschaftsId;
    mannschatsService.getMannschaftById(mannschaftId)
    .then(response => {
      if (this._isMounted) {
        this.setState({mannschaft: response});
      }
    })
    .catch(error => this.setState({hasError: error}));
  }

  loadLoggedInUser(){
    getCurrentUser()
    .then(response => {
      if (this._isMounted) {
        this.setState({loggedInUser: response});
      }
    })
    .catch(error => this.setState({hasError: error}));
  }

  onBeitreten() {
    spielerService.createSpielerForMannschaft(this.state.mannschaft.id, this.state.loggedInUser.id).then(result => {
      this.loadData();
      this.setState({accepted: true});
    })
    .catch(error => this.setState({hasError: error}));
  }
  
  render()
  {
    const { mannschaft, loggedInUser, accepted } = this.state;

    if (accepted)
    {
      return (
          <div className="container" id="invite">
            <h1>Einladung zu den
              {!!mannschaft &&
              <span> {mannschaft.name}</span>
              }
            </h1>
            <br/>

            Du hast die Einladung angenommen. Willkommen bei den
            {!!mannschaft &&
            <span> {mannschaft.name}</span>
            }
            .
          </div>
      );
    }
    
    return (
        <div className="container" id="invite">
          <h1>Einladung zu den
            {!!mannschaft &&
            <span> {mannschaft.name}</span>
            }
          </h1>
          <br/>
          
          Hallo
          {!!loggedInUser &&
          <span> {loggedInUser.username}</span>
          }
          ,<br/>
          du wurdest eingeladen, in die Mannschaft der
          
          {!!mannschaft &&
              <span> {mannschaft.name} </span>
          }
          einzutreten.
          <br/>
          <Button style={{float: 'right'}} variant="primary" onClick={() => this.onBeitreten()}>Beitreten</Button>
        </div>
    );
  }

}

export default Invitation;
