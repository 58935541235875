import React from 'react';
import {getCurrentUser} from "../../../_services";
import {setErrorState} from "../../../util/ErrorUtil";
import MatchProfilComponent from "./match_profil_component";

//Die Profilansicht. ANsicht, wo ich mein eigenes Profil anschauen kann.
class MatchProfil extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            user: {}
        };
        this.loadUsers = this.loadUsers.bind(this);
    }

    loadUsers() {
        getCurrentUser()
            .then(response => {
                if (this._isMounted) {
                    this.setState({user: response});

                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });
    }
    
    componentDidMount() {
        this._isMounted = true;
        this.loadUsers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadUsers();
        }
    }


    render() {
        
        const { user } = this.state;
        
        return (
            <React.Fragment>
            {!!user && !!user.id &&
                <div>
                    <h1>Deine Matches</h1>
                    <p>Hier kannst du deine gespielten Matches und deine Match-Statistik sehen.</p>

                    <span>
                        <MatchProfilComponent nutzerId={user.id}/>
                    </span>
                </div>
            }
            </React.Fragment>
        );
    }
}

export default MatchProfil;