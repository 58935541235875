import React from 'react';
import DetailedMatchOverview from "../../../match/match_statistik/detailed_match_overview";

//DIe Profilansicht. Ansicht, wo ich mein eigenes Profil anschauen kann.
class MatchProfilUebersichtComponent extends React.Component {
    _isMounted = false;

    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            //this.loadData();
        }
    }

    
    render() {
        
        return (
            <DetailedMatchOverview
                matches={this.props.matches}
                isDoppel={this.props.isDoppel}
                loggedInUser={this.props.loggedInUser}
                displayName={'Deine'}/>
        );
    }
}



export default MatchProfilUebersichtComponent;