import MatchDoppel from "../dto/match/MatchDoppel";
import MatchEinzel from "../dto/match/MatchEinzel";

export const matchMapper = {
    mapEinzel,
    mapEinzelList,
    mapDoppel,
    mapDoppelList
};

export function mapEinzel(toMap)
{
    return new MatchEinzel(toMap);
}

export function mapDoppel(toMap)
{
    return new MatchDoppel(toMap);
}

export function mapEinzelList(listeToMap)
{
    return listeToMap.map(entry => new MatchEinzel(entry));
}

export function mapDoppelList(listeToMap)
{
    return listeToMap.map(entry => new MatchDoppel(entry));
}