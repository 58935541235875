import React from 'react'
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Col, Form, Modal, Row, Select} from "antd";
import {trainingsplanungDoppelService} from "../../_services/training/planung/trainingsplanungDoppelService";

const FormItem = Form.Item;

class TrainingsplanungDoppelModal extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      nutzerHeim: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      nutzerGast: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      nutzerHeim2: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      nutzerGast2: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      successMsg: '',
      errorMsg: ''
    };
  }

  formRef = React.createRef();
  
  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }



  isNewAnsicht(){
    //dann handelt es sich um den Edit-Fall
    if(this.props.trainingsplanungRunde && this.props.trainingsplanungRunde.id > 0){
      return false;
    }else{
      //neu erstellen Fall
      return true;
    }
  }

  handleSelectInputChange(value, name) {
    this.setState({
      [name] : {
        value: value
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }



  handleCreateDoppel = () => {

    this.setState({
      successMsg: null,
      errorMsg: null,
    });

    var doppelId = 0;
    
    if(!this.isNewAnsicht()){
      doppelId = this.props.trainingsplanungDoppel.id;
    }

    const saveRequest = {
      id: doppelId,
      trainingsplanungRunde: this.props.runde,
      datum: new Date(),
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerHeim2Id: this.state.nutzerHeim2.value,
      nutzerGastId: this.state.nutzerGast.value,
      nutzerGast2Id: this.state.nutzerGast2.value,
    };
    
    trainingsplanungDoppelService.saveDoppel(saveRequest)
        .then(response => {
          this.props.onSetSuccessMessage("Das Doppel wurde erfolgreich gespeichert!");
          document.body.classList.remove('busy-cursor');
          this.props.onReloadDoppel();
          this.props.onCancel();
          this.formRef.current.resetFields();
        }).catch(error => {
      if (this._isMounted) {
        //setErrorState(error, this);
        this.props.onSetErrorMessage(error);
      }
      document.body.classList.remove('busy-cursor');
    });
  };
  
  render()
  {
    var vereinsSpielerDistinctNutzer = this.props.vereinsSpielerDistinctNutzer;

    let spielerItems = vereinsSpielerDistinctNutzer.map((spieler) =>
        <Select.Option value={spieler.nutzer.id} key={spieler.id}>{spieler.nickname}</Select.Option>
    );

    var initialValues = {};
    if(this.isNewAnsicht()){
      initialValues={
      };
    }else{
      initialValues={
        "nutzerHeim": this.props.trainingsplanungDoppel.nutzerHeim1.id,
        "nutzerHeim2": this.props.trainingsplanungDoppel.nutzerHeim2().id,
        "nutzerGast": this.props.trainingsplanungDoppel.nutzerGast1.id,
        "nutzerGast2": this.props.trainingsplanungDoppel.nutzerGast2().id,
      };
    }
    
    return (
        <React.Fragment>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }
          
          <Modal
              visible={this.props.visible}
              title="Neues Doppel erstellen"
              okText="Doppel erstellen"
              cancelText="Abbrechen"
              onCancel={this.props.onCancel}
              onOk={this.handleCreateDoppel}
          >
            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }
            <Form onFinish={this.onSave}
                  ref={this.formRef}
                  className="trainingplanung-doppel-edit-form"
                  initialValues={initialValues}>

              <Row>
                <Col span={11}>
                  <Row>
                    <Col>
                      <FormItem
                          labelAlign="left"
                          name="nutzerHeim"
                          rules={[{
                            required: true,
                            message: 'Bitte wähle einen Spieler aus' }]}
                          hasFeedback
                          validateStatus={this.state.nutzerHeim.validateStatus}
                          help={this.state.nutzerHeim.errorMsg}>
                        <Select
                            // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                            style={{width: '11em', fontWeight: 'normal'}}
                            size="large"
                            onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerHeim')}>
                          {spielerItems}
                        </Select>
                      </FormItem>                      
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormItem
                          labelAlign="left"
                          name="nutzerHeim2"
                          rules={[{
                            required: true,
                            message: 'Bitte wähle einen Spieler aus' }]}
                          hasFeedback
                          validateStatus={this.state.nutzerHeim2.validateStatus}
                          help={this.state.nutzerHeim2.errorMsg}>
                        <Select
                            // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                            style={{width: '11em', fontWeight: 'normal'}}
                            size="large"
                            onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerHeim2')}>
                          {spielerItems}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                
                <Col span={2}>-</Col>
                <Col span={11}>
                  <Row>
                    <Col>
                      <FormItem
                          labelAlign="left"
                          name="nutzerGast"
                          rules={[{
                            required: true,
                            message: 'Bitte wähle einen Spieler aus' }]}
                          hasFeedback
                          validateStatus={this.state.nutzerGast.validateStatus}
                          help={this.state.nutzerGast.errorMsg}>
                        <Select
                            // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                            style={{width: '11em', fontWeight: 'normal'}}
                            size="large"
                            onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerGast')}>
                          {spielerItems}
                        </Select>
                      </FormItem>  
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormItem
                          labelAlign="left"
                          name="nutzerGast2"
                          rules={[{
                            required: true,
                            message: 'Bitte wähle einen Spieler aus' }]}
                          hasFeedback
                          validateStatus={this.state.nutzerGast2.validateStatus}
                          help={this.state.nutzerGast2.errorMsg}>
                        <Select
                            // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                            style={{width: '11em', fontWeight: 'normal'}}
                            size="large"
                            onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerGast2')}>
                          {spielerItems}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>

                </Col>
              </Row>
              
            </Form>
            
          </Modal>

        </React.Fragment>
    );
  }

}


export default TrainingsplanungDoppelModal;
