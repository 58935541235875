import React from 'react'
import {deleteSpielerById, getSpielerById} from "../../_services/spielerService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

class MannschaftsMitgliederEdit extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      successMsg: '',
      errorMsg: ''
    };
    this.deleteSpieler = this.deleteSpieler.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.setState({
        mannschaft: this.props.mannschaft
      });
    }
  }

  deleteSpieler(spieler) {
    deleteSpielerById(spieler)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Der Spieler wurde erfolgreich gelöscht'});
            this.props.reloadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    const { mannschaft } = this.state;
    
    return (
        <div id="mannschaft_mitglieder_edit">
          <h2>Mannschafts-Mitglieder</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!mannschaft && !!mannschaft.spielerIds &&
            <table className="table table-hover">
              <tbody>
                {mannschaft.spielerIds.map((spielerId) =>
                      <MitgliedZeile key={spielerId} spielerId={spielerId} onDeleteSpieler={this.deleteSpieler}/>
                  )}
              </tbody>
            </table>
          }
        </div>
    );
  }

}


// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class MitgliedZeile extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {spieler: {}};

  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadSpieler();
  }
  
  loadSpieler(){
    const spielerId = this.props.spielerId;

    getSpielerById(spielerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          spieler: response
        });
      }
    })
    .catch(error => {
      setErrorState(error, this);
    });
  }

  onEdit(e, spielerId) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/spieler/edit/' + spielerId;
  }
  

  
  render()
  {
    const { spieler } = this.state;

    return (
      <React.Fragment>
        {!!spieler && !!spieler.id &&
          <tr key={spieler.nickname}>
            <td style={{textAlign: 'left'}}>{spieler.von}</td>
            <td style={{textAlign: 'left'}}>{spieler.nickname}</td>
            
            {/*bearbeiten ist hier nicht moeglich. Nur jeder Spieler selbst kann seine Infos bearbeiten*/}
            <td>
              {/*bearbeiten Button*/}
              <Button
                  className="btn btn-primary edit-button"
                  onClick={e => this.onEdit(e, spieler.id)}>
                <FontAwesomeIcon icon={faPen}  />
              </Button>
              
            {/*loeschen Button*/}
              <Button
                  className="btn btn-secondary delete-button"
                  onClick={()=>this.props.onDeleteSpieler(spieler)}>
                <FontAwesomeIcon icon={faTrashAlt}  />
              </Button>
            </td>
          </tr>
        }
      </React.Fragment>

    );
  }

}

export default MannschaftsMitgliederEdit;


