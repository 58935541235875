import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import {setErrorState} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {terminService} from "../../../_services/termin/terminService";
import moment from "moment";

class TerminList extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      termine: {},
      successMsg: '',
      errorMsg: ''
    };
    this.deleteTermin = this.deleteTermin.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    if (this._isMounted) {
      this.loadAllTermineForVerein();
    }
  }

  loadAllTermineForVerein(){
    const vereinId = this.props.verein.id;
    
    terminService.getAllTermineForVerein(vereinId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              termine: response
            });
          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }

  deleteTermin(termin) {
    terminService.deleteTerminById(termin)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Der Termin wurde erfolgreich gelöscht'});
            this.loadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    const { termine } = this.state;
    
    return (
        <div id="termin_liste">
          <h2>Termine</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!termine && !!termine.length <= 0 &&
          <p style={{paddingTop: '1.5rem'}}>Aktuell stehen keine Termine an.</p>
          }
          
          {!!termine && !!termine.length > 0 &&
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{textAlign: 'left'}}>Datum</th>
                <th style={{textAlign: 'left'}}>Name</th>
                <th style={{textAlign: 'left'}}>Ort</th>
                <th style={{textAlign: 'left'}}>Beschreibung</th>
                <th></th>
              </tr>
            </thead>
              <tbody>
                {termine.map((termin) =>
                      <TerminZeile key={termin.id} termin={termin} onDeleteTermin={this.deleteTermin}/>
                  )}
              </tbody>
            </table>
          }
        </div>
    );
  }
}


// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class TerminZeile extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {termin: this.props.termin};

  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  

  onEdit(e, terminId) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/verwaltung/verein/termin/edit/' + terminId;
  }
  

  
  render()
  {
    const { termin } = this.state;
    var formattedDate = moment(termin.datum).format('DD.MM.YYYY HH:mm');
    
    return (
      <React.Fragment>
        {!!termin && !!termin.id &&
          <tr key={termin.id}>
            <td style={{textAlign: 'left'}}>{formattedDate}</td>
            <td style={{textAlign: 'left'}}>{termin.name}</td>
            <td style={{textAlign: 'left'}}>{termin.ort}</td>
            <td style={{textAlign: 'left'}}>{termin.beschreibung}</td>
            
            {/*bearbeiten ist hier nicht moeglich. Nur jeder Spieler selbst kann seine Infos bearbeiten*/}
            <td>
              {/*bearbeiten Button*/}
              <Button
                  className="btn btn-primary edit-button"
                  onClick={e => this.onEdit(e, termin.id)}>
                <FontAwesomeIcon icon={faPen}  />
              </Button>
              
            {/*loeschen Button*/}
              <Button
                  className="btn btn-secondary delete-button"
                  onClick={()=>this.props.onDeleteTermin(termin)}>
                <FontAwesomeIcon icon={faTrashAlt}  />
              </Button>
            </td>
          </tr>
        }
      </React.Fragment>

    );
  }
}

export default TerminList;


