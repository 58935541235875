import React from 'react'
import {Button} from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import moment from 'moment'
import {createNewTrainingstermin} from "../../_services/trainingsService";
import {Form, Input} from "antd";
import {bereiteDatumsWertFuerRequestVor} from "../../util/DateUtil";
import {DATE_TIME_REGEX} from "../../constants/regex";

const FormItem = Form.Item;

class TrainingstageSingleNew extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      defaultValuesSet: false,
      successMsg: '',
      errorMsg: '',
      datum: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      }
    };
    this.loadData = this.loadData.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createTrainingstage = this.createTrainingstage.bind(this);
    
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    if (this._isMounted) {
      this.setState({mannschaft: this.props.mannschaft});
      this.setDefaultValues();
    }
  }
  
  setDefaultValues(){
    var now = moment();
    now.set('hour', 18);
    now.set('minute', 0);
    now.set('second', 0);
    now.set('millisecond', 0);
    
    var nowFormatted = now.format('DD.MM.YYYY HH:mm');
    
    if(!this.state.datum.value){
      let datumFromState = this.state.datum;
      datumFromState.value = nowFormatted;
      this.setState({
        datum: datumFromState,
        defaultValuesSet: true
      });
    }
  }

  handleSubmit(){
    this.createTrainingstage();
  }
  
  createTrainingstage(){
    this.setState({
      successMsg: '',
    });
    
    var datumToSave = bereiteDatumsWertFuerRequestVor(this.state.datum.value);

    const saveRequest = {
      mannschaft: this.props.mannschaft,
      datum: datumToSave
    };

    createNewTrainingstermin(saveRequest)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              successMsg: 'Trainingstag wurde erfolgreich angelegt.',
            });

            this.props.componentChanged();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }


  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue,
        ...validationFun(inputValue)
      }
    });
  }

  validateDatum = (datum) => {
    if(!datum) {
      return {
        validateStatus: 'error',
        errorMsg: `Bitte gib ein Datum an. Die Angabe eines Datums ist pflicht.`
      }
    } else if (!datum.match(DATE_TIME_REGEX)) {
      return {
        validationStatus: 'error',
        errorMsg: 'Datum entspricht nicht dem geforderten Format. Bitte geben Sie ein Datum mit einer Uhrzeit im Format dd.MM.yyyy HH:mm an.'
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null
      }
    }
  }

  isFormInvalid() {
    return this.state.datum.validateStatus === 'success';
  }
  
  render()
  {
    const { mannschaft } = this.state;

    return (
        <div id="mannschaft_trainingstage_saison_new">
          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!this.state.defaultValuesSet && !!mannschaft &&
              <div id="trainingstage_new">
                <h4>Einzelne Trainingseinheit anlegen</h4>
                <div>
                  <Form className="trainingstage-edit-form"
                        onFinish={this.createTrainingstage}
                        layout="inline"
                        initialValues={{
                          "datum": this.state.datum.value
                        }}>
                    <FormItem
                        labelAlign="left"
                        label="Datum"
                        name="datum"
                        style={{width: '20em'}}
                        rules={[{
                          required: true,
                          message: 'Bitte gib ein Datum an' }]}
                        hasFeedback
                        validateStatus={this.state.datum.validateStatus}
                        help={this.state.datum.errorMsg}>
                      <Input
                          size="large"
                          name="datum"
                          autoComplete="on"
                          placeholder=""
                          value={this.state.datum.value}
                          onChange={(event) => this.handleInputChange(event, this.validateDatum)}
                      />
                    </FormItem>

                    <FormItem>
                      <Button type="submit"
                              style={{float: 'right'}}
                              size="large"
                              disabled={(!this.isFormInvalid()) || this.state.disableSubmitButton}>Trainingstag hinzufügen</Button>                    
                    </FormItem>
                  </Form>

                </div>
              </div>
          }
        </div>
    );
  }

}

export default TrainingstageSingleNew;


