import React from 'react'
import {getCurrentUser} from "../../_services";

import {trainingsService} from "../../_services/trainingsService";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState} from "../../util/ErrorUtil";
import Trainingsuebersicht from "./trainingsuebersicht_component";


class TrainingsuebersichtByTraining extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      training: {},
      successMsg: '',
      errorMsg: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
    }
  }

  loadData() {
    this.loadTraining();
    this.loadLoggedInUser();
  }

  loadLoggedInUser(){
    getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }

  handleChange(event) {
    const trainingstagId = event.target.value;
    
    if (this._isMounted) {
      //window.location.href = '/training/uebersicht/id/' + trainingstagId;
      this.loadTraining(trainingstagId);
    }
  }
  
  loadTraining() {
    trainingsService.getTrainingById(this.props.match.params.trainingstagId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              training: response
            });
            
            //Das Training wurde nicht gefunden
            if(response.id === 0){
              this.setState({
                errorMsg: "Das Training konnte nicht gefunden werden."
              });
            }
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    return (
      <div id="training-by-id">
        {!!this.state.successMsg &&
        <SuccessMessage message={this.state.successMsg}/>
        }

        {!!this.state.errorMsg &&
        <ErrorMessage message={this.state.errorMsg}/>
        }
        
        {!!this.state.training && !!this.state.training.id && !!this.state.loggedInUser && !!this.state.loggedInUser.id &&
        <Trainingsuebersicht training={this.state.training} loggedInUser={this.state.loggedInUser}/>
        }
      </div>
    );
  }

}


export default TrainingsuebersichtByTraining;
