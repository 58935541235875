import React, {Component} from 'react';

//import { Form, Input, Button, notification } from 'antd';

class SignupConfirm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            error: null
        }
    }

    componentDidMount() {
        
    }
    
    render() {

        return (
            <div className="content-container">
                <div>
                    <h1 className="page-title">Registrierung erfolgreich</h1>
                    <p>
                        Herzlichen Glückwunsch! Du hast deine E-Mail-Adresse bestätigt und somit deine Registrierung abgeschlossen.
                        <br/>
                        Melde dich an, um fortzufahren.
                        <br/>
                        <br/>
                        <a className="btn btn-primary myLargeBtn" href="/login">Login</a>
                    </p>
                </div>
            </div>
        );
    }
    
}

export default SignupConfirm;