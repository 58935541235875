import {matchMapper} from "../../_mapper/matchMapper";

export default class MatchStatistikEinzelMap {
    constructor(matchStatistikJson) {
        this.anzahlMatchesInsgesamt = matchStatistikJson.anzahlMatchesInsgesamt;
        this.gewonnen = matchMapper.mapEinzelList(matchStatistikJson.gewonnen);
        this.verloren = matchMapper.mapEinzelList(matchStatistikJson.verloren);
        this.unentschieden = matchMapper.mapEinzelList(matchStatistikJson.unentschieden);
    }

}