import React from 'react';

import MyApp from "./MyApp";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {GridProvider} from "./GridContext";
import {TouchBackend} from "react-dnd-touch-backend";


class MyDnD3_Touch extends React.Component {
    _isMounted = false;


    constructor(props) {
        super(props);

        this.state = {
            spieler1: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler2: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },

        };

    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        //const { items, moveItem } = useContext(GridContext);

        return (
            <DndProvider backend={TouchBackend}>
                <GridProvider>
                    <MyApp />
                </GridProvider>
            </DndProvider>
        )
    }
}



export default MyDnD3_Touch;