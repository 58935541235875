import React from 'react'
import {getSpielerById, hasErsatzTrainingsspielerForMannschaft} from "../../_services/spielerService";

import {
  getErsatzSpielerAntwortenAggregated,
  getStammSpielerAntwortenAggregated,
  trainingsService
} from "../../_services/trainingsService";
import img_checked from "../../img/checked.png";
import img_cancelled from "../../img/cancelled.png";
import img_unbekannt from "../../img/unbekannt.png";
import img_null from '../../img/null.png';

import moment from "moment";
import {Button} from 'react-bootstrap';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState, setErrorStateWithMessage} from "../../util/ErrorUtil";
import TrainingComment from "../training_comment";
import {mannschatsService} from "../../_services/mannschatsService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import TrainingsplanungView from "../planung/view/trainingsplanung_view";
import MatchStatistikAndEditViewMannschaft from "../../match/MatchStatistikAndEditViewMannschaft";

class Trainingsuebersicht extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: this.props.loggedInUser,
      hasUserEditRights: false,
      training: this.props.training, 
      spieler: [],  
      antwortenStammSpielerAggregated: {},
      antwortenErsatzSpielerAggregated: {},
      zukuenftigeTrainings: [], 
      trainingId: {},
      successMsg: '',
      errorMsg: ''
    };
    
    this.setErrorState = this.setErrorState.bind(this);
    this.spielerChanged = this.spielerChanged.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
    if(this.props.training.id !== nextProps.training.id) {
      // Reset State
      this.loadData();
    }
    if(this.props.loggedInUser !== nextProps.loggedInUser){
      if(this.props.loggedInUser.id !== nextProps.loggedInUser.id) {
        // Reset State
        this.loadData();
      }     
    }
  }

  loadData(){
    this.loadAntwortenAggregated();
    this.loadZukuenftigeTrainingstage();
    this.hasUserEditRights();
  }
  
  loadAntwortenAggregated() {

    getStammSpielerAntwortenAggregated(this.state.training.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              antwortenStammSpielerAggregated: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });

    getErsatzSpielerAntwortenAggregated(this.state.training.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              antwortenErsatzSpielerAggregated: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });

    hasErsatzTrainingsspielerForMannschaft(this.state.training.mannschaft.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              hasErsatz: response
            });
          }
        })
        .catch(error => this.setState({hasError: error}));
  }


  loadZukuenftigeTrainingstage(){
    const mannschaftsId = this.state.training.mannschaft.id;

    trainingsService.getZukuenftigeTrainingsByMannschaftsId(mannschaftsId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          zukuenftigeTrainings: response
        });
      }
    })
    .catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
    });
  }

  handleChange(event) {
    const trainingstagId = event.target.value;

    if (this._isMounted) {
      window.location.href = '/training/uebersicht/id/' + trainingstagId;
    }
  }
  
  setErrorState(fehlertext){
    setErrorStateWithMessage(fehlertext, this);
  }
  
  spielerChanged(){
    this.loadAntwortenAggregated(this.state.trainingId);
  }

  hasUserEditRights(){
    if(this.props.loggedInUser !== undefined && this.props.loggedInUser.id !== undefined){
      mannschatsService.isNutzerMannschaftsAdmin(this.state.training.mannschaft.id, this.props.loggedInUser.id)
          .then(response => {
            if (this._isMounted) {
              this.setState({hasUserEditRights: response});
            }
          })
          .catch(error => {
            this.setErrorState(error);
          });      
    }
  }
  
  
  render()
  {
    const {training, zukuenftigeTrainings, antwortenStammSpielerAggregated, antwortenErsatzSpielerAggregated, hasErsatz, hasUserEditRights } = this.state;
    
    const mannschaft = training.mannschaft;
    
    let trainingItems = zukuenftigeTrainings.map((trainingstag) =>
        <option value={trainingstag.id} key={trainingstag.id}>{moment(trainingstag.datum).format("DD.MM.YYYY")}</option>
    );

    let trainingVonUhrzeit = '';
    
    const uhrzeit = moment(training.datum).format("HH:mm");
    //Wenn am Trainingsdatum eine Uhrzeit gesetzt ist
    if(uhrzeit && uhrzeit !== '00:00'){
      trainingVonUhrzeit = uhrzeit + ' Uhr';
    }
    
    
    var mannschaftsName = '';
    if(mannschaft){
      mannschaftsName = mannschaft.name
    }
    
    return (
        <React.Fragment>
        {!!this.state.training && !!this.state.training.id && !!this.props.loggedInUser && !!this.props.loggedInUser.id &&
          <div id="trainingsuebersicht">
              <h1>Training {mannschaftsName}</h1>
  
            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }
  
            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }
            
            {!!training &&
            <span>
              <select onChange={this.handleChange} value={training.id} style={{fontSize: "1.6rem"}}>
                {trainingItems}
              </select>
            </span>
            }

            {!!training &&
                <span style={{fontSize: "1.5rem", paddingLeft: '0.5em'}}>
                  {trainingVonUhrzeit}
                </span>
            }
  
            {!!antwortenStammSpielerAggregated &&
            <div className="float-right">
              <h3 style={{display: "inline-block"}}>
                <span
                    className="badge badge-pill badge-success">Zusagen: {antwortenStammSpielerAggregated.zusagen}</span>
              </h3>
              <h3 style={{display: "inline-block", paddingLeft: "10px", paddingRight: "10px"}}>
                <span className="badge badge-pill badge-warning">Offen: {antwortenStammSpielerAggregated.offen}</span>
              </h3>
              <h3 style={{display: "inline-block", paddingRight: "10px"}}>
                <span className="badge badge-pill badge-danger">Absagen: {antwortenStammSpielerAggregated.absagen}</span>
              </h3>
              
              {!!hasErsatz &&
                <h5 style={{display: 'inline-block'}}>
                  <span className="badge badge-secondary pull-right">Ersatz: {antwortenErsatzSpielerAggregated.zusagen}</span>
                </h5>
              }

            </div>
            }
            
              <table className="table table-hover">
                <thead className="thead-light">
                <tr>
                  <th key={'tableHeader_ab_Col'} style={{textAlign: 'left'}}>
                    ab
                  </th>
                  <th key={'tableHeader_Spieler_Col'} style={{textAlign: 'left'}}>
                    Spieler
                  </th>
                  <th key={'tableHeader_Antwort_Col'}>
                    Antwort
                  </th>
                  <th key={'tableHeader_Zusagen_Col'}>
                  </th>
                  <th key={'tableHeader_Absagen_Col'}>
                  </th>
                  <th key={'tableHeader_Aendern_Col'}>
                  </th>
                  <th key={'tableHeaderDelete_Col'}>
                  </th>
                  
                </tr>
                </thead>
                <tbody>
  
                {/*Die einzelnen Stamm-Spieler-Zeilen*/}
                {!!mannschaft && !!mannschaft.spielerIdsForTraining && !!training && !!training.id && !!this.props.loggedInUser && !!this.props.loggedInUser.id &&
                <React.Fragment>
                  {mannschaft.spielerIdsForTraining.map((spielerId) => 
                      <SpielerZeile key={spielerId} 
                                    onlyErsatz={false}
                                    spielerId={spielerId} 
                                    training={training}
                                    loggedInUser={this.props.loggedInUser}
                                    hasUserEditRights={hasUserEditRights}
                                    onSetErrorState={this.setErrorState}
                                    onChange={this.spielerChanged}/>
                  )}
                </React.Fragment>
                }

                
                {/*Die einzelnen Ersatz-Spieler-Zeilen*/}
                {!!mannschaft && !!mannschaft.spielerIdsForTraining && !!training && !!training.id && !!this.props.loggedInUser && !!this.props.loggedInUser.id &&
                <React.Fragment>
                  {!!hasErsatz &&
                  <tr>
                    <td colSpan="2" style={{textAlign: 'left'}}><h5>Ersatzspieler</h5></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                  </tr>
                  }
                  
                  {mannschaft.spielerIdsForTraining.map((spielerId) =>
                      <SpielerZeile key={spielerId}
                                    onlyErsatz={true}
                                    spielerId={spielerId}
                                    training={training}
                                    loggedInUser={this.props.loggedInUser}
                                    hasUserEditRights={hasUserEditRights}
                                    onSetErrorState={this.setErrorState}
                                    onChange={this.spielerChanged}/>
                  )}
                </React.Fragment>
                }
  
                </tbody>
              </table>
              
            <div>
              
              {!!mannschaft && !!mannschaft.id && !!mannschaft.zeigeMatchbilanz &&
                  <div>
                    <a href={'/training/planung/' + this.props.training.id}style={{float: 'right'}}>zur Planungsübersicht</a>
                    <br/>
                  </div>
              }
              
              <br/>
              <TrainingComment trainingstag={training}/>
              <br/>

              {!!mannschaft && !!mannschaft.id &&
                <TrainingsplanungView trainingstag={training}/>
              }
              <br/>
              <br/>

              {!!mannschaft && !!mannschaft.id && !!mannschaft.zeigeMatchbilanz &&
              <div>
                <MatchStatistikAndEditViewMannschaft mannschaft={mannschaft}/>
              </div>
              }
            </div>
          </div>
            
            
        }
        </React.Fragment>
    );
  }
}

// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class SpielerZeile extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      training: props.training,
      spielerId: props.spielerId,
      isUserAdmin: false,
      spieler: {},
      trainingSpieler: {},
      //weil es auch keinen  trainingSpieler geben kann, weil der Spieler einfach noch keine Antwort gegeben hat.
      //in der Render kann ich also dann nicht !!!trainingSpieler && machen, weil der ja einfach leer bleiben kann
      trainingSpielerLoaded: false,
      loggedInUser: {},
      dummy: false,
    };
  }
  
  componentWillReceiveProps(newProps) {
    if (this.props.training !== newProps.training) {
      if (this._isMounted) {
        this.setState({
          training: newProps.training
        });
      }
      this.loadTrainingSpieler(newProps.training.id);
    }

    if (this.props.spielerId !== newProps.spielerId) {
      this.loadSpieler(newProps.training.id);
    }
  }
  
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
    if((this.props.loggedInUser.id !== nextProps.loggedInUser.id)
    || (this.state.spieler.id !== nextState.spieler.id)) {
      // Reset State
      this.loadData();
    }

  }

  loadData()
  {
    this.loadSpielerFromState();
    this.loadTrainingSpielerFromState();
    this.isLoggedInUser();
    this.props.onChange();
  }

  loadSpielerFromState(){
    this.loadSpieler(this.state.spielerId);
  }
  
  loadSpieler(spielerId){
    getSpielerById(spielerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          spieler: response,
          spielerId: response.id
        });
      }
    })
    .catch(error => {
      this.setErrorState(error);
    });
  }


  loadTrainingSpielerFromState(){
    this.loadTrainingSpieler(this.state.training.id);
  }
  
  loadTrainingSpieler(trainingsId){
    const spielerId = this.state.spielerId;

    trainingsService.getTrainingSpielerByTrainingIdAndSpielerId(trainingsId, spielerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          trainingSpieler: response,
          trainingSpielerLoaded: true
        });
      }
    })
    .catch(error => {
      //hier laeuft er jetzt auch rein, wenn kein TrainingSpieler existiert (also NULL von der REST-API zurueckgegben wird.
      if (this._isMounted) {
        this.setState({
          hasError: error,
          trainingSpieler: {},
          trainingSpielerLoaded: true
        });
        console.log('error');
        console.log(error);
      }
    });
  }

  setErrorState(error)
  {
    if (this._isMounted) {
      this.setState({hasError: error})
    }
  }
  
  onZusagen() {
    this.createAntwort('ZUGESAGT');
  }

  onAbsagen() {
    this.createAntwort('ABGESAGT');
  }


  createAntwort(antwort){
    if(this.state.trainingSpieler.id > 0){
      //bei dem bereits existierenden Datensatz einfach das kommt aendern
      trainingsService.editTrainingSpieler(this.state.trainingSpieler.id, antwort).then(result => {
        this.loadData();
      })
      .catch(error => {
        this.setErrorState(error);
      });
    }else{
      
      //einen neuen Datensatz fuer den Spieler anlegen, weil noch keiner existiert. Daher fehlt auch die Id, da der Datensatz nicht existiert.
      //trainingsService.createTrainingSpieler(this.props.trainingsId, this.props.spielerId, antwort).then(result => {
      trainingsService.createTrainingSpieler(this.state.training.id, this.state.spielerId, antwort).then(result => {
          this.loadData();
        })
        .catch(error => {
          this.setErrorState(error);
        });
    }
  }



  isLoggedInUser(){
    if(this.state.spieler!== undefined && this.state.spieler.nutzer!== undefined && this.props.loggedInUser !== undefined){
      if(this.state.spieler.nutzer.id === this.props.loggedInUser.id){
        return true;
      } 
    }
    return false;
  }


  onEdit(e, trainingSpieler, spieler, training) {
    e.preventDefault();

    let linkTo = '/training/new/' + training.id + '/' + spieler.id;
    if(trainingSpieler && trainingSpieler.id > 0) {
      //falls bereits eine Antwort bestet
      linkTo = '/training/edit/' + trainingSpieler.id;
    }
    
    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = linkTo;
  }

  onDelete(e, id) {
    e.preventDefault();
    trainingsService.deleteTrainingSpielerById(id).then(result => {
      this.loadData();
    })
        .catch(error => {
          this.setErrorState(error);
        });
  }

  render() {

    const { training, trainingSpieler, spieler, trainingSpielerLoaded } = this.state;

    
    const trainingsUhrzeit = moment(training.datum).format("HH:mm");
    var vonUhrzeit = trainingsUhrzeit;

    let antwortIcon = <img src={img_null} className="img-responsive zugesagt-img" alt="hat noch nicht geantwortet" title="hat noch nicht geantwortet"/>;
    
    //Training, fuer das der Spieler noch nicht geantwortet hat, das aber als abgesagt markiert werden soll
    let immerAbsagen = false;

    //wenn es noch keinen Eintrag gibt
    if(trainingSpielerLoaded && (trainingSpieler.id === undefined || trainingSpieler.id === 0)){
      if(spieler.immerAbsagen){
        immerAbsagen = true;
        antwortIcon = <img src={img_cancelled} className="img-responsive zugesagt-img" alt="hat abgesagt"/>;
      }
    }
    
    if(trainingSpielerLoaded && trainingSpieler.id > 0 && trainingSpieler.antwort !== undefined)
    {
      var zeitpunkt = moment(trainingSpieler.letzteAenderung).format("DD.MM.YYYY HH:mm");
      if(trainingSpieler.antwort === 'ZUGESAGT'){
        antwortIcon = <img src={img_checked} className="img-responsive zugesagt-img" alt="hat zugesagt" title={"hat zugesagt am " + zeitpunkt}/>;
      }else if(trainingSpieler.antwort === 'ABGESAGT'){
        antwortIcon = <img src={img_cancelled} className="img-responsive zugesagt-img" alt="hat abgesagt" title={"hat abgesagt am " + zeitpunkt}/>;
      }else if(trainingSpieler.antwort === 'UNSICHER'){
        antwortIcon = <img src={img_unbekannt} className="img-responsive zugesagt-img" alt="hat abgesagt" title={"unsicher am " + zeitpunkt}/>;
      }
    }
    
    //liefert die Von-Uhrzeit von dem Spieler-Trainings-Eintrag, falls dieser existiert.
    if(spieler.von)
    {
      vonUhrzeit = spieler.von
    }
    if(trainingSpielerLoaded && trainingSpieler.von !== undefined && trainingSpieler.von !== null)
    {
      vonUhrzeit = trainingSpieler.von;
    }


    let von = <span>{vonUhrzeit}</span>;
    
    return (
        <React.Fragment>
          {!!spieler && !!spieler.ersatzTraining === this.props.onlyErsatz &&
          <tr style={{backgroundColor: this.props.onlyErsatz ? '#C4C8CC' : 'none'}}>
            <td style={{textAlign: 'left'}}>
              {!!spieler && trainingSpielerLoaded &&
              <span>{von}</span>
              }
            </td>
            <td style={{textAlign: 'left'}}>
              {!!spieler &&
              <span>{spieler.nickname}</span>
              }
            </td>
            <td>
              {!!trainingSpielerLoaded &&
              <div className="img-container">{antwortIcon}</div>
              }
            </td>
            <td>
              {!!trainingSpielerLoaded && (!!!trainingSpieler.id || trainingSpieler.antwort == 'ABGESAGT') && (!!this.isLoggedInUser() || !!this.props.hasUserEditRights) &&
              <Button style={{float: 'right'}} variant="success"
                      onClick={() => this.onZusagen()}>Zusagen</Button>
              }

            </td>
            <td>
              {!!trainingSpielerLoaded && (!!!immerAbsagen && !!!trainingSpieler.id || trainingSpieler.antwort == 'ZUGESAGT') && (!!this.isLoggedInUser() || !!this.props.hasUserEditRights) &&
              <Button style={{float: 'right'}} variant="danger"
                      onClick={() => this.onAbsagen()}>Absagen</Button>
              }

            </td>
            <td style={{textAlign: 'right'}}>
              {!!trainingSpielerLoaded && (!!this.isLoggedInUser() || !!this.props.hasUserEditRights) &&
              <Button
                  className="btn btn-primary"
                  onClick={e => this.onEdit(e, trainingSpieler, spieler, training)}>
                <FontAwesomeIcon icon={faPen}  />
              </Button>
              }
            </td>
            <td style={{textAlign: 'left', paddingLeft: '0px'}}>
              {!!(trainingSpielerLoaded && trainingSpieler.id && (!!this.isLoggedInUser() || !!this.props.hasUserEditRights)) &&
                  <Button
                      className="btn btn-secondary"
                      onClick={e => this.onDelete(e, trainingSpieler.id)}>
                    <FontAwesomeIcon icon={faTrash}  />
                  </Button>
              }
            </td>
          </tr>
          }
        </React.Fragment>
        
    );
  }
}
// end::SpielerZeile[]

export default Trainingsuebersicht;
