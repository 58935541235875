import React from 'react';

import MatchStatistikMannschaftComponent from "./match_statistik_mannschaft_component";
import {matchEinzelService} from "../../_services/matchEinzelService";
import {setErrorState} from "../../util/ErrorUtil";
import {matchDoppelService} from "../../_services/matchDoppelService";
import FilterFrom from "../statistik/filter/filterForm";

class MatchStatistikMannschaft extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        const filter = {
            satzFilter: null,
            nutzerFilter: null,
            datumFilterAb: "01.09.2023",
            datumFilterBis: null
        };

        this.state = {
            filter: filter,
            einzelStatistik: [],
            doppelStatistik: []
        };

        this.loadStatistik = this.loadStatistik.bind(this);
        this.handleFitler = this.handleFitler.bind(this);
    }

    loadData(){
        this.loadStatistik();
    }

    loadStatistik(){
        const filter = this.state.filter;

        matchEinzelService.getStatistikForMannschaft(this.props.mannschaftsId, filter)
        .then(response => {
            if (this._isMounted) {
                this.setState({
                    einzelStatistik: response
                });

            }
        })
        .catch(error => {
            if (this._isMounted) {
                setErrorState(error, this);
            }
        });

        matchDoppelService.getStatistikForMannschaft(this.props.mannschaftsId, filter)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        doppelStatistik: response
                    });

                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });

    }
    

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps, nextState) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }

        if(this.props.reload !== nextProps.reload) {
            // Reset State
            this.loadData();
        }

        if(this.state.filter !== nextState.filter) {
            // Reset State
            this.loadData();
        }
    }

    handleFitler(filter){
        this.setState({
            filter: filter
        });
    }
    
    render() {
        const { einzelStatistik, doppelStatistik } = this.state;

        return (

            <div>
                <h2>Match-Bilanz</h2>
                {!!einzelStatistik && !!doppelStatistik &&
                    <div>
                        <div id="filter-container">
                            <FilterFrom filterData={this.state.filter} onFitler={this.handleFitler} showSpielerFilter={false}/>
                        </div>
                        <br/>
                        <MatchStatistikMannschaftComponent
                            einzelStatistik={einzelStatistik}
                            doppelStatistik={doppelStatistik}/>
                    </div>

                }
            </div>

        );
    }
}

export default MatchStatistikMannschaft;