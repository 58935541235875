import '../matchEditAndListTabPaneView.css';
import React from 'react'
import {withRouter} from "react-router-dom";
import MatchListFilterComponent from "./match_list_filter_component";

class MatchFilterDoppelView extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  render()
  {
    return (
        <MatchListFilterComponent isDoppel={true}/>
    );
  }
}

export default withRouter(MatchFilterDoppelView);
