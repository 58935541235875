
// Produktion
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://www.mannschaftsplanung.de/mannschaftsplanung-rest-api/api';

//lokal
//export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';



export const WEB_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://www.mannschaftsplanung.de';


export const ACCESS_TOKEN = 'accessToken';

export const POLL_LIST_SIZE = 30;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 0;
export const NAME_MAX_LENGTH = 200;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const USERNAME_MIN_LENGTH = 1;
export const USERNAME_MAX_LENGTH = 200;

export const PHONE_MAX_LENGTH = 50;