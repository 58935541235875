import React from 'react'
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {Button} from "react-bootstrap";
import {bereiteDatumsWertFuerRequestVor} from "../../util/DateUtil";
import {matchEinzelService} from "../../_services/matchEinzelService";
import {setErrorState} from "../../util/ErrorUtil";
import {trainingsplanungRundenService} from "../../_services/training/planung/trainingsplanungRundenService";

const FormItem = Form.Item;

class TrainingsplanungRundeModal extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      bezeichnung: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      successMsg: '',
      errorMsg: ''
    };
  }

  formRef = React.createRef();
  
  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  isNewAnsicht(){
    //dann handelt es sich um den Edit-Fall
    if(this.props.trainingsplanungRunde && this.props.trainingsplanungRunde.id > 0){
      return false;
    }else{
      //neu erstellen Fall
      return true;
    }
  }

  

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }



  handleCreateRunden = () => {

    this.setState({
      successMsg: null,
      errorMsg: null,
    });

    var trainingsplanungRundeId = 0;
    
    if(!this.isNewAnsicht()){
      trainingsplanungRundeId = this.props.trainingsplanungRunde.id;
    }

    const saveRequest = {
      id: trainingsplanungRundeId,
      trainingstag: this.props.trainingstag,
      datum: this.props.trainingstag.datum,
      bezeichnung: this.state.bezeichnung.value
    };

    trainingsplanungRundenService.saveRunde(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Die Runde wurde erfolgreich gespeichert!"
          });
          document.body.classList.remove('busy-cursor');
          this.props.onReloadRunden();
          this.props.onCancel();
          this.formRef.current.resetFields();
        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
    });
  };
  
  render()
  {

    var initialValues = {};
    if(this.isNewAnsicht()){
      initialValues={
      };
    }else{
        initialValues={
          "bezeichnung": this.props.trainingsplanungRunde.bezeichnung
        };
    }
    
    return (
        <div id="trainingsplanungrundenedit">

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          
          <Modal
              visible={this.props.visible}
              title="Neue Runde erstellen"
              okText="Runde erstellen"
              cancelText="Abbrechen"
              onCancel={this.props.onCancel}
              onOk={this.handleCreateRunden}
          >
            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }
            <Form onFinish={this.onSave}
                  ref={this.formRef}
                  className="trainingplanung-runde-edit-form"
                  initialValues={initialValues}>

              <FormItem
                  labelAlign="left"
                  label="Bezeichnung"
                  name="bezeichnung"
                  rules={[{
                    required: true,
                    message: 'Bitte gib eine Bezeichnung für die Runde an' }]}
                  hasFeedback
                  validateStatus={this.state.bezeichnung.validateStatus}
                  help={this.state.bezeichnung.errorMsg}>
                <Input
                    size="large"
                    name="bezeichnung"
                    autoComplete="on"
                    placeholder=""
                    value={this.state.bezeichnung.value}
                    onChange={(event) => this.handleInputChange(event)}
                />
              </FormItem>
              
            </Form>
            
          </Modal>

        </div>
    );
  }

}


export default TrainingsplanungRundeModal;
