import { request } from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const spielerService = {
    getSpielerById,
    getSpielerByMannschaftsId,
    getSpielerByNavUri,
    deleteSpielerByNavUri,
    createSpielerForMannschaft,
    deleteSpielerById,
  getAllSpielerByNutzer,
  saveSpieler,
    createAndAddSpielerForMannschaft,
    getAllForVerein,
    getDistinctSpielerForNutzerByVerein,
    getSpielerByMannschaftsIdSortedByPosition,
    getErsatzPunktspielSpielerByMannschaftSortedByPosition,
    hasErsatzPunktspielerForMannschaft,
    hasErsatzTrainingsspielerForMannschaft
};

export function getSpielerById(spielerId)
{
    return request({
        url: API_BASE_URL + "/spieler/"  + spielerId,
        method: 'GET'
    });
}

export function getSpielerByMannschaftsId(mannschaftsId, page, size)
{
    return request({
        url: API_BASE_URL + "/spieler/list/"  + mannschaftsId + "?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

export function getSpielerByMannschaftsIdSortedByPosition(mannschaftsId)
{
    return request({
        url: API_BASE_URL + "/spieler/getByMannschaftSortedByPosition?mannschaftsId="  + mannschaftsId,
        method: 'GET'
    });
}

export function getErsatzPunktspielSpielerByMannschaftSortedByPosition(mannschaftsId, page, size)
{
    return request({
        url: API_BASE_URL + "/spieler/getErsatzPunktspielSpielerByMannschaftSortedByPosition?mannschaftsId="  + mannschaftsId,
        method: 'GET'
    });
}

export function getSpielerByNavUri(uri)
{
    return request({
        url: uri,
        method: 'GET'
    });
}

//loescht den Spieler und all seine Abhaengigkeiten (Punktspiel/Trainigns-Zusagen, Startseite, etc)
export function deleteSpielerById(spieler)
{
    return request({
        url: API_BASE_URL + "/spieler/deleteSpielerById/"  + spieler.id + '?mannschaftId=' + spieler.mannschaft.id + "&nutzerId=" + spieler.nutzer.id,
        method: 'DELETE'
    });
}



export function deleteSpielerByNavUri(uri)
{
    return request({
        url: uri,
        method: 'DELETE'
    });
}

export function createSpielerForMannschaft(mannschaftId, nutzerId)
{
    return request({
        url: API_BASE_URL + "/spieler/new?mannschaftId=" + mannschaftId + "&nutzerId=" + nutzerId,
        method: 'GET'
    });
}

export function getAllSpielerByNutzer(nutzerId)
{
  return request({
    url: API_BASE_URL + "/spieler/getByNutzer?nutzerId="  + nutzerId,
    method: 'GET'
  });
}


export function saveSpieler(spielerRequest) {
  return request({
    url: API_BASE_URL + "/spieler/save",
    method: 'POST',
    body: JSON.stringify(spielerRequest)
  });
}

// erstellt fuer die uebergebenen Nuetzerliste jeweils Spieler und uegt sie der uebergebenen Mannschaft hinzu
export function createAndAddSpielerForMannschaft(addSpielerToMannschaftRequest) {
    return request({
        url: API_BASE_URL + "/spieler/createAndAddNewSpieler",
        method: 'POST',
        body: JSON.stringify(addSpielerToMannschaftRequest)
    });
}

export function getAllForVerein(vereinId)
{
    return request({
        url: API_BASE_URL + "/spieler/findAllByVerein?vereinsId="  + vereinId,
        method: 'GET'
    });
}

//liefert fuer jeden Nutzer, der fuer den uebergebenen Verein einen oder mehrere Spieler hat,
//random maessig immer genau einen Spieler pro Nutzer zurueck.
export function getDistinctSpielerForNutzerByVerein(vereinId)
{
    return request({
        url: API_BASE_URL + "/spieler/getDistinctSpielerForNutzerByVerein?vereinsId="  + vereinId,
        method: 'GET'
    });
}

export function hasErsatzPunktspielerForMannschaft(mannschaftId)
{
    return request({
        url: API_BASE_URL + "/spieler/hasErsatzPunktspieler?mannschaftsId=" + mannschaftId,
        method: 'GET'
    });
}

export function hasErsatzTrainingsspielerForMannschaft(mannschaftId)
{
    return request({
        url: API_BASE_URL + "/spieler/hasErsatzTrainingsspieler?mannschaftsId=" + mannschaftId,
        method: 'GET'
    });
}