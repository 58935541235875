import React from 'react';
import {spielerService} from "../../../_services/spielerService";
import {Checkbox, Collapse, Form, Input, Select} from "antd";
import {Button} from "react-bootstrap";
import {getCurrentUser} from "../../../_services";
import {DATE_REGEX} from "../../../constants/regex";
import {getDisplayName} from "../../../_helpers/displayHelper";
import {faTrashAlt, faFilter} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const { Panel } = Collapse;
const FormItem = Form.Item;

class FilterFrom extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            spielerListe: [],
            filterHeaderCriteria: null,
            satzFilter: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            nutzerFilter: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            datumFilterAb: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            datumFilterBis: {
                value: '',
                validateStatus: '',
                errorMsg: null
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onNutzerFilterChange  = this.onNutzerFilterChange.bind(this);
        this.setFilterCriteriaOnHeader  = this.setFilterCriteriaOnHeader.bind(this);
    }

    formRef = React.createRef();

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
    }

    loadData(){
        this.loadSpieler();
        this.setInitialValues();
        //this.setFilterCriteriaOnHeader();
    }

    setInitialValues(){
        if(this.props.filterData.datumFilterAb != null){
            this.setState({
                datumFilterAb: {
                    value: this.props.filterData.datumFilterAb,
                    validateStatus: '',
                    errorMsg: null
                }
            },() => this.setFilterCriteriaOnHeader());  //callback-Function, wird ausgeführt, wenn der State gesetzt wurde.
        }
        if(this.props.filterData.datumFilterBis != null){
            this.setState({
                datumFilterBis: {
                    value: this.props.filterData.datumFilterBis,
                    validateStatus: '',
                    errorMsg: null
                }
            }, () => this.setFilterCriteriaOnHeader());
        }
        if(this.props.filterData.satzFilter != null){
            this.setState({
                satzFilter: {
                    value: this.props.filterData.satzFilter,
                    validateStatus: '',
                    errorMsg: null
                }
            },() => this.setFilterCriteriaOnHeader());
        }
    }

    loadSpielerFromLoggedInUser(){
        getCurrentUser()
            .then(response => {
                if (this._isMounted) {
                    spielerService.getAllForVerein(response.id)
                        .then(responseSpieler => {
                            if (this._isMounted) {
                                this.setState({spielerListe: responseSpieler});
                            }
                        })
                        .catch(errorSpieler => {
                            if (this._isMounted) {
                                this.setState({hasError: errorSpieler})
                            }
                        });
                }
            })
            .catch(error => this.setState({hasError: error}));
    }
    
    loadSpieler(){
        const filterData = this.props.filterData;
        const mannschaftsId = filterData.mannschaftsId;
        const showSpielerFilter = this.props.showSpielerFilter;
        
        if(showSpielerFilter == false){
            return;
        }
        
        if(mannschaftsId == null){
            this.loadSpielerFromLoggedInUser();

        }else{
            spielerService.getSpielerByMannschaftsIdSortedByPosition(mannschaftsId)
                .then(responseSpieler => {
                    if (this._isMounted) {
                        this.setState({spielerListe: responseSpieler});
                    }
                })
                .catch(errorSpieler => {
                    if (this._isMounted) {
                        this.setState({hasError: errorSpieler})
                    }
                });
        }
    }

    
    handleSubmit() {
        this.setState({
            errorMsg: null,
            successMsg: null
        });
        
        const filter = {
            satzFilter: this.state.satzFilter.value,
            nutzerFilter: this.state.nutzerFilter.value,
            datumFilterAb: this.state.datumFilterAb.value,
            datumFilterBis: this.state.datumFilterBis.value
        };
        
        this.props.onFitler(filter);
        this.setFilterCriteriaOnHeader();
    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }
    
    handleSelectInputChange(value, name) {
        this.setState({
            [name] : {
                value: value
            }
        });
    }

    setFilterCriteriaOnHeader(){
        var filterHeader = "Filter";
        let setFilter =[];

        if(this.state.satzFilter.value){
            if(this.state.satzFilter.value === "ALL"){
                setFilter.push("Satzfilter: alle Ergebnisse")
            }
            if(this.state.satzFilter.value === "ONE_SET"){
                setFilter.push("Satzfilter: min. ein Satz");
            }
            if(this.state.satzFilter.value === "CONPLETE_MATCHES"){
                setFilter.push("Satzfilter: nur vollständige Matches");
            }
        }

        if(this.state.datumFilterAb.value){
            setFilter.push("Ab: " + this.state.datumFilterAb.value)
        }

        if(this.state.datumFilterBis.value){
            setFilter.push("Bis: " + this.state.datumFilterBis.value)
        }

        if(setFilter.length > 0){
            filterHeader = filterHeader + " (" + setFilter.join(", ") + ")";
        }

        this.setState({
            filterHeaderCriteria: filterHeader
        });
    }

    onNutzerFilterChange(checkedValues) {
        this.setState({
            nutzerFilter : {
                value: checkedValues
            }
        });
    }

    getTurncatedDisplayName(nutzer){
        return getDisplayName(nutzer, true);
    }

    clearFilter(){
        //funktioniert nicht, wenn initial Values gesetzt waren
        //this.formRef.current.resetFields();

        this.formRef.current.setFieldsValue({
            satzFilter: '',
            datumFilterAb: '',
            datumFilterBis: ''
        });

        this.setState({
            satzFilter: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            datumFilterAb: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            datumFilterBis: {
                value: '',
                validateStatus: '',
                errorMsg: null
            }
        }, () => this.handleSubmit());

    }
    
    render() {

        const { spielerListe } = this.state;

        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };
        
        var initialValues = {
            "satzFilter": this.props.filterData.satzFilter,
            "datumFilterAb": this.props.filterData.datumFilterAb,
            "datumFilterBis": this.props.filterData.datumFilterBis,
        };
        
        let spielerOptions = spielerListe.map((spieler) =>
            ({label: this.getTurncatedDisplayName(spieler.nutzer), value: spieler.nutzer.id })
        );

        return (
            <React.Fragment>
                {!!((spielerListe && spielerListe.length) || (this.props.showSpielerFilter == false)) &&
                <Collapse ghost>
                    <Panel header={this.state.filterHeaderCriteria} key="1">
                    <Form
                        onFinish={this.handleSubmit}
                        ref={this.formRef}
                        {...layout}
                        initialValues={initialValues}
                        style={{maxWidth: '600px'}}>

                        <FormItem
                            label="Sätze"
                            name="satzFilter"
                            validateStatus={this.state.satzFilter.validateStatus}
                            help={this.state.satzFilter.errorMsg}>
                            
                            <Select
                                size="medium"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'satzFilter')}>
                                <Select.Option value="ALL" key="ALL">alle Ergebnisse</Select.Option>
                                <Select.Option value="ONE_SET" key="ONE_SET">min. 1 Satz</Select.Option>
                                <Select.Option value="CONPLETE_MATCHES" key="CONPLETE_MATCHES">nur vollständige Matches</Select.Option>
                            </Select>
                        </FormItem>


                        <FormItem
                            label="ab"
                            name="datumFilterAb"
                            hasFeedback
                            validateStatus={this.state.datumFilterAb.validateStatus}
                            help={this.state.datumFilterAb.errorMsg}>
                            <Input
                                size="medium"
                                name="datumFilterAb"
                                autoComplete="on"
                                placeholder="01.01.2000"
                                value={this.state.datumFilterAb.value}
                                onChange={(event) => this.handleInputChange(event, this.validateDatum)}
                            />
                        </FormItem>
                        
                        <FormItem
                            label="bis"
                            name="datumFilterBis"
                            hasFeedback
                            validateStatus={this.state.datumFilterBis.validateStatus}
                            help={this.state.datumFilterBis.errorMsg}>
                            <Input
                                size="medium"
                                name="datumFilterBis"
                                autoComplete="on"
                                placeholder="01.01.2000"
                                value={this.state.datumFilterBis.value}
                                onChange={(event) => this.handleInputChange(event, this.validateDatum)}
                            />
                        </FormItem>
                        
                        {!!this.props.showSpielerFilter &&
                            <FormItem
                                label="Spieler"
                                name="nutzerFilter"
                                validateStatus={this.state.nutzerFilter.validateStatus}
                                help={this.state.nutzerFilter.errorMsg}>
    
                                <Checkbox.Group options={spielerOptions}
                                                onChange={this.onNutzerFilterChange}/>
    
                            </FormItem>
                        }

                        <Button size="large"
                                onClick={()=>this.clearFilter()}
                                style={{marginLeft: '1em'}}
                                className="myLargeBtn btn btn-secondary delete-button">
                            <FontAwesomeIcon icon={faTrashAlt} style={{marginRight: '0.5em'}}/>
                            Zurücksetzen
                        </Button>

                        <Button type="submit"
                                size="large"
                                style={{marginLeft: '1em'}}
                                className="myLargeBtn">
                            <FontAwesomeIcon icon={faFilter} style={{marginRight: '0.5em'}}/>
                            Filtern
                        </Button>


                    </Form>

                        
                     
                        </Panel>
                    </Collapse>

                }
            </React.Fragment>
        );
    }

    
    validateDatum = (datum) => {
        if(!datum) {
            //Datum ist leer, und darf auch leer sein. Ist kein Pflichtfeld.
            return;
        } else if (!datum.match(DATE_REGEX)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Datum entspricht nicht dem geforderten Format. Bitte geben Sie ein Datum mit einer Uhrzeit im Format dd.MM.yyyy HH:mm an.'
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }
}



export default FilterFrom;