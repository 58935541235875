export default class Spielpaarung {
    
    constructor(gastHeim_Heim, gastHeim_Gast) {
        this.heim = gastHeim_Heim;
        this.gast = gastHeim_Gast;
    }

    istNutzerBeteiligt(nutzerId){
        return this.heim.istNutzerBeteiligt(nutzerId) || this.gast.istNutzerBeteiligt(nutzerId);
    }
}