import { API_BASE_URL, ACCESS_TOKEN } from '../constants';
import { request } from '../util/APIUtils';


export const authService = {
  login,
  signup,
  invite,
  getCurrentUser,
  checkEmailAvailability,
  getSignupRequest,
  forgottenPassword,
  resetPassword
};


export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}

// export function invite(inviteRequest) {
//   console.log("Service:")
//   console.log(inviteRequest);
//   return request({
//     url: API_BASE_URL + "/auth/invite",
//     method: 'POST',
//     body: JSON.stringify(invite)
//   });
// }

export function invite(inviteRequest) {
  return request({
    url: API_BASE_URL + "/auth/invite",
    method: 'POST',
    body: JSON.stringify(inviteRequest)
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: 'POST',
    body: JSON.stringify(signupRequest)
  });
}

export function checkEmailAvailability(email) {
  return request({
    url: API_BASE_URL + "/users/checkEmailAvailability?email=" + email,
    method: 'GET'
  });
}

export function getCurrentUser() {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/users/me",
    method: 'GET'
  });
}


export function getSignupRequest(registrationtoken)
{
  return request({
    url: API_BASE_URL + "/auth/signup/get?token=" + registrationtoken,
    method: 'GET'
  });
}

export function acceptInvitation(signupRequest)
{
  return request({
    url: API_BASE_URL + "/auth/signup/confirm",
    method: 'POST',
    body: JSON.stringify(signupRequest)
  });
}

export function forgottenPassword(emailRequest) {
  return request({
    url: API_BASE_URL + "/auth/password/forgotten",
    method: 'POST',
    body: JSON.stringify(emailRequest)
  });
}

export function resetPassword(resetPassRequest) {
  return request({
    url: API_BASE_URL + "/auth/password/save",
    method: 'POST',
    body: JSON.stringify(resetPassRequest)
  });
}
