import React from 'react'
import {setErrorState} from '../../util/ErrorUtil';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {getAllTrainingstageByMannschaftsId} from "../../_services/trainingsService";
import TrainingstageSaisonNew from "./trainingstage_saison_new_component";
import TrainingstageList from "./trainingstage_list_component";
import TrainingstageSingleNew from "./trainingstage_single_new_component";

class MannschaftTrainingsEdit extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      trainingsLoaded: false,
      trainings: [],
      successMsg: '',
      errorMsg: ''
    };
    this.loadData = this.loadData.bind(this);
    this.onChildChanged = this.onChildChanged.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }


  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.setState({mannschaft: this.props.mannschaft});
      this.loadTrainings();
    }
  }

  loadTrainings() {
    const mannschaft = this.props.mannschaft;

    getAllTrainingstageByMannschaftsId(mannschaft.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              trainings: response,
              trainingsLoaded: true
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            this.setState({
              trainingsLoaded: true
            });
            setErrorState(error, this);
          }
        });
  }

  onChildChanged(){
    this.loadData();
  }
  
  render()
  {
    const { trainings, mannschaft, trainingsLoaded } = this.state;

    return (
        <div id="mannschaft_trainingstage_edit">
          <h2>Trainingstermine</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          {!!trainings && !!trainings.length && !!mannschaft &&
          <div>
              <TrainingstageList trainings={trainings} componentChanged={this.onChildChanged}/>
          </div>
          }

          {!!trainingsLoaded && !!mannschaft &&
              <div>
                <TrainingstageSingleNew mannschaft={mannschaft}
                                        componentChanged={this.onChildChanged}/>

                <br/>
                <br/>
                <TrainingstageSaisonNew mannschaft={mannschaft}
                                        componentChanged={this.onChildChanged}/>
              </div>
          }

        </div>
    );
  }
}

export default MannschaftTrainingsEdit;