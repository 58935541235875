import React from 'react'
import {save} from "../../../_services/mannschatsService";
import {Button} from "react-bootstrap";
import {setErrorState} from '../../../util/ErrorUtil';
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {Form, Input} from "antd";

import {getCurrentUser} from "../../../_services";
import {vereinsService} from "../../../_services/vereinsService";

const FormItem = Form.Item;

class MannschaftNew extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      mannschaft: {},
      verein: {},
      loggedInUser: null,
      successMsg: '',
      errorMsg: '',
      name: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      }
    };
    this.loadData = this.loadData.bind(this);
    this.loadLoggedInUser = this.loadLoggedInUser.bind(this);
    this.loadVerein = this.loadVerein.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadLoggedInUser();
    this.loadVerein();
  }

  loadLoggedInUser(){
    getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }

  loadVerein() {
    //url Parameter
    const vereinId = this.props.match.params.vereinId;

    vereinsService.getById(vereinId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              verein: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  onSave() {

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');

    var id = 0;
    if(this.state.mannschaft){
      id = this.state.mannschaft.id;
    }

    const saveRequest = {
      id: id,
      verein: this.state.verein,
      name: this.state.name.value
    };
    
    save(this.state.loggedInUser.id, saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Die Mannschaft wurde erfolgreich angelegt!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false,
            mannschaft: response
          });

          this.redirectOnSaveSuccess(response.id);

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }
  
  redirectOnSaveSuccess(mannschaftId){
    window.location.href = '/verwaltung/mannschaft/edit/' + mannschaftId;
  }

  onBack() {
    window.location.href = '/verwaltung/verein/edit/' + this.state.verein.id;
  }
  
  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const { verein, loggedInUser } = this.state;
    
    return (
        <div id="mannschaft_edit">

          <h2>Neue Mannschaft anlegen</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          {!!loggedInUser && !!loggedInUser.id && !!verein && !!verein.id &&
          <div>
            <div className="form-container">
              <Form {...layout} className="mannschaft-edit-form"
                    initialValues={{
                      "name": this.state.mannschaft.name
                    }}>

                <FormItem
                    labelAlign="left"
                    label="Name"
                    name="name"
                    rules={[{
                      required: true,
                      message: 'Bitte gib einen Mannschaftsnamen an'
                    }]}
                    hasFeedback
                    validateStatus={this.state.name.validateStatus}
                    help={this.state.name.errorMsg}>
                  <Input
                      size="large"
                      name="name"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.name.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>

              </Form>
            </div>

            <div id="btn-container" className={"form-container"}>
              <Button type="submit"
                      style={{float: 'right'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onSave()}
                      disabled={this.state.disableSubmitButton}>Speichern</Button>

              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onBack()}>Zurück</Button>
            </div>
          </div>
          }
          
        </div>
    );
  }

}

export default MannschaftNew;
