import { request } from '../../util/APIUtils';
import {API_BASE_URL} from "../../constants";

export const abstimmungOptionAntwortService = {
    getAbstimmungAntwortenAggregated,
    getAllAbstimmungAntwortForTeilnehmer,
    getAndCreateAbstimmungAntwortenForTeilnehmer,
    zusagen,
    absagen,
    unsicher
};

export function getAbstimmungAntwortenAggregated(abstimmungsId)
{
    return request({
        url: API_BASE_URL + "/abstimmungoptionantwort/overview/aggregated/"  + abstimmungsId,
        method: 'GET'
    });
}

export function getAllAbstimmungAntwortForTeilnehmer(abstimmungTeilnehmerId)
{
    return request({
        url: API_BASE_URL + "/abstimmungoptionantwort/overview/"  + abstimmungTeilnehmerId,
        method: 'GET'
    });
}

export function getAndCreateAbstimmungAntwortenForTeilnehmer(abstimmungTeilnehmerId)
{
    return request({
        url: API_BASE_URL + "/abstimmungoptionantwort/teilnehmer/all/"  + abstimmungTeilnehmerId,
        method: 'GET'
    });
}

export function zusagen(abstimmungsOptionId, abstimmungTeilnehmerId)
{
    return antworten(abstimmungsOptionId, abstimmungTeilnehmerId, 'zusagen');
}

export function absagen(abstimmungsOptionId, abstimmungTeilnehmerId)
{
    return antworten(abstimmungsOptionId, abstimmungTeilnehmerId, 'absagen');
}

export function unsicher(abstimmungsOptionId, abstimmungTeilnehmerId)
{
    return antworten(abstimmungsOptionId, abstimmungTeilnehmerId, 'unsicher');
}

export function antworten(abstimmungsOptionId, abstimmungTeilnehmerId, antwort)
{
    return request({
        url: API_BASE_URL + "/abstimmungoptionantwort/" + antwort + "/?abstimmungsOptionId=" + abstimmungsOptionId + "&abstimmungTeilnehmerId=" + abstimmungTeilnehmerId,
        method: 'GET'
    });
}
