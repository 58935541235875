import React from 'react'
import SuccessMessage from "../_components/notifications/SuccessMessage";
import ErrorMessage from "../_components/notifications/ErrorMessage";
import {Form} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Button} from "react-bootstrap";
import {trainingsService} from "../_services/trainingsService";
import {setErrorState} from "../util/ErrorUtil";

const FormItem = Form.Item;

class TrainingComment extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      trainingstag: {},
      successMsg: '',
      errorMsg: '',
      kommentar: {
        value: this.props.trainingstag.kommentar,
        validateStatus: 'success',
        errorMsg: null
      },
    };
      this.onSave = this.onSave.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    
  }
  
  onSave(){

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    var saveRequest = {
      trainingstagId: this.props.trainingstag.id,
      kommentar: this.state.kommentar.value
    };

    trainingsService.saveKommentarOnTrainingstag(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Das Training wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }
  
  render()
  {
    return (
        <div id="trainings_kommentar">

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!this.props.trainingstag && !!this.props.trainingstag.id && 
            <div id="comment div">
              <Form
                  onFinish={this.onSave}
                  className="trainingstage-edit-form"
                    initialValues={{
                      "kommentar": this.props.trainingstag.kommentar
                    }}>
                <FormItem
                    name="kommentar"
                    validateStatus={this.state.kommentar.validateStatus}
                    help={this.state.kommentar.errorMsg}>
                  
                  <TextArea
                      size="large"
                      name="kommentar"
                      maxLength={1000}
                      rows={7} 
                      style={{width: '100%'}}
                      className="small-responsive-field"
                      value={this.state.kommentar.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>
                <div style={{paddingTop: '0.25em', paddingBottom: '2em'}}>
                  <Button type="submit"
                          style={{float: 'right'}}
                          size="large"
                          className="myLargeBtn"
                          disabled={this.state.disableSubmitButton}>Speichern</Button>
                  <br/>
                </div>
              </Form>
            </div>
          }

        </div>
    );
  }
}


export default TrainingComment;
