import React from 'react'
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Button} from "react-bootstrap";
import TrainingsplanungRundeModal from "./trainingsplanung_runde_modal";
import TrainingsplanungRundeRow from "./trainingsplanung_runde_row";
import {setErrorState} from "../../util/ErrorUtil";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TrainingsplanungRundenEdit extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      visibleRunden: false,
      successMsg: '',
      errorMsg: ''
    };
  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showModalRunden = () => {
    this.setState({ visibleRunden: true });
  };

  handleCancelRunden = () => {
    this.setState({ visibleRunden: false });
  };
  
  setSuccessMessage = (mes) => {
    this.setState({
      successMsg: mes,
    });
  };

  setErrorMessage = (error) => {
    setErrorState(error, this);
  };
  
  render()
  {

    return (
        <div id="trainingsplanungrundenedit">

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          <div>
            <h1>
              <span>Trainingsplan </span>
              <Button type="primary" onClick={this.showModalRunden}>
                <FontAwesomeIcon icon={faPlusCircle}/>  Neue Runde
              </Button>
            </h1>
            
            <table>
              <tbody>
              {this.props.runden.map((runde, i) =>
                  <TrainingsplanungRundeRow runde={runde}
                                            vereinsSpielerDistinctNutzer={this.props.vereinsSpielerDistinctNutzer}
                                            onReloadRunden={this.props.onReloadRunden}
                                            onSetSuccessMessage={this.setSuccessMessage}
                                            onSetErrorMessage={this.setErrorMessage}
                                            key={'k_' + i}/>
              )}
              </tbody>
            </table>
          </div>

          <TrainingsplanungRundeModal trainingstag={this.props.trainingstag} 
                                      visible={this.state.visibleRunden} 
                                      onCancel={this.handleCancelRunden}
                                      onReloadRunden={this.props.onReloadRunden}/>


        </div>
    );
  }

}


export default TrainingsplanungRundenEdit;
