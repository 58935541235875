
export function getDisplayName(nutzer, turncate){
    if(turncate){
        return truncateDisplayName(nutzer);
    }
    return nutzer.vorname + ' ' + nutzer.nachname;
}

function truncateDisplayName(nutzer){
    let nachname = nutzer.nachname;

    return nutzer.vorname + ' ' + nachname.substring(0, 1) + '.';
}