import './abstimmungsUbersicht.css';
import img_checked from '../img/checked.png';
import img_cancelled from '../img/cancelled.png';
import img_unbekannt from '../img/unbekannt.png';
import img_null from '../img/null.png';
//import { btnSuccess } from "@fortawesome/free-solid-svg-buttons";
import {Button} from 'react-bootstrap';

import React from 'react';
import moment from "moment";
import SuccessMessage from "../_components/notifications/SuccessMessage";
import ErrorMessage from "../_components/notifications/ErrorMessage";
import {setErrorState} from "../util/ErrorUtil";
import {
  abstimmungOptionAntwortService,
  unsicher
} from "../_services/abstimmung/abstimmungOptionAntwortService";
import {getDisplayName} from "../_helpers/displayHelper";
import {abstimmungTeilnehmerService} from "../_services/abstimmung/abstimmungTeilnehmerService";

class AbstimmungTeilnehmerEdit extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);

    this.state = {
      teilnehmerId: this.props.match.params.teilnehmerId,
      abstimmungAntwortListe: [],
      successMsg: '',
      errorMsg: '',
      disableZusagenButton: false,
      disableAbsagenButton: false,
      disableUnsicherButton: false
      
    };
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    this.loadAbstimmungAntwortenForTeilnehmer();
  }

  loadAbstimmungAntwortenForTeilnehmer(){
    const teilnehmerId = this.state.teilnehmerId;

    abstimmungOptionAntwortService.getAndCreateAbstimmungAntwortenForTeilnehmer(teilnehmerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          abstimmungAntwortListe: response
        });
      }
    })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  onZusagen(abstimmungsOptionId, teilnehmerId) {
    this.setState({
      disableZusagenButton: true
    });
    
    abstimmungOptionAntwortService.zusagen(abstimmungsOptionId, teilnehmerId).then(result => {
      this.setState({
        disableZusagenButton: false
      });
          this.loadData();
    })
    .catch(error => this.setState({hasError: error, disableZusagenButton: false}));
  }

  onAbsagen(abstimmungsOptionId, teilnehmerId) {
    this.setState({
      disableAbsagenButton: true
    });
    
    abstimmungOptionAntwortService.absagen(abstimmungsOptionId, teilnehmerId).then(result => {
      this.setState({
        disableAbsagenButton: false
      });
      this.loadData();
    })
    .catch(error => this.setState({hasError: error, disableAbsagenButton: false}));
  }

  onUnsicher(punktspielId, spielerId) {
    this.setState({
      disableUnsicherButton: true
    });

    abstimmungOptionAntwortService.unsicher(punktspielId, spielerId).then(result => {
      this.setState({
        disableUnsicherButton: false
      });
      this.loadData();
    })
        .catch(error => this.setState({hasError: error, disableUnsicherButton: false}));
  }

  onBack() {
    abstimmungTeilnehmerService.getById(this.state.teilnehmerId)
        .then(response => {
          if (this._isMounted) {
            window.location.href = '/abstimmung/view/' + response.abstimmung.id;
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  render()
  {
    const { abstimmungAntwortListe } = this.state;

    var spielerNick = '';
    if(abstimmungAntwortListe && abstimmungAntwortListe[0] && abstimmungAntwortListe[0].abstimmungTeilnehmer && abstimmungAntwortListe[0].abstimmungTeilnehmer.nutzer){
      spielerNick = getDisplayName(abstimmungAntwortListe[0].abstimmungTeilnehmer.nutzer, true)
    }
    
    return (
        <div id="punktspiele_edit">
          <h1>Abstimmung für {spielerNick}</h1>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          <table className="table table-hover small-table">
            <thead className="thead-light">
            <tr>
              <th scope="col" style={{textAlign: 'left'}}>Datum</th>
              <th scope="col"><div className="responsiveHide">Antwort</div></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>

            {!!abstimmungAntwortListe &&
            <tbody>

            {abstimmungAntwortListe.map((antwort, i) =>
                <tr key={'punktspiel_edit_' + i}>
                  <td style={{textAlign: 'left'}}>
                      {antwort.abstimmungOption.option}
                  </td>

                  <td>
                    {(antwort.antwort ==='ZUGESAGT') &&
                    <img src={img_checked} className="img-responsive zugesagt-img" alt="hat zugesagt" title={"hat zugesagt am " + moment(antwort.letzteAenderung).format("DD.MM.YYYY HH:mm")}/>
                    }

                    {(antwort.antwort ==='ABGESAGT') &&
                    <img src={img_cancelled} className="img-responsive zugesagt-img" alt="hat abgesagt" title={"hat abgesagt am " + moment(antwort.letzteAenderung).format("DD.MM.YYYY HH:mm")}/>
                    }

                    {(antwort.antwort ==='UNSICHER') &&
                    <img src={img_unbekannt} className="img-responsive zugesagt-img" alt="ist noch unsicher" title={"hat abgestimmt am " + moment(antwort.letzteAenderung).format("DD.MM.YYYY HH:mm")}/>
                    }
                    
                    {(antwort.antwort === null) &&
                    <img src={img_null} className="img-responsive zugesagt-img" alt="hat noch nicht geantwortet" title={"hat noch nicht geantwortet"}/>
                    }
                    
                  </td>

                  <td>
                  {(antwort.antwort === null || antwort.antwort !== 'ZUGESAGT') &&
                    <Button variant="success" disabled={this.state.disableZusagenButton} onClick={() => this.onZusagen(antwort.abstimmungOption.id, antwort.abstimmungTeilnehmer.id)}>Zusagen</Button>
                  }
                  </td>

                  <td>
                    {(antwort.antwort === null || antwort.antwort !== 'UNSICHER') &&
                    <Button variant="warning" disabled={this.state.disableUnsicherButton} onClick={() => this.onUnsicher(antwort.abstimmungOption.id, antwort.abstimmungTeilnehmer.id)}>Unsicher</Button>
                    }
                  </td>
                  
                  <td>
                    {(antwort.antwort === null || antwort.antwort !== 'ABGESAGT') &&
                    <Button variant="danger" disabled={this.state.disableAbsagenButton} onClick={() => this.onAbsagen(antwort.abstimmungOption.id, antwort.abstimmungTeilnehmer.id)}>Absagen</Button>
                    }
                  </td>
                </tr>
            )}
            
            </tbody>
            }

          </table>
          <Button onClick={() => this.onBack()}>Zurück</Button>
        </div>
    );
  }

}



export default AbstimmungTeilnehmerEdit;
