import React from 'react'
import {getMannschaftById} from "../../_services/mannschatsService";
import MannschaftsMitgliederEdit from "./mannschaft_mitglieder_edit";
import {Button} from "react-bootstrap";
import {setErrorState} from '../../util/ErrorUtil';
import MannschaftPunktspieleEdit from "./mannschaft_punktspiele_edit";
import MannschaftTrainingsEdit from "./mannschaft_trainings_edit";
import MannschaftsEinstellungenEdit from "./mannschaft_einstellungen_edit";
import BackButton from "../../common/BackButton";

class MannschaftEdit extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      successMsg: '',
      errorMsg: ''
    };
    this.loadData = this.loadData.bind(this);
    this.loadMannschaft = this.loadMannschaft.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadMannschaft();
  }

  loadMannschaft() {
    const mannschaftsId = this.props.match.params.mannschaftsId;

    getMannschaftById(mannschaftsId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          mannschaft: response
        });
      }
    })
    .catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
    });
  }
  

  
  render()
  {
    const { mannschaft} = this.state;
    
    return (
        <div id="mannschaft_edit">
          {!!mannschaft &&
          <h2> {mannschaft.name}</h2>
          }

          {!!mannschaft && !!mannschaft.id &&
          <MannschaftsEinstellungenEdit mannschaft={mannschaft} />
          }
          
          <br/>
          <br/>
          <br/>
          
          {!!mannschaft && !!mannschaft.id &&
            <MannschaftsMitgliederEdit mannschaft={mannschaft} reloadData={this.loadData}/>
          }

          <Button variant="primary" onClick={() => this.props.history.push('/invitation/new/' + mannschaft.id)}>Spieler hinzufügen</Button>

          <br/>
          <br/>
          <br/>
          
          {!!mannschaft && !!mannschaft.id &&
            <MannschaftPunktspieleEdit mannschaft={mannschaft}/>
          }
          <Button variant="primary" onClick={() => this.props.history.push('/punktspiele/new/' + mannschaft.id)}>Punktspiel hinzufügen</Button>

          <br/>
          <br/>
          <br/>

          {!!mannschaft && !!mannschaft.id &&
          <MannschaftTrainingsEdit mannschaft={mannschaft}/>
          }

            <br/>
            <br/>
            <BackButton/>
          
        </div>
    );
  }

}

export default MannschaftEdit;
