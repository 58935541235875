import React from 'react';
import {Form, Input, Select} from "antd";
import {startseiteService} from "../../_services/startseiteService";
import BackButton from "../../common/BackButton";
import {Button} from "react-bootstrap";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState} from "../../util/ErrorUtil";
import {checkEmailAvailability, userService} from "../../_services";
import {EMAIL_MAX_LENGTH, NAME_MAX_LENGTH} from "../../constants";

const FormItem = Form.Item;

// tag::SpielerProfilAllgemeineEinstellungen[]
class SpielerProfilAllgemeineEinstellungen extends React.Component
{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            formIsValid: true,
            nutzer: {},
            startseiteSelectItems: [],
            //selectedStartseite: {},
            //brauchen wir, da ein Nutzer auch keine Startseiten haben kann.
            //dann soll die Seite ja trotzdem angezeigt werden
            selectItemsLoaded: false,
            selectedStartseiteLoaded: false,
            selectedStartseite: {
                startseiteFromDb: {},
                selectedObject: {},
                selectedObjectId: {}
            },
            email: {
                value: this.props.nutzer.email,
                validateStatus: 'success',
                errorMsg: null
            },
            vorname: {
                value: this.props.nutzer.vorname,
                validateStatus: 'success',
                errorMsg: null
            },
            nachname: {
                value: this.props.nutzer.nachname,
                validateStatus: 'success',
                errorMsg: null
            },
            nickname: {
                value: this.props.nutzer.nickname,
                validateStatus: 'success',
                errorMsg: null
            },
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    }

    componentDidMount()
    {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
        if(this.props.nutzer !== nextProps.nutzer && this.props.nutzer.id !== nextProps.nutzer.id) {
            // Reset State
            this.loadData();
        }
    }

    loadData(){
        this.loadStartseite();
    }
    
    loadStartseite(){
            const nutzerId = this.props.nutzer.id;
            
            startseiteService.findByNutzer(nutzerId)
                .then(responseStartseite => {
                    if (this._isMounted) {
                        this.setState({
                            selectedStartseite: {
                                startseiteFromDb: responseStartseite,
                                selectedObject: responseStartseite.object,
                                selectedObjectId: responseStartseite.objectId
                            },
                            selectedStartseiteLoaded: true
                        });
                    }
                })
                .catch(errorStartseite => {
                    if (this._isMounted) {
                        this.setState({
                            hasError: errorStartseite,
                            selectedStartseiteLoaded: true
                        });
                    }
                });
            
            startseiteService.findAllPossibleStartseitenForNutzer(nutzerId)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            startseiteSelectItems: response,
                            selectItemsLoaded: true
                        });
                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        this.setState({
                            hasError: error,
                            selectItemsLoaded: true
                        });
                    }
                });
  

    }

    handleInputChange(event, validationFun, label) {

        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue, label)
            }
        });
    }
    
    handleSelectInputChange(value, name) {
        const myArray = value.split("_");
        let object = myArray[0];
        let objectId = myArray[1];
        
        this.setState({
            [name] : {
                selectedObject: object,
                selectedObjectId: objectId
            },
            //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
            //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
            successMsg: null,
        });
    }

    
    handleSubmit() {

        this.setState({
            errorMsg: null,
            successMsg: null
        });
        
        var startseiteToSave = null;
        //Wenn keine objectId gesetzt ist, dann wurde keine Startseite ausgewaehlt.
        //Dann darf das Object auch gar nicht mitgesendet werden, da sonst fuer die id und objectId
        //leere Felder mitgesendet werden und in der RESTApi sind das im Dto ja aber long (also Pflicht-) Felder
        if(this.state.selectedStartseite.selectedObjectId > 0){
            startseiteToSave = {
                nutzer: this.props.nutzer,
                object: this.state.selectedStartseite.selectedObject,
                objectId: this.state.selectedStartseite.selectedObjectId
            };           
        } else if(this.state.selectedStartseite.selectedObjectId == 0){
            //default Value, der leere Eintrag
            startseiteToSave = {
                nutzer: this.props.nutzer,
                object: 'LEER',
                objectId: 0
            };
        }
        
        var nutzerEinstellungenSaveRequest = {
            nutzerId: this.props.nutzer.id,
            email: this.state.email.value,
            vorname: this.state.vorname.value,
            nachname: this.state.nachname.value,
            nickname: this.state.nickname.value,
            startseite: startseiteToSave
        };
        
        userService.saveNutzerEinstellungen(nutzerEinstellungenSaveRequest)
            .then(response => {
                this.setState({
                    successMsg: "Profil erfolgreich gespeichert."
                });
            }).catch(error => {
            setErrorState(error, this);
        });
    }


   
    render()
    {
        const {startseiteSelectItems, selectedStartseite, selectItemsLoaded, selectedStartseiteLoaded } = this.state;
        
        let spielerItems = startseiteSelectItems.map((startseiteItem) =>
            <Select.Option value={startseiteItem.object + "_" + startseiteItem.objectId} key={startseiteItem.object + '_' + startseiteItem.objectId}>{startseiteItem.objectIdDisplayName} {startseiteItem.objectDisplayName}</Select.Option>
        );

        const emptySelection = <Select.Option value={"LEER_0"} key={"LEER_0"}> </Select.Option>;
        //ganz an den Anfang an die erste Position
        spielerItems.unshift( emptySelection );

        var layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };
        
        var formLayout = 'form-container-breit';
        if(this.props.showAdminFields){
            formLayout = 'allgemeine-einstellungen-form';
            layout = {};
        }

        var defaultValueForSelect = {};
        
        if(selectedStartseite.startseiteFromDb && selectedStartseite.startseiteFromDb.objectId){
            defaultValueForSelect = selectedStartseite.startseiteFromDb.object + '_' + selectedStartseite.startseiteFromDb.objectId;
        }else if (startseiteSelectItems && startseiteSelectItems.length > 0){
            defaultValueForSelect = "LEER_0";
        }
        
        return (
            <React.Fragment>
                {!!selectedStartseiteLoaded && !!selectItemsLoaded && 
                <div className={formLayout}>
                <Form onFinish={this.handleSubmit}
                      {...layout}
                      initialValues={{
                          "email": this.props.nutzer.email,
                          "vorname": this.props.nutzer.vorname,
                          "nachname": this.props.nutzer.nachname,
                          "nickname": this.props.nutzer.nickname
                      }}>

                    {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                    }

                    {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                    }
                        <fieldset>
                            <legend><h2>Allgemeine Einstellungen</h2></legend>

                            <FormItem
                                label="persönliche Startseite">
                                <Select
                                    defaultValue={defaultValueForSelect}
                                    size="large"
                                    onChange={(value, event) => this.handleSelectInputChange(value, 'selectedStartseite')}>
                                    {spielerItems}
                                </Select>
                            </FormItem>

                            <FormItem
                                label="E-Mail"
                                name="email"
                                rules={[{
                                    required: true,
                                    message: 'Bitte gib deinen E-Mail-Adresse an'
                                }]}
                                hasFeedback
                                validateStatus={this.state.email.validateStatus}
                                help={this.state.email.errorMsg}>
                                <Input
                                    size="large"
                                    name="email"
                                    type="email"
                                    autoComplete="on"
                                    placeholder="Deine Email-Adresse"
                                    value={this.state.email.value}
                                    onBlur={this.validateEmailAvailability}
                                    onChange={(event) => this.handleInputChange(event, this.validateEmail)}/>
                            </FormItem>

                            <FormItem
                                label="Vorname"
                                name="vorname"
                                rules={[{
                                    required: true,
                                    message: 'Bitte gib deinen Vornamen an'
                                }]}
                                hasFeedback
                                validateStatus={this.state.vorname.validateStatus}
                                help={this.state.vorname.errorMsg}>
                                <Input
                                    size="large"
                                    name="vorname"
                                    autoComplete="on"
                                    placeholder="Deine Vorname"
                                    value={this.state.vorname.value}
                                    onChange={(event) => this.handleInputChange(event, this.validateName, "Vorname")}/>
                            </FormItem>

                            <FormItem
                                label="Nachname"
                                name="nachname"
                                rules={[{
                                    required: true,
                                    message: 'Bitte gib deinen Nachnamen an'
                                }]}
                                hasFeedback
                                validateStatus={this.state.nachname.validateStatus}
                                help={this.state.nachname.errorMsg}>
                                <Input
                                    size="large"
                                    name="nachname"
                                    autoComplete="on"
                                    placeholder="Deine Nachname"
                                    value={this.state.nachname.value}
                                    onChange={(event) => this.handleInputChange(event, this.validateName, "Nachname")}/>
                            </FormItem>

                            <FormItem
                                label="Nickname"
                                name="nickname"
                                rules={[{
                                    required: true,
                                    message: 'Bitte gib deinen Nickname an'
                                }]}
                                hasFeedback
                                validateStatus={this.state.nickname.validateStatus}
                                help={this.state.nickname.errorMsg}>
                                <Input
                                    size="large"
                                    name="nickname"
                                    autoComplete="on"
                                    placeholder="Deine Nickname"
                                    value={this.state.nickname.value}
                                    onChange={(event) => this.handleInputChange(event, this.validateName, "Nickname")}/>
                            </FormItem>
                        </fieldset>
    
                        <BackButton/>
                        <Button type="submit"
                            size="large"
                            style={{marginLeft: '1em'}}
                            className="myLargeBtn">Speichern</Button>

                    <br/>
                    <br/>
                    <br/>
                  
                </Form>
                </div>
                }
            </React.Fragment>
        );
    }

    validateName= (name, label) => {
        if(!name) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deinen ${label}n an. Die Angabe des ${label}s ist pflicht.`
            }
        }


        if(name.length > NAME_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Der ${label} ist zu lang. Es sind maximal ${NAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        }

        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib deine Email-Adresse an. Die Angabe der Email-Adresse ist pflicht.'
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Das ist keine valide Email-Adresse.'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Email-Adresse ist zu lang. Es sind maximal ${EMAIL_MAX_LENGTH} Zeichen erlaubt.`
            }
        }
        
        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }

    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });
            return;
        }

        //zuerst schauen, ob die Email ueberhaupt geandert wurde.
        //sonst findet er ja ein Duplikat, und zwar meine eigene
        const originalEmail = this.props.nutzer.email;

        if(originalEmail === emailValue){
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
            return;
        }
        
        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
            .then(response => {
                if(response.available) {
                    this.setState({
                        email: {
                            value: emailValue,
                            validateStatus: 'success',
                            errorMsg: null
                        }
                    });
                } else {
                    this.setState({
                        email: {
                            value: emailValue,
                            validateStatus: 'error',
                            errorMsg: 'Ein Konto mit dieser Email-Adresse existiert bereits. Bitte melde ich an.'
                        }
                    });
                }
            }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
        });
    }
}


export default SpielerProfilAllgemeineEinstellungen;