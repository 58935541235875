import { request } from '../../util/APIUtils';
import {API_BASE_URL} from "../../constants";

export const terminTeilnehmerService = {
    getById,
    getAllTeilnehmerForTerminSorted,
    deleteTeilnehmerById,
    createAndAddNutzerForTermin
};

export function getById(terminTeilnehmerId)
{
    return request({
        url: API_BASE_URL + "/terminteilnehmer/"  + terminTeilnehmerId,
        method: 'GET'
    });
}

export function getAllTeilnehmerForTerminSorted(terminId)
{
    return request({
        url: API_BASE_URL + "/terminteilnehmer/all/"  + terminId,
        method: 'GET'
    });
}

export function deleteTeilnehmerById(teilnehmerId)
{
    return request({
        url: API_BASE_URL + "/terminteilnehmer/deleteById/"  + teilnehmerId,
        method: 'DELETE'
    });
}

export function createAndAddNutzerForTermin(addNutzerToTerminRequest) {
    return request({
        url: API_BASE_URL + "/terminteilnehmer/addNewNutzerToTermin",
        method: 'POST',
        body: JSON.stringify(addNutzerToTerminRequest)
    });
}