import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const userService = {
    getNutzerById,
    getAll,
    getUserProfile,
    getByVerein,
    getNutzerForVereinMinusMannschaftsspieler,
    saveNutzerEinstellungen,
    getAllRedirectInformationForNutzer
};

export function getNutzerById(nutzerId)
{
    return request({
        url: API_BASE_URL + "/users/"  + nutzerId,
        method: 'GET'
    });
}

export function getAll()
{
    return request({
        url: API_BASE_URL + "/users/users/",
        method: 'GET'
    });
}

export function getUserProfile(username) {
    return request({
        url: API_BASE_URL + "/users/" + username,
        method: 'GET'
    });
}

//Liefert eine Liste aller Nutzer des Vereins, in dem der uebergebene NUtzer Mitglied ist
export function getByVerein(vereinId) {
    return request({
        url: API_BASE_URL + "/users/getByVerein?vereinId=" + vereinId,
        method: 'GET'
    });
}

//Liefert eine Liste aller Nutzer des Vereins, abzüglich der Nutzer, die bereits einem Spieler zugeordnet sind, der bereits in der Mannschaft sind.
export function getNutzerForVereinMinusMannschaftsspieler(mannschaftId,  page, size) {
    return request({
        url: API_BASE_URL + "/users/listforinvitation/" + mannschaftId + "?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

export function saveNutzerEinstellungen(nutzerEinstellungenSaveRequest) {
    return request({
        url: API_BASE_URL + "/users/saveNutzerEinstellungen",
        method: 'POST',
        body: JSON.stringify(nutzerEinstellungenSaveRequest)
    });
}

export function getAllRedirectInformationForNutzer(nutzerId) {
    return request({
        url: API_BASE_URL + "/users/redirectInformation/" + nutzerId,
        method: 'GET'
    });
}

