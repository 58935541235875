import DoppelPaarung from "./DoppelPaarung";

export default class DoppelAufstellung {

    constructor(doppelaufstellungJson) {
        //die Sortierung aus der Datenbank, nach AufstellungBewertung
        this.originalOrderPosition = doppelaufstellungJson.orderPosition;
        //fuer die spaetere Sortierung per Drag and Drop
        this.orderPosition = doppelaufstellungJson.orderPosition;
        this.doppel1 = new DoppelPaarung(doppelaufstellungJson.doppel1);
        this.doppel2 = new DoppelPaarung(doppelaufstellungJson.doppel2);
        this.doppel3 = new DoppelPaarung(doppelaufstellungJson.doppel3);

        const alterAust = doppelaufstellungJson.alternativeAufstellungen;
        
        //die meisten haben ja gar keine alternative Austellungen, dass man die Doppel switchen koennte
        //sonst gibts einen Fehler hier
        if(alterAust && alterAust.length > 0){
            const mapped = alterAust.map((element) => new DoppelAufstellung(element));
            this.alternativeAufstellungen = mapped;
        }
        
        //die attribute die nur hier im FE gesetzt und verwendet werden:
        this.isPinned = false;
        this.isBanned = false;
    }

    getStyleClassForDoppel(){
        const bewertung = this.getDoppelAufstellungBewertung();

        if(bewertung <= -7){
            //return '#BF112E';
            //return 'doppelaufstellung-bewertung-minus3';
            return 'doppel-schlecht';
        }

        if(bewertung >= 7){
            //return '#35F04E';
            return 'doppel-gut';
        }

        if(bewertung <= -5){
            //return '#FF5759';
            //return 'doppelaufstellung-bewertung-minus2';
            return 'doppel-mittel-schlecht';
        }

        if(bewertung <= -2){
            //return '#FF939D';
            //return 'doppelaufstellung-bewertung-minus1';
            return 'doppel-bisschen-schlecht';
        }

        
        if(bewertung >= 5){
            //return '#9EF0A9';
            //return 'doppelaufstellung-bewertung-2';
            return 'doppel-mittel-gut';
        }

        if(bewertung >= 2){
            //return '#DEFFDB';
            //return 'doppelaufstellung-bewertung-1';
            return 'doppel-bisschen-gut';
        }
        
        return 'doppel-neutral';
    }
    
    getDoppelAufstellungBewertung(){
        return (this.doppel1.bewertung) + (this.doppel2.bewertung) + (this.doppel3.bewertung);
    }
    
    isDoppel1Switchable(doppelToProof){
        const toProof = doppelToProof.positionsSumme;
        const d1 = this.doppel1.positionsSumme;
        const d2 = this.doppel2.positionsSumme;
        const d3 = this.doppel3.positionsSumme;
        

        var same = 0;
        
        if(toProof === d1){
            same++;
        }
        if(toProof === d2){
            same++;
        }
        if(toProof === d3){
            same++;
        }
        //da das Doppel selbst ja auch jetzt hier mit ueberpruet wird, hat ja man ja zumindest einen Treffer, wo die Positionssumme gleich ist, naemlich man selbst
        //daher muss ich 2 gleiche Positionssummen haben
        if(same >= 2){
            return true;
        }
        return false;
    }
    
    //ob die Aufstellung ein Doppel der beidedn uebergebenen Spieler beinhaltet.
    containsDoppelpaarung(spielerId1, spielerId2) {
        if(this.doppel1.isDoppelPaarungForSpieler(spielerId1, spielerId2)){
            return true;
        }

        if(this.doppel2.isDoppelPaarungForSpieler(spielerId1, spielerId2)){
            return true;
        }

        if(this.doppel3.isDoppelPaarungForSpieler(spielerId1, spielerId2)){
            return true;
        }
    }
    
    matches(doppelAufstellung_Filter){
        if(doppelAufstellung_Filter.doppelReihenfolgeBeachten === false){
            const v1 = this.doppel1.matches(doppelAufstellung_Filter.doppel1) &&
                this.doppel2.matches(doppelAufstellung_Filter.doppel2) &&
                this.doppel3.matches(doppelAufstellung_Filter.doppel3);

            const v2 = this.doppel2.matches(doppelAufstellung_Filter.doppel1) &&
                this.doppel1.matches(doppelAufstellung_Filter.doppel2) &&
                this.doppel3.matches(doppelAufstellung_Filter.doppel3);

            const v3 = this.doppel3.matches(doppelAufstellung_Filter.doppel1) &&
                this.doppel2.matches(doppelAufstellung_Filter.doppel2) &&
                this.doppel1.matches(doppelAufstellung_Filter.doppel3);

            const v4 = this.doppel2.matches(doppelAufstellung_Filter.doppel1) &&
                this.doppel3.matches(doppelAufstellung_Filter.doppel2) &&
                this.doppel1.matches(doppelAufstellung_Filter.doppel3);

            const v5 = this.doppel1.matches(doppelAufstellung_Filter.doppel1) &&
                this.doppel3.matches(doppelAufstellung_Filter.doppel2) &&
                this.doppel2.matches(doppelAufstellung_Filter.doppel3);

            const v6 = this.doppel3.matches(doppelAufstellung_Filter.doppel1) &&
                this.doppel1.matches(doppelAufstellung_Filter.doppel2) &&
                this.doppel2.matches(doppelAufstellung_Filter.doppel3);
            return v1 || v2 || v3 || v4 || v5 || v6;
        }else{
            const aufst1 = this.matchesStrict(doppelAufstellung_Filter);
            
            if(aufst1){
                return true;
            }
            
            //die alternativen Aufstellungen, wenn eine Position tauschbar ist
            if(this.alternativeAufstellungen && this.alternativeAufstellungen.length > 0){
                for (const aufstellung of this.alternativeAufstellungen) {
                    const matches = aufstellung.matchesStrict(doppelAufstellung_Filter);
                    if(matches){
                        return true;
                    }
                }
            }

        }
        return false;
    }
    
    matchesStrict(doppelAufstellung_Filter){
        return this.doppel1.matches(doppelAufstellung_Filter.doppel1) &&
            this.doppel2.matches(doppelAufstellung_Filter.doppel2) &&
            this.doppel3.matches(doppelAufstellung_Filter.doppel3);
    }
}