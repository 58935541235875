import './match_edit_inline.css';
import React from 'react'
import {Button} from 'react-bootstrap';
import moment from 'moment'
import {Col, Form, Input, Row, Select} from "antd";
import {matchEinzelService} from "../../_services/matchEinzelService";
import {setErrorState} from "../../util/ErrorUtil";
import {matchDoppelService} from "../../_services/matchDoppelService";
import {bereiteDatumsWertFuerRequestVor, isDatumValid} from "../../util/DateUtil";
import {spielerService} from "../../_services/spielerService";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import MatchDoppel from "../../dto/match/MatchDoppel";
import MatchEinzel from "../../dto/match/MatchEinzel";
import {MATCH_REGEX} from "../../constants/regex";
import {matchMapper} from "../../_mapper/matchMapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";


const FormItem = Form.Item;

class MatchEditInline extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      disableSubmitButton2: false,
      vereinsSpielerDistinctNutzer: [],
      successMsg: '',
      errorMsg: '',
      duplicatesFound: [],
      datum: {
        value: moment().format('DD.MM.YYYY'),
        validateStatus: 'success',
        errorMsg: null
      },
      nutzerHeim: {
        value: this.props.loggedInUser.id,
        validateStatus: '',
        errorMsg: null
      },
      nutzerGast: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      //fuers Doppel
      nutzerHeim2: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      //fuers Doppel
      nutzerGast2: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      ergebnis: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
    };
    this.loadMatch = this.loadMatch.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  formRef = React.createRef();

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps, prevState) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.matchToEdit !== nextProps.matchToEdit) {
      // Reset State
      this.loadData();
    } else if(this.props.loggedInUser.id !== nextProps.loggedInUser.id){
      this.loadData();
    }
  }

  loadData(){
    if (this._isMounted) {
      if(!this.isNewAnsicht()){
        this.loadMatch();
      }else{
        //die Default Values fuer die Edit View basieren auf den Daten aus load Match und werden daher da drin geladen
        this.setDefaultFormValuesForNewView();
      }
      //Die Spieler sind fuer die Dropdown Felder. Die braucht man ja also auch in der New Ansicht
      this.loadAllSpieler();
    }
  }

  loadMatch() {
    if(this.props.isDoppel){
      this.loadDoppel();
    }else{
      this.loadEinzel();
    }
  }

  loadEinzel() {
    if(this.props.matchToEdit){
      const matchId = this.props.matchToEdit.id;
      matchEinzelService.getMatchById(matchId)
          .then(response => {
            const mapped = new MatchEinzel(response);
            this.setState({
              matchToEdit: mapped
            });

            //setting programmatically form field values in antd
            //initalValues werden nur beim ERST-Maligen Rendern beruecksichtigt.
            //danach nicht mehr.
            //fuer die Edit Ansicht, die ja hier mehrmals verwendet wird und nicht neu gerendert wird,
            //muessen also hiermit die Felder gefuellt werden:
            this.setDefaultFormValuesForEditView(mapped);

          })
          .catch(error => {
            if (this._isMounted) {
              setErrorState(error, this);
            }
          });
    }
  }

  loadDoppel() {
    if(this.props.matchToEdit){
      const matchId = this.props.matchToEdit.id;
      matchDoppelService.getMatchById(matchId)
          .then(response => {

            const mapped = new MatchDoppel(response);
            this.setState({
              matchToEdit: mapped
            });

            //setting programmatically form field values in antd
            //initalValues werden nur beim ERST-Maligen Rendern beruecksichtigt.
            //danach nicht mehr.
            //fuer die Edit Ansicht, die ja hier mehrmals verwendet wird und nicht neu gerendert wird,
            //muessen also hiermit die Felder gefuellt werden:
            this.setDefaultFormValuesForEditView(mapped);

          })
          .catch(error => {
            if (this._isMounted) {
              setErrorState(error, this);
            }
          });
    }
  }

  setDefaultFormValuesForEditView(match){

    if(match instanceof MatchEinzel){
      this.formRef.current.setFieldsValue({
        datum: moment(match.datum).format('DD.MM.YYYY'),
        nutzerHeim: match.getHeimNutzer().id,
        nutzerGast: match.getGastNutzer().id,
        ergebnis: match.ergebnis
      });
    }

    //Doppel:
    if(match instanceof MatchDoppel){
      this.formRef.current.setFieldsValue({
        datum: moment(match.datum).format('DD.MM.YYYY'),
        nutzerHeim: match.getHeimNutzer1().id,
        nutzerGast: match.getGastNutzer1().id,
        nutzerHeim2: match.getHeimNutzer2().id,
        nutzerGast2: match.getGastNutzer2().id,
        ergebnis: match.ergebnis
      });
    }

    if (this._isMounted) {
      if(match instanceof MatchEinzel){
        this.setState({
          matchToEdit: match,
          datum: {
            value: match.datum
          },
          nutzerHeim: {
            value: match.getHeimNutzer().id
          },
          nutzerGast: {
            value: match.getGastNutzer().id
          },
          ergebnis: {
            value: match.ergebnis
          }
        });
      }


      //Doppel
      if(match instanceof MatchDoppel){
        this.setState({
          matchToEdit: match,
          nutzerHeim: {
            value: match.getHeimNutzer1().id
          },
          nutzerHeim2: {
            value: match.getHeimNutzer2().id
          },
          nutzerGast: {
            value: match.getGastNutzer1().id
          },
          nutzerGast2: {
            value: match.getGastNutzer2().id
          }
        });
      }
    }
  }

  setDefaultFormValuesForNewView(){
    this.formRef.current.setFieldsValue({
      datum: moment().format('DD.MM.YYYY'),
      nutzerHeim: this.props.loggedInUser.id,
      nutzerGast: '',
      ergebnis: ''
    });

    if (this._isMounted) {
      this.setState({
        matchToEdit: {},
        datum: {
          value: moment().format('DD.MM.YYYY')
        },
        nutzerHeim: {
          value: this.props.loggedInUser.id
        },
        nutzerHeim2: {
          value: ''
        },
        nutzerGast: {
          value: ''
        },
        nutzerGast2: {
          value: ''
        },
        ergebnis: {
          value: ''
        }
      });
    }
  }

  //liefert den Verein, je nach dem, um was fuer eine Ansicht es sich gerade handelt
  //(die Bearbeiten Ansicht, eines bereits existenten Punktspiels oder die Neu Ansicht, zum neu Erstellen eines Punktspiels)
  getVerein(){
    //dann handelt es sich um den Edit-Fall
    if(this.props.matchToEdit && this.props.matchToEdit.id > 0){
      return this.props.matchToEdit.getGast().getVerein();
    }else{
      //neu erstellen Fall
      return this.props.verein;
    }
  }

  isNewAnsicht(){
    //dann handelt es sich um den Edit-Fall
    if(this.props.matchToEdit && this.props.matchToEdit.id > 0){
      return false;
    }else{
      //neu erstellen Fall
      return true;
    }
  }

  validateForm(){
    return this.validateErgebnis(this.state.ergebnis.value) && this.validateDatum(this.state.datum.value);
  }

  onFormSubmit(){
    this.onSave(true);
  }
  
  onSave(validateDuplicates) {
    this.setState({
      duplicatesFound: [],
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true,
      disableSubmitButton2: true
    });
    this.props.onDuplicatesFound([]);

    document.body.classList.add('busy-cursor');

    var dateFormattedToSave = bereiteDatumsWertFuerRequestVor(this.state.datum.value);

    const isValid = this.validateForm();
    if(!isValid){
      this.setState({
        disableSubmitButton: false,
        disableSubmitButton2: false,
      });
      document.body.classList.remove('busy-cursor');
      return;
    }

    if(this.props.isDoppel){
      if(validateDuplicates){
        this.validateAndSaveDoppel(dateFormattedToSave);
      }else{
        this.saveDoppel(dateFormattedToSave);
      }
    }else{
      if(validateDuplicates){
        this.validateAndSaveEinzel(dateFormattedToSave);
      }else{
        this.saveEinzel(dateFormattedToSave);
      }
    }
  }

  //schaut vor dem Speichern, ob genau das Match bereits gespeichert ist.
  validateAndSaveEinzel(dateFormattedToSave){
    const saveRequest = {
      id: this.props.matchToEdit.id,
      datum: dateFormattedToSave,
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerGastId: this.state.nutzerGast.value,
      ergebnis: this.state.ergebnis.value
    };

    matchEinzelService.findAlreadyExistent(saveRequest)
        .then(response => {
          this.setState({
            duplicatesFound: matchMapper.mapEinzelList(response)
          });
          //damit sie in der Liste rot markiert werden konnen
          this.props.onDuplicatesFound(matchMapper.mapEinzelList(response));
          
          if(response == undefined || response.length <= 0){
            this.saveEinzel(dateFormattedToSave);
          }

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false,
            disableSubmitButton2: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false,
        disableSubmitButton2: false
      });
    });
  }

  saveEinzel(dateFormattedToSave){
    const saveRequest = {
      id: this.props.matchToEdit.id,
      datum: dateFormattedToSave,
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerGastId: this.state.nutzerGast.value,
      ergebnis: this.state.ergebnis.value
    };

    matchEinzelService.saveMatch(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Das Match wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.props.onDataChanged();
          this.resetFields();

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  //schaut vor dem Speichern, ob genau das Match bereits gespeichert ist.
  validateAndSaveDoppel(dateFormattedToSave){
    const saveDoppelRequest = {
      id: this.props.matchToEdit.id,
      datum: dateFormattedToSave,
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerHeim2Id: this.state.nutzerHeim2.value,
      nutzerGastId: this.state.nutzerGast.value,
      nutzerGast2Id: this.state.nutzerGast2.value,
      ergebnis: this.state.ergebnis.value
    };

    matchDoppelService.findAlreadyExistent(saveDoppelRequest)
        .then(response => {
          this.setState({
            duplicatesFound: matchMapper.mapDoppelList(response)
          });
          //damit sie in der Liste rot markiert werden konnen
          this.props.onDuplicatesFound(matchMapper.mapDoppelList(response));

          if(response == undefined || response.length <= 0){
            this.saveDoppel(dateFormattedToSave);
          }

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false,
            disableSubmitButton2: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false,
        disableSubmitButton2: false
      });
    });
  }
  
  saveDoppel(dateFormattedToSave){

    const saveDoppelRequest = {
      id: this.props.matchToEdit.id,
      datum: dateFormattedToSave,
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerHeim2Id: this.state.nutzerHeim2.value,
      nutzerGastId: this.state.nutzerGast.value,
      nutzerGast2Id: this.state.nutzerGast2.value,
      ergebnis: this.state.ergebnis.value
    };
    
    matchDoppelService.saveMatch(saveDoppelRequest)
        .then(response => {
          this.setState({
            successMsg: "Das Match wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.props.onDataChanged();
          this.resetFields();

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  resetFields(){
    this.formRef.current.resetFields();
  }

  loadAllSpieler(){
    const verein = this.getVerein();

    if(verein && verein.id){
      //liefert eine Liste von Spielern des Vereins zurueck, wobei es hier eher auf die Nutzer ankommt
      //Spieler, die demselben Nutzer angehoeren werden auf nur einen Spieler reduziert.
      //Das Match-Ergebnis wird naemlich am Nutzer gespeichert.
      spielerService.getDistinctSpielerForNutzerByVerein(verein.id)
          .then(response => {

            if (this._isMounted) {
              this.setState({
                vereinsSpielerDistinctNutzer: response
              });
            }
          })
          .catch(error => {
            if (this._isMounted) {
              setErrorState(error, this);
            }
          });
    }
  }


  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }

  handleSelectInputChange(value, name) {
    this.setState({
      [name] : {
        value: value
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }


  validateErgebnis = (ergebnis) => {

    let isValid = false;

    let ergebnisOb = this.state.ergebnis;

    if(!ergebnis) {
      ergebnisOb.validateStatus = 'error';
      ergebnisOb.errorMsg = 'Bitte gib ein Ergebnis an.';
    } else if (!ergebnis.match(MATCH_REGEX)) {
      ergebnisOb.validateStatus = 'error';
      ergebnisOb.errorMsg = 'Bitte gib ein valides Ergebnis an.';
    } else {
      ergebnisOb.validateStatus = 'success';
      ergebnisOb.errorMsg = '';
      isValid = true;
    }

    this.setState({
      ergebnis : ergebnisOb
    });

    return isValid;
  }


  validateDatum = (datum) => {

    let isValid = isDatumValid(datum);

    let datumOb = this.state.datum;

    if(!datum) {
      datumOb.validateStatus = 'error';
      datumOb.errorMsg = 'Bitte gib ein Datum an.';
    } else if (!isValid) {
      datumOb.validateStatus = 'error';
      datumOb.errorMsg = 'Bitte gib ein valides Datum in dem Format dd.MM.yyyy an';
    } else {
      datumOb.validateStatus = 'success';
      datumOb.errorMsg = '';
      isValid = true;
    }

    this.setState({
      datum : datumOb
    });

    return isValid;
  }
  
  render()
  {
    const { matchToEdit, vereinsSpielerDistinctNutzer, duplicatesFound } = this.state;

    const isNewAnsicht = this.isNewAnsicht();
    //initialValues is like defaultValue of input, it will only works on first render. Please use setFieldValue or setFieldsValue to set new value.
    // heißt: fuer die Edit-Ansicht, bringt mir das nix. Da reichen die initialVaules nicht. Da muss ich wirklich die onChangeMethode aufrufen
    var initialValues = {};
    if(this.isNewAnsicht()){
      initialValues={
        "datum": moment().format('DD.MM.YYYY'),
        "nutzerHeim": this.props.loggedInUser.id,
      };
    }else{
      if(matchToEdit && matchToEdit.id > 0 && (matchToEdit instanceof MatchEinzel)){
        initialValues={
          "datum": moment(matchToEdit.datum).format('DD.MM.YYYY'),
          "nutzerHeim": matchToEdit.getHeimNutzer().id,
          "nutzerGast": matchToEdit.getGastNutzer().id,
          "ergebnis": matchToEdit.ergebnis
        };

        if(this.props.isDoppel){
          initialValues={
            "datum": moment(matchToEdit.datum).format('DD.MM.YYYY'),
            "nutzerHeim": matchToEdit.getHeimNutzer1().id,
            "nutzerHeim2": matchToEdit.getHeimNutzer2().id,
            "nutzerGast": matchToEdit.getGastNutzer1().id,
            "nutzerGast2": matchToEdit.getGastNutzer2().id,
            "ergebnis": matchToEdit.ergebnis
          };
        }
      }
    }

    let spielerItems = vereinsSpielerDistinctNutzer.map((spieler) =>
        <Select.Option value={spieler.nutzer.id} key={spieler.id}>{spieler.nickname}</Select.Option>
    );

    return (
        <React.Fragment>
          <div className="match-edit-container">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }

            {!!duplicatesFound && !!duplicatesFound.length > 0 &&
            <Form layout="inline">
              <div className="message warning-message">
                <div className="message-container">
                  <div className="message-icon fa fa-fw fa-2x fa-exclamation-triangle">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </div>

                  <div className="message-content-container">
                    <div className="message-body">
                      Es wurden bereits Matches mit diesen Daten erfasst. Diese wurden rot markiert.
                      <br/>
                      Versuche es mit Neuladen der Seite, falls die Duplikate nicht angezeigt werden.
                      <br/>
                      <br/>
                      Möchtest du das Match tortzdem speichern?
                      <Button type="submit"
                              className="btn btn-secondary"
                              style={{marginLeft: '1em', verticalAlign: 'middle'}}
                              size="large"
                              onClick={() => this.onSave(false)}
                              disabled={this.state.disableSubmitButton2}>Trotzdem Speichern</Button>

                    </div>
                  </div>
                </div>
              </div>

            </Form>
            }

            {(isNewAnsicht || (!!matchToEdit && !!matchToEdit.id)) ?
                <div>

                  <Form
                      onFinish={this.onFormSubmit}
                      ref={this.formRef}
                      className='match-edit-inline-form'
                      layout="inline" className="punktspiel-edit-form"
                      hideRequiredMark={true}
                      initialValues={initialValues}>

                    {/*Ich hab das hier mit Row und Col von antd gemacht (>Grid), damit ich den Speichern Button vertical bottom align machen kann.*/}
                    {/*Bei bestimmten Handy-Aufloesungen (z.B. auch meiner), wenn er noch neben dem Ergebnis angezeigt wird und nicht in einer neuen Zeile, dann schwebte er immer weit oben. Das sah sehr seltsam aus*/}
                    <Row align="bottom">
                      <Col>
                        <FormItem
                            label="Datum"
                            style={{fontWeight: 'bold'}}
                            name="datum"
                            rules={[{
                              required: true,
                              message: 'Bitte gib einen Spieltermin an' }]}
                            validateStatus={this.state.datum.validateStatus}
                            help={this.state.datum.errorMsg}>
                          <Input
                              size="large"
                              name="datum"
                              style={{width: '9em'}}
                              className="small-responsive-field"
                              autoComplete="on"
                              placeholder="01.01.2000"
                              value={this.state.datum.value}
                              onChange={(event) => this.handleInputChange(event)}
                          />
                        </FormItem>
                      </Col>

                      <Col>
                        <Row align="bottom">
                          <Col>
                            <FormItem
                                label="Begegnung"
                                style={{fontWeight: 'bold'}}
                                name="nutzerHeim"
                                rules={[{
                                  required: true,
                                  message: 'Bitte wähle einen Spieler aus'
                                }]}
                                validateStatus={this.state.nutzerHeim.validateStatus}
                                help={this.state.nutzerHeim.errorMsg}>

                              <Select
                                  // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                                  style={{width: '11em', fontWeight: 'normal'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerHeim')}>
                                {spielerItems}
                              </Select>
                            </FormItem>

                            {/*Der Doppel-Partner*/}
                            <FormItem
                                name="nutzerHeim2"
                                className="doppel-heim2"
                                style={{display: this.props.isDoppel ? "" : "none" }}
                                rules={[{
                                  required: this.props.isDoppel,
                                  message: 'Bitte wähle einen Spieler aus'
                                }]}
                                validateStatus={this.state.nutzerHeim2.validateStatus}
                                help={this.state.nutzerHeim2.errorMsg}>

                              <Select
                                  // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                                  style={{width: '11em', fontWeight: 'normal'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerHeim2')}>
                                {spielerItems}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col className="padding-top-responsive-match-edit">
                            <FormItem
                                name="nutzerGast"
                                rules={[{
                                  required: true,
                                  message: 'Bitte wähle einen Spieler aus'
                                }]}
                                validateStatus={this.state.nutzerGast.validateStatus}
                                help={this.state.nutzerGast.errorMsg}>

                              <Select
                                  style={{width: '11em'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerGast')}>
                                {spielerItems}
                              </Select>
                            </FormItem>

                            {/*Der Doppel-Partner*/}
                            <FormItem
                                name="nutzerGast2"
                                style={{ display: this.props.isDoppel ? "" : "none" }}
                                rules={[{
                                  required: this.props.isDoppel,
                                  message: 'Bitte wähle einen Spieler aus'
                                }]}
                                validateStatus={this.state.nutzerGast2.validateStatus}
                                help={this.state.nutzerGast2.errorMsg}>

                              <Select
                                  style={{width: '11em'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerGast2')}>
                                {spielerItems}
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>

                      </Col>



                      <Col>
                        <FormItem
                            label="Ergebnis"
                            style={{fontWeight: 'bold'}}
                            name="ergebnis"
                            className='error-no-margin-bottom-input'
                            rules={[{
                              required: true,
                              message: 'Bitte gib ein Ergebnis an' }]}
                            validateStatus={this.state.ergebnis.validateStatus}
                            help={this.state.ergebnis.errorMsg}>
                          <Input
                              style={{width: '7em'}}
                              className="small-responsive-field"
                              size="large"
                              name="ergebnis"
                              autoComplete="on"
                              placeholder="6:3 6:3"
                              value={this.state.ergebnis.value}
                              onChange={(event) => this.handleInputChange(event)}
                          />
                        </FormItem>
                      </Col>

                      <Col>
                        <FormItem
                            className="bottomButton"
                            style={{verticalAlign: 'middle'}}
                        >
                          <Button type="submit"
                                  style={{verticalAlign: 'middle'}}
                                  size="large"
                                  className="myLargeBtn"
                                  disabled={this.state.disableSubmitButton}>Speichern</Button>
                        </FormItem>

                      </Col>
                    </Row>
                  </Form>



                </div>
                :
                null
            }

          </div>
        </React.Fragment>
    );
  }
}

export default MatchEditInline;


