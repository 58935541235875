import React from 'react'
import {Button} from "react-bootstrap";

import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Form, Input} from "antd";

import {getCurrentUser} from "../../_services";
import BackButton from "../../common/BackButton";
import {vereinsService} from "../../_services/vereinsService";
import {setErrorState} from "../../util/ErrorUtil";

const FormItem = Form.Item;

class VereinNew extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      verein: {},
      loggedInUser: null,
      successMsg: '',
      errorMsg: '',
      name: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      }
    };
    this.loadData = this.loadData.bind(this);
    this.loadLoggedInUser = this.loadLoggedInUser.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadLoggedInUser();
  }

  loadLoggedInUser(){
    getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }

  onSave() {

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');

    var id = 0;
    if(this.state.verein){
      id = this.state.verein.id;
    }

    const saveRequest = {
      id: id,
      name: this.state.name.value
    };

    vereinsService.save(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Der Verein wurde erfolgreich angelegt!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false,
            mannschaft: response
          });

          this.redirectOnSaveSuccess(response.id);

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }
  
  redirectOnSaveSuccess(vereinsId){
    window.location.href = '/verwaltung/verein/edit/' + vereinsId;
  }
  
  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    
    return (
        <div id="verein_edit">

          <h2>Neuen Verein anlegen</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          <div>
            <div className="form-container">
              <Form {...layout} className="mannschaft-edit-form"
                    initialValues={{
                      "name": this.state.verein.name
                    }}>

                <FormItem
                    labelAlign="left"
                    label="Name"
                    name="name"
                    rules={[{
                      required: true,
                      message: 'Bitte gib einen Vereinsnamen an'
                    }]}
                    hasFeedback
                    validateStatus={this.state.name.validateStatus}
                    help={this.state.name.errorMsg}>
                  <Input
                      size="large"
                      name="name"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.name.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>

              </Form>
            </div>

            <div id="btn-container" className={"form-container"}>
              <Button type="submit"
                      style={{float: 'right'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onSave()}
                      disabled={this.state.disableSubmitButton}>Speichern</Button>

              <BackButton/>
            </div>
          </div>
          
          
        </div>
    );
  }

}

export default VereinNew;
