import {API_BASE_URL} from "../../../constants";
import {request} from "../../../util/APIUtils";


export const trainingsplanungDoppelService = {
    getDoppelById,
    saveDoppel,
    deleteDoppelById,
    getStatistikForMannschaft,
    getJederMitJedemStatistikForMannschaft,
    getJederMitJedemStatistikForTraining
};


export function getDoppelById(doppelId)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-doppel/"  + doppelId,
        method: 'GET'
    });
}

export function saveDoppel(saveRequest) {
    return request({
        url: API_BASE_URL + "/trainingsplanung-doppel/save",
        method: 'POST',
        body: JSON.stringify(saveRequest)
    });
}

export function deleteDoppelById(id)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-doppel/delete/"  + id,
        method: 'DELETE'
    });
}

export function getStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/trainingsplanung-doppel/getStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET'
    });
}

export function getJederMitJedemStatistikForMannschaft(mannschaftsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    return request({
        url: API_BASE_URL + "/trainingsplanung-doppel/getJederMitJedemStatistikForMannschaft?mannschaftsId="  + mannschaftsId + filterParams,
        method: 'GET'
    });
}

export function getJederMitJedemStatistikForTraining(trainingsId, filter)
{
    let filterParams = '';
    if(filter && filter.satzFilter){
        filterParams = filterParams + '&filterErgebnis=' + filter.satzFilter;
    }

    if(filter && filter.datumFilterAb){
        filterParams = filterParams + '&filterDatumAb=' + filter.datumFilterAb;
    }

    if(filter && filter.datumFilterBis){
        filterParams = filterParams + '&filterDatumBis=' + filter.datumFilterBis;
    }

    if(filter && filter.nutzerFilter && filter.nutzerFilter.length > 0){
        filterParams = filterParams + '&filterNutzer=' + filter.nutzerFilter;
    }

    filterParams = filterParams + '&filterDoppelpartner=' + false;

    return request({
        url: API_BASE_URL + "/trainingsplanung-doppel/getJederMitJedemStatistikForTraining?trainingsId="  + trainingsId + filterParams,
        method: 'GET'
    });
}