export default [
    {
        orderPosition: "1",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 3,
            "bewertung": 3
        },
        "doppel2": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": 3
        },
        "doppel3": {
            "spieler1": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 11,
            "bewertung": 2
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": 8
    },
    {
        orderPosition: "2",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 3,
            "bewertung": 3
        },
        "doppel2": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 9,
            "bewertung": 2
        },
        "doppel3": {
            "spieler1": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 9,
            "bewertung": 1
        },
        "alternativeAufstellungen": [
            {
                "doppel1": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 3,
                    "bewertung": 3
                },
                "doppel2": {
                    "spieler1": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 9,
                    "bewertung": 1
                },
                "doppel3": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 9,
                    "bewertung": 2
                },
                "alternativeAufstellungen": null,
                "gesamtBewertung": 6
            }
        ],
        "gesamtBewertung": 6
    },
    {
        orderPosition: "3",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 6,
            "bewertung": 1
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 6,
            "bewertung": 0
        },
        "doppel3": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 9,
            "bewertung": 2
        },
        "alternativeAufstellungen": [
            {
                "doppel1": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 6,
                    "bewertung": 0
                },
                "doppel2": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 6,
                    "bewertung": 1
                },
                "doppel3": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 9,
                    "bewertung": 2
                },
                "alternativeAufstellungen": null,
                "gesamtBewertung": 3
            }
        ],
        "gesamtBewertung": 3
    },
    {
        orderPosition: "4",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 5,
            "bewertung": -1
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 5,
            "bewertung": 1
        },
        "doppel3": {
            "spieler1": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 11,
            "bewertung": 2
        },
        "alternativeAufstellungen": [
            {
                "doppel1": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 5,
                    "bewertung": 1
                },
                "doppel2": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 5,
                    "bewertung": -1
                },
                "doppel3": {
                    "spieler1": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 11,
                    "bewertung": 2
                },
                "alternativeAufstellungen": null,
                "gesamtBewertung": 2
            }
        ],
        "gesamtBewertung": 2
    },
    {
        orderPosition: "5",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 6,
            "bewertung": 1
        },
        "doppel2": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": 3
        },
        "doppel3": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 8,
            "bewertung": -2
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": 2
    },
    {
        orderPosition: "6",
        "doppel1": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 5,
            "bewertung": 1
        },
        "doppel2": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": -1
        },
        "doppel3": {
            "spieler1": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 9,
            "bewertung": 1
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": 1
    },
    {
        orderPosition: "7",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 4,
            "bewertung": -2
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 6,
            "bewertung": 0
        },
        "doppel3": {
            "spieler1": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 11,
            "bewertung": 2
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": 0
    },
    {
        orderPosition: "8",
        "doppel1": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 5,
            "bewertung": 1
        },
        "doppel2": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 6,
            "bewertung": 1
        },
        "doppel3": {
            "spieler1": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 10,
            "bewertung": -3
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": -1
    },
    {
        orderPosition: "9",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": -1
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": -3
        },
        "doppel3": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": 3
        },
        "alternativeAufstellungen": [
            {
                "doppel1": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -1
                },
                "doppel2": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": 3
                },
                "doppel3": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -3
                },
                "alternativeAufstellungen": [],
                "gesamtBewertung": -1
            },
            {
                "doppel1": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -3
                },
                "doppel2": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -1
                },
                "doppel3": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": 3
                },
                "alternativeAufstellungen": [],
                "gesamtBewertung": -1
            },
            {
                "doppel1": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -3
                },
                "doppel2": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": 3
                },
                "doppel3": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -1
                },
                "alternativeAufstellungen": [],
                "gesamtBewertung": -1
            },
            {
                "doppel1": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": 3
                },
                "doppel2": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -1
                },
                "doppel3": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -3
                },
                "alternativeAufstellungen": [],
                "gesamtBewertung": -1
            },
            {
                "doppel1": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": 3
                },
                "doppel2": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -3
                },
                "doppel3": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 7,
                    "bewertung": -1
                },
                "alternativeAufstellungen": [],
                "gesamtBewertung": -1
            }
        ],
        "gesamtBewertung": -1
    },
    {
        orderPosition: "10",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 5,
            "bewertung": -1
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": -3
        },
        "doppel3": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 9,
            "bewertung": 2
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": -2
    },
    {
        orderPosition: "11",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 3,
            "bewertung": 3
        },
        "doppel2": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 8,
            "bewertung": -3
        },
        "doppel3": {
            "spieler1": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 10,
            "bewertung": -3
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": -3
    },
    {
        orderPosition: "12",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 4,
            "bewertung": -2
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 8,
            "bewertung": -2
        },
        "doppel3": {
            "spieler1": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 9,
            "bewertung": 1
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": -3
    },
    {
        orderPosition: "13",
        "doppel1": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 6,
            "bewertung": 0
        },
        "doppel2": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": -1
        },
        "doppel3": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 8,
            "bewertung": -3
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": -4
    },
    {
        orderPosition: "14",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 5,
            "bewertung": -1
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 8,
            "bewertung": -2
        },
        "doppel3": {
            "spieler1": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 8,
            "bewertung": -3
        },
        "alternativeAufstellungen": [
            {
                "doppel1": {
                    "spieler1": {
                        "id": 21,
                        "nutzer": {
                            "id": 15,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Vanessa",
                            "nachname": "Wolski",
                            "email": "vanessa67@wtnet.de",
                            "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 1,
                        "nickname": "Vanessa",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 20,
                        "nutzer": {
                            "id": 14,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Svenja",
                            "nachname": "Sturm",
                            "email": "svenja.sturm@arcor.de",
                            "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 4,
                        "nickname": "Svenja",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 5,
                    "bewertung": -1
                },
                "doppel2": {
                    "spieler1": {
                        "id": 4,
                        "nutzer": {
                            "id": 1,
                            "admin": true,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Annika",
                            "nachname": "Korz",
                            "email": "annikakorz@hotmail.com",
                            "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 3,
                        "nickname": "Anni K.",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 24,
                        "nutzer": {
                            "id": 17,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Antje",
                            "nachname": "Hoffmann",
                            "email": "antjehoffmann@kpmg.com",
                            "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 5,
                        "nickname": "Antje",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 8,
                    "bewertung": -3
                },
                "doppel3": {
                    "spieler1": {
                        "id": 15,
                        "nutzer": {
                            "id": 12,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Janine",
                            "nachname": "Kühl",
                            "email": "kuehl@ffgruppe.de",
                            "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 2,
                        "nickname": "Janine",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "spieler2": {
                        "id": 30,
                        "nutzer": {
                            "id": 19,
                            "admin": false,
                            "aktiv": true,
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "vorname": "Nina",
                            "nachname": "Lemke",
                            "email": "nina.lemke@gmx.com",
                            "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                            "passwordwiederholung": null,
                            "telefon": null,
                            "deletePhonenumber": null,
                            "freigeschaltet": true,
                            "pwdtoken": null,
                            "pwdresetdate": 0,
                            "loginErrorCount": 0,
                            "registrationtoken": null,
                            "registrationresetdate": null
                        },
                        "mannschaft": {
                            "id": 1,
                            "name": "Damen 30 I",
                            "verein": {
                                "id": 1,
                                "name": "TCGW Neustadt"
                            },
                            "spielerIds": [
                                4,
                                24,
                                16,
                                23,
                                15,
                                30,
                                20,
                                21
                            ]
                        },
                        "position": 6,
                        "nickname": "Nina",
                        "von": "10:00",
                        "immerAbsagen": false,
                        "ersatzTraining": false,
                        "ersatzPunktspiel": false,
                        "receiveResponseMessages": false,
                        "receiveResultMessages": false,
                        "receiveReminderMessages": false
                    },
                    "positionsSumme": 8,
                    "bewertung": -2
                },
                "alternativeAufstellungen": null,
                "gesamtBewertung": -6
            }
        ],
        "gesamtBewertung": -6
    },
    {
        orderPosition: "15",
        "doppel1": {
            "spieler1": {
                "id": 21,
                "nutzer": {
                    "id": 15,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Vanessa",
                    "nachname": "Wolski",
                    "email": "vanessa67@wtnet.de",
                    "password": "$2a$10$a84rxjVYDuuhXR/WADpe2e/KHMUQw/3CZxgzQ/i/OOdSUrrWU4z3C",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 1,
                "nickname": "Vanessa",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 4,
                "nutzer": {
                    "id": 1,
                    "admin": true,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Annika",
                    "nachname": "Korz",
                    "email": "annikakorz@hotmail.com",
                    "password": "$2a$10$15q.9zPXNZA8VTtT.VOTxeudXqzat8f1XlMpx/5VBaFxeOJ81DxZK",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 3,
                "nickname": "Anni K.",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 4,
            "bewertung": -2
        },
        "doppel2": {
            "spieler1": {
                "id": 15,
                "nutzer": {
                    "id": 12,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Janine",
                    "nachname": "Kühl",
                    "email": "kuehl@ffgruppe.de",
                    "password": "$2a$10$Fp/lvXjdIYq7ldsF/FSEGeLqGcoAq5JsiFLTjd1EAKRolLNo.Ng5O",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 2,
                "nickname": "Janine",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 24,
                "nutzer": {
                    "id": 17,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Antje",
                    "nachname": "Hoffmann",
                    "email": "antjehoffmann@kpmg.com",
                    "password": "$2a$10$qSxLsCdBaY84AbsOaaHysOynaoWc/7YprNNbvo5YeFgDoBFGvm7He",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 5,
                "nickname": "Antje",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 7,
            "bewertung": -3
        },
        "doppel3": {
            "spieler1": {
                "id": 20,
                "nutzer": {
                    "id": 14,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Svenja",
                    "nachname": "Sturm",
                    "email": "svenja.sturm@arcor.de",
                    "password": "$2a$10$UO78zjOeQSDAGqoAC/G3h.erj8khY0ElI4kw5UEclB5gusmWxHOX6",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 4,
                "nickname": "Svenja",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "spieler2": {
                "id": 30,
                "nutzer": {
                    "id": 19,
                    "admin": false,
                    "aktiv": true,
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "vorname": "Nina",
                    "nachname": "Lemke",
                    "email": "nina.lemke@gmx.com",
                    "password": "$2a$10$QeRxmg6i/hwnwknnMcF1w.V9dXgDHlRXLRkzLrEWxKj23eI.fdiLq",
                    "passwordwiederholung": null,
                    "telefon": null,
                    "deletePhonenumber": null,
                    "freigeschaltet": true,
                    "pwdtoken": null,
                    "pwdresetdate": 0,
                    "loginErrorCount": 0,
                    "registrationtoken": null,
                    "registrationresetdate": null
                },
                "mannschaft": {
                    "id": 1,
                    "name": "Damen 30 I",
                    "verein": {
                        "id": 1,
                        "name": "TCGW Neustadt"
                    },
                    "spielerIds": [
                        4,
                        24,
                        16,
                        23,
                        15,
                        30,
                        20,
                        21
                    ]
                },
                "position": 6,
                "nickname": "Nina",
                "von": "10:00",
                "immerAbsagen": false,
                "ersatzTraining": false,
                "ersatzPunktspiel": false,
                "receiveResponseMessages": false,
                "receiveResultMessages": false,
                "receiveReminderMessages": false
            },
            "positionsSumme": 10,
            "bewertung": -3
        },
        "alternativeAufstellungen": [],
        "gesamtBewertung": -8
    }
];