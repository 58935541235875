import { request } from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const mannschatsService = {
    getMannschaftById,
    save,
    saveEinstellungen,
    getMannschaftenForNavigationViewRightsOrHigher,
    getMannschaftenForNavigationAdminRightsOnly,
    getMannschaftenByNutzer,
    isNutzerMannschaftsAdmin,
    getAllMannschaftenForVerein,
    getAllMannschaftenOfVereineWhereNutzerIs,
    deleteMannschaftById
};

export function getMannschaftById(mannschaftId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/" + mannschaftId,
        method: 'GET'
    });
}

export function getMannschaftenByNutzer(nutzerdId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/getMannschaftenByNutzer?nutzerId=" + nutzerdId,
        method: 'GET'
    });
}

export function save(nutzerdId, mannschaftRequest) {
    return request({
        url: API_BASE_URL + "/mannschaften/save?nutzerId=" + nutzerdId,
        method: 'POST',
        body: JSON.stringify(mannschaftRequest)
    });
}

export function saveEinstellungen(mannschaftId, mannschaftRequest) {
    return request({
        url: API_BASE_URL + "/mannschaften/save-einstellungen/" + mannschaftId,
        method: 'POST',
        body: JSON.stringify(mannschaftRequest)
    });
}

// Liefert die Mannschaften zurueck, fuer die der Nutzer MINDESTENS die Rechte hat, sie zu sehen.
// Im Detail sind das:
//  - alle Mannschaften in denen der uebergebene Nutzer einen Spieler hat
//  - alle Mannschaften, fuer die der Nutzer VIEW-Rechte hat
//  - alle Mannschaften, fuer die der Nutzer ADMIN-Rechte hat
export function getMannschaftenForNavigationViewRightsOrHigher(nutzerId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/getMannschaftenForNavigationViewRightsOrHigher?id=" + nutzerId,
        method: 'GET'
    });
}

// Liefert nur die Mannschaften zurueck, fuer die dem Nutzer explizit ADMIN-Rechte vergeben wurden.
export function getMannschaftenForNavigationAdminRightsOnly(nutzerId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/getMannschaftenForNavigationAdminRightsOnly?id=" + nutzerId,
        method: 'GET'
    });
}

//Ob der uebergebene Nutzer ein Admin fuer die uebergebene Mannschaft ist.
export function isNutzerMannschaftsAdmin(mannschaftsId, nutzerId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/isNutzerMannschaftsAdmin?mannschaftsId=" + mannschaftsId + "&nutzerId=" + nutzerId,
        method: 'GET'
    });
}

export function getAllMannschaftenForVerein(vereinId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/getMannschaftenForVerein?vereinId=" + vereinId,
        method: 'GET'
    });
}

//Findet alle Mannschaften, fuer die Vereine, in denen der Nutzer Mitglied ist.
export function getAllMannschaftenOfVereineWhereNutzerIs(nutzerdId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/getMannschaftenForVereineFromNutzer?nutzerId=" + nutzerdId,
        method: 'GET'
    });
}

export function deleteMannschaftById(mannschaftId)
{
    return request({
        url: API_BASE_URL + "/mannschaften/deleteMannschaftById?mannschaftId=" + mannschaftId,
        method: 'DELETE'
    });
}