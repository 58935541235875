// DoppelaufstellungenListe

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faSort, faStar, faCrosshairs} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

class DoppelaufstellungDNDCell extends React.Component{
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            pinned: false,
            banned: false
        };
    }
    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getStyleClass(doppelPaarung){
        return {
            backgroundColor: `${doppelPaarung.getBackgroudnColor()}`
        };
    }

    getTauschbarIcon(doppelAufstellung, doppelPaarung){
        if(doppelAufstellung.alternativeAufstellungen && doppelAufstellung.alternativeAufstellungen.length > 0){

            if(doppelAufstellung.isDoppel1Switchable(doppelPaarung)){
                return <FontAwesomeIcon className='rotate-icon' icon={faSort} />;
            }
        }
        return '';
    }

    handlePin(e){
        e.preventDefault();

        let pinned = this.state.pinned;
        pinned = !pinned;
        this.props.onPinItem(this.props.doppelAufstellung.orderPosition, pinned, this.state.banned);
        this.setState({pinned: pinned});

        //wenn ein item, das gebaned ist, gepinned werden soll, muss es zuerst un-banned werden.
        //Das Un-bannen ist schon korrekt in der onPinItem-Methode passiert, aber der State muss hier natuerlich auch dann
        //korrekt gesetzt werden: das Item muss hier dann auch als un-banned markiert werden.
        if(pinned){
            if(this.state.banned){
                this.setState({banned: !this.state.banned});
            }
        }
    }

    handleBan(e){
        e.preventDefault();
        
        let banned = this.state.banned;
        banned = !banned;
        this.props.onBanItem(this.props.doppelAufstellung.orderPosition, banned, this.state.pinned);
        this.setState({banned: banned});

        //wenn ein item, das gepinned ist, gebanned werden soll, muss es zuerst un-pinned werden.
        // !this.state.banned bedeutet: wenn banned false ist, heißt das, dass das item gerade noch nicht gebanned war und
        //jetzt gebanned werden soll
        //Das Un-pinnen ist schon korrekt in der onBanItem-Methode passiert, aber der State muss hier natuerlich auch dann
        //korrekt gesetzt werden: das Item muss hier dann auch als un-pinned markiert werden.
        if(!this.state.banned){
            if(this.state.pinned){
                this.setState({pinned: !this.state.pinned});
            }
        }
    }

    getStyleClassForPinButton(){
        let styleClassBtn = 'doppelaufstellung-btn';
        let  styleClassPinnedBtn = styleClassBtn;
        if(this.state.pinned){
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-pinned';
        }else{
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-secondary';
        }
        return styleClassPinnedBtn;
    }

    getStyleClassForBanButton(){
        let styleClass = 'btn btn-secondary doppelaufstellung-btn';
        if(this.state.banned){
            styleClass = styleClass + ' banned_doppel';
        }
        return styleClass;
    }
    
    render() {
        const doppelAufstellung = this.props.doppelAufstellung;
        const { pinned, banned } = this.state;

        let styleClasses = doppelAufstellung.getStyleClassForDoppel() + ' doppelaufstellung-box';
        
        // console.log(doppelAufstellung.orderPosition + 'pinned: ' + pinned + 'banned' + banned);
        // console.log('banned: ' + banned);

        if(pinned){
            styleClasses = styleClasses + ' pinned_doppel';
        }

        if(banned){
            styleClasses = styleClasses + ' banned_doppel';
        }

        let styleClassBtn = 'doppelaufstellung-btn';
        let  styleClassPinnedBtn = styleClassBtn;
        if(this.state.pinned){
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-pinned';
        }else{
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-secondary';
        }

        let styleClassBannedBtn = styleClassBtn;
        if(this.state.banned){
            styleClassBannedBtn = styleClassBannedBtn + ' btn-banned';
        }else{
            styleClassBannedBtn = styleClassBannedBtn + ' btn-secondary';
        }
        
        return (
            <React.Fragment>
                <div key={doppelAufstellung.id + '_items'} className={styleClasses} >
                    <table className='doppelaustellung-table'>
                        <tbody>
                        <tr>
                            <td>
                                <Button
                                    className={styleClassPinnedBtn}
                                    onClick={e => this.handlePin(e)}>
                                    <FontAwesomeIcon className='doppel-header-icon' icon={faStar} />
                                </Button>

                                <Button
                                    className={styleClassPinnedBtn}
                                    onClick={e => this.handlePin(e)}>
                                    <FontAwesomeIcon className='doppel-header-icon' icon={faCrosshairs} />
                                </Button>
                                {doppelAufstellung.orderPosition}
                                <Button
                                    className={styleClassBannedBtn}
                                    style={{float: 'right'}}
                                    onClick={e => this.handleBan(e)}>
                                    <FontAwesomeIcon className='doppel-header-icon' icon={faBan} />
                                </Button>
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}}>
                                <div style={{fontWeight: 'bold', float: 'left'}} className='doppel-austellung-size'>
                                    <span className={doppelAufstellung.doppel1.getBackgroudnColor() + ' doppel-aufstellung'}>{doppelAufstellung.doppel1.positionsSumme} {doppelAufstellung.doppel1.getDisplayLabel()} {this.getTauschbarIcon(doppelAufstellung, doppelAufstellung.doppel1)}</span>

                                    <span className={doppelAufstellung.doppel2.getBackgroudnColor() + ' doppel-aufstellung'}>{doppelAufstellung.doppel2.positionsSumme} {doppelAufstellung.doppel2.getDisplayLabel()} {this.getTauschbarIcon(doppelAufstellung, doppelAufstellung.doppel2)}</span>

                                    <span className={doppelAufstellung.doppel3.getBackgroudnColor() + ' doppel-aufstellung'}>{doppelAufstellung.doppel3.positionsSumme} {doppelAufstellung.doppel3.getDisplayLabel()} {this.getTauschbarIcon(doppelAufstellung, doppelAufstellung.doppel3)}</span>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }
}

export default DoppelaufstellungDNDCell;