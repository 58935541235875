import moment from "moment";


//Die Datumswerte werden in der Oberflaeche in den Eingabefeldern als String dargestellt und auch eingegeben und 
//zwar in dem Format DD.MM.YYYY
//wenn man so einen Wert in einem Request mitschickt, muss das Datum allerdings im ISO Format formatiert sein,
//
//Wenn das Eingabefeld vorbefuellt wurde und nicht geandert wurde, dann steht auch schon der korrekte Wert drin,
//dann muss das Datum nicht formatiert werden,
export function bereiteDateTimeFuerRequestVor(dateToSave, dateTimepattern, comp) {

  //Wenn das Datum nicht bearbeitet wurde, dann ist es noch der inital gesetzte und formatierte Wert: 27.01.2023 18:00
  //Wurde es manipuliert, ist es ein String in der Art 2023-01-27T18:00:00.000+0000
  var dateFormattedToSave = dateToSave;
  
  if(!moment(dateToSave, moment.ISO_8601).isValid()){
    dateFormattedToSave = moment(dateFormattedToSave, dateTimepattern);
  } else {
    // format: 2023-01-27T18:00:00.000+0000
    dateFormattedToSave = moment(dateFormattedToSave).utc().local();
  }
  return dateFormattedToSave;
}

export function bereiteDatumsWertFuerRequestVor(dateToSave, comp) {
 
  return bereiteDateTimeFuerRequestVor(dateToSave, "DD.MM.YYYY HH:mm", comp); 
}

export function isDatumValid(dateToSave, comp) {

  var parsed = bereiteDateTimeFuerRequestVor(dateToSave, "DD.MM.YYYY HH:mm", comp);
  
  return parsed.isValid();
}




