import React from 'react'
import {Button} from "react-bootstrap";
import {setErrorState} from '../../util/ErrorUtil';
import {getById} from "../../_services/vereinsService";
import VereinsEinstellungenEdit from "./verein_einstellungen_edit";
import AbstimmungList from "./abstimmung/abstimmung_list";
import TerminList from "./termin/termin_list";
import MannschaftList from "./mannschaft/mannschaft_list";

class VereinOverview extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      verein: {},
      successMsg: '',
      errorMsg: ''
    };
    this.loadData = this.loadData.bind(this);
    this.loadVerein = this.loadVerein.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadVerein();
  }

    loadVerein() {
    const vereinId = this.props.match.params.vereinId;
    
    getById(vereinId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          verein: response
        });
      }
    })
    .catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
    });
  }
  

  
  render()
  {
    const { verein} = this.state;
    
    return (
        <div id="verein_edit">
          {!!verein &&
          <h2> {verein.name}</h2>
          }

          {!!verein && !!verein.id &&
            <VereinsEinstellungenEdit verein={verein} />
          }
          
          <br/>
          <br/>
          <br/>
          
          {!!verein && !!verein.id &&
            <AbstimmungList verein={verein} />
          }

          <Button variant="primary" onClick={() => this.props.history.push('/verwaltung/verein/abstimmung/new/' + verein.id)}>Neue Abstimmung anlegen</Button>


          <br/>
          <br/>
          <br/>

          {!!verein && !!verein.id &&
          <TerminList verein={verein} />
          }

          <Button variant="primary" onClick={() => this.props.history.push('/verwaltung/verein/termin/new/' + verein.id)}>Neuen Termin anlegen</Button>

          <br/>
          <br/>
          <br/>

          {!!verein && !!verein.id &&
          <MannschaftList verein={verein} />
          }

          <Button variant="primary" onClick={() => this.props.history.push('/verwaltung/verein/mannschaft/new/' + verein.id)}>Neue Mannschaft anlegen</Button>

        </div>
    );
  }

}

export default VereinOverview;
