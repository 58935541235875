import './matchEditAndListTabPaneView.css';
import React from 'react'
import {withRouter} from "react-router-dom";
import MatchStatistikMannschaft from "./match_statistik_mannschaft/matchStatistikMannschaft";
import MatchEditAndListTabPaneView from "./MatchEditAndListTabPaneView";

class MatchStatistikAndEditViewMannschaft extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      reloadMatchStatistik: false,
    };
    this.onRefreshMatchData = this.onRefreshMatchData.bind(this);
  }

  onRefreshMatchData(){
    //Damit sich der Wert auch wirklich aendert. Nach 2 Maligen aendern waere er ja sosnt schon auf true, oder?
    this.setState({
      reloadMatchStatistik: false
    });

    this.setState({
      reloadMatchStatistik: true
    });
  }
 
  render()
  {
    
    return (
        <div>
          <MatchStatistikMannschaft mannschaftsId={this.props.mannschaft.id}
                                    reload={this.state.reloadMatchStatistik}/>

          <br/>
          <br/>

          <MatchEditAndListTabPaneView verein={this.props.mannschaft.verein}
                                       onRefreshData={this.onRefreshMatchData}/>
        </div>
    );
  }
}

export default withRouter(MatchStatistikAndEditViewMannschaft);
