import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import moment from 'moment'
import {deleteTrainingstagById} from "../../_services/trainingsService";

class TrainingstageList extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      trainings: [],
      successMsg: '',
      errorMsg: '',
    };
    this.deleteTraining = this.deleteTraining.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.trainings !== nextProps.trainings) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.setState({trainings: this.props.trainings});
    }
  }

  deleteTraining(trainingId) {
    deleteTrainingstagById(trainingId)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Das Training wurde erfolgreich gelöscht'});
            this.props.componentChanged();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    const { trainings, mannschaft } = this.state;
    
    return (
        <div id="mannschaft_trainingstage_list_view">

          {!!trainings && !!trainings.length && !!mannschaft &&
            <table className="table table-hover">
              <tbody>
                {trainings.map((training) =>
                    <tr key={training.id}>
                      <td style={{textAlign: 'left'}}>
                        {moment(training.datum).format('DD.MM.YYYY')} &nbsp; &nbsp; {moment(training.datum).format('HH:mm')}
                      </td>
                      <td>
                        {/*loeschen Button*/}
                        <Button
                            className="btn btn-secondary delete-button"
                            onClick={()=>this.deleteTraining(training.id)}>
                          <FontAwesomeIcon icon={faTrashAlt}  />
                        </Button>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          }
        </div>
    );
  }

}


export default TrainingstageList;


