import React from 'react'

import {Button} from "react-bootstrap";

import InfiniteScroll from "react-infinite-scroll-component";

import {abstimmungService} from "../../../_services/abstimmung/abstimmungService";
import {setErrorState, setErrorStateWithMessage} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {getCurrentUser} from "../../../_services";
import * as abstimmungTeilnehmerService from "../../../_services/abstimmung/abstimmungTeilnehmerService";
import {getSpielerById} from "../../../_services/spielerService";

class AbstimmungTeilnehmerAdd extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);

    this.state = {
        abstimmung: {},
      nutzer: [],  //die Nutzer fuer die Anzeige, die zum Hinzufügen ausgewählt werden können
        hasMore: true,
        pageSize: 10,
        actualPage: 0,
        nutzerPicked: [],  //die ausgewählten Nutzer, die der Mannschaft hinzugefügt werden sollen
        loggedInUser: {}
    };

      this.loadNutzerToInvite = this.loadNutzerToInvite.bind(this);
      this.fetchMoreData = this.fetchMoreData.bind(this);
      this.addHinzuzufuegendeNutzer = this.addHinzuzufuegendeNutzer.bind(this);
      this.deleteHinzuzufuegendeNutzer = this.deleteHinzuzufuegendeNutzer.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
      this.loadAbstimmung();
      this.fetchMoreData();
      this.loadLoggedInUser();
  }

    loadAbstimmung(){
        const abstimmungId = this.props.match.params.abstimmungId;

        abstimmungService.getAbstimmungById(abstimmungId)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        abstimmung: response
                    });
                }
            })
            .catch(error => {
                setErrorState(error, this);
            });
    }
    
  loadNutzerToInvite(page, size) {
    const abstimmungId = this.props.match.params.abstimmungId;
      
      abstimmungService.getNutzerForVereinMinusMannschaftsspieler(abstimmungId, page, size)
        .then(response => {
          if (this._isMounted) {
              this.setState({
                  //gespielt: response.content,
                  nutzer:  this.state.nutzer.concat(response.content),
                  actualPage: response.number + 1,
                  hasMore: !response.last
              });
          }
        })
        .catch(error => {
            setErrorState(error, this);
        })
  }

    fetchMoreData() {
        //erste Seite = 0
        console.log("++++++ fetchMoreData actualPage:" + this.state.actualPage);
        this.loadNutzerToInvite(this.state.actualPage, this.state.pageSize);
    }
  
  loadLoggedInUser(){
    getCurrentUser()
    .then(response => {
      if (this._isMounted) {
        this.setState({loggedInUser: response});
      }
    })
    .catch(error => this.setState({hasError: error}));
  }

    handleInputChange(event) {

      const target = event.target;
      const nutzerId = target.name;  //die NutzerId
      const checked = target.checked;

      if(checked){
          this.addHinzuzufuegendeNutzer(nutzerId);
      }else{
          this.deleteHinzuzufuegendeNutzer(nutzerId);
      }
    }
    
    //fuegt den uebergebenen Nutzer der nutzerPicked-Liste hinzu
    addHinzuzufuegendeNutzer(nutzerId){
        // only add if the item doesn't exist in the list
        if(this.state.nutzerPicked.indexOf(nutzerId) < 0){
            this.setState((state) => ({
                nutzerPicked: state.nutzerPicked.concat([nutzerId])
            }))
        }
    }

    //loescht einen Nutzer von der nutzerPicked-Liste
    deleteHinzuzufuegendeNutzer(nutzerId){

        var array = this.state.nutzerPicked; // make a separate copy of the array
        var index = array.indexOf(nutzerId);

        if (index !== -1) {
            array.splice(index, 1);
            this.setState({nutzerPicked: array});
        }
    }

  onSend() {
      console.log(this.state.nutzerPicked);
      
      if(this.state.nutzerPicked && this.state.nutzerPicked.length > 0){
          console.log("es sind spieler hinzuzufuegen!")

          const addAndCreateRequest = {
              nutzerIdsToCreate: this.state.nutzerPicked,
              abstimmungId: this.state.abstimmung.id
          };

          abstimmungTeilnehmerService.createAndAddNutzerForAbstimmung(addAndCreateRequest)
              .then(response => {
                  this.setState({
                      successMsg: "Die Nutzer wurden der Abstimmung hinzugefügt."
                  });
                  this.deletePickedSpielerFromPicklist();
                  
                  //die InfiniteScroll Komponente updated sich einfach nicht.
                  //daher mach ich hart einen Seitenreload....
                  window.location.href = '/verwaltung/verein/abstimmung/' + this.state.abstimmung.id;

              }).catch(error => {
              setErrorStateWithMessage("Beim Hinzufügen des Teilnehmers ist ein Fehler aufgetreten.", this);
              console.log(error);
          });
      }
  }

    onBack() {
      
    }
  
  deletePickedSpielerFromPicklist(){
      for (var i = 0; i < this.state.nutzerPicked.length; i++) {
          console.log(this.state.nutzerPicked[i]);
          this.deleteHinzuzufuegendeNutzer(this.state.nutzerPicked[i]);
      }

      this.setState({
          nutzerPicked: []
      });
  }

  render()
  {
    const { abstimmung, nutzer } = this.state;
    
    return (
        <div className="container" id="invite">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }
            
            <h1>Teilnehmer zur 
              {!!abstimmung &&
              <React.Fragment> {abstimmung.name} </React.Fragment>
              }
              hinzufügen
            </h1>
            
          <div>
              {nutzer === undefined  || nutzer.length === 0 ?
                  //Wenn nutzer NULL ist
                  <i>Es gibt keine weiteren Nutzer, die der Abstimmung hinzugefügt werden könnten.</i>
                  :
                  <span>suche hier den gewünschten Nutzer aus den bereits existierenden Nutzers des Vereins heraus:</span>
              }


              {!!nutzer.length &&
              <div>
              <InfiniteScroll
                  dataLength={nutzer.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore}
                  loader={<h4>Loading...</h4>}
                  //total bescheurte Komponente. Wenn das Parent-Element nicht voll ist, wird auch nicht nachgeladen.
                  //also bei allen Screens, wo die bereits angezeigten Datensaetze locker in diese Hoehe reinpassen,
                  //klappt das mit dem Nachladen nicht.
                  height={250}
                  endMessage={
                      <p style={{ textAlign: 'center' }}>
                          <b>Das waren alle verfügbaren Nutzer :)</b>
                      </p>
                  }
              >
                  <table className="table table-striped">
                      <tbody>
                      {nutzer.map((n, index) =>
                          <tr key={n.id}>
                              <td>
                                  <input
                                      name={n.id}          
                                      type="checkbox"
                                      onChange={(event) => this.handleInputChange(event)} />
                              </td>
                              <td key={n.id + '_name'}>
                                  {n.nachname + ', ' + n.vorname}
                              </td>
                          </tr>
                      )}
                      </tbody>
                  </table>
              </InfiniteScroll>
                  
                  
                  <Button variant="primary" onClick={() => this.props.history.push('/verwaltung/verein/abstimmung/edit/' + abstimmung.id)}>Zurück</Button>

                  <Button style={{float: 'right'}} variant="primary" onClick={() => this.onSend()}>Nutzer hinzufügen</Button>
              </div>
              }
              
          </div>
          

        </div>
    );
  }

}

export default AbstimmungTeilnehmerAdd;
