import { request } from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const startseiteService = {
    findByNutzer,
    findAllPossibleStartseitenForNutzer
};

//liefert die Startseite, die der Nutzer in seinen Einstellungen koniguriert hat
export function findByNutzer(nutzerId)
{
    return request({
        url: API_BASE_URL + "/startseite/getByNutzer?nutzerId="  + nutzerId,
        method: 'GET'
    });
}

//liefert alle Startseiten, die dieser Nutzer auswaehlen kann
export function findAllPossibleStartseitenForNutzer(nutzerId)
{
    return request({
        url: API_BASE_URL + "/startseite/getAllPossibleStartseitenForNutzer?nutzerId="  + nutzerId,
        method: 'GET'
    });
}
