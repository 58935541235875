import {request} from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const vereinsService = {
    save,
    getAll,
    getById,
    saveEinstellungen,
    findAllForNavigation
};


export function save(vereinRequest) {
    return request({
        url: API_BASE_URL + "/verein/save",
        method: 'POST',
        body: JSON.stringify(vereinRequest)
    });
}

export function getAll()
{
    return request({
        url: API_BASE_URL + "/verein/list",
        method: 'GET'
    });
}

export function getById(vereinId)
{
    return request({
        url: API_BASE_URL + "/verein/"  + vereinId,
        method: 'GET'
    });
}

export function saveEinstellungen(vereinId, vereinRequest) {
    return request({
        url: API_BASE_URL + "/verein/save-einstellungen/" + vereinId,
        method: 'POST',
        body: JSON.stringify(vereinRequest)
    });
}

//hier werden die Vereine nur zureueckgegeben, wenn der Nutzer ein Admin ist.
export function findAllForNavigation(nutzerId)
{
    return request({
        url: API_BASE_URL + "/verein/all/"   + nutzerId,
        method: 'GET'
    });
}