import React from 'react'
import {withRouter} from "react-router-dom";
import MatchList from "../match_components/match_list_component";
import MatchEditInline from "../match_components/match_edit_inline";

class MatchEditAndListComponent extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      matchToEdit: {},
      duplicateMatches: [], //fuer das Erfassen von Matches. Manchmal werden sie ausversehen doppelt erfasst, weil die Anwendung wahrscheinlich zu langsam ist.
      verein: this.props.verein,
      successMsg: '',
      errorMsg: ''
    };
    this.onDataChanged = this.onDataChanged.bind(this);
    this.onEditMatch = this.onEditMatch.bind(this);
    this.onDuplicatesFound = this.onDuplicatesFound.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  
  onDataChanged(){
    this.props.onRefreshData();
    
    //falls ein Match bearbeitet wurde, das zu bearbeitende Match auf leer setzen, sonst werden die Eingabefelder
    //des Formulars nach dem Speichern nicht geleert.
    this.setState({
      matchToEdit: {}
    });
  }
  
  onDuplicatesFound(duplicateMatches){
    this.setState({
      duplicateMatches: duplicateMatches
    });
  }
  
  //setzt die Id des Matches, das fuer die Bearbeitung geladen werden soll.
  onEditMatch(match){
    this.setState({
      matchToEdit: match
    });
  }
  
  render()
  {
    const {verein, matchToEdit } = this.state;
    const matches = this.props.matches;
    const loggedInUser = this.props.loggedInUser;
    const isDoppel = this.props.isDoppel;
    
    return (
        <React.Fragment>
        {!!loggedInUser && !!loggedInUser.id &&
          <div id="matches_container" style={{paddingTop: '1.5rem'}}>
            
            <MatchEditInline verein={verein} 
                                   matchToEdit={matchToEdit} 
                                   loggedInUser={loggedInUser}
                                   onDataChanged={this.onDataChanged}
                                   onDuplicatesFound={this.onDuplicatesFound}
                                   isDoppel={isDoppel}/>

            <br/>
            
            <MatchList loggedInUser={loggedInUser}
                       matches={matches}
                       onEditMatch={this.onEditMatch}
                       duplicateMatches={this.state.duplicateMatches}
                       isOnlyView={false}/>
    
          </div>
        }
        </React.Fragment>
    );
  }
}


export default withRouter(MatchEditAndListComponent);
