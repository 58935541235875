import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {
  deletePunktspielById,
  getPunktspieleSortedByDatumForMannschaft
} from "../../_services/punktspielService";
import moment from 'moment'

class MannschaftPunktspieleEdit extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      punktspiele: [],
      successMsg: '',
      errorMsg: ''
    };
    this.deletePunktspiel = this.deletePunktspiel.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.setState({mannschaft: this.props.mannschaft});
      this.loadPunktspiele();
    }
  }

  deletePunktspiel(punktspielId) {
    deletePunktspielById(punktspielId)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Das Punktspiel wurde erfolgreich gelöscht'});
            this.loadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  loadPunktspiele() {
    const mannschaft = this.props.mannschaft;

    getPunktspieleSortedByDatumForMannschaft(mannschaft.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              punktspiele: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    const { punktspiele, mannschaft } = this.state;
    
    return (
        <div id="mannschaft_punktspiele_edit">
          <h2>Punktspiele</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!punktspiele && !!punktspiele.length && !!mannschaft &&
            <table className="table table-hover">
              <tbody>
                {punktspiele.map((punktspiel) =>
                      <PunktspielZeile key={punktspiel.id} punktspiel={punktspiel} mannschaft={mannschaft} onDeletePunktspiel={this.deletePunktspiel}/>
                  )}
              </tbody>
            </table>
          }
        </div>
    );
  }

}


// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class PunktspielZeile extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {punktspiel: {}};

  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.setState({
        punktspiel: this.props.punktspiel,
        mannschaft: this.props.mannschaft
      });
    }
  }


  onEdit(e, punktspielId) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/punktspiele/edit/' + punktspielId;
    //window.location.href = '/invitation/new/' + this.state.mannschaft.id;
    //this.props.history.push('/punktspiele/edit/' + punktspielId);
  }
  

  
  render()
  {
    const { punktspiel } = this.state;

    const vereinsname = this.props.mannschaft.verein.name;
    
    var heim = punktspiel.heimspiel ? <b>{vereinsname}</b> : punktspiel.gegner;
    var gast = punktspiel.heimspiel ? punktspiel.gegner : <b>{vereinsname}</b>;

    const formattedDate = moment(punktspiel.datum).format('DD.MM.YYYY HH:mm');
    
    return (
      <React.Fragment>
        {!!punktspiel && !!punktspiel.id &&
          <tr key={punktspiel.id}>
            <td style={{textAlign: 'left'}}>{formattedDate}</td>
         
            
            <td>{heim}</td>
            <td>{gast}</td>
            
            {/*bearbeiten ist hier nicht moeglich. Nur jeder Spieler selbst kann seine Infos bearbeiten*/}
            <td>
              {/*bearbeiten Button*/}
              <Button
                  className="btn btn-primary edit-button"
                  onClick={e => this.onEdit(e, punktspiel.id)}>
                <FontAwesomeIcon icon={faPen}  />
              </Button>
              
            {/*loeschen Button*/}
              <Button
                  className="btn btn-secondary delete-button"
                  onClick={()=>this.props.onDeletePunktspiel(punktspiel.id)}>
                <FontAwesomeIcon icon={faTrashAlt}  />
              </Button>
            </td>
          </tr>
        }
      </React.Fragment>

    );
  }

}

export default MannschaftPunktspieleEdit;


