import './trainingsplanung_view.css';
import React from 'react'
import * as trainingsplanungRundenService
    from "../../../_services/training/planung/trainingsplanungRundenService";
import {setErrorState} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {getDisplayName} from "../../../_helpers/displayHelper";
import {Card} from "react-bootstrap";





class TrainingsplanungView extends React.Component
{
    _isMounted = false;


    constructor(props) {
        super(props);
        this.state = {
            trainingsplanungRunden: [],
            trainingsplanungRundenLoaded: false,
            successMsg: '',
            errorMsg: ''
        };
    }

    componentDidMount()
    {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
    }

    loadData(){
        this.loadTrainingsplanungRunden();
    }


    loadTrainingsplanungRunden() {
        trainingsplanungRundenService.getRundenByTrainingstagIdWithMatches(this.props.trainingstag.id)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        trainingsplanungRunden: response,
                        trainingsplanungRundenLoaded: true,
                    });
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                    this.setState({
                        trainingsplanungRundenLoaded: true,
                    });
                }
            });
    }


    render()
    {
        const { trainingsplanungRunden, trainingsplanungRundenLoaded } = this.state;
        const trainingstag = this.props.trainingstag;
        
        return (
            <React.Fragment>

                {!!this.state.successMsg &&
                <SuccessMessage message={this.state.successMsg}/>
                }

                {!!this.state.errorMsg &&
                <ErrorMessage message={this.state.errorMsg}/>
                }


      
                
                {!!trainingstag && !!trainingstag.id > 0 && !!trainingsplanungRundenLoaded && !!trainingsplanungRunden && !!trainingsplanungRunden.length > 0 &&
                <Card>
                    <Card.Header as="h3" style={{backgroundColor: '#e9ecef', borderColor: '#dee2e6'}}>
                        Trainingsplan
                    </Card.Header>
                    <Card.Body style={{fontSize: '1.1em'}}>
                        <table className={'trainingsplanungViewTable'}>
                            <tbody>
                            {trainingsplanungRunden.map((runde, i) =>
                                <React.Fragment key={'trainingsplan_view_runde_einzel_spacer_frag_' + i}>
                                    <tr key={'trainingsplan_view_runde_einzel_spacer' + i}><td style={{paddingTop: '1em'}}></td></tr>
                                    {runde.trainingsplanungEinzel.map((einzel, j) =>
                                        <tr key={'trainingsplan_view_runde_einzel' + j}>
                                            <td><i>EINZEL</i></td>
                                            <td>{getDisplayName(einzel.nutzerHeim)}</td>
                                            <td>-</td>
                                            <td>{getDisplayName(einzel.nutzerGast)}</td>
                                        </tr>
                                    )}

                                    {runde.trainingsplanungDoppel.map((doppel, z) =>
                                        <React.Fragment key={'trainingsplan_view_runde_doppel_frag' + z}>
                                            <tr key={'trainingsplan_view_runde_doppel_spacer' + z}><td style={{paddingTop: '1em'}}></td></tr>
                                            <tr key={'trainingsplan_view_runde_doppel' + z}>
                                                <td><i>DOPPEL</i></td>
                                                <td>{getDisplayName(doppel.nutzerHeim1)}</td>
                                                <td>-</td>
                                                <td>{getDisplayName(doppel.nutzerGast1)}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{getDisplayName(doppel.nutzerHeim2)}</td>
                                                <td></td>
                                                <td>{getDisplayName(doppel.nutzerGast2)}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                    <tr key={'trainingsplan_view_runde_spacer' + i} className={'spacer_line'}>
                                        <td colSpan="100%"></td>
                                    </tr>
                                </React.Fragment>

                            )}
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>


                }
            </React.Fragment>
        );
    }
}

export default TrainingsplanungView;
