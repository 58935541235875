import Match from "./Match";
import Spielpaarung from "./Spielpaarung";
import GastHeimDoppel from "./GastHeimDoppel";

export default class MatchDoppel extends Match {
    
    constructor(matchJson) {
        super(matchJson);
        
        const heim = new GastHeimDoppel(matchJson.gastHeim.nutzerHeim, matchJson.gastHeim.nutzerHeim2);
        const gast = new GastHeimDoppel(matchJson.gastHeim.nutzerGast, matchJson.gastHeim.nutzerGast2);
        
        this.spielpaarung = new Spielpaarung(heim, gast);
    }

    getHeimNutzer1() {
        return this.spielpaarung.heim.nutzer1;
    }

    getHeimNutzer2() {
        return this.spielpaarung.heim.nutzer2;
    }

    
    getGastNutzer1() {
        return this.spielpaarung.gast.nutzer1;
    }
    getGastNutzer2() {
        return this.spielpaarung.gast.nutzer2;
    }
}