import React from 'react'
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Col, Form, Modal, Row, Select} from "antd";
import {trainingsplanungEinzelService} from "../../_services/training/planung/trainingsplanungEinzelService";

const FormItem = Form.Item;

class TrainingsplanungEinzelModal extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      nutzerHeim: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      nutzerGast: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      successMsg: '',
      errorMsg: ''
    };
  }

  formRef = React.createRef();
  
  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }



  isNewAnsicht(){
    //dann handelt es sich um den Edit-Fall
    if(this.props.trainingsplanungRunde && this.props.trainingsplanungRunde.id > 0){
      return false;
    }else{
      //neu erstellen Fall
      return true;
    }
  }

  handleSelectInputChange(value, name) {
    this.setState({
      [name] : {
        value: value
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }



  handleCreateEinzel = () => {

    this.setState({
      successMsg: null,
      errorMsg: null,
    });

    var einzelId = 0;
    
    if(!this.isNewAnsicht()){
      einzelId = this.props.trainingsplanungEinzel.id;
    }

    const saveRequest = {
      id: einzelId,
      trainingsplanungRunde: this.props.runde,
      datum: new Date(),
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerGastId: this.state.nutzerGast.value
    };
    
    
    trainingsplanungEinzelService.saveEinzel(saveRequest)
        .then(response => {
          // this.setState({
          //   successMsg: "Das Einzel wurde erfolgreich gespeichert!",
          // });
          this.props.onSetSuccessMessage("Das Einzel wurde erfolgreich gespeichert!");
          document.body.classList.remove('busy-cursor');
          this.props.onReloadEinzel();
          this.props.onCancel();
          this.formRef.current.resetFields();
        }).catch(error => {
      if (this._isMounted) {
        //setErrorState(error, this);
        this.props.onSetErrorMessage(error);
      }
      document.body.classList.remove('busy-cursor');
    });
  };
  
  render()
  {
    var vereinsSpielerDistinctNutzer = this.props.vereinsSpielerDistinctNutzer;

    let spielerItems = vereinsSpielerDistinctNutzer.map((spieler) =>
        <Select.Option value={spieler.nutzer.id} key={spieler.id}>{spieler.nickname}</Select.Option>
    );

    var initialValues = {};
    if(this.isNewAnsicht()){
      initialValues={
      };
    }else{
      initialValues={
        "nutzerHeim": this.props.trainingsplanungEinzel.nutzerHeim.id,
        "nutzerGast": this.props.trainingsplanungEinzel.nutzerGast.id,
      };
    }
    
    return (
        <React.Fragment>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }
          
          <Modal
              visible={this.props.visible}
              title="Neues Einzel erstellen"
              okText="Einzel erstellen"
              cancelText="Abbrechen"
              onCancel={this.props.onCancel}
              onOk={this.handleCreateEinzel}
          >
            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }
            <Form onFinish={this.onSave}
                  ref={this.formRef}
                  className="trainingplanung-einzel-edit-form"
                  initialValues={initialValues}>

              <Row>
                <Col span={11}>
                  <FormItem
                      labelAlign="left"
                      name="nutzerHeim"
                      rules={[{
                        required: true,
                        message: 'Bitte wähle einen Spieler aus' }]}
                      hasFeedback
                      validateStatus={this.state.nutzerHeim.validateStatus}
                      help={this.state.nutzerHeim.errorMsg}>
                    <Select
                        // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                        style={{width: '11em', fontWeight: 'normal'}}
                        size="large"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerHeim')}>
                      {spielerItems}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={2}>-</Col>
                <Col span={11}>
                  <FormItem
                      labelAlign="left"
                      name="nutzerGast"
                      rules={[{
                        required: true,
                        message: 'Bitte wähle einen Spieler aus' }]}
                      hasFeedback
                      validateStatus={this.state.nutzerGast.validateStatus}
                      help={this.state.nutzerGast.errorMsg}>
                    <Select
                        // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                        style={{width: '11em', fontWeight: 'normal'}}
                        size="large"
                        onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerGast')}>
                      {spielerItems}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              
            </Form>
            
          </Modal>

        </React.Fragment>
    );
  }

}


export default TrainingsplanungEinzelModal;
