import React from 'react'
import TerminEditComponent from "./termin_edit_component";

class TerminEdit extends React.Component
{
  _isMounted = false;
  
  
  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  render()
  {
    const terminId = this.props.match.params.terminId;
    
    return (
        <TerminEditComponent terminId={terminId}/>
    );
  }

}

export default TerminEdit;


