import React from 'react'
import {Button} from 'react-bootstrap';

import {Form, Input} from "antd";
import {setErrorState} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {getDisplayName} from "../../../_helpers/displayHelper";
import {terminService} from "../../../_services/termin/terminService";
import {terminTeilnehmerService} from "../../../_services/termin/terminTeilnehmerService";
import {bereiteDatumsWertFuerRequestVor, isDatumValid} from "../../../util/DateUtil";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const FormItem = Form.Item;


class TerminEditComponent extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      terminId: this.props.terminId,
      termin: {},
      terminTeilnehmer: [],
      successMsg: '',
      errorMsg: '',
      datum: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      name: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      ort: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      beschreibung: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
    };
    this.onSave = this.onSave.bind(this);
    this.loadTermin = this.loadTermin.bind(this);
    this.loadTerminTeilnehmer = this.loadTerminTeilnehmer.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onAddTeilnehmer = this.onAddTeilnehmer.bind(this);
    
    
    //this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.terminId !== nextProps.terminId) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    if (this._isMounted) {
      if(!this.isNewAnsicht()){
        this.loadTermin();
        this.loadTerminTeilnehmer();
      }
    }
  }

  loadTermin() {
    
    const terminId = this.state.terminId;

    terminService.getTerminById(terminId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              termin: response
            });

            this.setState({
              datum: {
                value: response.datum
              },
              name: {
                value: response.name
              },
              ort: {
                value: response.ort
              },
              beschreibung: {
                value: response.beschreibung
              },
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  loadTerminTeilnehmer() {

    const terminId = this.state.terminId;

    terminTeilnehmerService.getAllTeilnehmerForTerminSorted(terminId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              terminTeilnehmer: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  //lieert die Mannschaft, je nach dem, um was fuer eine Ansicht es sich gerade handelt
  //(die Bearbeiten Ansicht, eines bereits existenten Punktspiels oder die Neu Ansicht, zum neu Erstellen eines Punktspiels)
  getVerein(){
    //dann handelt es sich um den Edit-Fall
    if(this.state.terminId > 0){
      return this.state.termin.verein;
    }else{
      //neu erstellen Fall
      return this.props.verein;
    }
  }
  
  isNewAnsicht(){
    //dann handelt es sich um den Edit-Fall
    if(this.state.terminId > 0){
      return false;
    }else{
      //neu erstellen Fall
      return true;
    }
  }
  
  onSave() {
    
    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    var verein = this.getVerein();

    var dateFormattedToSave = bereiteDatumsWertFuerRequestVor(this.state.datum.value);

    const isValid = this.validateForm();
    if(!isValid){
      this.setState({
        disableSubmitButton: false,
      });
      document.body.classList.remove('busy-cursor');
      return;
    }
    
    const saveRequest = {
      id: this.state.terminId,
      verein: verein,
      datum: dateFormattedToSave,
      name: this.state.name.value,
      ort: this.state.ort.value,
      beschreibung: this.state.beschreibung.value,
    };

    if(this.isNewAnsicht()){
      this.saveNewTermin(saveRequest);
    }else{
      this.updateTermin(saveRequest);
    }

  }
  
  updateTermin(saveRequest){
    terminService.updateAllgemeineEinstellungen(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Der Termin wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.setState({
            termin: response,
            terminId: response.id
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  saveNewTermin(saveRequest){
    terminService.saveTermin(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Der Termin wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.setState({
            termin: response,
            terminId: response.id
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  onBack() {
    var vereinId = 0;
    //dann handelt es sich um den Edit-Fall
    if(this.state.terminId > 0){
      vereinId = this.state.termin.verein.id;
    }else{
      //neu erstellen Fall
      vereinId = this.props.verein.id;
    }
    
    window.location.href = '/verwaltung/verein/edit/' + vereinId;
  }


  onAddTeilnehmer(e) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/verwaltung/verein/termin/' + this.state.termin.id;
  }

  deleteTeilnehmer(teilnehmerId) {
    terminTeilnehmerService.deleteTeilnehmerById(teilnehmerId)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Der Spieler wurde erfolgreich gelöscht'});
            this.loadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }


  validateDatum = (datum) => {

    let isValid = isDatumValid(datum);

    let datumOb = this.state.datum;

    if(!datum) {
      datumOb.validateStatus = 'error';
      datumOb.errorMsg = 'Bitte gib ein Datum an.';
    } else if (!isValid) {
      datumOb.validateStatus = 'error';
      datumOb.errorMsg = 'Bitte gib ein valides Datum in dem Format dd.MM.yyyy an';
    } else {
      datumOb.validateStatus = 'success';
      datumOb.errorMsg = '';
      isValid = true;
    }

    this.setState({
      datum : datumOb
    });

    return isValid;
  }

  validateForm(){
    return this.validateDatum(this.state.datum.value);
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const inputName = target.name;
    const checked = target.checked;

    this.setState({
      [inputName] : {
        value: checked
      }
    });
  }
  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const { termin, terminTeilnehmer } = this.state;
    var formattedDate = moment(termin.datum).format('DD.MM.YYYY HH:mm');
    const isNewAnsicht = this.isNewAnsicht();
    if(this.isNewAnsicht()){
      formattedDate = '';
    }
    
    return (
        <React.Fragment>
          <div className="termin-edit-container">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }

            {(isNewAnsicht || (!!termin && !!termin.id)) ?
            <div className="form-container">
              <Form onFinish={this.onSave}
                    {...layout}
                    className="punktspiel-edit-form"
                    initialValues={{
                      "datum": formattedDate,
                      "name": this.state.termin.name,
                      "ort": this.state.termin.ort,
                      "beschreibung": this.state.termin.beschreibung,
                    }}>
                <FormItem
                    labelAlign="left"
                    label="Datum"
                    name="datum"
                    rules={[{
                      required: true,
                      message: 'Bitte gib das Datum des Termins an' }]}
                    hasFeedback
                    validateStatus={this.state.datum.validateStatus}
                    help={this.state.datum.errorMsg}>
                  <Input
                      size="large"
                      name="datum"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.datum.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>

                <FormItem
                    labelAlign="left"
                    label="Name"
                    name="name"
                    rules={[{
                      required: true,
                      message: 'Bitte gib den Namen der Termin an' }]}
                    hasFeedback
                    validateStatus={this.state.name.validateStatus}
                    help={this.state.name.errorMsg}>
                  <Input
                      size="large"
                      name="name"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.name.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>

                <FormItem
                    labelAlign="left"
                    label="Ort"
                    name="ort"
                    rules={[{
                      required: true,
                      message: 'Bitte gib den Ort des Termins an' }]}
                    hasFeedback
                    validateStatus={this.state.ort.validateStatus}
                    help={this.state.ort.errorMsg}>
                  <Input
                      size="large"
                      name="ort"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.ort.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>

                <FormItem
                    labelAlign="left"
                    label="Beschreibung"
                    name="beschreibung"
                    rules={[{
                      required: true,
                      message: 'Bitte gib die Beschreibung des Termins an' }]}
                    hasFeedback
                    validateStatus={this.state.beschreibung.validateStatus}
                    help={this.state.beschreibung.errorMsg}>

                  <TextArea
                      size="large"
                      name="beschreibung"
                      maxLength={500}
                      rows={4}
                      style={{width: '100%'}}
                      className="small-responsive-field"
                      value={this.state.beschreibung.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />                  
                </FormItem>
                
                <div id="btn-container" className={"form-container"}>
                  <Button type="submit"
                          style={{float: 'right'}}
                          size="large"
                          className="myLargeBtn"
                          disabled={this.state.disableSubmitButton}>Speichern</Button>

                </div>
              </Form>
              
              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onBack()}>Zurück</Button>

            </div>
                :
                null
            }
            


            {!!termin && !!termin.id &&
            <div>
              
              <div>
                <h2>Teilnehmer</h2>
                {!!termin && !!terminTeilnehmer && !!terminTeilnehmer.length > 0 &&
                <table className="table table-hover">
                  <tbody>
                  {terminTeilnehmer.map((teilnehmer) =>
  
                      <tr key={"teilnehmer_zeile_" + teilnehmer.id}>
                        <td style={{textAlign: 'left'}}>{getDisplayName(teilnehmer.nutzer)}</td>
  
                        <td>
                          {/*loeschen Button*/}
                          <Button
                              className="btn btn-secondary delete-button"
                              onClick={()=>this.deleteTeilnehmer(teilnehmer.id)}>
                            <FontAwesomeIcon icon={faTrashAlt}  />
                          </Button>
                        </td>
                      </tr>
                  )}
                  </tbody>
                </table>
                }
                <Button variant="primary" onClick={e => this.onAddTeilnehmer(e)}>Teilnehmer hinzufügen</Button>
              </div>
              <br/>
              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onBack()}>Zurück</Button>
            </div>
            }
            

            
          </div>
        </React.Fragment>

    );
  }

}

export default TerminEditComponent;


