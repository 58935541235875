import './matchEditAndListTabPaneView.css';
import React from 'react'
import {Tab, Tabs} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {getCurrentUser} from "../_services";
import MatchEditAndListEinzel from "./match_combined_components/match_edit_and_list_einzel";
import MatchEditAndListDoppel from "./match_combined_components/match_edit_and_list_doppel";
import {setErrorState} from "../util/ErrorUtil";

class MatchEditAndListTabPaneView extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: {},
      successMsg: '',
      errorMsg: ''
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadLoggedInUser();
  }

  loadLoggedInUser(){
    getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }
        })
        .catch(error => {
          setErrorState(error);
        });
  }
 
  render()
  {
    
    return (
        <div>
          <h2>Matches</h2>
        
        <Tabs defaultActiveKey="einzel" id="matches-tab-pane" className="matches-tab-pane" style={{fontSize: '1rem'}}>
          <Tab eventKey="einzel" title="Einzel" className="tab-header">
            <MatchEditAndListEinzel 
                verein={this.props.verein}
                loggedInUser={this.state.loggedInUser}
                onRefreshData={this.props.onRefreshData}/>
          </Tab>
          <Tab eventKey="doppel" title="Doppel">
            <MatchEditAndListDoppel
                verein={this.props.verein}
                loggedInUser={this.state.loggedInUser}
                onRefreshData={this.props.onRefreshData}/>
          </Tab>
        </Tabs>
        </div>
    );
  }
}

export default withRouter(MatchEditAndListTabPaneView);
