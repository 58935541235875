import React from 'react';
import {getCurrentUser} from "../../_services/authService";
import {spielerService} from "../../_services/spielerService";
import SpielerProfilComponent from "./spielerProfilComponent";

//DIe Profilansicht. ANsicht, wo ich mein eigenes Profil anschauen kann.
class Profil extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            spielerListe: []
        };
        
        this.loadUsers = this.loadUsers.bind(this);
    }


    loadUsers() {
        getCurrentUser()
        .then(response => {
            if (this._isMounted) {
                this.setState({user: response});
                this.loadSpieler(response.id);
                
            }
        })
        .catch(error => {
            if (this._isMounted) {
                this.setState({hasError: error})
            }
        });


    }
    
    loadSpieler(nutzerId){
        spielerService.getAllSpielerByNutzer(nutzerId)
        .then(responseSpieler => {
            if (this._isMounted) {
                this.setState({spielerListe: responseSpieler});
            }
        })
        .catch(errorSpieler => {
            if (this._isMounted) {
                this.setState({hasError: errorSpieler})
            }
        });
    }
    
    componentDidMount() {
        this._isMounted = true;
        this.loadUsers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadUsers();
        }
    }


    render() {
        
        const { user, spielerListe } = this.state;
        
        const spielerDerEinzelnenMannschaften = spielerListe.map(spieler =>
            <SpielerProfilComponent key={spieler.id} spieler={spieler} showAdminFields={false}/>
        );
        
        
        return (
            <React.Fragment>
            {!!user && !!user.id &&
                <div>
                    <h1>Dein Profil</h1>
                    <p>Hier kannst du deine einzlenen Spieler-Profile für die jeweiligen Mannschaften, in denen du
                        Mitglied bist, bearbeiten.</p>

                    <span>
                        {spielerDerEinzelnenMannschaften}
                    </span>
                </div>
            }
            </React.Fragment>
        );
    }
}

export default Profil;