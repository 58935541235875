import React, { Component, createContext } from "react";
import sampleDoppel from "./sampleDoppel";
import DoppelAufstellung from "../../../../../dto/wettkampf/DoppelAufstellung";

// Helper functions

function move(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
        newIndex = array.length - 1;
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
}

function moveElement(array, index, offset) {
    const newIndex = index + offset;

    return move(array, index, newIndex);
}

// Context

const GridContext = createContext({ items: [] });

export class GridProvider extends Component {
    constructor(props) {
        super(props);

        const doppel = sampleDoppel;
        var da1 = new DoppelAufstellung(doppel[0]);
        var da2 = new DoppelAufstellung(doppel[1]);
        var da3 = new DoppelAufstellung(doppel[2]);
        var da4 = new DoppelAufstellung(doppel[3]);
        var da5 = new DoppelAufstellung(doppel[4]);

        var da6 = new DoppelAufstellung(doppel[6]);
        var da7 = new DoppelAufstellung(doppel[7]);
        var da8 = new DoppelAufstellung(doppel[8]);
        var da9 = new DoppelAufstellung(doppel[9]);
        var da10 = new DoppelAufstellung(doppel[10]);
        var da11 = new DoppelAufstellung(doppel[11]);
        var da12 = new DoppelAufstellung(doppel[12]);
        var da13 = new DoppelAufstellung(doppel[13]);
        var da14 = new DoppelAufstellung(doppel[14]);

        const cars = [da1, da2, da3, da4, da5, da6, da7, da8, da9, da10, da11, da12, da13, da14];

        this.state = {
            items: cars,
            moveItem: this.moveItem,
            pinItem: this.pinItem,
            banItem: this.banItem,
            setItems: this.setItems,
            anzahlPinnedItems: 0,
            anzahlBannedItems: 0,
        };
    }

    render() {
        return (
            <GridContext.Provider value={this.state}>
                {this.props.children}
            </GridContext.Provider>
        );
    }

    setItems = items => this.setState({ items });

    moveItem = (sourceId, destinationId) => {
        //die wollen die beiden seltsamerweise als String.
        //also falls außversehen ein int uebergeben wird, den in String umwandeln
        if(!isNaN(destinationId)){
            //Wenn es sich um eine Zahl handelt, in einen String umwandeln
            destinationId = destinationId.toString();
        }
        if(!isNaN(sourceId)){
            //Wenn es sich um eine Zahl handelt, in einen String umwandeln
            sourceId = sourceId.toString();
        }

        const sourceIndex = this.state.items.findIndex(
            item => item.orderPosition === sourceId
        );
        const destinationIndex = this.state.items.findIndex(
            item => item.orderPosition === destinationId
        );

        // If source/destination is unknown, do nothing.
        if (sourceId === -1 || destinationId === -1) {
            return;
        }

        const offset = destinationIndex - sourceIndex;

        this.setState(state => ({
            items: moveElement(state.items, sourceIndex, offset)
        }));
    };

    pinItem = (sourceId, pin, isBanned) => {
        //pin = true, wenn das Item gepinned werden soll
        // pin = false, wenn das Item un-pinned werden soll
        if(pin && isBanned){
            this.pinBannedItem(sourceId);
            return;
        }
        
        //pin the item
        if(pin){
            this.moveItemFirst(sourceId);
            this.countPinned(true);
        } else{
            //un-pin
            var anzahlPinnedItems = this.state.anzahlPinnedItems;
            if(anzahlPinnedItems < 0){
                return;
            }
            const destinationIndex = this.state.items[anzahlPinnedItems - 1].orderPosition;
            this.moveItem(sourceId, destinationIndex);
            this.countPinned(false);
        }

    };

    //braucht ne extra Methode zu. Man kann nicht zuerst un-ban und dann pin Methode aufrufen, da in den beiden
    //Methoden die Items direkt im State geaendert werden und der State da nicht so schnell hinterkommt mit dem zweimal verschieben/Positionen wechseln.
    //Daher hier jetzt eine eigene Methode, mit nur einem Verschiebe-Vorgang
    pinBannedItem = (sourceId) => {
        //1. UN-BAN
        //zuerst das Item un-bannen: wir zaehlen hier nur die banned items runter. das verhscieben sparen wir uns.
        //werden nachher nur einmal im Ganzen verschieben.
        this.countBanned(false);
        //2. PIN
        //das Item PINnen
        this.moveItemFirst(sourceId);
        this.countPinned(true);
    }

    //braucht ne extra Methode zu. Man kann nicht zuerst un-ban und dann pin Methode aufrufen, da in den beiden
    //Methoden die Items direkt im State geaendert werden und der State da nicht so schnell hinterkommt mit dem zweimal verschieben/Positionen wechseln.
    //Daher hier jetzt eine eigene Methode, mit nur einem Verschiebe-Vorgang
    banPinnedItem = (sourceId) => {
        //1. UN-PIN
        //zuerst das Item un-bannen: wir zaehlen hier nur die banned items runter. das verhscieben sparen wir uns.
        //werden nachher nur einmal im Ganzen verschieben.
        this.countPinned(false);
        //2. BAN
        //das Item BANnen
        this.moveItemLast(sourceId);
        this.countBanned(true);
    }
    
    banItem = (sourceId, ban, isPinned) => {

        //pin = true, wenn das Item ge-banned werden soll
        // pin = false, wenn das Item un-banned werden soll
        if(ban && isPinned){
            this.banPinnedItem(sourceId);
            return;
        }
        
        //ban the item
        if(ban){
            this.moveItemLast(sourceId);
            this.countBanned(true);
        } else{
            //un-ban
            const size = this.state.items.length;
            var anzahlBannedItems = this.countBanned(false);
            if(anzahlBannedItems < 0){
                anzahlBannedItems = 0;
            }

            const destinationIndex = this.state.items[size- anzahlBannedItems - 1].orderPosition;
            this.moveItem(sourceId, destinationIndex);
        }
    };

    moveItemFirst = (sourceId) => {
        //die wollen die beiden seltsamerweise als String.
        //also falls außversehen ein int uebergeben wird, den in String umwandeln
        if(!isNaN(sourceId)){
            //Wenn es sich um eine Zahl handelt, in einen String umwandeln
            sourceId = sourceId.toString();
        }

        const destinationIndex = this.state.items[0].orderPosition;

        this.moveItem(sourceId, destinationIndex);
    };

    moveItemLast = (sourceId) => {
        //die wollen die beiden seltsamerweise als String.
        //also falls außversehen ein int uebergeben wird, den in String umwandeln
        if(!isNaN(sourceId)){
            //Wenn es sich um eine Zahl handelt, in einen String umwandeln
            sourceId = sourceId.toString();
        }

        const destinationIndex = this.state.items[this.state.items.length - 1].orderPosition;

        this.moveItem(sourceId, destinationIndex);
    };

    countPinned(countUp){
        var anzahlPinnedItems = this.state.anzahlPinnedItems;
        if(countUp){
            //hochzaehlen
            anzahlPinnedItems = anzahlPinnedItems + 1;

        }else{
            //runter zaehlen
            anzahlPinnedItems = anzahlPinnedItems - 1;
            if(anzahlPinnedItems < 0){
                anzahlPinnedItems = 0;
            }
        }

        this.setState(state => ({
            anzahlPinnedItems: anzahlPinnedItems
        }));

        return anzahlPinnedItems;
    }

    countBanned(countUp){
        var anzahlBannedItems = this.state.anzahlBannedItems;
        if(countUp){
            //hochzaehlen
            anzahlBannedItems = anzahlBannedItems + 1;
        }else{
            //runter zaehlen
            anzahlBannedItems = anzahlBannedItems - 1;
            if(anzahlBannedItems < 0){
                anzahlBannedItems = 0;
            }
        }

        this.setState(state => ({
            anzahlBannedItems: anzahlBannedItems
        }));

        return anzahlBannedItems;
    }


}

export default GridContext;