import React, {Component} from 'react';
import {getSignupRequest} from '../../_services/authService';
import {setErrorState} from '../../util/ErrorUtil';
//import { Form, Input, Button, notification } from 'antd';
import Signup from "../signup/Signup";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

//Die Seite, auf die der Nutzer aus seiner E-Mail weitergeleitet wird
//Hier gibt er seine Daten an und registriert sich.
class InvitationAccept extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            regtoken: {
                value: ''
            },
            nutzer: null,
            //ob die Registierung erfolgreich war
            registrationStatus: '',
            error: null
        }
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }
    
    loadData() {
        //holt das Token aus dem Request-Parameter der URL raus
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');

        getSignupRequest(token)
            .then(response => {
                this.setState({
                    nutzer: response
                });
                //this.props.history.push("/login")

            }).catch(error => {
            setErrorState(error, this);
        });
    }



    render() {
        const { nutzer } = this.state;
        
        
        return (
            <div className="content-container">
                {!!this.state.successMsg &&
                <SuccessMessage message={this.state.successMsg}/>
                }

                {!!this.state.errorMsg &&
                <ErrorMessage message={this.state.errorMsg}/>
                }
                
                {!!nutzer && !!nutzer.id &&
                <div>
                    <Signup nutzer={nutzer} location={this.props.location}/>
                </div>
                }

            </div>
        );
    }
    
}

export default InvitationAccept;