import './Punktspieluebersicht.css';
import img_checked from '../img/checked.png';
import img_cancelled from '../img/cancelled.png';
import img_unbekannt from '../img/unbekannt.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from "@fortawesome/free-solid-svg-icons";


import {setErrorStateWithMessage} from "../util/ErrorUtil"

import React from 'react';
import Moment from 'react-moment';
import moment from "moment";

import {getMannschaftById, mannschatsService} from "../_services/mannschatsService";
import {
  getSpielerById,
  hasErsatzPunktspielerForMannschaft,
  spielerService
} from "../_services/spielerService";

import {
  getErsatzspielerPunktspielAntwortenForMannschaft,
  getPunktspielAbstimmungForSpieler,
  getPunktspieleSortedByDatumForMannschaft,
  getStammspielerPunktspielAntwortenForMannschaft
} from "../_services/punktspielService";
import {getCurrentUser} from "../_services";
import img_null from "../img/null.png";
import TrainingComment from "../training/training_comment";
import PunktspielComment from "./punktspiel_comment";

class Punktspieluebersicht extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: {},
      hasUserEditRights: false,
      mannschaft: {},
      spieler: [],
      ersatzPunktspieler: [],
      punktspiele: [],
      punktspieleLoaded: false,
      antwortenStammspielerGrouped: {},
      antwortenErsatzspielerGrouped: {},
      hasErsatz: {}
    };

    this.setErrorState = this.setErrorState.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadLoggedInUserAndSetRights();
    this.loadMannschaft();
    this.loadPunktspiele();
    this.loadStammPunktSpielerSorted();
    this.loadAntworten();
  }


  
  loadMannschaft() {
    const mannschaftsId = this.props.match.params.mannschaftsId;
    getMannschaftById(mannschaftsId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          mannschaft: response
        });
      }
    })
  .catch(error => this.setState({hasError: error}));
  }

  loadStammPunktSpielerSorted() {
    const mannschaftsId = this.props.match.params.mannschaftsId;
    //liefert nur die Stammspieler
    spielerService.getSpielerByMannschaftsIdSortedByPosition(mannschaftsId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              spieler: response
            });
          }
        })
        .catch(error => this.setState({hasError: error}));

    spielerService.getErsatzPunktspielSpielerByMannschaftSortedByPosition(mannschaftsId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              ersatzPunktspieler: response
            });
          }
        })
        .catch(error => this.setState({hasError: error}));
    
  }
  
  loadPunktspiele() {
    const mannschaftsId = this.props.match.params.mannschaftsId;
    getPunktspieleSortedByDatumForMannschaft(mannschaftsId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          punktspiele: response,
          punktspieleLoaded: true,
        });
      }
    })
    .catch(error => this.setState({hasError: error, punktspieleLoaded: true}));
  }

  loadAntworten() {
    const mannschaftsId = this.props.match.params.mannschaftsId;
    getStammspielerPunktspielAntwortenForMannschaft(mannschaftsId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          antwortenStammspielerGrouped: response
        });
      }
    })
    .catch(error => this.setState({hasError: error}));

    getErsatzspielerPunktspielAntwortenForMannschaft(mannschaftsId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              antwortenErsatzspielerGrouped: response
            });
          }
        })
        .catch(error => this.setState({hasError: error}));
    
    hasErsatzPunktspielerForMannschaft(mannschaftsId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              hasErsatz: response
            });
          }
        })
        .catch(error => this.setState({hasError: error}));
    
  }

  loadLoggedInUserAndSetRights(){
    const mannschaftsId = this.props.match.params.mannschaftsId;
    getCurrentUser()
        .then(response => {
          const loggedInUser = response;
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }

          if(loggedInUser.id !== undefined){
            mannschatsService.isNutzerMannschaftsAdmin(mannschaftsId, loggedInUser.id)
                .then(response => {
                  if (this._isMounted) {
                    this.setState({hasUserEditRights: response});
                  }
                })
                .catch(error => {
                  this.setErrorState(error);
                });
          }
          
        })
        .catch(error => this.setState({hasError: error}));
  }

  setErrorState(fehlertext){
    setErrorStateWithMessage(fehlertext, this);
  }
  
  render()
  {

    const { mannschaft, punktspiele, antwortenStammspielerGrouped, hasErsatz, spieler, ersatzPunktspieler, punktspieleLoaded, hasUserEditRights, loggedInUser } = this.state;

    var mannschaftsName = '';
    if(mannschaft){
      mannschaftsName = mannschaft.name
    }

    let anzahlCols = punktspiele.length;
    //+2: einmal die Spielernamen Spalte und einmal die Edit-Button-Spalte
    anzahlCols = anzahlCols + 2;
    return (
        <div id="trainingsuebersicht">
          <h1>Punktspiele {mannschaftsName}</h1>

          {!!mannschaft && punktspieleLoaded && punktspiele.length <= 0 &&
              <span>Für diese Mannschaft stehen keine Punktspiele an.</span>
          }
          
          {!!mannschaft && !!mannschaft.spielerIds && punktspieleLoaded && punktspiele.length > 0 &&
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th key={'tableHeader_Spieler_Col'} className="normal">
                      
                    </th>
                    {punktspiele.map((punktspiel) =>
                        <th key={'tableHeader_' + punktspiel.id}>
                          <span className='spieldatum'>
                            <Moment format="DD.MM.YYYY">
                              {punktspiel.datum}
                            </Moment>
                            &nbsp;
                            <Moment  format="HH:mm">
                              {punktspiel.datum}
                            </Moment>
                          </span>
                          
                          <br/>

                          {(punktspiel.heimspiel) &&
                          <span>
                            <span className='heim'>{punktspiel.mannschaft.verein.name} </span>
                            <br/>
                            <span className='gast'>{punktspiel.gegner}</span>
                          </span>
                          }

                          {!(punktspiel.heimspiel) &&
                          <span>
                            <span className='gast'>{punktspiel.gegner} </span>
                            <br/>
                            <span className='heim'>{punktspiel.mannschaft.verein.name}</span>
                          </span>
                          }
                        </th>
                    )}
                    {/*Die leere Zelle in der Spalte fuer den Bearbeiten-Button*/}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                
                {/*Die Summen-Zeile der Antworten*/}
                {!!antwortenStammspielerGrouped &&
                <tr>
                  <td> </td>
                  {punktspiele.map((punktspiel) =>
                      <AntwortenZeile  key={punktspiel.id} 
                                       antwort={this.state.antwortenStammspielerGrouped[punktspiel.id]}
                                       hasErsatz={hasErsatz}
                                       antwortenErsatzSpieler={this.state.antwortenErsatzspielerGrouped[punktspiel.id]}/>
                  )}

                  {/*Die leere Zelle in der Spalte fuer den Bearbeiten-Button*/}
                  <td></td>
                </tr>
                }
                
                
                {/*Die einzelnen Stamm-Spieler-Zeilen*/}
                {!!punktspiele && !!spieler && !!spieler.length && !!loggedInUser && !!loggedInUser.id &&
                  <React.Fragment>
                    {spieler.map((s) =>
                        <SpielerZeile key={s.id} 
                                      spielerId={s.id} 
                                      punktspiele={this.state.punktspiele}
                                      loggedInUser={loggedInUser}
                                      hasUserEditRights={hasUserEditRights}
                                      ersatz={false}/>
                    )}
                  </React.Fragment>
                }

                {/*Die einzelnen ERSATZ-Spieler-Zeilen*/}
                {!!punktspiele && !!ersatzPunktspieler && !!ersatzPunktspieler.length && !!loggedInUser && !!loggedInUser.id &&
                <React.Fragment>
                  {!!hasErsatz &&
                  <tr>
                    <td colSpan={anzahlCols} style={{textAlign: 'left'}}><h5>Ersatzspieler</h5></td>
                  </tr>
                  }
                  {ersatzPunktspieler.map((s) =>
                      <SpielerZeile key={s.id} 
                                    spielerId={s.id} 
                                    punktspiele={this.state.punktspiele}
                                    loggedInUser={loggedInUser}
                                    hasUserEditRights={hasUserEditRights}
                                    ersatz={true}/>
                  )}
                </React.Fragment>
                }

                </tbody>
              </table>
          }

          {!!mannschaft && !!mannschaft.id && punktspieleLoaded && punktspiele.length > 0 &&
          <div>
              <br/>
              <PunktspielComment mannschaft={mannschaft}/>
              <br/>
              <br/>
          </div>
          }
          
        </div>
    );
  }

}

// tag::AntwortenZeile[]
// Die Antworten-Zelle fuer ein Punktspiel
class AntwortenZeile extends React.Component{
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const antwort = this.props.antwort;
    const antwortenErsatzSpieler = this.props.antwortenErsatzSpieler;
    
    return (
              <td>
                {!!this.props.antwort && 
                <span>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-success pull-right">Zusagen: {antwort.zusagen}</span>
                  </h4>
                  <br/>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-warning">Unsicher: {antwort.unsicher}</span>
                  </h4>
                  <br/>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-danger">Absagen: {antwort.absagen}</span>
                  </h4>
                  <br/>
                  <h4 style={{display: 'inline-block'}}>
                    <span className="badge badge-pill badge-secondary">Offen: {antwort.offen}</span>
                  </h4>
                  {!!this.props.hasErsatz && !!this.props.antwortenErsatzSpieler &&
                  <span>
                    <br/>
                  <h5 style={{display: 'inline-block'}}>
                    <span className="badge badge-primary pull-right">Ersatz: {antwortenErsatzSpieler.zusagen}</span>
                  </h5>
                </span>
                  }
                  
                </span>
                }
              </td>
    )
    
  }
}
// end::AntwortenZeile[]


// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class SpielerZeile extends React.Component{
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      spieler: {},
      punktspielSpielerListe: [],
      loggedInUser: {},
      ersatz: this.props.spieler
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData()
  {
    this.loadPunktspielAbstimmungForSpieler();
    this.loadSpieler();
  }
    

  loadPunktspielAbstimmungForSpieler(){
    const spielerId = this.props.spielerId;

    getPunktspielAbstimmungForSpieler(spielerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          punktspielSpielerListe: response
        });
      }
    })
    .catch(error => this.setState({hasError: error}));
  }
  
  loadSpieler(){
    const spielerId = this.props.spielerId;

    getSpielerById(spielerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          spieler: response
        });
      }
    })
    .catch(error => this.setState({hasError: error}));
  }




  isLoggedInUser(){
    if(this.state.spieler!== undefined && this.state.spieler.nutzer!== undefined && this.props.loggedInUser !== undefined){
      if(this.state.spieler.nutzer.id === this.props.loggedInUser.id){
        return true;
      }
    }
    return false;
  }
  
  render() {

    const { punktspielSpielerListe, spieler } = this.state;
    const spielerId = this.props.spielerId;

    let zellen = [];
    if(this.props.punktspiele){
      for (let i = 0; i < this.props.punktspiele.length; i++) {
        let laufVar = this.props.punktspiele[i];
        let act = punktspielSpielerListe.filter(punktspielSpieler => punktspielSpieler.punktspiel.id === laufVar.id);
        
        if(act !=  null && act.length > 0){
          
          var punktspielSpieler = act[0];
          var zeitpunkt = moment(punktspielSpieler.letzteAenderung).format("DD.MM.YYYY HH:mm");
          
          if(punktspielSpieler.antwort === 'ZUGESAGT'){
            zellen.push(<img src={img_checked} className="img-responsive zugesagt-img" alt="hat zugesagt" title={"hat zugesagt am " + zeitpunkt}/>);
          }else if (punktspielSpieler.antwort === 'ABGESAGT'){
            zellen.push(<img src={img_cancelled} className="img-responsive zugesagt-img" alt="hat abgesagt" title={"hat abgesagt am " + zeitpunkt}/>);
          } else {
            zellen.push(<img src={img_unbekannt} className="img-responsive zugesagt-img" alt="hat abgestimmt" title={"hat abgestimmt am " + zeitpunkt}/>);
          }
        }else{
          zellen.push(<img src={img_null} className="img-responsive zugesagt-img" alt="hat noch nicht geantwortet" title="hat noch nicht geantwortet"/>);
        }
        
      }
    }
    
    return [
      !!spieler &&
        <tr key={'spielerZeile' + spieler.id} style={{backgroundColor: this.props.ersatz ? '#C4C8CC' : 'none'}}>
          <td key={'spielerName' + spieler.nickname} style={{textAlign: 'left'}}>
            {spieler.nickname}
          </td>
          
          {zellen.map((zelle, i) =>
            <td key={'spielerAntwort' + spieler.id + '_' + i}>{zelle}</td>
          )}
          
          <td>
            {!!this.state.loggedInUser && !!this.state.spieler.nutzer && (!!this.isLoggedInUser() || !!this.props.hasUserEditRights) &&
            <a href={'/punktspiele/edit/spieler/' + spielerId}
               className="btn btn-primary edit-button">
              <FontAwesomeIcon icon={faPen} />
            </a>
            }

          </td>
        </tr>
    ]
  }
}
// end::SpielerZeile[]

export default Punktspieluebersicht;
