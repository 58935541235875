import { request } from '../../util/APIUtils';
import {API_BASE_URL} from "../../constants";

export const abstimmungOptionService = {
    saveAbstimmungOption,
    getById,
    getAllOptionenForAbstimmung,
    deleteOptionById
};

export function saveAbstimmungOption(abstimmungOptionRequest) {
    return request({
        url: API_BASE_URL + "/abstimmungoption/save",
        method: 'POST',
        body: JSON.stringify(abstimmungOptionRequest)
    });
}

export function getById(abstimmungOptionId)
{
    return request({
        url: API_BASE_URL + "/abstimmungoption/"  + abstimmungOptionId,
        method: 'GET'
    });
}

export function getAllOptionenForAbstimmung(abstimmungId)
{
    return request({
        url: API_BASE_URL + "/abstimmungoption/all/"  + abstimmungId,
        method: 'GET'
    });
}

//loescht den Spieler und all seine Abhaengigkeiten (Punktspiel/Trainigns-Zusagen, Startseite, etc)
export function deleteOptionById(abstimmungOptionId)
{
    return request({
        url: API_BASE_URL + "/abstimmungoption/deleteById/"  + abstimmungOptionId,
        method: 'DELETE'
    });
}

