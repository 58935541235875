import React from 'react';
import {Form, Input, Select} from "antd";

import {userService} from "../../../../_services";
import {setErrorState} from "../../../../util/ErrorUtil";
import SuccessMessage from "../../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../../_components/notifications/ErrorMessage";
import {Button} from 'react-bootstrap';
import {doppelBewertungsService} from "../../../../_services/doppelBewertungsService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const FormItem = Form.Item;


class NutzerDoppelEinstellungen extends React.Component
{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            formIsValid: true,
            doppelBewertungen: [],
            bewertungToEdit: {},
            nutzerSelectItems: [],
            nutzer1: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            nutzer2: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            bewertung: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            id: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    formRef = React.createRef();
    
    componentDidMount()
    {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
        if(this.props.nutzer !== nextProps.nutzer && this.props.nutzer.id !== nextProps.nutzer.id) {
            // Reset State
            this.loadData();
        }
    }

    loadData(){
        this.loadNutzer();
        this.loadDoppelBewertungen();
    }
    

    loadNutzer(){
        const vereinId = this.props.match.params.vereinId;

        userService.getByVerein(vereinId)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({nutzerSelectItems: response});
                    }
                })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({hasError: error})
                }
            });
    }

    
    loadDoppelBewertungen(){
        const vereinId = this.props.match.params.vereinId;

        doppelBewertungsService.getByVereinId(vereinId)
            .then(response => {
                if (this._isMounted) {
                    this.setState({doppelBewertungen: response});
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({hasError: error})
                }
            });
    }
    
    handleInputChange(event, validationFun) {

        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }
    
    handleSelectInputChange(value, name) {
        this.setState({
            [name] : {
                value: value
            },
            //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
            //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
            successMsg: null,
        });
    }

    
    handleSubmit() {
        this.setState({
            errorMsg: null,
            successMsg: null
        });
        
        var doppelBewertungToSave = {
                nutzer1Id: this.state.nutzer1.value,
                nutzer2Id: this.state.nutzer2.value,
                bewertung: this.state.bewertung.value
            };

        console.log(doppelBewertungToSave);
        
        doppelBewertungsService.saveDoppelbewertung(doppelBewertungToSave)
            .then(response => {
                this.setState({
                    successMsg: "Doppel-Einstellung gespeichert.",
                    bewertungToEdit: {}
                });
                this.loadData();
                this.resetFields();
            }).catch(error => {
            setErrorState(error, this);
        });
    }

    onEditBewertung(bewertung){
        
        this.setState({
            bewertungToEdit: bewertung
        });
        this.setDefaultFormValuesForEditView(bewertung);
    }

    deleteBewertung(bewertungId){
        doppelBewertungsService.deleteById(bewertungId)
            .then(response => {
                this.setState({
                    successMsg: "Doppel-Einstellung wurde gelöscht."
                });
                this.loadData();
                this.resetFields();
            }).catch(error => {
            setErrorState(error, this);
        });
    }

    resetFields(){
        this.formRef.current.resetFields();
    }

    setDefaultFormValuesForEditView(bewertung){
        
        this.formRef.current.setFieldsValue({
            nutzer1: bewertung.nutzer1.id,
            nutzer2: bewertung.nutzer2.id,
            bewertung: bewertung.bewertung
        });
        
        if (this._isMounted) {
            this.setState({
                bewertungToEdit: bewertung,
                nutzer1: {
                    value: bewertung.nutzer1.id
                },
                nutzer2: {
                    value: bewertung.nutzer2.id
                },
                bewertung: {
                    value: bewertung.bewertung
                },
                id: bewertung.id
            });
        }


    }

    isNewAnsicht(){
        //dann handelt es sich um den Edit-Fall
        if(this.props.bewertungToEdit && this.props.bewertungToEdit.id > 0){
            return false;
        }else{
            //neu erstellen Fall
            return true;
        }
    }
    
    render()
    {
        const { doppelBewertungen, bewertungToEdit, nutzerSelectItems } = this.state;

        let nutzerItems = nutzerSelectItems.map((nutzerItem) =>
            <Select.Option value={nutzerItem.id} key={nutzerItem.id}>{nutzerItem.vorname} {nutzerItem.nachname}</Select.Option>
        );

        var initialValues = {};
        if(bewertungToEdit && bewertungToEdit.id > 0){
                initialValues={
                    "nutzer1": bewertungToEdit.nutzer1.id,
                    "nutzer2": bewertungToEdit.nutzer2.id,
                    "bewertung": bewertungToEdit.bewertung
                };
        }
        
        return (
            <React.Fragment>
                {!!nutzerSelectItems && !!nutzerSelectItems.length > 0 && 
                <div>
                    <h2>Doppel Einstellungen</h2>
                    
                    <Form onFinish={this.handleSubmit}
                          ref={this.formRef}
                          layout="inline"
                          initialValues={initialValues}>

                    {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                    }

                    {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                    }

                        <FormItem
                            label="Spieler"
                            name="nutzer1"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.nutzer1.validateStatus}
                            help={this.state.nutzer1.errorMsg}>

                            <Select
                                // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                                style={{width: '11em', fontWeight: 'normal'}}
                                size="large"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'nutzer1')}>
                                {nutzerItems}
                            </Select>
                        </FormItem>
                        
                        <FormItem
                            label="Spieler"
                            name="nutzer2"
                            rules={[{
                                required: true,
                                message: 'Bitte wähle einen Spieler aus'
                            }]}
                            validateStatus={this.state.nutzer2.validateStatus}
                            help={this.state.nutzer2.errorMsg}>

                            <Select
                                // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                                style={{width: '11em', fontWeight: 'normal'}}
                                size="large"
                                onChange={(value, event) => this.handleSelectInputChange(value, 'nutzer2')}>
                                {nutzerItems}
                            </Select>
                        </FormItem>
                            
                            <FormItem
                                label="Bewertung"
                                name="bewertung"
                                rules={[{
                                    required: true,
                                    message: 'Bitte gib eine Bewertung zwischen -3 und 3 an.'
                                }]}
                                hasFeedback
                                validateStatus={this.state.bewertung.validateStatus}
                                help={this.state.bewertung.errorMsg}>
                                <Input
                                    size="large"
                                    name="bewertung"
                                    placeholder="Zahl zwischen -3 und 0"
                                    value={this.state.bewertung.value}
                                    onChange={(event) => this.handleInputChange(event, this.validateBewertung)}/>
                            </FormItem>

                    <FormItem>
                        <Button 
                            type="submit"
                            className="myLargeBtn"
                            size="large"
                            style={{marginLeft: '1em'}}>Speichern</Button>
                    </FormItem>
                    <br/>

                </Form>
                    
                    <div id="bewertungsList">
                        {!!doppelBewertungen && !!doppelBewertungen.length > 0 &&
                        <table className="table table-hover">
                            <tbody>
                            {doppelBewertungen.map((bewertung) =>
                                <tr key={'doppelBewertung_' + bewertung.id}>
                                    <td>{bewertung.nutzer1.vorname} {bewertung.nutzer1.nachname}</td>
                                    <td>{bewertung.nutzer2.vorname} {bewertung.nutzer2.nachname}</td>
                                    <td>{bewertung.bewertung}</td>
                                    <td>
                                        <Button
                                            className="btn btn-primary edit-button"
                                            onClick={()=>this.onEditBewertung(bewertung)}>
                                            <FontAwesomeIcon icon={faPen}  />
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            className="btn btn-secondary delete-button"
                                            onClick={()=>this.deleteBewertung(bewertung.id)}>
                                            <FontAwesomeIcon icon={faTrashAlt}  />
                                        </Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        }
                    </div>
                </div>
                }
            </React.Fragment>
        );
    }

    
    validateBewertung = (bewertung) => {
        if(!bewertung) {
            return {
                validateStatus: 'error',
                errorMsg: 'Bitte gib eine Bewertung zwischen -3 und 3 an.'
            }
        }

        if(isNaN(bewertung)){
            return {
                validateStatus: 'error',
                errorMsg: `Die Bewertung muss eine Ganzzahl zwischen -3 und 3 sein.`
            }
        }

        if(bewertung > 5 || bewertung < -5){
            return {
                validateStatus: 'error',
                errorMsg: `Die Bewertung muss eine Ganzzahl zwischen -3 und 3 sein.`
            }
        }

        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }
}


export default NutzerDoppelEinstellungen;