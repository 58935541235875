import React, {Component} from 'react';
import {acceptInvitation} from '../../_services/authService';
import './Signup.css';
import {setErrorState} from '../../util/ErrorUtil';

import {Link} from 'react-router-dom';
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, USERNAME_MAX_LENGTH} from '../../constants';

import {Button} from 'react-bootstrap';
//import { Form, Input, Button, notification } from 'antd';
import {Checkbox, Form, Input, notification} from 'antd';

const FormItem = Form.Item;

class Signup extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            disableSubmitButton: false,
            nutzer: this.props.nutzer,
            vorname: {
                value: this.props.nutzer.vorname
            },
            nachname: {
                value: this.props.nutzer.nachname
            },
            nickname: {
                value: this.props.nutzer.nickname
            },
            email: {
                value: '',
                validateStatus: 'success',
                errorMsg: null
            },
            password: {
                value: ''
            },
            isDatenschutzZugestimmt: {
                value: false
            }
        }


        this.handleInputChange = this.handleInputChange.bind(this);
        this.doConfirmationPostRequest = this.doConfirmationPostRequest.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
        this.validateFormFields = this.validateFormFields.bind(this);

    }

    componentDidMount() {
        this.validateFormFields();
    }
    
    handleInputChange(event, validationFun, label) {

        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue, label)
            }
        });
    }

    handleCheckboxChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;
        const checked = target.checked;

        this.setState({
            [inputName] : {
                value: checked,
                ...validationFun(checked)
            }
        });
    }

    doConfirmationPostRequest() {
        //holt das Token aus dem Request-Parameter der URL raus
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token')

        const signUpConfirmRequest = {
            registrationtoken: token,
            vorname: this.state.vorname.value,
            nachname: this.state.nachname.value,
            nickname: this.state.nickname.value,
            password: this.state.password.value
        };
        acceptInvitation(signUpConfirmRequest)
            .then(response => {
                notification.success({
                    message: 'Mannschatsplanung',
                    description: "Du hast dich erfolgreich registriert.",
                });

                this.setState({
                    registrationStatus: 'success'
                });
                //this.props.history.push("/login")
                //keine Ahnung warum hier nicht this.props.history.push funktioniert
                window.location.href = '/signupConfirm';

            }).catch(error => {
            setErrorState(error, this);
            this.setState({
                registrationStatus: 'error',
                error: error
            });
        });
    }

    validateFormFields(){
        //muss so gemacht werden, wegen den inital Values. Da diese sonst validateStatus undefined haben und der Submit-Button disabled ist.
        // (andere Variante wäre, oben im Konstruktor den validateStatus auf success stellen, aber das ist ja auch doof. Ausserdem koennte die View ja auch wiederverwendet werden,
        // fuer eine normale Registrierung.
        const vornameResult = this.validateName(this.state.vorname.value, "Vorname");
        this.setState({
            vorname : {
                validateStatus: vornameResult.validateStatus,
                errorMsg: vornameResult.errorMsg
            }
        });

        const nachnameResult = this.validateName(this.state.nachname.value, "Nachname");
        this.setState({
            nachname : {
                validateStatus: nachnameResult.validateStatus,
                errorMsg: nachnameResult.errorMsg
            }
        });

        const nicknameResult = this.validateName(this.state.nickname.value, "Nickname");
        this.setState({
            nickname : {
                validateStatus: nicknameResult.validateStatus,
                errorMsg: nicknameResult.errorMsg
            }
        });
    }
    
    isFormInvalid() {
        var invalid = this.areFormInputsInvalid();
        return invalid || !(this.state.isDatenschutzZugestimmt.validateStatus === 'success');
    }

    areFormInputsInvalid(){
        return !(this.state.vorname.validateStatus === 'success' &&
            this.state.nachname.validateStatus === 'success' &&
            this.state.nickname.validateStatus === 'success' &&
            this.state.password.validateStatus === 'success'
        );
    }

    toButtonDisable() {
        return (this.areFormInputsInvalid());
    }

    render() {
        const { nutzer } = this.state;
        
        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };

        const tailLayout = {
            wrapperCol: {
                offset: 8,
                span: 16,
            },
        };

        return (
            <div className="signup-container">
                <h1 className="page-title">Registrierung</h1>
                {!!nutzer && !!nutzer.id &&
                <div className="signup-content">
                    <Form {...layout} onFinish={this.doConfirmationPostRequest} className="signup-form"
                          initialValues={{
                              "vorname": this.props.nutzer.vorname,
                              "nachname": this.props.nutzer.nachname,
                              "nickname": this.props.nutzer.nickname,
                              "email": this.props.nutzer.email
                          }}
                    >
                        <FormItem
                            label="Vorname"
                            name="vorname"
                            rules={[{
                                required: true,
                                message: 'Bitte gib deinen Vornamen an'
                            }]}
                            hasFeedback
                            validateStatus={this.state.vorname.validateStatus}
                            help={this.state.vorname.errorMsg}>
                            <Input
                                size="large"
                                name="vorname"
                                autoComplete="on"
                                placeholder="Dein Vorname"
                                value={this.state.vorname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateName, "Vorname")}/>
                        </FormItem>
                        <FormItem label="Nachname"
                                  name="nachname"
                                  rules={[{
                                      required: true,
                                      message: 'Bitte gib deinen Nachnamen an'
                                  }]}
                                  hasFeedback
                                  validateStatus={this.state.nachname.validateStatus}
                                  help={this.state.nachname.errorMsg}>
                            <Input
                                size="large"
                                name="nachname"
                                autoComplete="on"
                                placeholder="Dein Nachname"
                                value={this.state.nachname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateName, "Nachname")}/>
                        </FormItem>
                        <FormItem label="Nickname"
                                  name="nickname"
                                  rules={[{
                                      required: true,
                                      message: 'Bitte gib deinen Nicknamen an'
                                  }]}
                                  hasFeedback
                                  validateStatus={this.state.nickname.validateStatus}
                                  help={this.state.nickname.errorMsg}>
                            <Input
                                size="large"
                                name="nickname"
                                autoComplete="on"
                                placeholder="Dein Nickname"
                                value={this.state.nickname.value}
                                onChange={(event) => this.handleInputChange(event, this.validateName, "Nickname")}/>
                        </FormItem>
                        <FormItem
                            label="E-Mail"
                            name="email"
                            rules={[{
                                required: true,
                                message: 'Bitte gib deinen E-Mail-Adresse an'
                            }]}
                            hasFeedback
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input
                                size="large"
                                name="email"
                                type="email"
                                autoComplete="on"
                                disabled={true}
                                placeholder="Deine Email-Adresse"
                                value={this.state.email.value}/>
                        </FormItem>
                        <FormItem
                            label="Passwort"
                            name="password"
                            rules={[{
                                required: true,
                                message: 'Bitte gib ein Passwort an'
                            }]}
                            validateStatus={this.state.password.validateStatus}
                            help={this.state.password.errorMsg}>
                            <Input
                                size="large"
                                name="password"
                                type="password"
                                autoComplete="off"
                                placeholder="Passwort zwischen 6 und 20 Zeichen"
                                value={this.state.password.value}
                                onChange={(event) => this.handleInputChange(event, this.validatePassword)}/>
                        </FormItem>
                        <FormItem className={"verticalAlignCeckbox"}
                                  name="isDatenschutzZugestimmt"
                                  valuePropName="checked"
                                  initialValue={false}
                                  rules={[{
                                      required: true,
                                      transform: value => (value || undefined),  // Those two lines
                                      type: 'boolean',                           // Do the magic
                                      message: 'Bitte stimme den Datenschutzrichtlinien zu'
                                  }]}
                                  hasFeedback
                                  validateStatus={this.state.isDatenschutzZugestimmt.validateStatus}
                                  help={this.state.isDatenschutzZugestimmt.errorMsg}
                                  onChange={(event) => this.handleCheckboxChange(event, this.validateDatenschutzRichtlinien)}>
                            <Checkbox>Ja, ich stimme den <Link
                                to="/impressum">Datenschutzrichtlinien</Link> zu.</Checkbox>
                        </FormItem>
                        <FormItem {...tailLayout}>
                            <Button type="submit"
                                    size="large"
                                    className="myLargeBtn"
                                    disabled={this.toButtonDisable() || this.state.disableSubmitButton}>Konto
                                erstellen</Button>
                        </FormItem>
                    </Form>
                </div>
                }
            </div>
        );
    }

    validateName = (name, label) => {
        if(!name) {
            return {
                validateStatus: 'error',
                errorMsg: `Bitte gib deinen ${label}n an. Die Angabe des ${label}s ist pflicht.`
            }
        } else if (name.length > USERNAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Der ${label} ist zu lang. Es sind maximal ${USERNAME_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null
            }
        }
    }
    validateDatenschutzRichtlinien = (isZugestimmt) => {
        if(isZugestimmt === undefined || !isZugestimmt) {
            return {
                validateStatus: 'error',
                errorMsg: `Um die registrieren zu können, stimme bitte den Datenschutzrichtlinien zu.`
            }
        }

        return {
            validateStatus: 'success',
            errorMsg: null
        }
    }


    validatePassword = (password) => {
        if(password.length < PASSWORD_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Das Passwort ist zu kurz. Das Passwort muss mindestens aus ${PASSWORD_MIN_LENGTH} Zeichen bestehen.`
            }
        } else if (password.length > PASSWORD_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Das Passwort ist zu lang. Es sind maximal ${PASSWORD_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
    }

}

export default Signup;