import './jederGegenJedenTraining.css';

import React from 'react';
import {matchEinzelService} from "../../_services/matchEinzelService";
import {setErrorState} from "../../util/ErrorUtil";
import JederGegenJedenComponent from "./jeder_gegen_jeden_component";
import {matchDoppelService} from "../../_services/matchDoppelService";
import FilterFrom from "../statistik/filter/filterForm";
import TrainingComment from "../../training/training_comment";
import {trainingsService} from "../../_services/trainingsService";
import BackButton from "../../common/BackButton";
import TrainingsplanungRunden from "../../training/planung/trainingsplanung_runden";
import {Collapse, Form, Tabs} from "antd";
import {trainingsplanungEinzelService} from "../../_services/training/planung/trainingsplanungEinzelService";
import {trainingsplanungDoppelService} from "../../_services/training/planung/trainingsplanungDoppelService";
import {Button, Nav} from "react-bootstrap";
import {trainingsplanungRundenService} from "../../_services/training/planung/trainingsplanungRundenService";

const { Panel } = Collapse;
const { TabPane } = Tabs;

class JederGegenJedenTraining extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        const filter = {
            satzFilter: null,
            nutzerFilter: null,
            datumFilterAb: "01.09.2023",
            datumFilterBis: null
        };

        this.state = {
            filter: filter,
            planungEinzelStatistik: [],
            planungDoppelStatistik: [],
            einzelStatistik: [],
            doppelStatistik: [],
            training: null,
        };
        
        this.loadStatistik = this.loadStatistik.bind(this);
        this.loadTrainingById = this.loadTrainingById.bind(this);
        this.handleFitler = this.handleFitler.bind(this);
    }

    loadData(){
        this.loadStatistik();
        this.loadTrainingById();
    }

    loadStatistik(){
        const filter = this.state.filter;
        if(this.props.match.params.trainingsId > 0){
            //Planungs-Statistik
            //Das ist die Statistik, von den Spielplänen die ich für jedes Training erstelle
            trainingsplanungEinzelService.getJederGegenJederStatistikForTraining(this.props.match.params.trainingsId, filter)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            planungEinzelStatistik: response
                        });
                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        setErrorState(error, this);
                        console.log('error');
                        console.log(error);
                    }
                });


            trainingsplanungDoppelService.getJederMitJedemStatistikForTraining(this.props.match.params.trainingsId, filter)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            planungDoppelStatistik: response
                        });

                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        setErrorState(error, this);
                    }
                });
            
            //Match-Statistik
            //das hier sind die Match Statistiken. Also von wirklich eingetragenen Matches
            matchEinzelService.getJederGegenJederStatistikForTraining(this.props.match.params.trainingsId, filter)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            einzelStatistik: response
                        });
                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        setErrorState(error, this);
                        console.log('error');
                        console.log(error);
                    }
                });


            matchDoppelService.getJederMitJedemStatistikForTraining(this.props.match.params.trainingsId, filter)
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            doppelStatistik: response
                        });

                    }
                })
                .catch(error => {
                    if (this._isMounted) {
                        setErrorState(error, this);
                    }
                });
        }
    }


    loadTrainingById() {
        trainingsService.getTrainingById(this.props.match.params.trainingsId)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        training: response
                    });
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps, nextState) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }

        if(this.state.trainingsId !== nextState.trainingsId) {
            // Reset State
            this.loadData();
        }

        if(this.state.filter !== nextState.filter) {
            // Reset State
            this.loadData();
        }
    }
    
    handleFitler(filter){
        this.setState({
            filter: filter
        });
    }

    migrate(){
        trainingsplanungRundenService.migrate();    
    }

    savetest(){
        trainingsplanungRundenService.save_test();
    }
    
    render() {
        const { planungEinzelStatistik, planungDoppelStatistik, einzelStatistik, doppelStatistik, training } = this.state;

        return (
            <div>

                <Tabs type="card" size="large" style={{ margin: '0.5em' }}>
                    <TabPane tab="Spiel Statistik" key="1" style={{ margin: '0.5em' }}>
                            <p>
                                Hier findest du eine Übersicht der Spielpläne, die für das Training erstellt wurden.
                            </p>

                            <br/>

                            <div id="filter-container">
                                <FilterFrom filterData={this.state.filter} onFitler={this.handleFitler} showSpielerFilter={false}/>
                            </div>
                            <br/>


                            <h3>Einzel</h3>
                            {!!einzelStatistik &&
                            <JederGegenJedenComponent einzelStatistik={planungEinzelStatistik} isDoppel={false} onlyPlanung={true}/>
                            }

                            <h3>Doppel</h3>

                            {!!doppelStatistik &&
                            <JederGegenJedenComponent einzelStatistik={planungDoppelStatistik} isDoppel={true} onlyPlanung={true}/>
                            }
                    </TabPane>
                    <TabPane tab="Match Statistik" key="2" style={{ margin: '1em' }}>
                        <p>
                            Hier findest du eine Übersicht der Match-Bilanzen der Spieler, die aktuell für das Training zugesagt haben.
                        </p>


                        <br/>

                        <div id="filter-container">
                            <FilterFrom filterData={this.state.filter} onFitler={this.handleFitler} showSpielerFilter={false}/>
                        </div>
                        <br/>


                        <h3>Einzel</h3>
                        {!!einzelStatistik &&
                        <JederGegenJedenComponent einzelStatistik={einzelStatistik} isDoppel={false} onlyPlanung={false}/>
                        }

                        <h3>Doppel</h3>

                        {!!doppelStatistik &&
                        <JederGegenJedenComponent einzelStatistik={doppelStatistik} isDoppel={true} onlyPlanung={false}/>
                        }
                    </TabPane>
                </Tabs>
                


 
                {!!training &&
                <div>
                    <br/>
                    <TrainingsplanungRunden trainingstag={training}/>
                    <br/>
                </div>
                }
                
                {!!training &&
                    <div>
                        <br/>
                        <TrainingComment trainingstag={training}/><BackButton/>
                        <br/>
                    </div>
                }
            </div>

        );
    }
}


export default JederGegenJedenTraining;