// DoppelaufstellungenListe

import './doppelaufstellungCell.css';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faSort, faStar} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

class DoppelaufstellungCell extends React.Component{
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            pinned: false,
            banned: false
        };
        this.handlePin = this.handlePin.bind(this);
        this.handleBan = this.handleBan.bind(this);
    }
    
    componentDidMount() {
        this._isMounted = true;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    getStyleClass(doppelPaarung){
        return {
            backgroundColor: `${doppelPaarung.getBackgroudnColor()}`
        };
    }

    getTauschbarIcon(doppelAufstellung, doppelPaarung){
        if(doppelAufstellung.alternativeAufstellungen && doppelAufstellung.alternativeAufstellungen.length > 0){

            if(doppelAufstellung.isDoppel1Switchable(doppelPaarung)){
                return <FontAwesomeIcon className='rotate-icon' icon={faSort} />;
            }
        }
        return '';
    }

    handlePin(e){
        e.preventDefault();

        this.props.onPinItem(this.props.doppelAufstellung);
    }
    


    handleBan(e){
        e.preventDefault();

        this.props.onBanItem(this.props.doppelAufstellung);
    }

    getStyleClassForPinButton(){
        let styleClassBtn = 'doppelaufstellung-btn';
        let  styleClassPinnedBtn = styleClassBtn;
        if(this.state.pinned){
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-pinned';
        }else{
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-secondary';
        }
        return styleClassPinnedBtn;
    }

    getStyleClassForBanButton(){
        let styleClass = 'btn btn-secondary doppelaufstellung-btn';
        if(this.state.banned){
            styleClass = styleClass + ' banned_doppel';
        }
        return styleClass;
    }
    
    render() {
        const doppelAufstellung = this.props.doppelAufstellung;
        //ich konnte pinned und banned nicht hier im State halten, da das mit dem hin und her sortieren (pinnen und bannen) nicht funktioniert hat
        //der Style schien irgendwie immer an der Position zu haengen. Das heisst das weg ge-swichte Doppel hat immer seinen Style behalten und das Doppel,
        //das augerueckt ist, hat dann den pinned oder banned style bekommen....
        const pinned = this.props.doppelAufstellung.pinned;
        const banned = this.props.doppelAufstellung.banned;
        
        //Die Buttons
        let styleClassBtn = 'doppelaufstellung-btn';
        let  styleClassPinnedBtn = styleClassBtn;
        let  styleClassBannedBtn = styleClassBtn;
        
        //Die Box an sich
        let styleClasses = doppelAufstellung.getStyleClassForDoppel() + ' doppelaufstellung-box';
        
        if(pinned){
            styleClasses = styleClasses + ' pinned_doppel';
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-pinned';
        }else{
            styleClassPinnedBtn = styleClassPinnedBtn + ' btn-secondary';
        }

        if(banned){
            styleClasses = styleClasses + ' banned_doppel';
            styleClassBannedBtn = styleClassBannedBtn + ' btn-banned';
        }else{
            styleClassBannedBtn = styleClassBannedBtn + ' btn-secondary';
        }



        
        return (
            <React.Fragment>
                <div key={doppelAufstellung.id + '_items'} className={styleClasses} >
                    <table className='doppelaustellung-table'>
                        <tbody>
                        <tr>
                            <td style={{textAlign: 'center'}}>
                                <Button
                                    className={styleClassPinnedBtn}
                                    style={{float: 'left'}}
                                    onClick={e => this.handlePin(e)}>
                                    <FontAwesomeIcon className='doppel-header-icon' icon={faStar} />
                                </Button>
                                
                                <Button
                                    className={styleClassBannedBtn}
                                    style={{float: 'right'}}
                                    onClick={e => this.handleBan(e)}>
                                    <FontAwesomeIcon className='doppel-header-icon' icon={faBan} />
                                </Button>
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}}>
                                <div style={{fontWeight: 'bold', float: 'left'}} className='doppel-austellung-size'>
                                    <span className={doppelAufstellung.doppel1.getBackgroudnColor() + ' doppel-aufstellung'}>{doppelAufstellung.doppel1.positionsSumme} {doppelAufstellung.doppel1.getDisplayLabel()} {this.getTauschbarIcon(doppelAufstellung, doppelAufstellung.doppel1)}</span>

                                    <span className={doppelAufstellung.doppel2.getBackgroudnColor() + ' doppel-aufstellung'}>{doppelAufstellung.doppel2.positionsSumme} {doppelAufstellung.doppel2.getDisplayLabel()} {this.getTauschbarIcon(doppelAufstellung, doppelAufstellung.doppel2)}</span>

                                    <span className={doppelAufstellung.doppel3.getBackgroudnColor() + ' doppel-aufstellung'}>{doppelAufstellung.doppel3.positionsSumme} {doppelAufstellung.doppel3.getDisplayLabel()} {this.getTauschbarIcon(doppelAufstellung, doppelAufstellung.doppel3)}</span>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }
}

export default DoppelaufstellungCell;