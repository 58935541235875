import React from 'react'

import {trainingsService} from "../../_services/trainingsService";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState} from "../../util/ErrorUtil";
import {spielerService} from "../../_services/spielerService";
import {trainingsplanungRundenService} from "../../_services/training/planung/trainingsplanungRundenService";
import TrainingsplanungRundenEdit from "./trainingsplanung_runden_edit";

class TrainingsplanungRunden extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      trainingsplanungRunden: [],
      trainingsplanungRundenLoaded: false,
      vereinsSpielerDistinctNutzer: [],
      successMsg: '',
      errorMsg: ''
    };

    this.reloadRunden = this.reloadRunden.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadAllSpieler();
    this.loadTrainingsplanungRunden();
  }


  loadAllSpieler(){
    const verein = this.props.trainingstag.mannschaft.verein;

    if(verein && verein.id){
      //liefert eine Liste von Spielern des Vereins zurueck, wobei es hier eher auf die Nutzer ankommt
      //Spieler, die demselben Nutzer angehoeren werden auf nur einen Spieler reduziert.
      //Das Match-Ergebnis wird naemlich am Nutzer gespeichert.
      spielerService.getDistinctSpielerForNutzerByVerein(verein.id)
          .then(response => {

            if (this._isMounted) {
              this.setState({
                vereinsSpielerDistinctNutzer: response
              });
            }
          })
          .catch(error => {
            if (this._isMounted) {
              setErrorState(error, this);
            }
          });
    }
  }

  loadTrainingsplanungRunden() {
    trainingsplanungRundenService.getRundenByTrainingstagId(this.props.trainingstag.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              trainingsplanungRunden: response,
              trainingsplanungRundenLoaded: true,
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
            this.setState({
              trainingsplanungRundenLoaded: true,
            });
          }
        });
  }

  reloadRunden(){
    this.loadTrainingsplanungRunden();
  }


  render()
  {
    const { trainingsplanungRundenLoaded, vereinsSpielerDistinctNutzer } = this.state;
    const trainingstag = this.props.trainingstag;
  
    return (
        <React.Fragment>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
        {!!trainingstag && !!trainingstag.id > 0 && !!trainingsplanungRundenLoaded && !!vereinsSpielerDistinctNutzer && !!vereinsSpielerDistinctNutzer.length > 0 &&
            <TrainingsplanungRundenEdit trainingstag={trainingstag} runden={this.state.trainingsplanungRunden} vereinsSpielerDistinctNutzer={vereinsSpielerDistinctNutzer} onReloadRunden={this.reloadRunden}/>
        }
        </React.Fragment>
    );
  }

}


export default TrainingsplanungRunden;
