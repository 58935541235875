import { request } from '../../util/APIUtils';
import {API_BASE_URL} from "../../constants";

export const abstimmungService = {
    getAllAbstimmungenForVerein,
    getAbstimmungById,
    saveAbstimmung,
    saveKommentarOnAbstimmung,
    updateAllgemeineEinstellungen,
    getAllByVereinForNavigation,
    getNutzerForVereinMinusMannschaftsspieler
};

export function getAllAbstimmungenForVerein(vereinId)
{
    return request({
        url: API_BASE_URL + "/abstimmung/all/"  + vereinId,
        method: 'GET'
    });
}

export function getAbstimmungById(abstimmungId)
{
    return request({
        url: API_BASE_URL + "/abstimmung/"  + abstimmungId,
        method: 'GET'
    });
}

export function saveAbstimmung(abstimmungRequest) {
    return request({
        url: API_BASE_URL + "/abstimmung/save",
        method: 'POST',
        body: JSON.stringify(abstimmungRequest)
    });
}

export function saveKommentarOnAbstimmung(abstimmungKommentarRequest) {
    return request({
        url: API_BASE_URL + "/abstimmung/saveKommentar",
        method: 'POST',
        body: JSON.stringify(abstimmungKommentarRequest)
    });
}

export function updateAllgemeineEinstellungen(abstimmungRequest) {
  return request({
    url: API_BASE_URL + "/abstimmung/updateAllgemeineEinstellungen",
    method: 'POST',
    body: JSON.stringify(abstimmungRequest)
  });
}

//loescht die Abstimmung und all seine Abhaengigkeiten (Optionen/Teilnhemer, Antworten, etc)
export function deleteAbstimmungById(abstimmung)
{
    return request({
        url: API_BASE_URL + "/abstimmung/delete/"  + abstimmung.id,
        method: 'DELETE'
    });
}

export function getAllByVereinForNavigation(nutzerId)
{
    return request({
        url: API_BASE_URL + "/abstimmung/navigation?nutzerId=" + nutzerId,
        method: 'GET'
    });
}

//Liefert eine Liste aller Nutzer des Vereins, abzüglich der Nutzer, die bereits einem Spieler zugeordnet sind, der bereits in der Mannschaft sind.
export function getNutzerForVereinMinusMannschaftsspieler(abstimmungId,  page, size) {
    return request({
        url: API_BASE_URL + "/abstimmung/listforteilnehmeradd/" + abstimmungId + "?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

