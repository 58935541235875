import React from 'react'
import {setErrorState} from '../../util/ErrorUtil';
import PunktspielEditComponent from "./punktspiel_edit_component";
import {getMannschaftById} from "../../_services/mannschatsService";


class PunktspielNew extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {}
    };
    this.loadMannschaft = this.loadMannschaft.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.punktspielId !== nextProps.punktspielId) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.loadMannschaft();
    }
  }

  loadMannschaft() {

    //url Parameter
    const mannschaftId = this.props.match.params.mannschaftId;

    getMannschaftById(mannschaftId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              mannschaft: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  
  render()
  {
    const mannschaft = this.state.mannschaft;
    
    return (
        <React.Fragment>
        {!!mannschaft && !!mannschaft.id &&
          <PunktspielEditComponent mannschaft={mannschaft} isNewAnsicht={true}/>
         }
        </React.Fragment>
    );
  }

}

export default PunktspielNew;


