import React, {Component} from 'react';

import {getCurrentUser, userService} from "../_services";
import DefaultStartseite from "./DefaultStartseite";
import {getRedirectUrlForNutzerInfo} from "../util/StartseiteUtil";


class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInUser: {},
            startseite: {},
            nutzerRedirectInfoAndUserLoaded: false,
        };
    }

    componentDidMount()
    {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            //this.loadData();
        }
    }

    loadData(){
        this.loadLoggedInUser();
    }
    
    loadLoggedInUser(){
        getCurrentUser()
            .then(response => {
                this.setState({
                    loggedInUser: response
                });

                if(response && response.id > 0){
                    userService.getAllRedirectInformationForNutzer(response.id)
                        .then(responseNutzerRedirectInfo => {
                            this.setState({
                                nutzerRedirectInfo: responseNutzerRedirectInfo,
                                nutzerRedirectInfoAndUserLoaded: true,
                            });

                            if(responseNutzerRedirectInfo){
                                this.redirectToStartseite(responseNutzerRedirectInfo);
                            }
                        }).catch(error => {
                        this.setState({
                            nutzerRedirectInfoAndUserLoaded: true
                        });
                    });
                        
                }else{
                    this.setState({
                        nutzerRedirectInfoAndUserLoaded: true
                    });
                }
            }).catch(error => {
                this.setState({
                    nutzerRedirectInfoAndUserLoaded: true
                });
            });
            //Fehler sind voellig egal. Bei der Startseite wird das ignoriert. Dann wird halt einfach nicht die persoenlich Startseite angezeigt.
    }

    redirectToStartseite(startseite){
        //redirect
        const redirectTo = this.getRedirectPathFromStartseite(startseite);
        this.props.history.push(redirectTo);
    }

    getRedirectPathFromStartseite(startseite){
        var homeLink = getRedirectUrlForNutzerInfo(startseite);
        
        if(homeLink === undefined){
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            return from;
        }

        return homeLink;
    }
    
    render() {
        
        return (
            <React.Fragment>
                {!!this.state.nutzerRedirectInfoAndUserLoaded &&
                    <DefaultStartseite/>
                }               
            </React.Fragment>
            
        );
    }
}


export default Home;