import { request } from '../../util/APIUtils';
import {API_BASE_URL} from "../../constants";

export const terminService = {
    getAllTermineForVerein,
    getTerminById,
    saveTermin,
    getAllByNutzer,
    updateAllgemeineEinstellungen,
    deleteTerminById,
    getNutzerForVereinMinusMannschaftsspieler,
};

export function getAllTermineForVerein(vereinId)
{
    return request({
        url: API_BASE_URL + "/termin/all/"  + vereinId,
        method: 'GET'
    });
}

export function getTerminById(terminId)
{
    return request({
        url: API_BASE_URL + "/termin/"  + terminId,
        method: 'GET'
    });
}

export function saveTermin(terminRequest) {
    return request({
        url: API_BASE_URL + "/termin/save",
        method: 'POST',
        body: JSON.stringify(terminRequest)
    });
}

export function updateAllgemeineEinstellungen(terminRequest) {
  return request({
    url: API_BASE_URL + "/termin/updateAllgemeineEinstellungen",
    method: 'POST',
    body: JSON.stringify(terminRequest)
  });
}

//loescht die Abstimmung und all seine Abhaengigkeiten (Optionen/Teilnhemer, Antworten, etc)
export function deleteTerminById(termin)
{
    return request({
        url: API_BASE_URL + "/termin/delete/"  + termin.id,
        method: 'DELETE'
    });
}

export function getAllByNutzer(nutzerId)
{
    return request({
        url: API_BASE_URL + "/termin/nutzer?nutzerId=" + nutzerId,
        method: 'GET'
    });
}

//Liefert eine Liste aller Nutzer des Vereins, abzüglich der Nutzer, die bereits einem Spieler zugeordnet sind, der bereits in der Mannschaft sind.
export function getNutzerForVereinMinusMannschaftsspieler(terminId,  page, size) {
    return request({
        url: API_BASE_URL + "/termin/listforteilnehmeradd/" + terminId + "?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

