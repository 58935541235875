import React from "react";
import GastHeimDoppel from "../../dto/match/GastHeimDoppel";
import GastHeimEinzel from "../../dto/match/GastHeimEinzel";

export default function GastHeimLabel(props) {
    const gastHeim = props.gastHeim;
    
    if(gastHeim instanceof GastHeimDoppel){
        return <span>{gastHeim.getLabelNuter1()}<br/>{gastHeim.getLabelNuter2()}</span>;
    }

    if(gastHeim instanceof GastHeimEinzel){
        return <span>{gastHeim.getLabel()}</span>;
    }

    return null;
}
