import React, { Component } from 'react';
import { resetPassword } from '../../_services/authService';
import { setErrorState } from '../../util/ErrorUtil';

import {
    PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH
} from '../../constants';

import { Button } from 'react-bootstrap';
import { Form, Input } from 'antd';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

const FormItem = Form.Item;


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            successMsg: '',
            errorMsg: '',
            pwdtoken: {
                value: ''
            },
            password: {
                value: ''
            },
            passwordRepeat: {
                value: ''
            }            
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
    }
    
    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    handleSubmit(event) {

        //holt das Token aus dem Request-Parameter der URL raus
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token')
        
        const resetForgottenRequest = {
            pwdtoken: token,
            password: this.state.password.value,
            passwordRepeat: this.state.passwordRepeat.value
        };
        resetPassword(resetForgottenRequest)
        .then(response => {
            this.setState({
                successMsg: "Dein Passwort wurde zurückgesetzt."
            });
            
            this.props.history.push("/login")

        }).catch(error => {
            setErrorState(error, this);
        });
    }

    isFormInvalid() {        
        return !(this.state.password.validateStatus === 'success' &&
            this.state.passwordRepeat.validateStatus === 'success');
    }

    render() {
        return (
            <div className="signup-container">
                <h1 className="page-title">Neues Passwort setzen</h1>
                <div className="signup-content">
                    {!!this.state.successMsg &&
                    <SuccessMessage message={this.state.successMsg}/>
                    }

                    {!!this.state.errorMsg &&
                    <ErrorMessage message={this.state.errorMsg}/>
                    }

                    
                    <Form onFinish={this.handleSubmit} className="signup-form">
                        <FormItem
                            label="neues Passwort"
                            validateStatus={this.state.password.validateStatus}
                            help={this.state.password.errorMsg}>
                            <Input
                                size="large"
                                name="password"
                                type="password"
                                autoComplete="off"
                                placeholder="Passwort zwischen 6 und 20 Zeichen"
                                value={this.state.password.value}
                                onChange={(event) => this.handleInputChange(event, this.validatePassword)} />
                        </FormItem>
                        <FormItem
                            label="Passwort bestätigen"
                            validateStatus={this.state.passwordRepeat.validateStatus}
                            help={this.state.passwordRepeat.errorMsg}>
                            <Input
                                size="large"
                                name="passwordRepeat"
                                type="password"
                                autoComplete="off"
                                placeholder="Passwort zwischen 6 und 20 Zeichen"
                                value={this.state.passwordRepeat.value}
                                onChange={(event) => this.handleInputChange(event, this.validatePassword)}
                                onBlur={(event) => this.handleInputChange(event, this.validatePasswordRepeat)}/>
                        </FormItem>
                        <FormItem>
                            <Button type="submit"
                                size="large" 
                                className="signup-form-button"
                                disabled={this.isFormInvalid()}>Passwort speichern</Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }

    // Validation Functions
    validatePassword = (password) => {
        if(password.length < PASSWORD_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Das Passwort ist zu kurz. Das Passwort muss mindestens aus ${PASSWORD_MIN_LENGTH} Zeichen bestehen.`
            }
        } else if (password.length > PASSWORD_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Das Passwort ist zu lang. Es sind maximal ${PASSWORD_MAX_LENGTH} Zeichen erlaubt.`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
    }

    validatePasswordRepeat = () => {
        const password = this.state.password.value;
        const passwordRepeat = this.state.passwordRepeat.value;
        
        if(passwordRepeat !== password) {
            return {
                validateStatus: 'error',
                errorMsg: `Die Passwörter stimmen nicht überein!`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
    }   


}

export default ResetPassword;