export function getRedirectUrlForNutzerInfo(nutzerRedirectInfo) {
  
    return getRedirectPathFromNutzerInfo(nutzerRedirectInfo);
}

function getRedirectPathFromNutzerInfo(nutzerRedirectInfo){
  const abstimmungRedirectURL = getRedirectPathFromAbstimmung(nutzerRedirectInfo.abstimmung);
  var redirectURL = abstimmungRedirectURL;
  if(!abstimmungRedirectURL || abstimmungRedirectURL === undefined){
    return getRedirectPathFromStartseite(nutzerRedirectInfo.startseite);
  }
  return redirectURL;
}

function getRedirectPathFromAbstimmung(abstimmung){
  //Wenn der Nutzer keine Startseite eingetragen hat
  if(!abstimmung || abstimmung.id === undefined){
    return undefined;
  }

    return '/abstimmung/view/' + abstimmung.id;
}

function getRedirectPathFromStartseite(startseite){
  //Wenn der Nutzer keine Startseite eingetragen hat
  if(!startseite || startseite.id === undefined){
    return undefined;
  }

  if(startseite.object === 'TRAINING'){
    return '/training/uebersicht/' + startseite.objectId;
  } else if(startseite.object === 'PUNKTSPIELE'){
    return '/punktspiele/uebersicht/' + startseite.objectId;
  }
}
