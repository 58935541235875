import React from 'react'
import {getSpielerById} from "../../_services/spielerService";

import {trainingsService} from "../../_services/trainingsService";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState} from "../../util/ErrorUtil";
import TrainingSpieler from "./trainingSpieler_component";

class TrainingNew extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {}, 
      training: {},
      //trainingseinheitId: this.props.match.params.trainingseinheitId,
      //spielerId: this.props.match.params.spielerId,
      successMsg: '',
      errorMsg: ''
    };
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadSpieler();
    this.loadTraining();
  }

  loadSpieler(){
    getSpielerById(this.props.match.params.spielerId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              spieler: response
            });
          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }

  loadTraining(trainingId) {
    trainingsService.getTrainingById(this.props.match.params.trainingseinheitId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              training: response,
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    const { spieler, training } = this.state;
    
    return (
        <div id="trainingedit">

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          {!!spieler && !!spieler.id && !!training && !!training.id &&
            <TrainingSpieler training={training} spieler={spieler} />
          }
        
        </div>
    );
  }

}


export default TrainingNew;
