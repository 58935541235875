import React from "react";
import {List} from "./List";

class MyListDnD extends React.Component {
    _isMounted = false;


    constructor(props) {
        super(props);

        this.state = {
            spieler1: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler2: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },

        };

    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (
            <List />
        )
    }
}



export default MyListDnD;