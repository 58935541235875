import React from 'react'
import {Button} from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import moment from 'moment'
import {createNewTrainingstermine} from "../../_services/trainingsService";
import {Form, Input, Select} from "antd";
import {bereiteDatumsWertFuerRequestVor} from "../../util/DateUtil";
import {DATE_TIME_REGEX} from "../../constants/regex";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";

const FormItem = Form.Item;

class TrainingstageSaisonNew extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      defaultValuesSet: false,
      successMsg: '',
      errorMsg: '',
      beginn: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      ende: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      wiederholung: {
        value: 'WOECHENTLICH',
        validateStatus: 'success',
        errorMsg: null
      }
    };
    this.loadData = this.loadData.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    if (this._isMounted) {
      this.setState({mannschaft: this.props.mannschaft});
      this.setDefaultValues();
    }
  }
  
  setDefaultValues(){
    var now = moment();
    now.set('hour', 18);
    now.set('minute', 0);
    now.set('second', 0);
    now.set('millisecond', 0);

    //beginn
    var nowFormatted = now.format('DD.MM.YYYY HH:mm');

    const actualMonth = now.month();

    var end = moment();
    end.set('hour', 18);
    end.set('minute', 0);
    end.set('second', 0);
    end.set('millisecond', 0);
    //month 1 = Januar, 9 = Oktober
    if(actualMonth > 9 || actualMonth === 0 || actualMonth === 1 || actualMonth === 2){
      //ENDE Wintersaison
      if(actualMonth > 9){
        //wenn wir noch im alten Jahr sind, endet die Wintersaison ja im naechsten Jahr
        end.set('year', now.year() + 1);
      }
      end.set('month', 2);
      end.set('date', 31);
    }else{
      //ENDE Sommersaison : 30 September
      end.set('month', 8);
      end.set('date', 30);
    }

    //ende
    var endFormatted = end.format('DD.MM.YYYY HH:mm');
    
    if(!this.state.beginn.value){
      let beginnFromState = this.state.beginn;
      beginnFromState.value = nowFormatted;

      let endeFromState = this.state.beginn;
      endeFromState.value = endFormatted;
      
      this.setState({
        beginn: beginnFromState,
        ende: endeFromState,
        defaultValuesSet: true
      });
    }
  }
  

  
  createTrainingstage(){

    var beginnToSave = bereiteDatumsWertFuerRequestVor(this.state.beginn.value);
    var endeToSave = bereiteDatumsWertFuerRequestVor(this.state.ende.value);
    
    const saveRequest = {
      mannschaft: this.props.mannschaft,
      beginn: beginnToSave,
      ende: endeToSave,
      wiederholung: this.state.wiederholung.value
    };

    
    createNewTrainingstermine(saveRequest)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              successMsg: 'Trainingstage wurden erfolgreich angelegt',
            });

            this.props.componentChanged();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue,
        ...validationFun(inputValue)
      }
    });
  }

  validateDatum = (datum) => {
    if(!datum) {
      return {
        validateStatus: 'error',
        errorMsg: `Bitte gib ein Datum an. Die Angabe eines Datums ist pflicht.`
      }
    } else if (!datum.match(DATE_TIME_REGEX)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Datum entspricht nicht dem geforderten Format. Bitte geben Sie ein Datum mit einer Uhrzeit im Format dd.MM.yyyy HH:mm an.'
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null
      }
    }
  }

  handleSelectInputChange(value, name) {
    this.setState({
      [name] : {
        value: value
      }
    });
  }

  isFormInvalid() {
    return this.state.beginn.validateStatus === 'success' &&
        this.state.ende.validateStatus === 'success';
  }

  render()
  {
    const { mannschaft } = this.state;

    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    return (
        <div id="mannschaft_trainingstage_saison_new">
          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!this.state.defaultValuesSet && !!mannschaft &&
              <div id="trainingstage_new">
                <h4>Neue Trainingstermine anlegen:</h4>

                <div className="form-container-left-aligned">
                  <Form {...layout} className="trainingstage-edit-form"
                        initialValues={{
                          "beginn": this.state.beginn.value,
                          "ende": this.state.ende.value,
                          "wiederholung": 'WOECHENTLICH'
                        }}>
                    <FormItem
                        labelAlign="left"
                        label="Beginn"
                        name="beginn"
                        rules={[{
                          required: true,
                          message: 'Bitte gib einen Beginn an' }]}
                        hasFeedback
                        validateStatus={this.state.beginn.validateStatus}
                        help={this.state.beginn.errorMsg}>
                      <Input
                          size="large"
                          name="beginn"
                          autoComplete="on"
                          placeholder=""
                          value={this.state.beginn.value}
                          onChange={(event) => this.handleInputChange(event, this.validateDatum)}
                      />
                    </FormItem>

                    <FormItem
                        labelAlign="left"
                        label="Ende"
                        name="ende"
                        rules={[{
                          required: true,
                          message: 'Bitte gib einen Ende an' }]}
                        hasFeedback
                        validateStatus={this.state.ende.validateStatus}
                        help={this.state.ende.errorMsg}>
                      <Input
                          size="large"
                          name="ende"
                          autoComplete="on"
                          placeholder=""
                          value={this.state.ende.value}
                          onChange={(event) => this.handleInputChange(event, this.validateDatum)}
                      />
                    </FormItem>

                    <FormItem
                        labelAlign="left"
                        label="Wiederholung"
                        name="wiederholung"
                        rules={[{
                          required: true,
                          message: 'Bitte gib einen Intervall an' }]}
                        hasFeedback
                        validateStatus={this.state.wiederholung.validateStatus}
                        help={this.state.wiederholung.errorMsg}>
                      <Select onChange={(value, event) => this.handleSelectInputChange(value, 'wiederholung')}>
                        <Select.Option value="WOECHENTLICH">wöchentlich</Select.Option>
                        <Select.Option value="ZWEI_WOECHENTLICH">2-wöchentlich</Select.Option>
                        <Select.Option value="MONATLICH">monatlich</Select.Option>
                      </Select>
                    </FormItem>
                  </Form>

                  <div id="btn-container" className={"form-container"}>
                    <Button type="submit"
                            style={{float: 'right'}}
                            size="large"
                            className="myLargeBtn"
                            onClick={() => this.createTrainingstage()}
                            disabled={(!this.isFormInvalid()) || this.state.disableSubmitButton}>Trainings anlegen</Button>
                  </div>
                </div>
              </div>
          }
        </div>
    );
  }

}

export default TrainingstageSaisonNew;


