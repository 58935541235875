import React from 'react'

import {saveTrainingSpieler} from "../../_services/trainingsService";

import moment from "moment";
import {Button} from 'react-bootstrap';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState} from "../../util/ErrorUtil";
import {Form, Input} from "antd";

const FormItem = Form.Item;

class TrainingSpieler extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
  
    let vonInitialValue = '';
    //muss man setzen, damit bei nicht aendern des Input-Feldes nicht NULL uebertragen wird
    if(this.props.spieler){
      vonInitialValue = this.props.spieler.von;
    } else if(this.props.trainingSpieler){
      vonInitialValue = this.props.trainingSpieler.von;
    }
    
    this.state = {
      //fuer die edit-Ansicht: das bestehende trainingSpieler-Objekt
      trainingSpieler: this.props.trainingSpieler,
      //fuer die new-Ansicht: training und spieler
      training: this.props.training,
      spieler: this.props.spieler,
      //trainingseinheitId: this.props.match.params.trainingseinheitId,
      //spielerId: this.props.match.params.spielerId,
      successMsg: '',
      errorMsg: '',
      von: {
        value: vonInitialValue,
        validateStatus: 'success',
        errorMsg: null
      },
      name: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      }
    };


  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
    }
  }



  onSave() {
    
    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    var saveRequest = {};
    
    if(this.state.trainingSpieler && this.state.trainingSpieler.id){
      //edit Request
      saveRequest = {
        id: this.state.trainingSpieler.id,
        kommt: true,
        trainingstag: this.state.trainingSpieler.trainingstag,
        spieler: this.state.trainingSpieler.spieler,
        von: this.state.von.value,
        letzteAenderung: moment()
      };
    }else{
      //new request
      saveRequest = {
        id: 0,
        kommt: true,
        trainingstag: this.state.training,
        spieler: this.state.spieler,
        von: this.state.von.value,
        letzteAenderung: moment()
      };
    }


    saveTrainingSpieler(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Das Training wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.setState({
            punktspiel: response,
            punktspielId: response.id
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  onBack() {
    const spieler = this.getSpielerFromState();
    const trainingstag = this.getTrainingstagFromState();
    
    if(trainingstag && trainingstag.id){
      window.location.href = '/training/uebersicht/id/' + trainingstag.id;
    }else{
      window.location.href = '/training/uebersicht/' + spieler.mannschaft.id;
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  getSpielerFromState() {
    if (this.state.trainingSpieler && this.state.trainingSpieler.id){
      return this.state.trainingSpieler.spieler;
    }
    if (this.state.spieler && this.state.spieler.id){
      return this.state.spieler;
    }
    return undefined;
  }

  getTrainingstagFromState() {
    if (this.state.trainingSpieler && this.state.trainingSpieler.id){
      return this.state.trainingSpieler.trainingstag;
    }
    if (this.state.training && this.state.training.id){
      return this.state.training;
    }

    return undefined;
  }
  
  render()
  {
    const { spieler, training, trainingSpieler } = this.state;

    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    // const initialValues = {
    //   users: [
    //     { age: undefined } // undefined will render the placeholder
    //   ]
    // };
    

    var initialValues = {};
    //der new-Fall, fuer das neue Anlegen
    if(this.state.spieler){
      const trainingstag = this.getTrainingstagFromState();
      let vonUhrzeit = moment(trainingstag.datum).format("HH:mm");

      if(this.state.spieler.von){
        vonUhrzeit = this.state.spieler.von;
      }
       initialValues={
        "von": vonUhrzeit,
        "name": this.state.spieler.nickname
      };
    } else if(this.state.trainingSpieler){
      initialValues={
        "von": this.state.trainingSpieler.von,
        "name": this.state.trainingSpieler.spieler.nickname
      };
    }

    
    return (
        <div id="trainingedit">

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

         
          {((!!spieler && !!training) || (!!trainingSpieler && trainingSpieler.id)) ?    
          <div className="form-container-left-aligned">
            <Form className="trainingstage-edit-form"
                  {...layout}
                  initialValues={initialValues}>
              <FormItem
                  labelAlign="left"
                  label="Von"
                  name="von"
                  rules={[{
                    required: true,
                    message: 'Bitte gib eine Von-Uhrzeit an'
                  }]}
                  hasFeedback
                  validateStatus={this.state.von.validateStatus}
                  help={this.state.von.errorMsg}>
                <Input
                    size="large"
                    name="von"
                    autoComplete="on"
                    placeholder=""
                    value={this.state.von.value}
                    onChange={(event) => this.handleInputChange(event)}
                />
              </FormItem>
  
              <FormItem
                  labelAlign="left"
                  label="Name"
                  name="name"
                  rules={[{
                    required: true,
                    message: 'Bitte gib einen Namen an'
                  }]}
                  hasFeedback
                  validateStatus={this.state.name.validateStatus}
                  help={this.state.name.errorMsg}>
                <Input
                    size="large"
                    name="name"
                    disabled={true}
                    autoComplete="on"
                    placeholder=""
                    value={this.state.name.value}
                    onChange={(event) => this.handleInputChange(event)}
                />
              </FormItem>

            </Form>

            <div id="btn-container" className={"form-container"}>
              <Button type="submit"
                      style={{float: 'right'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onSave()}
                      disabled={this.state.disableSubmitButton}>Zusagen</Button>

              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onBack()}>Zurück</Button>              
            </div>
          </div>
            :
            null
          }
        
        </div>
    );
  }

}


export default TrainingSpieler;
