export default class DoppelPaarung_Filter {

    constructor(spieler1Id, spieler2Id) {
        this.spieler1Id = spieler1Id;
        this.spieler2Id = spieler2Id;
    }
    
    isVollstaendig(){
        if(this.spieler1Id > 0){
            if(this.spieler2Id > 0){
                return true;
            }
        }
        return false;
    }
    
    getSpielerForUnvollstaendigePaarung(){
        if(this.spieler1Id > 0){
            return this.spieler1Id;
        }
        if(this.spieler2Id > 0){
            return this.spieler2Id;
        }
        return null;
    }
}