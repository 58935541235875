import React from 'react'
import {Button} from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {getPunktspielById, savePunktspiel} from "../../_services/punktspielService";
import moment from 'moment'
import {Checkbox, Form, Input} from "antd";
import {bereiteDatumsWertFuerRequestVor} from "../../util/DateUtil";

const FormItem = Form.Item;


class PunktspielEditComponent extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      punktspielId: this.props.punktspielId,
      punktspiel: {},
      successMsg: '',
      errorMsg: '',
      termindatum: '',
      datum: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      gegner: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
      heimspiel: {
        value: 'false',
        validateStatus: 'success',
        errorMsg: null
      },
    };
    this.loadPunktspiel = this.loadPunktspiel.bind(this);
    this.loadData = this.loadData.bind(this);
    //this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.punktspielId !== nextProps.punktspielId) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    if (this._isMounted) {
      if(!this.isNewAnsicht()){
        this.loadPunktspiel();
      }
    }
  }

  loadPunktspiel() {
    
    const punktspielId = this.state.punktspielId;

    getPunktspielById(punktspielId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              punktspiel: response
            });

            this.setState({
              datum: {
                value: response.datum
              },
              gegner: {
                value: response.gegner
              },
              heimspiel: {
                value: response.heimspiel
              }
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  //lieert die Mannschaft, je nach dem, um was fuer eine Ansicht es sich gerade handelt
  //(die Bearbeiten Ansicht, eines bereits existenten Punktspiels oder die Neu Ansicht, zum neu Erstellen eines Punktspiels)
  getMannschaft(){
    //dann handelt es sich um den Edit-Fall
    if(this.state.punktspielId > 0){
      return this.state.punktspiel.mannschaft;
    }else{
      //neu erstellen Fall
      return this.props.mannschaft;
    }
  }
  
  isNewAnsicht(){
    if(this.props.isNewAnsicht){
      //neu erstellen Fall
      return true;
    }else{
      //dann handelt es sich um den Edit-Fall
      return false;
    }
  }
  
  onSave() {

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    var mannschaft = this.getMannschaft();

    //Wenn das Datum nicht bearbeitet wurde, dann ist es noch der programmatisch gesetzte Wert, also ein Date-Objekt
    //Wurde es manipuliert, ist es ein String
    var dateFormattedToSave = bereiteDatumsWertFuerRequestVor(this.state.datum.value);
    // var dateFormattedToSave = this.state.datum.value;
    // if(!moment(dateFormattedToSave, moment.ISO_8601).isValid()){
    //   var date2 = moment(dateFormattedToSave, "DD.MM.YYYY HH:m");
    //   dateFormattedToSave = date2;
    // }

    const saveRequest = {
      id: this.state.punktspielId,
      mannschaft: mannschaft,
      datum: dateFormattedToSave,
      gegner: this.state.gegner.value,
      heimspiel: this.state.heimspiel.value
    };
    

    savePunktspiel(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Das Punktspiel wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

          this.setState({
            punktspiel: response,
            punktspielId: response.id
          });

          this.redirectOnSaveSuccess(response.mannschaft.id);

        }).catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
       document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  redirectOnSaveSuccess(mannschaftsId){
    
    if(this.isNewAnsicht()){
      window.location.href = '/punktspiele/new/' + mannschaftsId;
    }else{
      window.location.href = '/verwaltung/mannschaft/edit/' + mannschaftsId;
    }
  }
  
  onBack() {
    var mannschaftsId = 0;
    //dann handelt es sich um den Edit-Fall
    if(this.state.punktspielId > 0){
      mannschaftsId = this.state.punktspiel.mannschaft.id;
    }else{
      //neu erstellen Fall
      mannschaftsId = this.props.mannschaft.id;
    }
    
    window.location.href = '/verwaltung/mannschaft/edit/' + mannschaftsId;
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const inputName = target.name;
    const checked = target.checked;
    
    this.setState({
      [inputName] : {
        value: checked
      }
    });
  }
  
  //function(date: moment, dateString: string)
  //also value ist moment und dateString ist ein String
  //Fuer den Datepicker
  // handleChange(value, dateString) {
  //   //wenn auf NULL/Leer gesetzt wird
  //   if(!value){
  //     this.setState({
  //       datum: {
  //         value: null
  //       }
  //     });
  //     return;
  //   }
  //
  //   this.setState({
  //     datum: {
  //       value: value
  //     }
  //   });
  // }
  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const { punktspiel } = this.state;

    var formattedDate = moment(punktspiel.datum).format('DD.MM.YYYY HH:mm');

    //const dateTimeFormat = 'DD.MM.YYYY HH:mm';
    // const dateTimeFormat = 'DD.MM.YYYY';
    // var terminDate = new Date(this.state.punktspiel.datum);
    
    const isNewAnsicht = this.isNewAnsicht();
    if(this.isNewAnsicht()){
      formattedDate = '';
    }
    
    return (
        <React.Fragment>
          <div className="punktspiel-edit-container">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }

            {(isNewAnsicht || (!!punktspiel && !!punktspiel.id)) ?
            <div className="form-container">
              <Form {...layout} className="punktspiel-edit-form"
                    initialValues={{
                      "datum": formattedDate,
                      "gegner": this.state.punktspiel.gegner,
                      "heimspiel": this.state.punktspiel.heimspiel
                    }}>
                {/*<FormItem*/}
                {/*    labelAlign="left"*/}
                {/*    label="Datum"*/}
                {/*    rules={[{*/}
                {/*      required: true,*/}
                {/*      message: 'Bitte gib einen Spieltermin an!' }]}>*/}
                {/*  <DatePicker*/}
                {/*      value={this.state.datum.value}*/}
                {/*      locale={deDE}*/}
                {/*      //default-Wert hier extra deaktiviert werden*/}
                {/*      //defaultValue={moment(this.state.termin, dateFormat)}*/}
                {/*      placeholder="Datum"*/}
                {/*      allowClear={false}*/}
                {/*      defaultValue={moment(terminDate, dateTimeFormat)}*/}
                {/*      format={dateTimeFormat}*/}
                {/*      onChange={this.handleChange}*/}
                {/*  />*/}
                {/*</FormItem>*/}
                <FormItem
                    labelAlign="left"
                    label="Datum"
                    name="datum"
                    rules={[{
                      required: true,
                      message: 'Bitte gib einen Spieltermin an' }]}
                    hasFeedback
                    validateStatus={this.state.datum.validateStatus}
                    help={this.state.datum.errorMsg}>
                  <Input
                      size="large"
                      name="datum"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.datum.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>
                <FormItem
                    labelAlign="left"
                    label="Gegner"
                    name="gegner"
                    rules={[{
                      required: true,
                      message: 'Bitte gib den gegnerischen Verein an' }]}
                    hasFeedback
                    validateStatus={this.state.gegner.validateStatus}
                    help={this.state.gegner.errorMsg}>
                  <Input
                      size="large"
                      name="gegner"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.gegner.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>
                <FormItem className={"verticalAlignCeckbox"}
                          name="heimspiel"
                          valuePropName="checked"
                          validateStatus={this.state.heimspiel.validateStatus}
                          help={this.state.heimspiel.errorMsg}
                          onChange={(event) => this.handleCheckboxChange(event)}>
                  <Checkbox name="heimspiel">Heimspiel</Checkbox>
                </FormItem>                
              </Form>


            </div>
                :
                null
            }
            
            <div id="btn-container" className={"form-container"}>
              <Button type="submit"
                      style={{float: 'right'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onSave()}
                      disabled={this.state.disableSubmitButton}>Speichern</Button>
              
            <Button type="submit"
                    style={{float: 'left'}}
                    size="large"
                    className="myLargeBtn"
                    onClick={() => this.onBack()}>Zurück</Button>
            </div>
          </div>
        </React.Fragment>

    );
  }

}

export default PunktspielEditComponent;


