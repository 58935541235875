import {matchMapper} from "../../_mapper/matchMapper";

export default class MatchStatistikDoppelMap {
    constructor(matchStatistikJson) {
        this.anzahlMatchesInsgesamt = matchStatistikJson.anzahlMatchesInsgesamt;
        this.gewonnen = matchMapper.mapDoppelList(matchStatistikJson.gewonnen);
        this.verloren = matchMapper.mapDoppelList(matchStatistikJson.verloren);
        this.unentschieden = matchMapper.mapDoppelList(matchStatistikJson.unentschieden);
    }

}