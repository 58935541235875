import './match_filter_inline.css';
import React from 'react'
import {Button} from 'react-bootstrap';
import {Col, Form, Row, Select} from "antd";
import {matchEinzelService} from "../../_services/matchEinzelService";
import {setErrorState} from "../../util/ErrorUtil";
import {matchDoppelService} from "../../_services/matchDoppelService";
import {spielerService} from "../../_services/spielerService";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import MatchList from "../match_components/match_list_component";
import DetailedMatchOverview from "../match_statistik/detailed_match_overview";
import {mannschatsService} from "../../_services/mannschatsService";
import MatchStatistikDoppelMap from "../../dto/match/MatchStatistikDoppelMap";
import MatchStatistikEinzelMap from "../../dto/match/MatchStatistikEinzelMap";
import {matchMapper} from "../../_mapper/matchMapper";


const FormItem = Form.Item;

class MatchFilterInline extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      statistikMatches: {
        anzahlMatchesInsgesamt: 0,
        gewonnen: [],
        verloren: [],
        unentschieden: []
      },
      disableSubmitButton: false,
      vereinsSpielerDistinctNutzer: [],
      mannschaften: [],
      successMsg: '',
      errorMsg: '',
      mannschaft: {
        value: '',
        validateStatus: '',
        errorMsg: null
      },
      nutzerHeim: {
        value: this.props.isStatistikView ? this.props.loggedInUser.id : '',
        displayName: null,
        //damit nicht schon bei der Auswahl des Drop-Downs sich die label anpassen
        //der wert wird beim klick auf filter nach displayName uebertragen
        displayNameTemp: null,
        validateStatus: '',
        errorMsg: null
      },
      nutzerGast: {
        value: '',
        displayName: null,
        displayNameTemp: null,
        validateStatus: '',
        errorMsg: null
      },
      //fuers Doppel
      nutzerHeim2: {
        value: '',
        displayNameTemp: null,
        validateStatus: '',
        errorMsg: null
      },
      //fuers Doppel
      nutzerGast2: {
        value: '',
        displayNameTemp: null,
        validateStatus: '',
        errorMsg: null
      },
    };
    this.loadData = this.loadData.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  formRef = React.createRef();

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps, prevState) {
  if(this.props.loggedInUser.id !== nextProps.loggedInUser.id){
      this.loadData();
    }
  }

  loadData(){
    
    if (this._isMounted) {
      //Die Spieler sind fuer die Dropdown Felder. Die braucht man ja also auch in der New Ansicht
      this.loadMatches();
      this.loadAllSpieler();
      this.loadMannschaftenByNutzer();
      
      if(this.props.isStatistikView){
        this.setDefaultFormValuesForStatistikView();
      }
    }
  }

  loadMannschaftenByNutzer(){

    //alle Mannschaften des Nutzers:
    //  - alle Mannschaften in denen der uebergebene Nutzer einen Spieler hat
    //  - alle Mannschaften, fuer die der Nutzer VIEW-Rechte hat
    //  - alle Mannschaften, fuer die der Nutzer ADMIN-Rechte hat     
    mannschatsService.getMannschaftenForNavigationViewRightsOrHigher(this.props.loggedInUser.id)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              mannschaften: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  loadMatches() {
    if(this.props.isDoppel){
      this.filterDoppel();
    }else{
      this.filterEinzel();
    }
  }

  loadEinzel() {
    matchEinzelService.findAllOrderByDatum()
        .then(response => {
          if (this._isMounted) {
            this.setState({
              matches: matchMapper.mapEinzelList(response)
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  loadDoppel() {
    matchDoppelService.findAllOrderByDatum()
        .then(response => {
          if (this._isMounted) {
            this.setState({
              matches: matchMapper.mapDoppellList(response)
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  setDefaultFormValuesForStatistikView(){

    this.formRef.current.setFieldsValue({
      nutzerHeim: this.props.loggedInUser.id
    });
    
    if (this._isMounted) {
      this.setState({
        nutzerHeim: {
          value: this.props.loggedInUser.id
        }
      });
    }
  }

  //liefert den Verein, je nach dem, um was fuer eine Ansicht es sich gerade handelt
  //(die Bearbeiten Ansicht, eines bereits existenten Punktspiels oder die Neu Ansicht, zum neu Erstellen eines Punktspiels)
  getVerein(){
    return this.props.verein;
  }

  onFilter() {

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    
    document.body.classList.add('busy-cursor');

    if(this.props.isDoppel){
      this.filterDoppel();
    }else{
      this.filterEinzel();
    }

    let nutzerHeimTemp = this.state.nutzerHeim;
    nutzerHeimTemp.displayName = this.state.nutzerHeim.displayNameTemp;

    let nutzerHeim2Temp = this.state.nutzerHeim2;
    nutzerHeim2Temp.displayName = this.state.nutzerHeim2.displayNameTemp;

    this.setState({
      nutzerHeim: nutzerHeimTemp,
      nutzerHeim2: nutzerHeim2Temp
    });
  }

  filterEinzel(){
    const filterRequest = {
      mannschaftsId: this.state.mannschaft.value,
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerGastId: this.state.nutzerGast.value,
    };
    
    if(this.props.isStatistikView){
      this.filterEinzelStatstik(filterRequest);
    }else{
      this.filterEinzelListe(filterRequest);
    }
  }
  
  filterEinzelListe(filterRequest){
    matchEinzelService.filterMatches(filterRequest)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              matches: matchMapper.mapEinzelList(response)
            });
          }
          
          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  filterEinzelStatstik(filterRequest){
    matchEinzelService.filterMatchesStatistik(filterRequest)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              statistikMatches: new MatchStatistikEinzelMap(response)
            });
          }

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }
  
  filterDoppel(){
    
    const filterRequest = {
      mannschaftsId: this.state.mannschaft.value,
      nutzerHeimId: this.state.nutzerHeim.value,
      nutzerHeim2Id: this.state.nutzerHeim2.value,
      nutzerGastId: this.state.nutzerGast.value,
      nutzerGast2Id: this.state.nutzerGast2.value
    };

    if(this.props.isStatistikView){
      this.filterDoppelStatstik(filterRequest);
    }else{
      this.filterDoppelListe(filterRequest);
    }
  
  }

  filterDoppelListe(filterRequest){

    matchDoppelService.filterMatches(filterRequest)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              matches: matchMapper.mapDoppelList(response)
            });
          }

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  filterDoppelStatstik(filterRequest){

    matchDoppelService.filterMatchesStatistik(filterRequest)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              statistikMatches: new MatchStatistikDoppelMap(response)
            });
          }

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  loadAllSpieler(){
    const verein = this.getVerein();
    
    if(verein && verein.id){
      //liefert eine Liste von Spielern des Vereins zurueck, wobei es hier eher auf die Nutzer ankommt
      //Spieler, die demselben Nutzer angehoeren werden auf nur einen Spieler reduziert.
      //Das Match-Ergebnis wird naemlich am Nutzer gespeichert.
      spielerService.getDistinctSpielerForNutzerByVerein(verein.id)
          .then(response => {

            if (this._isMounted) {
              this.setState({
                vereinsSpielerDistinctNutzer: response
              });
            }

            var index = response.findIndex(e => e.nutzer.id === this.props.loggedInUser.id);
            if (index !== -1) {
              var nutzerHeim = this.state.nutzerHeim;
              nutzerHeim.displayName = response[index].nickname;
              nutzerHeim.displayNameTemp = response[index].nickname;
              
              this.setState({
                nutzerHeim: nutzerHeim
              });
            }
            
          })
          .catch(error => {
            if (this._isMounted) {
              setErrorState(error, this);
            }
          });     
    }
  }


  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    
    this.setState({
      [inputName] : {
        value: inputValue
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }

  handleSelectInputChange(value, name, event) {
    let displayName = null;
    
    if(event){
      displayName = event.children;
    }
    
    this.setState({
      [name] : {
        value: value,
        displayNameTemp: displayName,
        //sonst wird er mit null/undefined hier ueberschrieben, wenn man den hier nicht mit setzt
        displayName: this.state[name].displayName
      },
      //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
      //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
      successMsg: null,
    });
  }

  getNutzerDisplayNameFromSelect() {

    let displayNameArr = [];
    if (this.state.nutzerHeim.value > 0 && this.state.nutzerHeim.displayName) {
      displayNameArr.push(this.state.nutzerHeim.displayName + '\'s');
    }

    if (this.state.nutzerHeim2.value > 0 && this.state.nutzerHeim2.displayName) {
      displayNameArr.push(this.state.nutzerHeim2.displayName  + '\'s');
    }
    
    let text = displayNameArr.join(" und ");
    
    return text;
  }
  
  render()
  {
    const { vereinsSpielerDistinctNutzer, matches, mannschaften } = this.state;
    
    //initialValues is like defaultValue of input, it will only works on first render. Please use setFieldValue or setFieldsValue to set new value.
    // heißt: fuer die Edit-Ansicht, bringt mir das nix. Da reichen die initialVaules nicht. Da muss ich wirklich die onChangeMethode aufrufen
    var initialValues = {};

    let emptyItem = <Select.Option value={""} key={0}></Select.Option>;
 
    //spieler select options
    let spielerItems = vereinsSpielerDistinctNutzer.map((spieler) =>
        <Select.Option value={spieler.nutzer.id} key={spieler.id}>{spieler.nickname}</Select.Option>
    );
    spielerItems = [emptyItem].concat(spielerItems)

    //mannschaft select options
    let mannschaftItems = mannschaften.map((mannschaft) =>
        <Select.Option value={mannschaft.id} key={mannschaft.id}>{mannschaft.name}</Select.Option>
    );
    mannschaftItems = [emptyItem].concat(mannschaftItems)
    
    return (
        <React.Fragment>
          <div className="match-edit-container">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }


                <div>
                  
                  <Form
                      onFinish={this.onFilter}
                      ref={this.formRef}
                      className="match-filter-form"
                      initialValues={initialValues}>

                    {/*Ich hab das hier mit Row und Col von antd gemacht (>Grid), damit ich den Speichern Button vertical bottom align machen kann.*/}
                    {/*Bei bestimmten Handy-Aufloesungen (z.B. auch meiner), wenn er noch neben dem Ergebnis angezeigt wird und nicht in einer neuen Zeile, dann schwebte er immer weit oben. Das sah sehr seltsam aus*/}

                        <Row gutter={32} align="bottom">
                          <Col>
                            <FormItem
                                label="Begegnung"
                                style={{fontWeight: 'bold', marginBottom: '0px'}}
                                name="nutzerHeim"
                                rules={[{
                                  required: this.props.isStatistikView,
                                  message: 'Die Angabe dieses Feldes ist beim Statistik-Filter Pflicht.'
                                }]}
                                validateStatus={this.state.nutzerHeim.validateStatus}
                                help={this.state.nutzerHeim.errorMsg}>

                              <Select
                                  // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                                  style={{width: '11em', fontWeight: 'normal'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerHeim', event)}>
                                
                                {spielerItems}
                              </Select>
                            </FormItem>

                            {/*Der Doppel-Partner*/}
                            <FormItem
                                name="nutzerHeim2"
                                className="doppel-heim2-filter"
                                style={{display: this.props.isDoppel ? "" : "none", marginBottom: '0px'}}
                                validateStatus={this.state.nutzerHeim2.validateStatus}
                                help={this.state.nutzerHeim2.errorMsg}>

                              <Select
                                  // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                                  style={{width: '11em', fontWeight: 'normal'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerHeim2', event)}>
                                {spielerItems}
                              </Select>
                            </FormItem>
                          </Col>
                          
                          <Col className="padding-top-responsive">
                            <FormItem
                                name="nutzerGast"
                                style={{marginBottom: '0px'}}
                                validateStatus={this.state.nutzerGast.validateStatus}
                                help={this.state.nutzerGast.errorMsg}>

                              <Select
                                  style={{width: '11em'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerGast')}>
                                {spielerItems}
                              </Select>
                            </FormItem>

                            {/*Der Doppel-Partner*/}
                            <FormItem
                                name="nutzerGast2"
                                style={{ display: this.props.isDoppel ? "" : "none", marginBottom: '0px' }}
                                validateStatus={this.state.nutzerGast2.validateStatus}
                                help={this.state.nutzerGast2.errorMsg}>

                              <Select
                                  style={{width: '11em'}}
                                  size="large"
                                  onChange={(value, event) => this.handleSelectInputChange(value, 'nutzerGast2')}>
                                {spielerItems}
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>


                    <Row gutter={32} align="bottom">
                      <Col>
                        <FormItem
                            label="Mannschaft"
                            style={{fontWeight: 'bold', marginTop: '1.5em'}}
                            name="mannschaft"
                            validateStatus={this.state.mannschaft.validateStatus}
                            help={this.state.mannschaft.errorMsg}>

                          <Select
                              // muss fontWeight: 'normal' setzen, da das setzen von bold oben im FormItem macht hier die Auswahl irgendwie auch fett sonst
                              style={{width: '11em', fontWeight: 'normal'}}
                              // bei der Statistik View ist der Heim Nutzer Pflicht und daher ist das label mit einem roten Pflicht-sternchen versehen und alles rutscht nach rechts
                              className={ this.props.isStatistikView ? 'mannschaftslabel-responsive' : ''}
                              size="large"
                              onChange={(value, event) => this.handleSelectInputChange(value, 'mannschaft', event)}>

                            {mannschaftItems}
                          </Select>
                        </FormItem>
                      </Col>

                      <Col>
                        <FormItem
                            className="bottomButton"
                            style={{verticalAlign: 'middle'}}>
                          <Button type="submit"
                                  style={{verticalAlign: 'middle'}}
                                  size="large"
                                  className="myLargeBtn"
                                  disabled={this.state.disableSubmitButton}>Filter</Button>
                        </FormItem>

                      </Col>
                    </Row>

                   
                  </Form>

                  {!!!this.props.isStatistikView &&
                  <MatchList loggedInUser={this.props.loggedInUser}
                             matches={matches}
                             isOnlyView={true}/>
                  }

                  {!!this.props.isStatistikView &&
                    <React.Fragment>
                      <br/>
                     
                      <DetailedMatchOverview
                          matches={this.state.statistikMatches}
                          isDoppel={this.props.isDoppel}
                          loggedInUser={this.props.loggedInUser}
                          displayName={''}/>
                    </React.Fragment>
                  }
                  
                </div>

          </div>
        </React.Fragment>
    );
  }
}

export default MatchFilterInline;


