import {API_BASE_URL} from "../../../constants";
import {request} from "../../../util/APIUtils";


export const trainingsplanungRundenService = {
    getRundeById,
    getRundenByTrainingstagId,
    saveRunde,
    deleteRundeById
};


export function getRundeById(rundenId)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-runde/"  + rundenId,
        method: 'GET'
    });
}

export function getRundenByTrainingstagId(trainingstagId)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-runde/all/"  + trainingstagId,
        method: 'GET'
    });
}

//Liefert als Response das Responseobjekt zurueck, wo auch die Einzel und Doppel mit drin sind.
//Return TrainingsplanungRundeResponse
export function getRundenByTrainingstagIdWithMatches(trainingstagId)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-runde/all-with-matches/"  + trainingstagId,
        method: 'GET'
    });
}

export function saveRunde(saveRequest) {
    return request({
        url: API_BASE_URL + "/trainingsplanung-runde/save",
        method: 'POST',
        body: JSON.stringify(saveRequest)
    });
}

export function deleteRundeById(id)
{
    return request({
        url: API_BASE_URL + "/trainingsplanung-runde/delete/"  + id,
        method: 'DELETE'
    });
}