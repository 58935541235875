import React from 'react'
import {Button} from 'react-bootstrap';
import {setErrorState} from '../../util/ErrorUtil';
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {Checkbox, Form, Input} from "antd";
import {mannschatsService} from "../../_services/mannschatsService";

const FormItem = Form.Item;

class MannschaftsEinstellungenEdit extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mannschaft: {},
      successMsg: '',
      errorMsg: '',
      name: {
        value: this.props.mannschaft.name,
        validateStatus: 'success',
        errorMsg: null
      },
      spieltPunktspiele: {
        value: this.props.mannschaft.spieltPunktspiele,
        validateStatus: 'success',
        errorMsg: null
      },
      machtTraining: {
        value: this.props.mannschaft.machtTraining,
        validateStatus: 'success',
        errorMsg: null
      },
      zeigeMatchbilanz: {
        value: this.props.mannschaft.zeigeMatchbilanz,
        validateStatus: 'success',
        errorMsg: null
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.setState({
        mannschaft: this.props.mannschaft
      });
    }
  }

  handleSubmit() {

    this.setState({
      successMsg: null,
      errorMsg: null
    });

    const mannschaftsId = this.state.mannschaft.id;
    const saveRequest = {
      name: this.state.name.value,
      spieltPunktspiele: this.state.spieltPunktspiele.value,
      machtTraining: this.state.machtTraining.value,
      zeigeMatchbilanz: this.state.zeigeMatchbilanz.value
    };
    
    mannschatsService.saveEinstellungen(mannschaftsId, saveRequest)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Die Einstellungen wurden erfolgreich gespeichert.'});
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const inputName = target.name;
    const checked = target.checked;

    this.setState({
      [inputName] : {
        value: checked
      }
    });
  }
  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    
    const { mannschaft } = this.state;
    
    return (
        <div id="mannschaft_einstellungen_edit">
          <h2>Allgemeine Einstellungen</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!mannschaft && !!mannschaft.id > 0 &&
          <div className="form-container-breit-left-aligned">
            <Form onFinish={this.handleSubmit}
                {...layout} 
                className="mannschaft-einstellungen-edit-form"
                  initialValues={{
                    "name": this.state.mannschaft.name,
                    "spieltPunktspiele": this.state.mannschaft.spieltPunktspiele,
                    "machtTraining": this.state.mannschaft.machtTraining,
                    "zeigeMatchbilanz": this.state.mannschaft.zeigeMatchbilanz
                  }}>

              <FormItem
                  labelAlign="left"
                  label="Name"
                  name="name"
                  rules={[{
                    required: true,
                    message: 'Bitte gib einen Mannschaftsnamen an' }]}
                  hasFeedback
                  validateStatus={this.state.name.validateStatus}
                  help={this.state.name.errorMsg}>
                <Input
                    size="large"
                    name="name"
                    autoComplete="on"
                    placeholder=""
                    value={this.state.name.value}
                    onChange={(event) => this.handleInputChange(event)}
                />
              </FormItem>
              
              <FormItem labelAlign="left"
                        label="spielt Punktspiele"
                        name="spieltPunktspiele"
                        valuePropName="checked"
                        validateStatus={this.state.spieltPunktspiele.validateStatus}
                        help={this.state.spieltPunktspiele.errorMsg}
                        onChange={(event) => this.handleCheckboxChange(event)}>
                <Checkbox name="spieltPunktspiele"/>
              </FormItem>

              <FormItem labelAlign="left"
                        label="macht Training"
                        name="machtTraining"
                        valuePropName="checked"
                        validateStatus={this.state.machtTraining.validateStatus}
                        help={this.state.machtTraining.errorMsg}
                        onChange={(event) => this.handleCheckboxChange(event)}>
                <Checkbox name="machtTraining"/>
              </FormItem>


              <FormItem labelAlign="left"
                        label="zeige Matcherfassung"
                        name="zeigeMatchbilanz"
                        valuePropName="checked"
                        validateStatus={this.state.zeigeMatchbilanz.validateStatus}
                        help={this.state.zeigeMatchbilanz.errorMsg}
                        onChange={(event) => this.handleCheckboxChange(event)}>
                <Checkbox name="zeigeMatchbilanz"/>
              </FormItem>

              <Button type="submit"
                      style={{float: 'left'}}
                      size="large"
                      className="myLargeBtn">Speichern</Button>
            </Form>


          </div>
          }
        </div>
    );
  }
}


export default MannschaftsEinstellungenEdit;


