import './Punktspieluebersicht.css';
import img_checked from '../img/checked.png';
import img_cancelled from '../img/cancelled.png';
import img_unbekannt from '../img/unbekannt.png';
import {Button} from 'react-bootstrap';

import React from 'react';
import Moment from 'react-moment';
import moment from "moment";

import {getAllPunktspielAbstimmungForSpieler, punktspielService} from "../_services/punktspielService";
import SuccessMessage from "../_components/notifications/SuccessMessage";
import ErrorMessage from "../_components/notifications/ErrorMessage";
import {setErrorState} from "../util/ErrorUtil";
import {getSpielerById} from "../_services/spielerService";
import img_null from "../img/null.png";

class PunktspielSpielerEdit extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);

    this.state = {
      spielerId: this.props.match.params.spielerId, 
      punktspielSpielerListe: [],
      successMsg: '',
      errorMsg: ''
    };

    //this.onZusagen = this.onZusagen.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    this.loadPunktspielAbstimmungForSpieler();
  }

  loadPunktspielAbstimmungForSpieler(){
    const spielerId = this.state.spielerId;

    getAllPunktspielAbstimmungForSpieler(spielerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          punktspielSpielerListe: response
        });
      }
    })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  onZusagen(punktspielId, spielerId) {
    this.setState({
      disableZusagenButton: true
    });
    punktspielService.zusagen(punktspielId, spielerId).then(result => {
      this.setState({
        disableZusagenButton: false
      });
          this.loadData();
    })
    .catch(error => this.setState({hasError: error, disableZusagenButton: false}));
  }

  onAbsagen(punktspielId, spielerId) {
    this.setState({
      disableAbsagenButton: true
    });
    punktspielService.absagen(punktspielId, spielerId).then(result => {
      this.setState({
        disableAbsagenButton: false
      });
      this.loadData();
    })
    .catch(error => this.setState({hasError: error, disableAbsagenButton: false}));
  }
  
  onUnsicher(punktspielId, spielerId) {
    this.setState({
      disableUnsicherButton: true
    });

    punktspielService.unsicher(punktspielId, spielerId).then(result => {
      this.setState({
        disableUnsicherButton: false
      });
      this.loadData();
    }).catch(error => this.setState({hasError: error, disableUnsicherButton: false}));
  }
  

  onBack() {
    getSpielerById(this.state.spielerId)
        .then(response => {
          if (this._isMounted) {
            window.location.href = '/punktspiele/uebersicht/' + response.mannschaft.id;
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  render()
  {
    const { punktspielSpielerListe } = this.state;

    var spielerNick = '';
    if(punktspielSpielerListe && punktspielSpielerListe[0]){
      spielerNick = punktspielSpielerListe[0].spieler.nickname
    }
    
    return (
        <div id="punktspiele_edit">
          <h1>Punktspiele {spielerNick}</h1>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          <table className="table table-hover small-table">
            <thead className="thead-light">
            <tr>
              <th scope="col" style={{textAlign: 'left'}}>Datum</th>
              <th scope="col" style={{textAlign: 'left'}}>Heim</th>
              <th scope="col" style={{textAlign: 'left'}}>Gast</th>
              <th scope="col"><div className="responsiveHide">Antwort</div></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>

            {!!punktspielSpielerListe &&
            <tbody>

            {punktspielSpielerListe.map((punktspielSpieler, i) =>
                <tr key={'punktspiel_edit_' + i}>
                  <td style={{textAlign: 'left'}}>
                    <Moment format="DD.MM.YYYY">
                      {punktspielSpieler.punktspiel.datum}
                    </Moment>
                    
                    <Moment  format=" HH:mm">
                      {punktspielSpieler.punktspiel.datum}
                    </Moment>
                  </td>

                  <td style={{textAlign: 'left'}}>
                    {(punktspielSpieler.punktspiel.heimspiel) &&
                      <div className='heim'>{punktspielSpieler.punktspiel.mannschaft.verein.name} </div>
                    }

                    {!(punktspielSpieler.punktspiel.heimspiel) &&
                      <div className='gast'>{punktspielSpieler.punktspiel.gegner}</div>
                    }
                  </td>

                  <td style={{textAlign: 'left'}}>
                    {(punktspielSpieler.punktspiel.heimspiel) &&
                    <span className='gast'>{punktspielSpieler.punktspiel.gegner} </span>
                    }

                    {!(punktspielSpieler.punktspiel.heimspiel) &&
                    <span className='heim'>{punktspielSpieler.punktspiel.mannschaft.verein.name}</span>
                    }
                  </td>
                  
                  <td>
                    {(punktspielSpieler.antwort ==='ZUGESAGT') &&
                    <img src={img_checked} className="img-responsive zugesagt-img" alt="hat zugesagt" title={"hat zugesagt am " + moment(punktspielSpieler.letzteAenderung).format("DD.MM.YYYY HH:mm")}/>
                    }

                    {(punktspielSpieler.antwort ==='ABGESAGT') &&
                    <img src={img_cancelled} className="img-responsive zugesagt-img" alt="hat abgesagt" title={"hat abgesagt am " + moment(punktspielSpieler.letzteAenderung).format("DD.MM.YYYY HH:mm")}/>
                    }
                    
                    {(punktspielSpieler.antwort ==='UNSICHER') &&
                    <img src={img_unbekannt} className="img-responsive zugesagt-img" alt="ist noch unsicher" title={"hat noch nicht geantwortet"}/>
                    }
                    
                    {(punktspielSpieler.zugesagt === null) &&
                    <img src={img_null} className="img-responsive zugesagt-img" alt="hat noch nicht geantwortet" title={"hat noch nicht geantwortet"}/>
                    }
                    
                  </td>

                  <td>
                    {(punktspielSpieler.antwort === null || punktspielSpieler.antwort !== 'ZUGESAGT') &&
                    <Button variant="success" onClick={() => this.onZusagen(punktspielSpieler.punktspiel.id, punktspielSpieler.spieler.id)}>Zusagen</Button>
                  }
                  </td>
                  <td>
                    {(punktspielSpieler.antwort === null || punktspielSpieler.antwort !== 'UNSICHER') &&
                    <Button variant="warning" onClick={() => this.onUnsicher(punktspielSpieler.punktspiel.id, punktspielSpieler.spieler.id)}>Unsicher</Button>
                    }
                  </td>
                  <td>
                    {(punktspielSpieler.antwort === null || punktspielSpieler.antwort !== 'ABGESAGT') &&
                    <Button variant="danger" onClick={() => this.onAbsagen(punktspielSpieler.punktspiel.id, punktspielSpieler.spieler.id)}>Absagen</Button>
                    }
                  </td>
                </tr>
            )}
            
            </tbody>
            }

          </table>
          <Button onClick={() => this.onBack()}>Zurück</Button>
        </div>
    );
  }

}



export default PunktspielSpielerEdit;
