import React from 'react';
import {getCurrentUser} from "../../_services/authService";
import SpielerProfilAllgemeineEinstellungen from "./spielerProfilAllgemeineEinstellungen";
import {getNutzerById} from "../../_services";

//DIe Profilansicht. ANsicht, wo ich mein eigenes Profil anschauen kann.
class ProfilEinstellungen extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            user: {}
        };
        
        this.loadUsers = this.loadUsers.bind(this);
    }


    loadUsers() {
        getCurrentUser()
        .then(response => {
            if (this._isMounted) {

                getNutzerById(response.id).then(responseNutzer => {
                        if (this._isMounted) {
                            this.setState({user: responseNutzer});
                        }
                    })
                    .catch(error => {
                        if (this._isMounted) {
                            this.setState({hasError: error})
                        }
                    });
            }
        })
        .catch(error => {
            if (this._isMounted) {
                this.setState({hasError: error})
            }
        });
    }
    
    
    componentDidMount() {
        this._isMounted = true;
        this.loadUsers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadUsers();
        }
    }


    render() {
        
        const { user } = this.state;

        return (
            <React.Fragment>
            {!!user && !!user.id &&
                <div>
                    <SpielerProfilAllgemeineEinstellungen key={'allgemeineEinstellungen' + user.id} nutzer={user} showAdminFields={false}/>
                </div>
            }
            </React.Fragment>
        );
    }
}

export default ProfilEinstellungen;