import React from 'react'

import {trainingsService} from "../../_services/trainingsService";
import SuccessMessage from "../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../_components/notifications/ErrorMessage";
import {setErrorState} from "../../util/ErrorUtil";
import TrainingSpieler from "./trainingSpieler_component";

class TrainingEdit extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      trainingSpieler: {},
      successMsg: '',
      errorMsg: ''
    };
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadTrainingSpieler();
  }

  loadTrainingSpieler() {

    trainingsService.getTrainingSpielerById(this.props.match.params.trainingSpielerId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              trainingSpieler: response,
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
            console.log("error");
          }
        });
  }

  render()
  {
    const { trainingSpieler } = this.state;

    return (
        <div id="trainingedit">

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          {!!trainingSpieler && !!trainingSpieler.id && 
            <TrainingSpieler trainingSpieler={trainingSpieler} />
          }

        </div>
    );
  }

}


export default TrainingEdit;
