import GastHeim from "./GastHeim";
import {getDisplayName} from "../../_helpers/displayHelper";

export default class GastHeimEinzel extends GastHeim{

    constructor(nutzer) {
        super();
        this.nutzer = nutzer;
    }

    getLabel() {
        return getDisplayName(this.nutzer);
    }

    getVerein(){
        if(this.nutzer){
            return this.nutzer.verein;
        }
        return null;
    }

    istNutzerBeteiligt(nutzerId){
        if(this.nutzer){
            return this.nutzer.id === nutzerId;
        }
        return false;
    }
    
    istGleich(gastHeimEinzel){
        if(this.nutzer){
            return this.nutzer.id === gastHeimEinzel.nutzer.id;
        }
        return false;
    }
}