import React from 'react'
import SuccessMessage from "../_components/notifications/SuccessMessage";
import ErrorMessage from "../_components/notifications/ErrorMessage";
import {Form} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Button} from "react-bootstrap";
import {setErrorState} from "../util/ErrorUtil";
import {punktspielService} from "../_services/punktspielService";

const FormItem = Form.Item;

class PunktspielComment extends React.Component
{
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      punktspielKommentar: {},
      punktspielKommentarLoaded: false,
      successMsg: '',
      errorMsg: '',
      kommentar: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
    };
      this.onSave = this.onSave.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    if (this._isMounted) {
        this.loadPunktspielKommentar();
    }
  }

  loadPunktspielKommentar() {
    
    const mannschaftId = this.props.mannschaft.id;

    punktspielService.getPunktspielKommentarForMannschaft(mannschaftId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              punktspielKommentar: response,
              punktspielKommentarLoaded: true
            });

            this.setState({
              kommentar: {
                value: response.kommentar
              }
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
            this.setState({
              punktspielKommentarLoaded: true
            });
          }
        });
  }
  
  onSave(){

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    var saveRequest = {
      id: this.state.punktspielKommentar.id,
      mannschaft: this.props.mannschaft,
      kommentar: this.state.kommentar.value
    };

    punktspielService.savePunktspielKommentar(saveRequest)
        .then(response => {
          this.setState({
            punktspielKommentar: response,
            successMsg: "Der Kommentar wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });

        }).catch(error => {
      if (this._isMounted) {
        setErrorState(error, this);
      }
      document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }
  
  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }
  
  render()
  {
    const { punktspielKommentar } = this.state;
    
    return (
        <React.Fragment>
        <div id="punktspiel_kommentar">

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }

          {!!this.state.punktspielKommentarLoaded && 
              <div>
                <Form
                    onFinish={this.onSave}
                    className="punktspielKommentar-edit-form"
                    initialValues={{
                      "kommentar": this.state.punktspielKommentar.kommentar
                    }}>
                  <FormItem
                      name="kommentar"
                      validateStatus={this.state.kommentar.validateStatus}
                      help={this.state.kommentar.errorMsg}>

                    <TextArea
                        size="large"
                        name="kommentar"
                        maxLength={1500}
                        rows={7}
                        style={{width: '100%'}}
                        className="small-responsive-field"
                        value={this.state.kommentar.value}
                        onChange={(event) => this.handleInputChange(event)}
                    />
                  </FormItem>
                  <Button type="submit"
                          style={{float: 'right', marginTop: '1em', marginBottom: '1em'}}
                          size="large"
                          className="myLargeBtn"
                          disabled={this.state.disableSubmitButton}>Speichern</Button>
                </Form>
              </div>
          }
        </div>
        </React.Fragment>
    );
  }
}


export default PunktspielComment;
