import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Home from '../Home/Home.js'
import 'antd/dist/antd.css';

import PrivateRoute from '../_components/PrivateRoute.js';

import UserProfil from '../user/profil/profil.js';

import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Invitation from '../user/invite/invitation';
import InvitationNew from '../user/invite/invitation_new';

import LoadingIndicator from '../common/LoadingIndicator';
import MannschaftEdit from "../verwaltung/mannschaft/mannschaft_edit";
import Punktspieluebersicht from "../punktspiel/punktspieluebersicht";
import PunktspielSpielerEdit from "../punktspiel/punktspielSpielerEdit";
import SpielerProfil from "../user/profil/spielerProfil";
import InvitationAccept from "../user/invite/InvitationAccept";
import SignupConfirm from "../user/signup/SignupConfirm";
import PunktspielEdit from "../verwaltung/mannschaft/punktspiel_edit";
import PunktspielNew from "../verwaltung/mannschaft/punktspiel_new";
import TrainingEdit from "../training/uebersicht/training_edit";
import TrainingNew from "../training/uebersicht/training_new";
import TrainingsuebersichtByMannschaft from "../training/uebersicht/trainingsuebersicht_byMannschaft";
import TrainingsuebersichtByTraining from "../training/uebersicht/trainingsuebersicht_byTraining";
import MannschaftNew from "../verwaltung/verein/mannschaft/mannschaft_new";
import ForgottenPassword from "../user/forgottenPassword/ForgottenPassword";
import ResetPassword from "../user/forgottenPassword/ResetPassword";
import Impressum from "../Impressum/Impressum";
import ProfilEinstellungen from "../user/profil/profil_einstellungen";
import MatchProfil from "../user/profil/match/matchProfil";
import SpielerMatchProfil from "../user/profil/match/spielerMatchProfil";
import JederGegenJeden from "../match/match_statistik/jederGegenJeden";
import MatchFilterStatistikTabPanel from "../match/match_filter_view/MatchFilterStatistikTabPanel";
import MatchFilterTabPanel from "../match/match_filter_view/MatchFilterTabPanel";
import Doppelaufstellung from "../wettkampf/vorbereitung/doppel/aufstellung/doppelaufstellung";
import NutzerDoppelEinstellungen
  from "../wettkampf/vorbereitung/doppel/einstellung/nutzerDoppelEinstellungen";
import FormTst from "../wettkampf/vorbereitung/doppel/aufstellung/form_test";
import MyDnD from "../wettkampf/vorbereitung/doppel/aufstellung/dnd/MyDnD";
import MyListDnD from "../wettkampf/vorbereitung/doppel/aufstellung/dndlist/MyListDnD";
import MyDnD3 from "../wettkampf/vorbereitung/doppel/aufstellung/dnd3/MyDnD3";
import MyDnD3_Touch from "../wettkampf/vorbereitung/doppel/aufstellung/dnd3_touch/MyDnD3_Touch";
import MyDnD4 from "../wettkampf/vorbereitung/doppel/aufstellung/dnd4_custom/MyDnD4";
import JederGegenJedenTraining from "../match/match_statistik/jederGegenJedenTraining";
import VereinOverview from "../verwaltung/verein/verein_overview";
import AbstimmungNew from "../verwaltung/verein/abstimmung/abstimmung_new";
import AbstimmungEdit from "../verwaltung/verein/abstimmung/abstimmung_edit";
import AbstimmungUebersicht from "../abstimmung/abstimmungUebersicht";
import AbstimmungTeilnehmerEdit from "../abstimmung/abstimmungTeilnehmerEdit";
import AbstimmungTeilnehmerAdd from "../verwaltung/verein/abstimmung/abstimmung_teilnehmer_add";
import AbstimmungOptionEdit from "../verwaltung/verein/abstimmung/abstimmung_option_edit";
import AbstimmungOptionNew from "../verwaltung/verein/abstimmung/abstimmung_option_new";
import TerminNew from "../verwaltung/verein/termin/termin_new";
import TerminEdit from "../verwaltung/verein/termin/termin_edit";
import TerminTeilnehmerAdd from "../verwaltung/verein/termin/termin_teilnehmer_add";
import TerminUebersichtForNutzer from "../termine/terminUebersichtForNutzer";
import VereinNew from "../verwaltung/verein/verein_new";
import TrainingsplanungRunden from "../training/planung/trainingsplanung_runden";

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
  }
  
  
  render() {
    if(this.state.isLoading) {
      return <LoadingIndicator />
    }
    return (

            <div className="container-fluid parent-container">
              <Switch>
                <Route exact path='/impressum' component={Impressum}/>
                <Route exact path='/' render={(props) => <Home currentUser={this.props.currentUser} {...props} />}/>

                <PrivateRoute exact  path='/training/uebersicht/:mannschaftsId' component={TrainingsuebersichtByMannschaft}/>
                <PrivateRoute exact  path='/training/uebersicht/id/:trainingstagId' component={TrainingsuebersichtByTraining}/>
                <PrivateRoute path='/training/new/:trainingseinheitId/:spielerId' component={TrainingNew}/>
                <PrivateRoute path='/training/edit/:trainingSpielerId' component={TrainingEdit}/>
                
                {/*Trainingsplanung*/}
                <PrivateRoute exact path='/training/planung/:trainingsId' component={JederGegenJedenTraining}/>


                <PrivateRoute path='/punktspiele/uebersicht/:mannschaftsId' component={Punktspieluebersicht}/>

                <PrivateRoute path='/punktspiele/edit/spieler/:spielerId' component={PunktspielSpielerEdit}/>

                <PrivateRoute exact path='/user/profil/spieler' component={UserProfil}/>
                <PrivateRoute exact path='/user/profil/einstellungen' component={ProfilEinstellungen}/>
                <PrivateRoute exact path='/user/profil/matches' component={MatchProfil}/>


                <PrivateRoute path='/spieler/edit/:spielerId' component={SpielerProfil}/>
                <PrivateRoute path='/spieler/matches/:nutzerId' component={SpielerMatchProfil}/>
                <Route path="/spieler/matches-doppel/:nutzerId"
                       render={(props) => <SpielerMatchProfil isDoppel={true} {...props} />}></Route>
                
                {/*wettkampf*/}
                <PrivateRoute exact path='/wettkampf/doppelaufstellung/doppelbewertung/:vereinId' component={NutzerDoppelEinstellungen}/>
                <PrivateRoute path='/wettkampf/doppelaufstellung/:mannschaftsId' component={Doppelaufstellung}/>
                {/*<PrivateRoute path='/wettkampf/gegner/:vereinId' component={GegnerUebersicht}/>*/}
                
                
                <PrivateRoute path='/verwaltung/mannschaft/edit/:mannschaftsId' component={MannschaftEdit}/>

                {/* # Verein-Verwaltung */}
                <PrivateRoute path='/verwaltung/verein/edit/:vereinId' component={VereinOverview}/>
                <PrivateRoute path='/verwaltung/verein/new' component={VereinNew}/>
                
                {/* ##Mannschaften */}
                <PrivateRoute path='/verwaltung/verein/mannschaft/new/:vereinId' component={MannschaftNew}/>


                {/* ##Abstimmungen */}
                <PrivateRoute path='/verwaltung/verein/abstimmung/new/:vereinId' component={AbstimmungNew}/>
                <PrivateRoute path="/verwaltung/verein/abstimmung/edit/:abstimmungId" component={AbstimmungEdit}></PrivateRoute>
                <PrivateRoute path="/verwaltung/verein/abstimmung/:abstimmungId" component={AbstimmungTeilnehmerAdd}></PrivateRoute>
                <PrivateRoute path="/verwaltung/verein/abstimmungoption/edit/:abstimmungOptionId" component={AbstimmungOptionEdit}></PrivateRoute>
                <PrivateRoute path="/verwaltung/verein/abstimmungoption/new/:abstimmungId" component={AbstimmungOptionNew}></PrivateRoute>
                
                <PrivateRoute path="/abstimmung/view/:abstimmungId" component={AbstimmungUebersicht}></PrivateRoute>
                <PrivateRoute path="/abstimmungsantwort/edit/teilnehmer/:teilnehmerId" component={AbstimmungTeilnehmerEdit}></PrivateRoute>

                {/* ##Termine */}
                <PrivateRoute path='/verwaltung/verein/termin/new/:vereinId' component={TerminNew}/>
                <PrivateRoute path="/verwaltung/verein/termin/edit/:terminId" component={TerminEdit}></PrivateRoute>
                <PrivateRoute path="/verwaltung/verein/termin/:terminId" component={TerminTeilnehmerAdd}></PrivateRoute>

                <PrivateRoute path="/termine/nutzer" component={TerminUebersichtForNutzer}></PrivateRoute>


                
                <PrivateRoute path='/test/form' component={FormTst}/>
                <PrivateRoute path='/test/dnd' component={MyDnD}/>
                <PrivateRoute path='/xyz' component={MyDnD3}/>
                <PrivateRoute path='/abc' component={MyDnD3_Touch}/>
                <PrivateRoute path='/dnd4' component={MyDnD4}/>
                <PrivateRoute path='/test/my-list' component={MyListDnD}/>


                
                
                
                
                <Route path="/login"
                       render={(props) => <Login onLogin={this.props.onLogin} {...props} />}></Route>

                <Route path="/signup"
                       render={(props) => <Signup onSignup={this.props.onSignup} {...props} />}></Route>

                <Route path="/invitation/confirm" component={InvitationAccept}/>
                {/*Achtung!!! die URL muss signupconfirm lauten. Da darf kein Slash mit rein nach signp (signup/confirm), da er sonst vorher zu SignUp umleitet.*/}
                <Route path="/signupconfirm" component={SignupConfirm}/>

                <Route path="/password/forgotten"
                       render={(props) => <ForgottenPassword {...props} />}></Route>
                <Route path="/password/reset"
                       render={(props) => <ResetPassword {...props} />}></Route>
                
                <PrivateRoute path="/invitation/accept/:mannschaftsId" component={Invitation}></PrivateRoute>
                <PrivateRoute path="/invitation/new/:mannschaftsId" component={InvitationNew}></PrivateRoute>

                <PrivateRoute path="/punktspiele/edit/:punktspielId" component={PunktspielEdit}></PrivateRoute>
                <PrivateRoute path="/punktspiele/new/:mannschaftId" component={PunktspielNew}></PrivateRoute>

                <PrivateRoute path="/matches/statistik" component={JederGegenJeden}></PrivateRoute>
                <PrivateRoute path="/matches/bilanzen" component={MatchFilterStatistikTabPanel}></PrivateRoute>
                <PrivateRoute path="/matches/list" component={MatchFilterTabPanel}></PrivateRoute>
                
              </Switch>
            </div>
    );
  }
}
export default Main;