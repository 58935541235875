

import {setErrorStateWithMessage} from "../util/ErrorUtil"

import React from 'react';
import moment from "moment";
import {getCurrentUser} from "../_services";
import {terminService} from "../_services/termin/terminService";

class TerminUebersichtForNutzer extends React.Component
{
  _isMounted = false;
  
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: {},
      termine: [],
    };

    this.setErrorState = this.setErrorState.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps, nextState) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadLoggedInUser();
  }



  loadTermine(nutzerId) {
    terminService.getAllByNutzer(nutzerId)
    .then(response => {
      if (this._isMounted) {
        this.setState({
          termine: response
        });
      }
    })
  .catch(error => this.setState({hasError: error}));
  }

  loadLoggedInUser(){
    getCurrentUser()
        .then(response => {
          const loggedInUser = response;
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }

          if(loggedInUser.id !== undefined){
            this.loadTermine(loggedInUser.id);
          }
          
        })
        .catch(error => this.setState({hasError: error}));
  }

  setErrorState(fehlertext){
    setErrorStateWithMessage(fehlertext, this);
  }
  
  render()
  {

    const { termine } = this.state;
    
    return (
        <div id="termine">
          <h1>Termine</h1>

          {!!termine && termine.length <= 0 &&
              <span>Momentan stehen keine Termine an.</span>
          }
          
          {!!termine && termine.length > 0 &&
              <table className="table table-hover">
                <thead>
                  <th style={{textAlign: 'left'}}>Datum</th>
                  <th style={{textAlign: 'left'}}>Name</th>
                  <th style={{textAlign: 'left'}}>Ort</th>
                  <th style={{textAlign: 'left'}}>Beschreibung</th>
                </thead>
                <tbody>
                
                {!!termine && termine.length > 0 &&
                <React.Fragment>
                  {termine.map((termin) =>
                      <TerminZeile  key={termin.id}
                                    termin={termin}/>
                  )}
                </React.Fragment>
                }

                </tbody>
              </table>
          }
          
          
        </div>
    );
  }
}

// start::TerminZeile[]
class TerminZeile extends React.Component{
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const termin = this.props.termin;
    var formattedDate = moment(termin.datum).format('DD.MM.YYYY HH:mm');
    
    return (
        <tr>
          <td style={{textAlign: 'left'}}>{formattedDate + ' Uhr'}</td>
          <td style={{textAlign: 'left'}}>{termin.name}</td>
          <td style={{textAlign: 'left'}}>{termin.ort}</td>
          <td style={{textAlign: 'left', whiteSpace: 'pre-wrap'}}>{termin.beschreibung}</td>
        </tr>
    )

  }
}
// end::TerminZeile[]


export default TerminUebersichtForNutzer;
