import React from 'react';

import './doppelaufstellung.css';
import {Col, Form, Input, Row, Select} from "antd";
import {Option} from "antd/es/mentions";

const FormItem = Form.Item;

class FormTst extends React.Component {
    _isMounted = false;


    constructor(props) {
        super(props);

        this.state = {
            spieler1: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },
            spieler2: {
                value: '',
                validateStatus: '',
                errorMsg: null
            },

        };

    }
    
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    

    render() {

        return (
            <React.Fragment>

                <Form>

                    <FormItem
                        label="Spieler 1"
                        name="spieler1"
            
                        className="doppelaufstellung-form-item"
                        rules={[{
                            required: true,
                            message: 'Bitte wähle einen Spieler aus'
                        }]}
                        validateStatus={this.state.spieler1.validateStatus}
                        help={this.state.spieler1.errorMsg}>

                        <Input type="text"
                               style={{width: '50px'}}
                               size="large"
                               name="spieler1"/>
                        
                    </FormItem>
                    
                </Form>

                <Form>
                    <Row>
                        <Col span={24} style={{ border: "solid 1px red" }}>
                            <Form.Item label="Label">
                              
                                    <Select style={{ width: "100%" }} placeholder="Select a person">
                                        <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="tom">Tom</Option>
                                    </Select>
                               
                               
                            </Form.Item>
                        </Col>
                    </Row>,                   
                </Form>
            </React.Fragment>
        )
    }
}



export default FormTst;