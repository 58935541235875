import { request } from '../util/APIUtils';
import {API_BASE_URL} from "../constants";

export const trainingsService = {
    getTrainingById,
    getAllTrainingstageByMannschaftsId,
    getTrainingByMannschaftsId,
    getZukuenftigeTrainingsByMannschaftsId,
    getStammSpielerAntwortenAggregated,
    getErsatzSpielerAntwortenAggregated,
    getTrainingSpielerByTrainingIdAndSpielerId,
    getTrainingSpielerById,
    editTrainingSpieler,
    createTrainingSpieler,
    saveKommentarOnTrainingstag,
    saveTrainingSpieler,
    deleteTrainingSpielerById
};

export function getTrainingById(trainingId)
{
    return request({
        url: API_BASE_URL + "/trainingstag/getById?trainingstagId=" + trainingId,
        method: 'GET'
    });
}

export function getAllTrainingstageByMannschaftsId(mannschaftsId)
{
    return request({
        url: API_BASE_URL + "/trainingstag/getAllByMannschaftsId?mannschaftsId=" + mannschaftsId,
        method: 'GET'
    });
}

//liefert nur das NAECHSTE Training
export function getTrainingByMannschaftsId(mannschaftsId)
{
    return request({
        url: API_BASE_URL + "/trainingstag/getNaechstesByMannschat?mannschaftsId=" + mannschaftsId,
        method: 'GET'
    });
}

export function getZukuenftigeTrainingsByMannschaftsId(mannschaftsId)
{
    return request({
        url: API_BASE_URL + "/trainingstag/getZukuenftigeByMannschaftsId?mannschaftsId=" + mannschaftsId,
        method: 'GET'
    });
}

export function deleteTrainingstagById(trainingsId)
{
    return request({
        url: API_BASE_URL + "/trainingstag/delete/"  + trainingsId,
        method: 'DELETE'
    });
}

// erstellt fuer die uebergebenen Nuetzerliste jeweils Spieler und fuegt sie der uebergebenen Mannschaft hinzu
export function createNewTrainingstermine(trainingstageCreateRequest) {
    return request({
        url: API_BASE_URL + "/trainingstag/saveNewTrainingstermine",
        method: 'POST',
        body: JSON.stringify(trainingstageCreateRequest)
    });
}

//erstellt einen neuen Trainingstag
export function createNewTrainingstermin(trainingstag) {
    return request({
        url: API_BASE_URL + "/trainingstag/saveNewTrainingstermin",
        method: 'POST',
        body: JSON.stringify(trainingstag)
    });
}

//erstellt einen neuen Trainingstag
export function saveKommentarOnTrainingstag(trainingstagRequest) {
    return request({
        url: API_BASE_URL + "/trainingstag/saveKommentar",
        method: 'POST',
        body: JSON.stringify(trainingstagRequest)
    });
}

export function getStammSpielerAntwortenAggregated(trainingsId)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/getAntwortenAggregated?trainingsId=" + trainingsId + "&onylErsatz=" + false,
        method: 'GET'
    });
}

export function getErsatzSpielerAntwortenAggregated(trainingsId)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/getAntwortenAggregated?trainingsId=" + trainingsId + "&onylErsatz=" + true,
        method: 'GET'
    });
}

//Die TrainignSpieler-Logik
export function getTrainingSpielerByTrainingIdAndSpielerId(trainingsId, spielerId)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/getByTrainingAndSpieler?trainingsId=" + trainingsId + "&spielerId=" + spielerId,
        method: 'GET'
    });
}

export function getTrainingSpielerById(trainingSpielerId)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/getById?id=" + trainingSpielerId,
        method: 'GET'
    });
}


export function editTrainingSpieler(trainingSpielerId, antwort)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/edit?trainingSpielerId=" + trainingSpielerId + "&antwort=" + antwort,
        method: 'GET'
    });
}

export function createTrainingSpieler(trainingsId, spielerId, antwort)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/new?trainingstagId=" + trainingsId+ "&spielerId=" + spielerId + "&antwort=" + antwort,
        method: 'GET'
    });
}

export function saveTrainingSpieler(saveTrainingSpielerRequest)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/save",
        method: 'POST',
        body: JSON.stringify(saveTrainingSpielerRequest)
    });
}

export function deleteTrainingSpielerById(trainingSpielerId)
{
    return request({
        url: API_BASE_URL + "/trainingspieler/delete/"  + trainingSpielerId,
        method: 'DELETE'
    });
}
