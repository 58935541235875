import React from 'react';
import {setErrorState} from "../../../util/ErrorUtil";
import {userService} from "../../../_services";
import MatchProfilComponent from "./match_profil_component";

//Die Ansicht eines beliebigen Spielers.
class SpielerMatchProfil extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            nutzer: {}
        };
        this.loadNutzer = this.loadNutzer.bind(this);
    }

    loadNutzer() {
        userService.getNutzerById(this.props.match.params.nutzerId)
            .then(response => {
                if (this._isMounted) {
                    this.setState({nutzer: response});

                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });


    }
    
    componentDidMount() {
        this._isMounted = true;
        this.loadNutzer();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadNutzer();
        }
    }


    render() {
        
        const { nutzer } = this.state;
        
        let defaultActiveKey = 'einzel';
        if(this.props.isDoppel){
            defaultActiveKey = 'doppel';
        }
        
        return (
            <React.Fragment>
            {!!nutzer && !!nutzer.id &&
                <div>
                    <h1>{nutzer.vorname} {nutzer.nachname}</h1>
                    <span>
                        <MatchProfilComponent nutzerId={nutzer.id} defaultActiveKey={defaultActiveKey}/>
                    </span>
                </div>
            }
            </React.Fragment>
        );
    }
}

export default SpielerMatchProfil;