import '../matchEditAndListTabPaneView.css';
import React from 'react'
import {withRouter} from "react-router-dom";
import {getCurrentUser, userService} from "../../_services";
import {setErrorState} from "../../util/ErrorUtil";
import MatchFilterInline from "./match_filter_inline";

class MatchListFilterComponent extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      verein: {},
      loggedInUser: {},
      successMsg: '',
      errorMsg: ''
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
      // Reset State
      this.loadData();
    }
  }

  loadData(){
    this.loadLoggedInUser();
  }


  loadLoggedInUser(){
    getCurrentUser()
        .then(response => {
          if (this._isMounted) {
            this.setState({loggedInUser: response});
          }

          //weil bei getCurrentUser nur mail und co gefuellt sind
          userService.getNutzerById(response.id)
              .then(response => {
                if (this._isMounted) {
                  if(response && response.verein){
                    this.setState({verein: response.verein});
                  }
                }
              })
              .catch(error => {
                setErrorState(error);
              });
          
        })
        .catch(error => {
          setErrorState(error);
        });
  }
  
  render()
  {
    return (
        <div>
          <h2>Match-Filter</h2>
          {!!this.state.verein && !!this.state.verein.id &&
              <div>
                <MatchFilterInline verein={this.state.verein}
                                   loggedInUser={this.state.loggedInUser}
                                   isDoppel={this.props.isDoppel}
                                   isStatistikView={false}/>
              </div>
          }

        </div>
    );
  }
}

export default withRouter(MatchListFilterComponent);
