import React from 'react';
import {spielerService} from "../../_services/spielerService";
import SpielerProfilComponent from "./spielerProfilComponent";
import SpielerProfilAllgemeineEinstellungen from "./spielerProfilAllgemeineEinstellungen";
import SpielerProfilRechteEinstellungen from "./spielerProfilRechteEinstellungen";

// tag::SpielerMannschaftsProfil[]
// Das Profils eines Spielers der Mannschaft
// Das hier ist nur die Seite, die zur URL
//  /spieler/edit/:spielerId
// gehoert (wird z.B. von mannschaft_mitglieder_edit.js gebraucht, fuer den Edit-Button (onEdit-Funktion),
// da bei einem onclick auf einen Button keine React-Komponente erstellt und auch keine Properties ubergeben 
// werden koennen
//Die Admin Ansicht
class SpielerProfil extends React.Component
{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            formIsValid: true,
            spielerId: this.props.match.params.spielerId,
            spieler: {}
        };

    }

    componentDidMount()
    {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps, nextState) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
        if(this.state.spieler !== nextState.spieler && this.state.spieler.id !== nextState.spieler.id) {
            // Reset State
            this.loadData();
        }
    }

    loadData(){
        this.loadSpieler();
    }

    loadSpieler(){
        
        if(this.state.spieler && this.state.spieler.id){
            
        }
        const spielerId = this.state.spielerId;
        
        spielerService.getSpielerById(spielerId)
            .then(responseSpieler => {
                if (this._isMounted) {
                    this.setState({spieler: responseSpieler});
                }
            })
            .catch(errorSpieler => {
                if (this._isMounted) {
                    this.setState({hasError: errorSpieler})
                }
            });
    }

    handleSelectInputChange(value, name) {
        this.setState({
            [name] : {
                value: value
            },
            //wir setzen hier wegen der Schnellerfassung die SuccessMeldung auf null
            //sonst bleibt sie stehen, falls ich ein zweites Match erfassen will, aber ein Eingabefehler mache
            successMsg: null,
        });
    }
   
    render()
    {
        const { spieler } = this.state;
        
        return (
            <React.Fragment>
                {!!spieler && !!spieler.id &&
                    <div>
                        <SpielerProfilAllgemeineEinstellungen key={'allgemeineEinstellungen' + spieler.id} nutzer={spieler.nutzer} showAdminFields={true}/>
                        <SpielerProfilRechteEinstellungen key={'rechteEinstellungen' + spieler.id} nutzer={spieler.nutzer} showAdminFields={true}/>
                    </div>
                }

                
                {!!spieler && !!spieler.id &&
                    <SpielerProfilComponent key={spieler.id} spieler={spieler} showAdminFields={true}/>
                }
            </React.Fragment>

        );
    }

}

export default SpielerProfil;