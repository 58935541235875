import {notification} from "antd";

export function setErrorState(error, comp) {
  //Die Abfrage hat z.B. bei InvitationConfirm nicht funktioniert
  // if (comp._isMounted) {
    comp.setState({
      hasError: error,
      errorMsg: error.message
    });
  // }

  if(error.status === 401){
    //TODO die history war iwie ab und zu mal undefined
    //keine Ahnung wieso
    if(comp.props.history){
      comp.props.history.push("/login");
      notification.info({
        message: 'Mannschatsplanung',
        description: 'Bitte melden Sie sich an, um fortzuahren.'
      });

      comp.setState({
        hasError: error,
        errorMsg: 'Bitte melden Sie sich an, um fortzuahren.'
      });
    }

    return;
  }
  
  // notification.error({
  //   message: 'Mannschatsplanung',
  //   description: error.message || 'Sorry! Etwas ist schief gelaufen. Versuche es Bitte noch einmal!'
  // });
}

export function setErrorStateWithMessage(errorMessage, comp) {
  comp.setState({
    hasError: true,
    errorMsg: errorMessage
  });
}
