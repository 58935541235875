import React from 'react'

import {vereinsService} from "../../../_services/vereinsService";
import AbstimmungEditComponent from "./abstimmung_edit_component";
import {setErrorState} from "../../../util/ErrorUtil";


class AbstimmungNew extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      verein: {}
    };
    this.loadVerein = this.loadVerein.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.match.params.vereinid !== nextProps.match.params.vereinid) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    //const mannschaft = this.props.mannschaft;
    if (this._isMounted) {
      this.loadVerein();
    }
  }

  loadVerein() {

    //url Parameter
    const vereinId = this.props.match.params.vereinId;
    
    vereinsService.getById(vereinId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              verein: response
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  
  render()
  {
    const verein = this.state.verein;
    
    return (
        <React.Fragment>
        {!!verein && !!verein.id &&
          <AbstimmungEditComponent verein={verein}/>
         }
        </React.Fragment>
    );
  }

}

export default AbstimmungNew;


