 export default class Match {
    constructor(matchJson) {
        this.id = matchJson.id;
        this.ergebnis = matchJson.ergebnis;
        this.datum = matchJson.datum;
        this.gewinner = matchJson.gewinner;

    }
    
     getHeim() {
         return this.spielpaarung.heim;
     }

     getGast() {
         return this.spielpaarung.gast;
     }

     istNutzerBeteiligt(nutzerId){
         return this.spielpaarung.istNutzerBeteiligt(nutzerId);
     }
}
