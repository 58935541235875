import './match_profil_component.css';
import React from 'react';
import {setErrorState} from "../../../util/ErrorUtil";
import {matchEinzelService} from "../../../_services/matchEinzelService";
import {getCurrentUser} from "../../../_services";
import {matchDoppelService as matchDopelService} from "../../../_services/matchDoppelService";
import MatchProfilUebersichtComponent from "./match_profil_uebersicht_component";
import {Tab, Tabs} from "react-bootstrap";
import {matchMapper} from "../../../_mapper/matchMapper";

//DIe Profilansicht. ANsicht, wo ich mein eigenes Profil anschauen kann.
class MatchProfilComponent extends React.Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            einzel: {
                anzahlMatchesInsgesamt: 0,
                gewonnen: [],
                verloren: [],
                unentschieden: []
            },
            doppel: {
                anzahlMatchesInsgesamt: 0,
                gewonnen: [],
                verloren: [],
                unentschieden: []
            }
        };
        
        this.loadMatches = this.loadMatches.bind(this);
    }

    loadLoggedInUser() {
        getCurrentUser()
            .then(response => {
                if (this._isMounted) {
                    this.setState({user: response});

                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });
    }

    
    loadMatches(){
        //Einzel
        matchEinzelService.findMatchesByNutzerId(this.props.nutzerId)
        .then(response => {
            if (this._isMounted) {
                this.setState({
                    einzel: {
                        anzahlMatchesInsgesamt: response.anzahlMatchesInsgesamt,
                        gewonnen: matchMapper.mapEinzelList(response.gewonnen),
                        verloren: matchMapper.mapEinzelList(response.verloren),
                        unentschieden: matchMapper.mapEinzelList(response.unentschieden)
                    }
                });
            }
        })
        .catch(error => {
            if (this._isMounted) {
                setErrorState(error, this);
            }
        });
        
        //Doppel
        matchDopelService.findMatchesByNutzerId(this.props.nutzerId)
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        doppel: {
                            anzahlMatchesInsgesamt: response.anzahlMatchesInsgesamt,
                            gewonnen: matchMapper.mapDoppelList(response.gewonnen),
                            verloren: matchMapper.mapDoppelList(response.verloren),
                            unentschieden: matchMapper.mapDoppelList(response.unentschieden)
                        }
                    });

                }
            })
            .catch(error => {
                if (this._isMounted) {
                    setErrorState(error, this);
                }
            });
    }
    
    loadData(){
        this.loadMatches();
        this.loadLoggedInUser(); 
    }
    
    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.loadData();
        }
    }

    
    render() {

        
        const { einzel, doppel } = this.state;

        let defaultActiveKey = 'einzel';
        if(this.props.defaultActiveKey){
            defaultActiveKey = this.props.defaultActiveKey;
        }
        
        return (

            <Tabs defaultActiveKey={defaultActiveKey} id="matches-tab-pane" className="matches-tab-pane" style={{fontSize: '1rem'}}>
                <Tab eventKey="einzel" title="Einzel" className="tab-header">
                    <MatchProfilUebersichtComponent
                        matches={einzel}
                        isDoppel={false}
                        loggedInUser={this.state.user}/>
                </Tab>
                <Tab eventKey="doppel" title="Doppel">
                    <MatchProfilUebersichtComponent
                        matches={doppel}
                        isDoppel={true}
                        loggedInUser={this.state.user}/>
                </Tab>
            </Tabs>

        );
    }
}

export default MatchProfilComponent;