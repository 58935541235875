import React from 'react'
import AbstimmungEditComponent from "./abstimmung_edit_component";

class AbstimmungEdit extends React.Component
{
  _isMounted = false;
  
  
  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  render()
  {
    const abstimmungId = this.props.match.params.abstimmungId;
    
    return (
        <AbstimmungEditComponent abstimmungId={abstimmungId}/>
    );
  }

}

export default AbstimmungEdit;


