import React from 'react'
import AbstimmungOptionEditComponent from "./abstimmung_option_edit_component";

class AbstimmungOptionEdit extends React.Component
{
  _isMounted = false;
  
  
  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  render()
  {
    const abstimmungOptionId = this.props.match.params.abstimmungOptionId;
    
    return (
        <AbstimmungOptionEditComponent abstimmungOptionId={abstimmungOptionId}/>
    );
  }

}

export default AbstimmungOptionEdit;


