import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';

import {
  deleteAbstimmungById,
  getAllAbstimmungenForVerein
} from "../../../_services/abstimmung/abstimmungService";
import {setErrorState} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";

class AbstimmungList extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      abstimmungen: {},
      successMsg: '',
      errorMsg: ''
    };
    this.deleteAbstimmung = this.deleteAbstimmung.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.mannschaft !== nextProps.mannschaft) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    if (this._isMounted) {
      this.loadAllAbstimmungenForVerein();
    }
  }

  loadAllAbstimmungenForVerein(){
    const vereinId = this.props.verein.id;
    
    getAllAbstimmungenForVerein(vereinId)
        .then(response => {
          if (this._isMounted) {
            this.setState({
              abstimmungen: response
            });
          }
        })
        .catch(error => {
          setErrorState(error, this);
        });
  }

  deleteAbstimmung(abstimmung) {
    deleteAbstimmungById(abstimmung)
        .then(response => {
          if (this._isMounted) {
            this.setState({successMsg: 'Die Abstimmung wurde erfolgreich gelöscht'});
            this.loadData();
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }
  
  render()
  {
    const { abstimmungen } = this.state;
    
    return (
        <div id="abstimmung_liste">
          <h2>Abstimmungen</h2>

          {!!this.state.successMsg &&
          <SuccessMessage message={this.state.successMsg}/>
          }

          {!!this.state.errorMsg &&
          <ErrorMessage message={this.state.errorMsg}/>
          }
          
          {!!abstimmungen && !!abstimmungen.length <= 0 &&
          <p style={{paddingTop: '1.5rem'}}>Es wurden noch keine Abstimmungen erstellt.</p>
          }
          
          {!!abstimmungen && !!abstimmungen.length > 0 &&
          <table className="table table-hover">
              <tbody>
                {abstimmungen.map((abstimmung) =>
                      <AbstimmungZeile key={abstimmung.id} abstimmung={abstimmung} onDeleteAbstimmung={this.deleteAbstimmung}/>
                  )}
              </tbody>
            </table>
          }
        </div>
    );
  }
}


// tag::SpielerZeile[]
// Die Punktspiel-Zeile fuer einen bestimmten Spieler
class AbstimmungZeile extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {abstimmung: this.props.abstimmung};

  }

  componentDidMount()
  {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  

  onEdit(e, abstimmungId) {
    e.preventDefault();

    //keine Ahnung warum hier nicht this.props.history.push funktioniert
    window.location.href = '/verwaltung/verein/abstimmung/edit/' + abstimmungId;
  }
  

  
  render()
  {
    const { abstimmung } = this.state;

    return (
      <React.Fragment>
        {!!abstimmung && !!abstimmung.id &&
          <tr key={abstimmung.id}>
            <td style={{textAlign: 'left'}}>{abstimmung.name}</td>
            <td style={{textAlign: 'left'}}>{abstimmung.aktiv ? "aktiv" : "inaktiv" }</td>
            
            {/*bearbeiten ist hier nicht moeglich. Nur jeder Spieler selbst kann seine Infos bearbeiten*/}
            <td>
              {/*bearbeiten Button*/}
              <Button
                  className="btn btn-primary edit-button"
                  onClick={e => this.onEdit(e, abstimmung.id)}>
                <FontAwesomeIcon icon={faPen}  />
              </Button>
              
            {/*loeschen Button*/}
              <Button
                  className="btn btn-secondary delete-button"
                  onClick={()=>this.props.onDeleteAbstimmung(abstimmung)}>
                <FontAwesomeIcon icon={faTrashAlt}  />
              </Button>
            </td>
          </tr>
        }
      </React.Fragment>

    );
  }

}

export default AbstimmungList;


