import React from 'react';
import MatchList from "../match_components/match_list_component";


class DetailedMatchOverview extends React.Component {
    _isMounted = false;


    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            //this.loadData();
        }
    }

    onEditMatch(){
        //do nothing
        //der Button wird gar nicht erst angezeigt
    }

    render() {

        const statTable = {
            padding: '4px 9px',
            fontSize: '14px',
        };

        const statErgebnisCell = {
            paddingLeft: '30px',
            fontSize: '16px',
        };


        const matches = this.props.matches;

        const matchesInsgesamt = matches.anzahlMatchesInsgesamt;
        const matchesGewonnen = matches.gewonnen.length;
        const matchesVerloren = matches.verloren.length;

        let einzelOrDoppelLabel = 'Einzel';
        if(this.props.isDoppel){
            einzelOrDoppelLabel = 'Doppel';
        }

        return (
            <React.Fragment>

                <div>
                    <h2>Match-Bilanz</h2>
                    <table style={statTable}>
                        <tbody>
                        {/*Einzel*/}
                        <tr>
                            <td>{einzelOrDoppelLabel} isngesamt</td>

                            <td style={statErgebnisCell}>
                                {matchesInsgesamt}
                            </td>
                        </tr>
                        <tr>
                            <td>{einzelOrDoppelLabel}-Bilanz</td>

                            <td style={statErgebnisCell}>
                                {matchesGewonnen}:{matchesVerloren}
                            </td>

                        </tr>

                        </tbody>
                    </table>

                    <br/>

                    <h2>{this.props.displayName} Siege</h2>
                    {!!matches.gewonnen && !!matches.gewonnen.length > 0 &&
                    <MatchList loggedInUser={this.props.loggedInUser}
                               matches={matches.gewonnen}
                               onEditMatch={this.onEditMatch}
                               isOnlyView={true}/>
                    }
                    {!!matches.gewonnen.length <= 0 &&
                    <p>Schade, du hast noch keine {einzelOrDoppelLabel} gewonnen. Versuche es weiter!</p>
                    }

                    <h2>{this.props.displayName} Niederlagen</h2>
                    {!!matches.verloren && !!matches.verloren.length > 0 &&
                    <MatchList loggedInUser={this.props.loggedInUser}
                               matches={matches.verloren}
                               onEditMatch={this.onEditMatch}
                               isOnlyView={true}/>
                    }
                    {!!matches.verloren.length <= 0 &&
                    <p>Hurra! Du hast noch keine {einzelOrDoppelLabel} verloren :)</p>
                    }

                    {!!matches.unentschieden && !!matches.unentschieden.length > 0 &&
                    <React.Fragment>
                        <h2>{this.props.displayName} Unentschiedenen</h2>
                        <MatchList loggedInUser={this.props.loggedInUser}
                                   matches={matches.unentschieden}
                                   onEditMatch={this.onEditMatch}
                                   isOnlyView={true}/>
                    </React.Fragment>
                    }

                </div>

            </React.Fragment>
        );
    }
}

export default DetailedMatchOverview;