import React from 'react'
import {Button} from 'react-bootstrap';

import {Form, Input} from "antd";
import {abstimmungOptionService} from "../../../_services/abstimmung/abstimmungOptionService";
import {setErrorState} from "../../../util/ErrorUtil";
import SuccessMessage from "../../../_components/notifications/SuccessMessage";
import ErrorMessage from "../../../_components/notifications/ErrorMessage";

const FormItem = Form.Item;


class AbstimmungOptionEditComponent extends React.Component
{
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      disableSubmitButton: false,
      abstimmungOptionId: this.props.abstimmungOptionId,
      abstimmungOption: {},
      successMsg: '',
      errorMsg: '',
      option: {
        value: '',
        validateStatus: 'success',
        errorMsg: null
      },
    };
    this.loadAbstimmungOption = this.loadAbstimmungOption.bind(this);
    this.loadData = this.loadData.bind(this);
    //this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }
  
  componentDidMount()
  {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    //this did the trick: damit aendert sich jetzt auch das Kind, wenn das Parent sich andert.
    if(this.props.punktspielId !== nextProps.punktspielId) {
      // Reset State
      this.loadData();
    }
  }
  
  loadData(){
    if (this._isMounted) {
      if(!this.isNewAnsicht()){
        this.loadAbstimmungOption();
      }
    }
  }

  loadAbstimmungOption() {
    
    const abstimmungOptionId = this.state.abstimmungOptionId;

    abstimmungOptionService.getById(abstimmungOptionId)
        .then(response => {

          if (this._isMounted) {
            this.setState({
              abstimmungOption: response
            });

            this.setState({
              option: {
                value: response.datum
              }
            });
          }
        })
        .catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
        });
  }

  //lieert die Mannschaft, je nach dem, um was fuer eine Ansicht es sich gerade handelt
  //(die Bearbeiten Ansicht, eines bereits existenten Punktspiels oder die Neu Ansicht, zum neu Erstellen eines Punktspiels)
  getAbstimmung(){
    //dann handelt es sich um den Edit-Fall
    if(this.state.abstimmungOptionId > 0){
      return this.state.abstimmungOption.abstimmung;
    }else{
      //neu erstellen Fall
      return this.props.abstimmung;
    }
  }
  
  isNewAnsicht(){
    //dann handelt es sich um den Edit-Fall
    if(this.state.abstimmungOptionId > 0){
      return false;
    }else{
      //neu erstellen Fall
      return true;
    }
  }
  
  onSave() {

    this.setState({
      successMsg: null,
      errorMsg: null,
      disableSubmitButton: true
    });

    document.body.classList.add('busy-cursor');
    
    var abstimmung = this.getAbstimmung();
    
    const saveRequest = {
      id: this.state.abstimmungOptionId,
      abstimmung: abstimmung,
      option: this.state.option.value
    };
    

    abstimmungOptionService.saveAbstimmungOption(saveRequest)
        .then(response => {
          this.setState({
            successMsg: "Die Abstimmungsoption wurde erfolgreich gespeichert!"
          });

          document.body.classList.remove('busy-cursor');
          this.setState({
            disableSubmitButton: false
          });
          
          this.setState({
            abstimmungOption: response,
            abstimmungOptionId: response.id
          });

        }).catch(error => {
          if (this._isMounted) {
            setErrorState(error, this);
          }
       document.body.classList.remove('busy-cursor');
      this.setState({
        disableSubmitButton: false
      });
    });
  }

  onBack() {
    var abstimmungId = 0;
    //dann handelt es sich um den Edit-Fall
    if(this.state.abstimmungOptionId > 0){
      abstimmungId = this.state.abstimmungOption.abstimmung.id;
    }else{
      //neu erstellen Fall
      abstimmungId = this.props.abstimmung.id;
    }
    
    window.location.href = '/verwaltung/verein/abstimmung/edit/' + abstimmungId;
  }

  handleInputChange(event) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName] : {
        value: inputValue
      }
    });
  }
  
  render()
  {
    const layout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };

    const { abstimmungOption } = this.state;

    const isNewAnsicht = this.isNewAnsicht();
    
    return (
        <React.Fragment>
          <div className="abstimmungoption-edit-container">

            {!!this.state.successMsg &&
            <SuccessMessage message={this.state.successMsg}/>
            }

            {!!this.state.errorMsg &&
            <ErrorMessage message={this.state.errorMsg}/>
            }

            {(isNewAnsicht || (!!abstimmungOption && !!abstimmungOption.id)) ?
            <div className="form-container">
              <Form {...layout} className="punktspiel-edit-form"
                    initialValues={{
                      "option": this.state.abstimmungOption.option
                    }}>
                
                <FormItem
                    labelAlign="left"
                    label="Option"
                    name="option"
                    rules={[{
                      required: true,
                      message: 'Bitte gib eine Option an' }]}
                    hasFeedback
                    validateStatus={this.state.option.validateStatus}
                    help={this.state.option.errorMsg}>
                  <Input
                      size="large"
                      name="option"
                      autoComplete="on"
                      placeholder=""
                      value={this.state.option.value}
                      onChange={(event) => this.handleInputChange(event)}
                  />
                </FormItem>
              </Form>


            </div>
                :
                null
            }
            
            <div id="btn-container" className={"form-container"}>
              <Button type="submit"
                      style={{float: 'right'}}
                      size="large"
                      className="myLargeBtn"
                      onClick={() => this.onSave()}
                      disabled={this.state.disableSubmitButton}>Speichern</Button>
              
            <Button type="submit"
                    style={{float: 'left'}}
                    size="large"
                    className="myLargeBtn"
                    onClick={() => this.onBack()}>Zurück</Button>
            </div>
          </div>
        </React.Fragment>

    );
  }

}

export default AbstimmungOptionEditComponent;


