import React from 'react';

import MyApp from "./MyApp";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {GridProvider} from "./GridContext";
import {wettkampfService} from "../../../../../_services/wettkampfService";


class MyDnD4 extends React.Component {
    _isMounted = false;


    constructor(props) {
        super(props);

        this.state = {
            spieler1: 21,
            spieler2: 15,
            spieler3: 4,
            spieler4: 20,
            spieler5: 23,
            spieler6: 16,
            doppelaufstellungen: []
        };

    }

    componentDidMount() {
        this._isMounted = true;
        this.handleSubmit();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit() {
        console.log('MyDnD3 - handleSubmit: ');
        
        wettkampfService.getAllPossibleDoppelaufstellungen(
            this.state.spieler1,
            this.state.spieler2,
            this.state.spieler3,
            this.state.spieler4,
            this.state.spieler5,
            this.state.spieler6)
            .then(response => {
                if (this._isMounted) {
                    this.setState({doppelaufstellungen: response});
                    console.log('MyDnD3 - response: ');
                    console.log(response);
                }
            })
            .catch(error => {
                if (this._isMounted) {
                    this.setState({hasError: error})
                }
            });
    }



    render() {
        //const { items, moveItem } = useContext(GridContext);

        return (
            <React.Fragment>
            {!!this.state.doppelaufstellungen && !!this.state.doppelaufstellungen.length > 0 &&
            <DndProvider backend={HTML5Backend}>
                <GridProvider doppelaufstellungen={this.state.doppelaufstellungen}>
                    <MyApp />
                </GridProvider>
            </DndProvider>
            }
            </React.Fragment>
        )
    }
}



export default MyDnD4;