import React from 'react';
import {useHistory} from "react-router";
import {Button} from "react-bootstrap";

const BackButton = () => {
    const history = useHistory();

    return (
        <div>
            <Button type="submit"
                    style={{float: 'left'}}
                    size="large"
                    className="myLargeBtn"
                    onClick={history.goBack}>Zurück</Button>
        </div>
    );
};

export default BackButton;