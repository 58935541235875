import GastHeim from "./GastHeim";
import {getDisplayName} from "../../_helpers/displayHelper";

export default class GastHeimDoppel extends GastHeim{

    constructor(nutzer1, nutzer2) {
        super();
        this.nutzer1 = nutzer1;
        this.nutzer2 = nutzer2;
    }

    getLabelNuter1(){
        return this.getLabelForNutzer(this.nutzer1);
    }

    getLabelNuter2(){
        return this.getLabelForNutzer(this.nutzer2);
    }
    
    getLabelForNutzer(nutzer) {
        return getDisplayName(nutzer, true);
    }

    getVerein(){
        if(this.nutzer1){
            return this.nutzer1.verein;
        }

        if(this.nutzer2){
            return this.nutzer2.verein;
        }
        
        return null;
    }
    istNutzerBeteiligt(nutzerId){
        return this.nutzer1.id === nutzerId || this.nutzer2.id === nutzerId;
    }
    
    istGleich(gastHeimDoppel){

        if(this.nutzer1 === null || this.nutzer2 === null){
            return false;
        }
        
        return this.istNutzerBeteiligt(gastHeimDoppel.nutzer1.id) && 
            this.istNutzerBeteiligt(gastHeimDoppel.nutzer2.id);
    }
    
}