export default class DoppelPaarung {

    constructor(doppelPaarungJson) {
        this.spieler1 = doppelPaarungJson.spieler1;
        this.spieler2 = doppelPaarungJson.spieler2;
        this.positionsSumme = doppelPaarungJson.positionsSumme;
        this.bewertung = doppelPaarungJson.bewertung;
    }

    getDisplayLabel() {
        return this.spieler1.nickname + ' ' + this.spieler2.nickname;
    }
    
    getBackgroudnColor(){
        if(this.bewertung === -3){
            //return '#BF112E';
            return 'doppelaufstellung-bewertung-minus3';
        }

        if(this.bewertung === -2){
            //return '#FF5759';
            return 'doppelaufstellung-bewertung-minus2';
        }

        if(this.bewertung === -1){
            //return '#FF939D';
            return 'doppelaufstellung-bewertung-minus1';
        }

        if(this.bewertung === 3){
            //return '#35F04E';
            return 'doppelaufstellung-bewertung-3';
        }

        if(this.bewertung === 2){
            //return '#9EF0A9';
            return 'doppelaufstellung-bewertung-2';
        }

        if(this.bewertung === 1){
            //return '#DEFFDB';
            return 'doppelaufstellung-bewertung-1';
        }
        return 'doppelaufstellung-bewertung-0';
    }

    //ob das ein Doppel der beidedn uebergebenen Spieler ist.
    isDoppelPaarungForSpieler(spielerId1, spielerId2) {
        if(this.spieler1.id === spielerId1){
            if(this.spieler2.id === spielerId2){
                return true;
            }
        }

        if(this.spieler2.id === spielerId1){
            if(this.spieler1.id === spielerId2){
                return true;
            }
        }
        
        return false;
    }

    containsSpieler(spielerId) {
        return this.spieler1.id === spielerId || this.spieler2.id === spielerId;
    }
    
    isVollstaendig(){
        if(this.spieler1 && this.spieler1.id > 0){
            if(this.spieler2 && this.spieler2.id > 0){
                return true;
            }
        }
        return false;
    }
    
    getSpielerForUnvollstaendigePaarung(){
        if(this.spieler1 && this.spieler1.id > 0){
            return this.spieler1;
        }
        if(this.spieler2 && this.spieler2.id > 0){
            return this.spieler2;
        }
        return null;
    }

    
    matches(doppelpaarung_filter){
        if(!((doppelpaarung_filter.spieler1Id > 0) || (doppelpaarung_filter.spieler2Id > 0))){
            //doppelpaarung filter is empty. So it matches!
            return true;
        }

        if (doppelpaarung_filter.isVollstaendig()) {
            return this.isDoppelPaarungForSpieler(doppelpaarung_filter.spieler1Id, doppelpaarung_filter.spieler2Id);
        }

        const spieler = doppelpaarung_filter.getSpielerForUnvollstaendigePaarung();

        return this.containsSpieler(spieler);
    }
}